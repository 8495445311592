import { PackageStore } from "features/manage/PackageStore";
import { Package } from "features/packages/Package";
import "features/packages/PackageIndex-style";

import { Routing } from "lib/Routing";
import "./ManageIndexElement-style";

class ManageIndexElement extends HTMLElement {
    protected static path = ["manage"];
    public static tag = "manage-index";
    private packages = new PackageStore().all();
    

    connectedCallback(){
        this.classList.add("package-index");
        this.innerHTML = this.view(this.packages);
    }

    private view = (packages: Package[]) => `${packages.map(thePackage => this.packageView(thePackage)).join("")}`;

    private packageView = (thePackage: Package) => `
    <a href="/manage/${thePackage.slug}" route>
        <card>
            <header style="background-image:url(${thePackage.logo})"></header>
            <div class="title">${thePackage.name}</div>
            <div class="description">${thePackage.description}</div>
        </card>
    </a>`;
}

customElements.define(ManageIndexElement.tag, ManageIndexElement);

Routing.instance.onChange(async route => {
    if (route.path[0] === "manage" && !route.path[1]) {
        Routing.instance.replace("/manage/notifications")
    }
});