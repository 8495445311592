// import ResizeObserver from "resize-observer-polyfill";

/*
    This is used to simplify having a scrollable element within a flex context
*/
export class ScrollContainerHeightObserver {
    constructor(private scrollElement: HTMLElement) {
        scrollElement.style.height = `${this.calculateHeight()}px`;
    }

    private calculateHeight = () => {
        const parent = this.scrollElement.parentElement;
        const height = parent.clientHeight;

        const children = Array.from(parent.childNodes)
            .filter(m => m instanceof HTMLElement)
            .filter(m => m !== this.scrollElement) as HTMLElement[];

        const occupied = children.map(child => child.clientHeight).reduce( (a, b) => a + b, 0);

        console.log("occupied", occupied);
        const remaining = height - occupied;
        console.log("remain", remaining);
        return remaining;
    }
}