import "./TagInputElement-style";
import { TagStore } from "./TagStore";

/**
 * A smarter input element for adding and suggesting tags
 * @element tag-input
 * @fires added - when a new tag is added by entering or selection
 */
class TagInputElement extends HTMLElement {
    private input: HTMLInputElement;
    private suggest: HTMLElement;
    private addButton: HTMLButtonElement;

    connectedCallback() {
        this.innerHTML = this.view();
        this.input = this.querySelector("input");
        this.suggest = this.querySelector("[name=suggest]");
        this.addButton = this.querySelector("[name=add]");

        this.input.addEventListener("input", this.showSuggest);
        this.input.addEventListener("input", this.activateAddButton);
        this.input.addEventListener("focus", this.showSuggest);
        this.addEventListener("mouseover", this.mouseOverHandler);
        this.addEventListener("keydown", this.keyHandler);
        document.addEventListener("click", this.globalClickHandler);
        this.addEventListener("click", this.clickHandler);
        this.addButton.addEventListener("click", this.addButtonHandler);
    }

    disconnectedCallback() {
        this.input?.removeEventListener("input", this.showSuggest);
        this.input?.removeEventListener("input", this.activateAddButton);
        this.input?.removeEventListener("focus", this.showSuggest);
        this.removeEventListener("mouseover", this.mouseOverHandler);
        this.removeEventListener("keydown", this.keyHandler);
        document.removeEventListener("click", this.globalClickHandler);
        this.removeEventListener("click", this.clickHandler);
        this.addButton?.removeEventListener("click", this.addButtonHandler);
    }

    private addButtonHandler = () => {
        if (this.addButton.hasAttribute("disabled"))
            return;

        const tag = TagStore.formatTag(this.input.value);
        this.input.value = "";
        this.dispatchEvent(new CustomEvent("added", { detail: tag } ));
        this.addButton.setAttribute("disabled", "");
    }

    private activateAddButton = () => {
        const show = this.input.value.length >= 1;
        this.addButton.toggleAttribute("disabled", !show);
    }

    private clickHandler = (event: MouseEvent) => {
        if (!(event.target instanceof(Element)))
            return;

        const suggest = event.target.closest(".suggestion");
        if (!suggest)
            return;

        suggest.remove();
        this.dispatchEvent(new CustomEvent("added", { detail: suggest.getAttribute("tag") } ));

    }

    private globalClickHandler = (event: MouseEvent) => {
        if (!(event.target instanceof(Element)))
            return;

        if (this.contains(event.target))
            return;

        this.suggest.innerHTML = "";
    }

    private keyHandler = (event: KeyboardEvent) => {
        const move = (delta: number) => {
            const suggests = Array.from(this.querySelectorAll(".suggestion"));
            const selected = this.querySelector(".suggestion[selected]");

            let target = 0;
            if (!selected) {
                if (delta === -1)
                    target = suggests.length-1;
            }
            else
                target = suggests.indexOf(selected)+delta;

            if (target < 0)
                target = 0;
            
            if (target > suggests.length-1)
                target = suggests.length-1;

            
            suggests[target]?.setAttribute("selected", "");
            selected?.removeAttribute("selected");

        }

        if (event.key === "ArrowUp") {
            move(-1);
        }

        if (event.key === "ArrowDown") {
            move(1);
        }

        if (event.key === "Escape") {
            this.suggest.innerHTML = "";
            this.input.value = "";
            this.input.focus();
        }

        if (event.key === "Enter") {
            const selected = this.querySelector(".suggestion[selected]"); 
            let tag = "";
            if (selected) {
                tag = selected.getAttribute("tag");
            } else if (this.input.value !== "") {
                tag = TagStore.formatTag(this.input.value);
                this.input.value = "";
            }

            if (tag && tag !== "") {
                this.dispatchEvent(new CustomEvent("added", { detail: tag } ));
            }

            this.input.focus();
            this.input.select();      
            this.activateAddButton();      
        }
    }

    private mouseOverHandler = (event: MouseEvent) => {
        if (!(event.target instanceof(Element)))
            return;

        const targetElement = event.target as Element;

        const suggest = targetElement.closest(".suggestion");

        if (suggest) {
            this.querySelector(".suggestion[selected]")?.removeAttribute("selected");
            suggest.setAttribute("selected", "");
        }
    }

    private showSuggest = async () => {
        const result = await TagStore.instance.suggest(this.input.value);
        this.suggest.innerHTML = this.suggestView(result);
    }

    private suggestView = (tags: string[]) =>  tags.map(tag => 
        `<div class=suggestion tag="${tag}">
            <div>${tag}</div>
        </div>`
    ).join("");
    private view = () => `<input type="text" enterkeyhint="done" placeholder="Add tag ..."><button type=button disabled name=add>Add</button><div name=suggest></div>`;
}

customElements.define("tag-input", TagInputElement);