import "./ManageMembershipElement-style"
import { AppContextStore } from "features/application-context/AppContextStore";

import { BaseManagePackageElement } from "features/manage/BaseManagePackageElement";
import { Formatting } from "lib/Formatting"
import { PaymentStore } from "features/payment/PaymentStore"
import { Routing } from "lib/Routing"
import { TaskStore } from "lib/Tasks";

export class ManageMembershipElement extends BaseManagePackageElement {
    protected static path = ["manage", "membership"];
    protected slug = "membership";
    public static tag = "manage-membership";
    private payment;

    async setupDOM(){
        
        const renewElement = this.querySelector("[name=renew]") as HTMLInputElement;

        
        if (renewElement){
            renewElement.addEventListener("change", async () => {
                await PaymentStore.instance.setAutoRenew(renewElement.checked);
            })
        }
            
    }

    protected async initialize() {
        this.payment = await TaskStore.instance.add(PaymentStore.instance.all());
    }

    protected detailView = () => `
        <div class="box membership-details">
            <header>Current membership</header>
            <div class=description>
                With premium membership you get rid of the ads and support the future development of linkstacks.
            </div>

            <div class=line>
                <label>Current level</label>
                <div>${Formatting.capitalize(this.payment.currentLevel)}</div>
            </div>


            ${this.payment.memberSince ? `
                    <div class=line>
                        <label>Since</label>
                        <div>${Formatting.shortDateNamedMonth(this.payment.memberSince)}</div>
                    </div>
                ` : ""}
            
            ${this.payment.nextRenewal ? `
                    <div class=line>
                        <label>Renewal</label>
                        <div>${Formatting.shortDateNamedMonth(this.payment.nextRenewal)}</div>
                    </div>
                ` : ""}

            <div class="action space">
                ${this.payment.currentLevel === "basic" && window.location.protocol !== "file:" ? `<form method=get action=/price><button class=power>Upgrade to premium</button></form>` : ""}
                ${this.payment.currentLevel !== "basic" ? `
                        <input type=checkbox id=renew name=renew${this.payment.autoRenew ? " checked" : ""} />
                        <label for=renew>Auto-renew subscription</label>
                    ` : ""}
            </div>
        </div>
        
            ${this.payment.transactions && this.payment.transactions.length > 0 ? `
                <div class="box transactions">

                    <header>Payment history</header>
                    

                    <div class="transaction header">
                        <div>Date</div>
                        <div class=valid-from>from</div>
                        <div class=valid-to>to</div>
                        <div>Amount</div>
                        <div class=card>Card</div>
                    </div>
                    
                    ${this.payment.transactions.map(transaction => `
                        <div class=transaction> 
                            <div>${Formatting.shortDate(transaction.created)}</div>
                            <div class=valid-from>${Formatting.shortDate(transaction.validFrom)}</div>
                            <div class=valid-to>${Formatting.shortDate(transaction.validTo)}</div>
                            <div>${transaction.capturedAmount / 100} ${transaction.currency}</div>
                            <div class=card>${transaction.card ? transaction.card.scheme : ""} xxxx-xxxx-xxxx-${transaction.card ? transaction.card.last4 : ""}</div>
                        </div>
                        `).join("")}
                </div>
            ` : ""}
        `;
}

customElements.define(ManageMembershipElement.tag, ManageMembershipElement);


Routing.instance.onChange(async route => {
    if (route.path[0] === "manage" && route.path[1] === "membership") {
        await AppContextStore.instance.present("", document.createElement("manage-membership"), "Manage", "Membership");
        document.title = "Manage Membership - Linkstacks";
    }
});