import "./MembershipPromotionElement-style";
import { AuthenticationStore } from "features/authentication";

import { BrowserInfo } from "lib/Browser";
import { DialogController } from "features/dialog";
import { UrlProvider } from "lib/UrlProvider";
import { UserPreferenceStore, UserPreferenceFlag } from "features/user-preferences/UserPreferenceStore";

class MembershipPromotionElement extends HTMLElement {
    private urlProvider = new UrlProvider();
    private _phoneMediaMatch = window.matchMedia("screen and (max-width: 850px)");
    
    resizeHandler = async () => {
        const user = await AuthenticationStore.instance.currentAccount();
        const promotion = await UserPreferenceStore.instance.isSet(UserPreferenceFlag.Promotion);
            
        if (user && promotion && !BrowserInfo.isIOSApp() && !this._phoneMediaMatch.matches)
            document.body.setAttribute("promote", "");
        else
            document.body.removeAttribute("promote");
        
    }

    connectedCallback() {
        this.innerHTML = this.view();
        this.classList.add("promote-membership");
        AuthenticationStore.instance.onStateChanged(this.resizeHandler);
        this._phoneMediaMatch.addListener(this.resizeHandler);

        if (BrowserInfo.isApp())
            this.addEventListener("click", async event => {
                event.preventDefault();

                const option = await new DialogController().prompt({
                    caption: "Subscribe",
                    message: `Please go to our website to subscribe.`,
                    options: ["Cancel", "Go to website"]
                })
                if (option === "Go to website")
                    window.open(`${this.urlProvider.root}/price`);
            });
    }

    disconnectedCallback() {
        AuthenticationStore.instance.offStateChanged(this.resizeHandler);
        this._phoneMediaMatch.removeListener(this.resizeHandler);
    }

    private view = () => `
        <div>Support Linkstacks by <a href="/price">subscribing</a> today</div>
    `;
}


customElements.define("membership-promotion", MembershipPromotionElement);