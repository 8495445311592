import "./MyFeedStoreTests";
import "./FeedViewerElement";
import "./FeedHeaderElement";
import "./EntryControlElement";
import "./FeedActivator";
import "./FeedControlElement";
import "./FloatNavigationElement";


export interface EntryMarker {
    feedId: string;
    sequence: number;
}



export interface FeedRequest
{
    category: string;
    feedId: string;
    from?: string;
    refresh?: boolean;
}

export interface PagedResult<TItem> {
    items: TItem[];
    previousPageMarker: string;
}

export interface FeedCriteria {
    url?: string;
    previousPageMarker?: string;
    refresh?: boolean; 
}

export interface FeedDicoveryResult {
    status: FeedDiscoveryStatus;
    items: FeedReference[];
    feed?: Feed;
}

export enum FeedDiscoveryStatus{
    invalidUrl = "invalidUrl",
    success = "success",
    unableToFindServer = "unableToFindServer",
    unableToFindFeed = "unableToFindFeed"
}

export interface FeedReference{
    url: string;
    title: string;
}

export interface Feed {
    id: string;
    title: string;
    description: string;
    site: Link;
    url: string;
    entries: Entry[];
    unread?: number;
    previousPageMarker?: string;
    state: State,
    isCategory: boolean;
    categories: Category[];
    icon: string;
    image?: string;
    viewSettings: FeedViewSettings;
}

export interface FeedViewSettings {
    hideReadEntries?: boolean;
}

export type CategoryType = "feed";

export interface Category {
    slug: string;
    title: string;
    categorytype?: CategoryType;
}

export enum State {
    notLoaded = "notLoaded",
    loaded = "loaded",
    loadTried = "loadTried"
    } 

export interface Link {
    url: string;
    title: string;
}

export interface Entry {
    id: string;
    feedId: string;
    sequence: number;
    publishDate: string;
    url: string;
    summary: string;
    description: string;
    title: string;
    slug: string;
    read: boolean;
    image: string;
    picoImage?: string;
    picoColor?: string;
}