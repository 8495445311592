class ServiceWorkerInstaller {
    constructor() {

        if (navigator && navigator.serviceWorker && window.location.protocol === "https:") {
            navigator.serviceWorker.register("/serviceworker", {
                scope: "/"
            }).then(reg => {
                //console.log('◕‿◕', reg);
            }, err => {
                //console.log('ಠ_ಠ', err);
            });
        }
       
    }

}
const swi = new ServiceWorkerInstaller()