import { UrlProvider } from "lib/UrlProvider";
import { Pipeline, Request } from "lib/HTTP";

export class MetadataStore {
    private _urlProvider = new UrlProvider();
    private static _instance = new MetadataStore();
    static get instance() : MetadataStore { return MetadataStore._instance; }
    private constructor() {}
    
    async getMetadata(url: string, abortController: AbortController): Promise<Metadata> {
        const action = `${this._urlProvider.root}/api/metadata`;
        const params = new URLSearchParams();
        params.set("_token", Request.getSourceToken());
        params.set("url", url);
        
        let abortSignal: AbortSignal;
        if (abortController)
            abortSignal = abortController.signal;

        const response = await new Pipeline().fetch(`${action}?${params.toString()}`, Request.get.authenticate().setSignal(abortSignal));
        
        if (abortController && abortController.signal && abortController.signal.aborted)
            return;

        if (response.ok) {
            return await response.json() as Metadata;
        }

        return undefined;
    }
}

export interface FeedSpecification{
    url: string;
    title: string;
    type: string;
    icon: string;
}

export interface Metadata {
    title?: string;
    preview?: string;
    detectedTags?: string[];
    feeds: FeedSpecification[];
    url: string;
    
}