import { TopMenuStore } from "features/top-panel/TopMenuStore";
import { SidebarActivator } from "features/side-bar/SidebarActivator";
import "./SearchPanelElement";

//tslint:disable-next-line: no-unused-variable
class SearchPanelActivator {
    //tslint:disable-next-line: no-unused-variable
    private static _instance = new SearchPanelActivator();

    private constructor() {
        TopMenuStore.instance.append("search", {
            title: "Search",
            name: "search", 
            side: "right",
            icon: require("!!raw-loader!image/search-20-v2.svg"),
            handler: this.togglePanel,
            priority: 1
        });
    }

    private togglePanel = async () => {
        await SidebarActivator.toggleElementAsync("search-panel", this.createElement)
    }

    private createElement = async () => {
        //const search = await import(/* webpackChunkName: "search-panel", webpackPrefetch: true */ "./SearchPanelElement");
        //const search = 
        //return new search.SearchPanelElement() as HTMLElement;
        return document.createElement("search-panel");
    }
}