import "./FavoritesWidgetElement-style";
import * as cardsStyle from "../../../../styles/grid-cards-module.less"
import * as widgetStyle from "../Widgets-module.less";
import { BaseWidget, PreferSize } from "../BaseWidget";
import { BoxLoaderElement } from "features/box-loader";
import { FavoriteViewElement } from "./FavoriteViewElement";
import { Link } from "features/links";
import { cityline } from "features/cityline";
import { wrapGrid } from "animate-css-grid";

class FavoritesWidgetElement extends BaseWidget {
    private container: HTMLElement;
    private forceGridAnimation: () => void;
    private favoriteElements: { [id: string]: FavoriteViewElement; } = {};

    public get preferredWidth(): PreferSize {
        return "wide";
    }
    
    async connectedCallback() {
        super.connectedCallback();
        this.innerHTML = this.initialView();
        this.container = this.querySelector("[name=container]");
        this.container.append(new BoxLoaderElement());

        window.requestIdleCallback(async () => {
            await this.render();
            cityline.addEventListener("favorites", this.favoriteHandler);


            setTimeout(() => {
                const { forceGridAnimation } = wrapGrid(this.container, { duration: 800 });
                this.forceGridAnimation = forceGridAnimation;
            }, 3000);
           
        }, { timeout: 100 });
    }

    disconnectedCallback() {
        cityline.removeEventListener("favorites", this.favoriteHandler);
    }

    private favoriteHandler = (event: CustomEvent<Link[]>) => {
        const incomingIds = event.detail.map(m => m.id);
        const goneIds = Object.keys(this.favoriteElements).filter(id => incomingIds.indexOf(id) === -1);

        for(const goneId of goneIds) {
            const existing = this.favoriteElements[goneId];
            if (existing) {
                existing.remove();
                delete this.favoriteElements[goneId];
            }
        }

        for(const link of event.detail) {
            const existing = this.favoriteElements[link.id];
            if (existing) {
                existing.favorite = link;
            } else {
                const element = new FavoriteViewElement();
                this.container.insertAdjacentElement("afterbegin", element);
                this.favoriteElements[link.id] = element;
                element.favorite = link;
            }
        }

        this.checkEmpty();

        if (this.forceGridAnimation)
            this.forceGridAnimation();
    }

    private checkEmpty = () => {
        const emptyElement = this.querySelector("[name=empty]");
        if (Object.keys(this.favoriteElements).length === 0) {
            this.toggleAttribute("empty", true);

            if (emptyElement)
                return;

            this.container.insertAdjacentHTML("afterbegin", this.emptyView());
        } else {
            this.toggleAttribute("empty", false);

            if (emptyElement)   
                emptyElement.remove();
        }
    }

    private initialView = () => `
        <header>
            <h3>Favorites</h3>
        </header>
        <div class="${cardsStyle.container}" name=container></div>
    `;

    private emptyView = () => `
        <div name=empty class="${widgetStyle.emptyText}">${require("!!raw-loader!image/favorite.svg")} a link to get started</div>
    `

    private render = async () => {
        const favorites = await cityline.getFrame<Link[]>("favorites");

        this.container.innerHTML = "";
        for(const favorite of favorites) {
            const element = new FavoriteViewElement();
            this.container.append(element);
            this.favoriteElements[favorite.id] = element;
            element.favorite = favorite;
        }
        
        this.checkEmpty();
    }
}

customElements.define("favorites-widget", FavoritesWidgetElement);