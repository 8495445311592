import { DOMLite } from "lib/DOMLite"

declare var require: any;

export interface NotifyHandle {
    success?: boolean;
    hide?: () => void;
}

export interface NotificationMessage {
    title: string;
    body?: string;
    icon?: string;
    showSeconds?: number;
    showLoader?: boolean;
    showOk?: boolean;
}


export class NotifyViewController {
    private static slots: number[] = [];
    private okIcon = () => `<svg xmlns="http://www.w3.org/2000/svg" height="64" viewBox="0 0 54 54" width="64"><path d="M3.672 34.114l15.95 13.3L50.857 7.58" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>`;
    private static _style = require("!!raw-loader!less-loader!./NotifyStyle");

    showWeb(message: NotificationMessage): NotifyHandle {

        const isolatedElement = DOMLite.createIsolated();

        let slot = 0;
        while (NotifyViewController.slots.indexOf(slot) !== -1)
            slot++;

        NotifyViewController.slots.push(slot);

        isolatedElement.innerHTML = `
            <style>${NotifyViewController._style}</style>

            <div id=ls1289 style="width:100%;height:100%">                   
                <div class="notify${message.showLoader || message.showOk || message.icon ? " has-img" : ""}${message.body ? "" : " title-only"}" style="pointer-events:auto;bottom:${(slot * 102) + 30}px">
                    ${message.showLoader ? "<div class=loader></div>" : ""}
                    ${message.showOk ? this.okIcon() : ""}
                    ${message.icon ? `<img src="${message.icon}" />` : ""}
                    <header>${message.title}</header>
                    ${message.body ? `<div class=body>${message.body}</div>` : ""}
                </div>
            </div>
        `;


   
        const t = window.setTimeout;

        const notify = <HTMLElement>isolatedElement.getElementsByClassName("notify")[0];

        t(() => {
            notify.classList.add("active");
        }, 100);

        const handler = {
            hide: () => {
                if (!notify) // can be dismissed by user
                    return; 

                notify.classList.add("hide");
                notify.style.bottom = `${(slot * 102) + 130}px`;
                NotifyViewController.slots = NotifyViewController.slots.filter(i => i !== slot);

                t(() => {
                    const p = notify.parentElement;
                    if (p && p.parentElement)
                        p.parentElement.removeChild(p);

                    if (isolatedElement && isolatedElement.remove)
                        isolatedElement.remove();
                }, 1100);
            }
        };

        
        notify.addEventListener("click", handler.hide); 

        if (message.showSeconds)
            t(handler.hide, message.showSeconds * 1000);

        return handler;
    }
}
