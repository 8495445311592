import { cityline } from "features/cityline";
import EventTarget from "@ungap/event-target";
import { ClassTypename, Typename } from "lib/Types";
import { Pipeline, Request } from "lib/HTTP";
import { UrlProvider } from "lib/UrlProvider";

interface EventMap {
    "tag-frequencies-changed": { [id: string]: number; };
}

@ClassTypename("TagStore")
export class TagStore {
    private eventTarget = new EventTarget();
    private allTags: string[];
    private tagFrequency: { [id: string]: number; } = {};
    private static _instance = new TagStore();
    static get instance() { return TagStore._instance; } 
    private initializer: Promise<void>;

    private constructor() {
        this.initializer = this.doInitialize();
        setTimeout(async () => await this.initializer);
    }

    addEventListener<K extends Extract<keyof EventMap, string>>(type: K, listener: (this: EventSource, ev: CustomEvent<EventMap[K]>) => any, options?: boolean | AddEventListenerOptions) {
        return this.eventTarget.addEventListener(type, listener, options);
    }
    
    removeEventListener<K extends Extract<keyof EventMap, string>>(type: K, listener: (this: EventSource, ev: CustomEvent<EventMap[K]>) => any, options?: boolean | AddEventListenerOptions) {
        return this.eventTarget.removeEventListener(type, listener, options);
    }
    
    private emit<K extends Extract<keyof EventMap, string>, T extends EventMap>(type: K, value: T[K]) {
        return this.eventTarget.dispatchEvent(new CustomEvent(type, { detail:  Object.assign({}, value, { [Typename] : TagStore[Typename] })})); 
    }
    

    private async doInitialize() {
        this.allTags = await cityline.getFrame("user-tags");
        cityline.addEventListener("user-tags", (event: CustomEvent<string[]>) => {
            this.allTags = event.detail;
        });

        this.updateTagFrequencies(await cityline.getFrame("tags-frequency"));
        cityline.addEventListener("tags-frequency", (event: CustomEvent<{ [id: string]: number; }>) => this.updateTagFrequencies(event.detail));
    }

    async tagFrequencites() : Promise<{ [id: string]: number; }> {
        await this.initializer;
        return this.tagFrequency;
    }

    private updateTagFrequencies = (tagFrequencies: { [id: string]: number; }) => {
        this.tagFrequency = {};
        for(const key of Object.keys(tagFrequencies)) {
            const tag = this.allTags.filter(m => m.toLowerCase() === key)[0];
            if (tag)
                this.tagFrequency[tag] = tagFrequencies[key];
            else
                this.tagFrequency[key] = tagFrequencies[key];
        }
        this.emit("tag-frequencies-changed", this.tagFrequency);
    }

    async suggest(source: string, excludes: string[] = []): Promise<string[]> {
        await this.initializer;

        source = source.toLocaleLowerCase();
        let matches = this.allTags;

        if (source !== "") {
            matches =  matches
                .filter(tag => excludes.map(m => m.toLowerCase()).indexOf(tag.toLowerCase()) === -1)
                .filter(tag => tag.toLowerCase().indexOf(source.toLowerCase()) !== -1);
        }

        return matches; //.slice(0, 15);
    }

    static formatTag(source: string): string {
        if (!source || source === "")
            return "";

        source = source.replace(/[^0-9a-z #]/gi, "");

        return source;
        //return `${source[0].toLocaleUpperCase()}${source.length > 1 ? source.substring(1, source.length).toLocaleLowerCase() : ""}`;
    }

    async autoTag(linkId: string) : Promise<string[]> {
        const params = new URLSearchParams();
        params.append("linkId", linkId);

        const response = await new Pipeline().fetch(`${new UrlProvider().root}/api/tags?${params.toString()}`, Request.get.authenticate());
        const result = await response.json() as AutoTagResponse;

        return result.tags || [];
    }
}


interface AutoTagResponse {
    tags: string[];
}