import "./break-login-theme";
import { AdaptivePopup } from "features/authentication/AdaptivePopup";
import { LoginResponse, SignInStatus, ValidationMessage } from "./Model";

import { AuthenticationStore } from "./AuthenticationStore";

class UserLoginElement extends AdaptivePopup {
    async connectedCallback() {
        this.setAttribute("slide", "");
        requestAnimationFrame(() => {
            requestAnimationFrame(() => this.removeAttribute("slide"));
        });
        
        this.innerHTML = this.view();
        super.connectedCallback();


        const loginButton = this.querySelector("[name=login]") as HTMLButtonElement;
        loginButton.addEventListener("click", async () => await this.login());

        
        this.querySelector("[name=register]")
            .addEventListener("click", () => AdaptivePopup.addAndslide(document.createElement("user-registration-2")));

        this.querySelector("[name=forgot-password]")
            .addEventListener("click", () => AdaptivePopup.addAndslide(document.createElement("forgot-password-2")));


        this.addEventListener("keydown", async event => {
            if (event.key === "Enter")
                await this.login();
        });

        const socialProviders = this.querySelector("social-providers");

        socialProviders.addEventListener("success", async () => await this.closeHandler());

        socialProviders.addEventListener("complete-external", (args: CustomEventInit) => {

            const login = <LoginResponse>args.detail;

            const externalUserRegistrationElement = document.createElement("external-user-registration-2");
            externalUserRegistrationElement.setAttribute("provider", login.provider);
            externalUserRegistrationElement.setAttribute("username", login.username || "");
            externalUserRegistrationElement.setAttribute("email", login.email || "");
            
            AdaptivePopup.addAndslide(externalUserRegistrationElement);

            externalUserRegistrationElement.addEventListener("success", this.closeHandler);

        }); 
    }

    private async login() {
        this.setAttribute("busy", "");

        if ((<any>window).TapticEngine) 
            (<any>window).TapticEngine.unofficial.weakBoom();

        const usernameElement = this.querySelector("[name=username-or-email]") as HTMLInputElement;
        const passwordElement = this.querySelector("[name=password]") as HTMLInputElement;

        let result;
        try{
            result = await AuthenticationStore.instance.login(usernameElement.value, passwordElement.value);
        } finally {
            this.removeAttribute("busy");
        }
        
        if ((<LoginResponse>result).status && (<LoginResponse>result).status === "success") {
            await this.closeHandler();
        } else if ((<LoginResponse>result).status && (<LoginResponse>result).status !== "success") {
            const formMessageElement = this.querySelector(`[name="form-message"]`) as HTMLSpanElement;

            if ((<LoginResponse>result).status === "requiresVerification")
            {
                this.setAttribute("busy", "");
                try{
                    if (await AuthenticationStore.instance.resendRegistrationMail(usernameElement.value)) {
                        AdaptivePopup.addAndslide(document.createElement("activationmail-info"));
                        return;
                    } else {
                        formMessageElement.innerText = "Unable to resend registration mail";
                        return;
                    }
                } finally {
                    this.removeAttribute("busy");
                }
            } else if ((<LoginResponse>result).status === "failure") {
                
                this.showForgotPassword();
             } else {
                if (formMessageElement)
                    formMessageElement.innerText = this.translateMessage((<LoginResponse>result).status);
             }

        } else if ((<ValidationMessage>result).modelState) {
            for(const key in (<ValidationMessage>result).modelState){
                const messageElement = this.querySelector(`[name="${key}"]`) as HTMLSpanElement;
                if (messageElement)
                    messageElement.innerText = (<ValidationMessage>result).modelState[key];
            }
        }  
    }

    private showForgotPassword() {
        const forgotPasswordElement = this.querySelector(`[name="forgot-password"]`) as HTMLElement;
        forgotPasswordElement.style.display = "block";
        
    }

    private translateMessage(status: SignInStatus): string {
        switch (status) {
            case "lockedOut": return "Account locked, please try later";
            case "requiresVerification": return "Account not verified";
            case "failure": return "Invalid username/email or password";
            default: return "Unknown error";
        }
    }

    private view = () => `
        <div>
            <header>
                <div><button type=button name=back class=icon>${require("!!raw-loader!image/arrow-left-20-v1.svg")}</button></div>
                <div>Log in to Linkstacks</div>
                <div></div>
            </header>
            <section>
                <fieldset>
                    <legend for="username-or-email">Username or email</legend>
                    <div class=field>
                        <input name=username-or-email autocapitalize="none" autocorrect="off" type="text" value="">
                    </div>
                </fieldset>
                <span name="model.UsernameOrEmail" class="error"></span>

                <fieldset>
                    <legend for="Password">Password</legend>
                    <div class=field>
                        <input name="password" type="password">
                    </div>
                </fieldset>
                <span name="model.Password" class="error"></span>

                <fieldset>
                    <legend>Login or register via</legend>
                    <social-providers></social-providers>
                </fieldset>

                <span name="form-message" class="error"></span>
                
            </section>
            <footer>
                <button name="forgot-password" class=power style="display:none">Did you forget your password?</button>
                <button type=button class=power name=login>Log in</button>
                <button type="button" class=power name="register">Register as a new user</button>
            </footer>
        </div>
    `;
}

customElements.define("user-login-2", UserLoginElement);