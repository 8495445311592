import "./AppLoaderElement-style";
import "../mini-progress";
import { cityline } from "features/cityline";
import { AuthenticationStore } from "features/authentication";

class AppLoaderElement extends HTMLElement {
    private loader: HTMLElement;

    async connectedCallback() {
        if ((<any>window).cloning)
            return;

        if (this.hasAttribute("rendered"))
            return;
            
        this.insertAdjacentHTML("afterbegin", this.view());
        this.loader = this.querySelector(".loader" ) as HTMLElement;

        const miniProgress = this.querySelector("mini-progress") as HTMLElement;

        miniProgress.setAttribute("value", "30");

        window.requestIdleCallback(
            async () => {
                miniProgress.setAttribute("value", "50");

                if (await AuthenticationStore.instance.isAuthenticated()) {
                    
                    await cityline.getFrames(
                        "account",
                        "user-preferences",
                        "stacks",
                    );    
                }
                
                miniProgress.setAttribute("value", "100");

                const template = this.querySelector(
                    "template"
                ) as HTMLTemplateElement;

                let element;
                if (!template.content) {
                    element = (<any>(
                        template.firstElementChild.cloneNode(true)
                    )) as HTMLElement; // IE 11
                } else {
                    element = (<any>(
                        template.content.firstElementChild
                    )) as HTMLElement;
                }

                this.insertAdjacentElement("afterbegin", element);

                setTimeout(() => {
                    this.loader.style.opacity = "0";
                    this.loader.style.pointerEvents = "none";
    
                }, 1200);
            },
            { timeout: 1000 }
        );
    }

    private view = () => `
        <div class=loader>
            <div name=logo>
                ${require("!!raw-loader!image/logo-solo.svg")}
            </div>
            <mini-progress value="30"  />
        </div>
    `;
}

customElements.define("app-loader", AppLoaderElement);
