
import { StackStore, Linkstack } from "features/stacks/StackStore";
import { Link, LinkStore } from "features/links";
import { BaseSideMenuElement, MenuCategory, MenuItem, DragEffect } from "features/side-menu/BaseSideMenuElement";
import { Routing } from "lib/Routing";
import { HotkeyStore } from "features/help/HotkeyStore";
import { LinkSession } from "features/link-page/LinkSession";

class BookmarkSideMenuElement extends BaseSideMenuElement {
    async connectedCallback() {
        await super.connectedCallback();
        StackStore.instance.addEventListener("stack-added", this.stackCreatedHandler);
        StackStore.instance.addEventListener("stack-removed", this.stackDeletedHandler);
        StackStore.instance.addEventListener("stack-updated", this.stackUpdatedHandler);
        LinkSession.instance.addEventListener("stack-changed", this.stackChangedHandler);

        window.addEventListener("keydown", this.keyboardHandler);
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        StackStore.instance.removeEventListener("stack-added", this.stackCreatedHandler);
        StackStore.instance.removeEventListener("stack-removed", this.stackDeletedHandler);
        StackStore.instance.removeEventListener("stack-updated", this.stackUpdatedHandler);
        LinkSession.instance.removeEventListener("stack-changed", this.stackChangedHandler);

        window.removeEventListener("keydown", this.keyboardHandler);
    }

    private keyboardHandler = async (event: KeyboardEvent) => {
        if (!this.isConnected)
            return;
        
        if ((event.target as HTMLElement).closest("input"))
            return;
        
        if ((event.target as HTMLElement).closest("textarea"))
            return;

        const element = event.target as HTMLElement;

        if (element.hasAttribute && element.hasAttribute("contenteditable"))
            return;

        if (event.altKey && event.code === "KeyI") {
            event.preventDefault();
            Routing.instance.go(`/@${this.user.userId}/stacks/inbox`);
        }

        if (event.altKey && event.code === "KeyC") {
            event.preventDefault();
            Routing.instance.go(`/@${this.user.userId}/stacks/create`);
        }

        if (event.altKey && event.code === "KeyF") {
            event.preventDefault();
            Routing.instance.go(`/filters`);
        }
    };

    private stackChangedHandler = (event: CustomEvent<Linkstack>) => {
        if (event.detail)
            this.selectItem(event.detail.id);
    };

    private stackDeletedHandler = (event: CustomEvent<Linkstack>) => this.removeItem(`${this.user.userId}/${event.detail.slug}`);
    
    private stackCreatedHandler = () => this.refresh();

    private stackUpdatedHandler = (event: CustomEvent<Linkstack>) => {
        const mapped = this.mapStack(event.detail, () => {});
        this.updateItem(mapped);
    };

    private mapStack = (stack: Linkstack, selectedEvaluator: (item: MenuItem) => void) : MenuItem => {
        const mapped: MenuItem = {
            id: stack.id,
            title: stack.title, 
            imgIcon: stack.smallPreview,
            svgIcon: undefined,
            count: stack.linkCount,
            dropTarget: true,
            path: `/@${this.user.userId}/stacks/${stack.slug}` 
        };

        selectedEvaluator(mapped);

        if (stack.slug === "inbox") {
            mapped.svgIcon = require("!!raw-loader!image/icons/v2/inbox-20-v8.svg");
            mapped.imgIcon = undefined;
        }
            
        return mapped;
    }

    private parseLink(data: string) : Link {
        // BUG: data is empty in chrome on windows
        if (data.length > 0) {
            console.log(data);
            const link = <Link>JSON.parse(data);
            if (link.slug)
                return link;
        }

        return undefined;
    }

    protected dragHover(itemId: string, data: string, ctrlKey: boolean) : DragEffect {
        const link = this.parseLink(data);

        const stackId = itemId.split("/")[1];
        
        if (!stackId)
            return "none";

        if (link) {
            if (link.stack === stackId)
                return "none";
        }

        return ctrlKey ? "copy" : "move";
    }

    protected async drop(itemId: string, data: string, ctrlKey: boolean) : Promise<void> {
        const link = this.parseLink(data);

        const targetStack = itemId.split("/")[1];

        if (link && link.stack === targetStack) {
            console.log("cant move to itself");
            return;
        }
        
        if (ctrlKey || !await this._urlProvider.currentUserIsDisplayUser())
            await LinkStore.instance.copyToStack(link.slug, link.stack, targetStack);
        else
            await LinkStore.instance.moveToStack(link.slug, link.stack, targetStack);
    }

    protected async getMenuCategories(selectedEvaluator: (item: MenuItem) => void) : Promise<MenuCategory[]> {

        const stacks = await StackStore.instance.getAll();
        const inbox = stacks.filter(m => m.slug === "inbox")[0];
        const stackMenuItems = stacks.filter(m => m.slug !== "inbox").map(stack => this.mapStack(stack, selectedEvaluator));

        const topItems = [
            this.mapStack(inbox, selectedEvaluator),
            {
                id: "create-stack",
                title: "Create stack",
                path: `/@${this.user.userId}/stacks/create`,
                svgIcon: require("!!raw-loader!image/icons/v2/add-stack-20-v2.svg")
            },
            {
                id: "filters",
                title: "Filters",
                path: "/filters",
                svgIcon: require("!!raw-loader!image/icons/v2/filter-20-v3.svg")
            }
        ];

        topItems.forEach(selectedEvaluator);

        return [
            {   
                items: topItems
            },
            {
                title: "MY STACKS",
                devider: true,
                items: stackMenuItems
            }
        ];
    }
}


customElements.define("bookmark-side-menu", BookmarkSideMenuElement);

window.requestIdleCallback(() => {
    HotkeyStore.instance
        .confirm("showInbox")
        .confirm("createStack")
        .confirm("filters");

    // HotkeyStore.instance.register("bookmarks", { description: "Inbox", hotkeys: [ { code: "KeyI", altKey: true }]});
    // HotkeyStore.instance.register("bookmarks", { description: "Create stack", hotkeys: [ { code: "KeyC", altKey: true }]});
    // HotkeyStore.instance.register("bookmarks", { description: "Filters", hotkeys: [ { code: "KeyF", altKey: true }]});
});
