export class GestureStore {
    private static _instance = new GestureStore();
    private _gestureSets: { [key: string]: GestureSet[] } = {
        "bookmarks": [
            { gesture: "tap", description: "Tap header to visit source." }
        ],
        "feeds": [
            { gesture: "tap", description: "Tap on header to open an article. Tap again to visit source." },
            { gesture: "swipe-left", description: "Swipe left on a closed article to mark it read." },
            { gesture: "swipe-right", description: "Swipe right on a closed article to mark it unread." },
            { gesture: "long-press", description: "Long press on header and links to save." }
        ]
    };

    static get instance() { return GestureStore._instance; }

    get gestureSets(): { [key: string]: GestureSet[] } {
        return this._gestureSets;
    }
}

export interface GestureSet {
    description: string;
    gesture: Gesture;
}

export type Gesture = "tap" | "swipe-left" | "swipe-right" | "long-press";


