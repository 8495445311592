import * as labelStyle from "features/forms/labels.module.less";
import * as panelStyle from "./SearchPanel-module.less";
import { BaseSidebarChildElement } from "features/side-bar/BaseSidebarChildElement";
import { Entry, Feed } from "features/feeds";
import { Formatting } from "lib/Formatting";
import { Link } from "features/links";
import { Linkstack } from "features/stacks/StackStore";
import { MyFeedStore } from "features/feeds/MyFeedStore";
import { PagedResult, IHighlighted } from "lib/Model";
import { Pipeline, Request } from "lib/HTTP";
import { QueryStatement, SearchStore } from "./SearchStore";
import { Routing } from "lib/Routing";
import { StackHelper } from "features/stacks/StackHelper";
import { UrlProvider } from "lib/UrlProvider";
import { TopMenuStore } from "features/top-panel/TopMenuStore";
import * as cardsStyle from "../../styles/cards-module.less"
import { LinkSession } from "../link-page/LinkSession";

export class SearchPanelElement extends BaseSidebarChildElement {
    protected titleText: string = "Search";
    private named = <T extends HTMLElement> (name: string): T => this.querySelector(`[name=${name}]`) as T;
    private searchBox: HTMLInputElement;
    private escape = Formatting.htmlEscape;
    private abortController: AbortController;
    private resultFrame: HTMLElement;
    private clearButton: HTMLElement;
    private phoneMediaMatch = window.matchMedia("screen and (max-width: 850px)");
    private refreshRoutine: () => Promise<void>;
    private suggestArea: HTMLElement;
    private _previousSearchToken;
    
    async connectedCallback() {
        super.connectedCallback();

        TopMenuStore.instance.toggle("search");

        this.className += ` ${panelStyle.searchPanel}`;



        this.insertAdjacentHTML("beforeend", this.view());
        this.searchBox = this.named("search-box");
        this.resultFrame = this.named("result-frame");
        this.clearButton = this.named("clear");
        this.suggestArea = this.named("suggest");
        
        setTimeout(() => {
            this.searchBox.focus();
        }, 300);

        this.searchBox.addEventListener("input", this.searchHandler);
        this.searchBox.addEventListener("keypress", this.searchKeyHandler);
        this.clearButton.addEventListener("click", this.clear);
        this.resultFrame.addEventListener("click", this.clickHandler);
        Routing.instance.onChange(this.routeChangeHandler);
        await this.renderQuickSearch();

        SearchStore.instance.onRecentChanged(this.searchesChangedHandler);
        SearchStore.instance.onSavedChanged(this.searchesChangedHandler);

        this.suggestArea.addEventListener("click", this.suggestionClickHandler);


        this.addEventListener("click", this.stackClickHandler);
    }

    disconnectedCallback() {
        super.disconnectedCallback();

        TopMenuStore.instance.toggle("search", false);

        this.searchBox.removeEventListener("input", this.searchHandler);
        this.searchBox.removeEventListener("keypress", this.searchKeyHandler);
        this.clearButton.removeEventListener("click", this.clear);
        this.resultFrame.removeEventListener("click", this.clickHandler);
        Routing.instance.offChange(this.routeChangeHandler);

        SearchStore.instance.offRecentChanged(this.searchesChangedHandler);
        SearchStore.instance.offSavedChanged(this.searchesChangedHandler);
        this.suggestArea.removeEventListener("click", this.suggestionClickHandler);

        this.removeEventListener("click", this.stackClickHandler);
    }

    private stackClickHandler = async (event: UIEvent) => {
        const element = event.target as HTMLElement;
        if (element.closest("a"))
            await this.closeHandler();
    }

    private suggestionClickHandler = (event: UIEvent) => {
        const button = (event.target as HTMLElement).closest("button");
        if (!button)
            return;

        const replacement = button.innerText;
        const value = this.searchBox.value;
        this.searchBox.value = value.replace(new RegExp(this.escape`${this._previousSearchToken}$`), this.escape`${replacement} `);
        this.searchBox.focus();
        setTimeout(async () => this.searchHandler(undefined));
    }

    private routeChangeHandler =  async () => {
        if (this.refreshRoutine)
            setTimeout(async () => await this.refreshRoutine(), 10);

    }

    private timer: number;
    private searchKeyHandler = async (event: KeyboardEvent) => {
        if (event.key === "Enter") {
            const escapedQuery = Formatting.htmlEncode2(this.searchBox.value);
            await SearchStore.instance.search({ query: escapedQuery, entityType: "link" });

            if (this.phoneMediaMatch.matches) {
                await this.closeHandler();
            }
        }
    }


    private searchHandler = (event: KeyboardEvent) => {
        if (this.abortController)
            this.abortController.abort();

        if ("AbortController" in window)
            this.abortController = new AbortController();

        window.clearTimeout(this.timer);

        this.timer = window.setTimeout(async () => {
            await Promise.all([this.suggestStacks(this.searchBox.value, this.abortController), this.suggestWords(this.searchBox.value, this.abortController)]);
        }, 100);
    }

    

    private clickHandler = async (event: MouseEvent) => {
        const target = event.target as HTMLElement;

        const button = target.closest("button");

        if (!button)
            return;
            
        const statementElement = button.parentElement.querySelector("script[type='application/json+statement']");

        switch (button.name) {
            case "save":
                await this.saveSearch(statementElement, button);
                break;
            case "un-save":
                await this.unSaveSearch(statementElement, button);
                break;
            case "go":
                await this.performSearch(statementElement);
                break;
        }
    }

    private searchesChangedHandler = async () => {
        if (this.refreshRoutine)
            return;

        await this.renderQuickSearch();
    }

    private clear = async () => {
        this.searchBox.value = "";
        await this.renderQuickSearch();
        this.searchBox.parentElement.removeAttribute("filled");
        this.refreshRoutine = undefined;
    }

    private async renderQuickSearch() {
        const savedSearches = await SearchStore.instance.savedSearches();
        const recentSearches = await SearchStore.instance.recentSearches();
        this.resultFrame.innerHTML = await this.quickSearchView(recentSearches.reverse(), savedSearches.reverse());
    }

    private async performSearch(statementElement: Element) {
        const statement = JSON.parse(statementElement.innerHTML);
        await SearchStore.instance.search(statement);

        if (this.phoneMediaMatch.matches) {
            await this.closeHandler();
        }
    }

    private async unSaveSearch(statementElement: Element, button: HTMLButtonElement) {
        const statement = JSON.parse(statementElement.innerHTML);
        const result = await SearchStore.instance.unSave(statement);
        if (result)
            button.outerHTML = this.saveButtonView();
    }

    private async saveSearch(statementElement: Element, button: HTMLButtonElement) {
        const statement = JSON.parse(statementElement.innerHTML);
        const result = await SearchStore.instance.save(statement);
        if (result)
            button.outerHTML = this.unSaveButtonView();
        return { statement, result };
    }

    private async suggestStacks(query: string, abortController: AbortController) {

        if (query !== "")
            this.searchBox.parentElement.setAttribute("filled", "");
        else
            await this.clear();


        const searchParams = new URLSearchParams();
        searchParams.set("query", query);
        searchParams.set("size", "5");

        let abortSignal: AbortSignal;
        if (abortController)
            abortSignal = abortController.signal;

        const searchResponse = await new Pipeline().fetch(`${new UrlProvider().root}/api/search/stack-suggest?${searchParams.toString()}`, Request.get.setSignal(abortSignal).authenticate());

        if (abortController && abortController.signal && abortController.signal.aborted)
            return;

        if (!searchResponse.ok)
            return;


        const searchResult = await searchResponse.json() as SearchResult;

        this.refreshRoutine = async () => {
            this.resultFrame.innerHTML = await this.resultView(await this.getSearchStatements(query), searchResult, query);
            StackHelper.resolveStacks();
        }

        await this.refreshRoutine();
    }

    private async suggestWords(query: string, abortController: AbortController) {

        const tokens = query.split(" ");
        const searchToken = tokens[tokens.length-1];
        
        if (searchToken === this._previousSearchToken)
            return;

        this._previousSearchToken = searchToken;

        if (searchToken.length < 1) {
            this.showSuggest([]);
            return;
        }
            
        const searchParams = new URLSearchParams();
        searchParams.set("query", searchToken);

        let abortSignal: AbortSignal;
        if (abortController)
            abortSignal = abortController.signal;

        const searchResponse = await new Pipeline().hideLoader().fetch(`${new UrlProvider().root}/api/search/suggest?${searchParams.toString()}`, Request.get.setSignal(abortSignal).authenticate());
        if (abortController && abortController.signal && abortController.signal.aborted)
            return;

        if (!searchResponse.ok)
            return;
            
        const result = await searchResponse.json() as string[];
        this.showSuggest(result || []);
    }

    private showSuggest(suggestions: string[]) {
        this.suggestArea.innerHTML = suggestions.map(suggestion => `<button type="button">${suggestion[0].toLocaleUpperCase()}${suggestion.substring(1)}</button>`).join("");
    }

    private async getSearchStatements(query: string) : Promise<QueryStatement[]> {
        const result: QueryStatement[] = [];

        const escapedQuery = Formatting.htmlEncode2(query);

        result.push({query: escapedQuery });

        const stack = LinkSession.instance.currentStack;
        if (stack && stack.slug !== "links")
            result.push({stackId: stack.id, query: escapedQuery, entityType: "link"});

        
        result.push({query: escapedQuery, entityType: "link"});
        result.push({query: escapedQuery, entityType: "stack"});
        
        const route = Routing.instance.route;
        if (Routing.instance.matches("@*/feeds") && route.path[2]) {
            const feed = await MyFeedStore.instance.getFeed(route.path[2]);
            if (feed && feed.id !== "all")
                result.push({feedId: feed.id, query: escapedQuery, entityType: "article"});
        }
        
        result.push({query: escapedQuery, entityType: "article"});
        result.push({query: escapedQuery, entityType: "feed"});

        return result;
    }

    private saveButtonView = () => `
        <button type=button title="Save search" name=save>
            ${require("!!raw-loader!image/star.svg")}
        </button>
    `;

    private unSaveButtonView = () => `
        <button type=button title="Remove saved search" name=un-save>
            ${require("!!raw-loader!image/star.svg")}
        </button>
    `;

    private statementView = async (statement: QueryStatement) => {
        const text = await SearchStore.instance.translateStatement(statement);

        return this.escape`
                <div>
                    <script type="application/json+statement">$${JSON.stringify(statement)}</script>
                    <button type=button name=go>
                        <div class="${panelStyle.iconBorder}">
                            $${require("!!raw-loader!image/search-20-v2.svg")}
                        </div>
                        <div class="${panelStyle.searchLabel}">
                            <div>$${text.primary}</div>
                            $${text.secondary ? `<div>${text.secondary}</div>` : ""}
                        </div>
                        
                    </button>
                    $${SearchStore.instance.isSaved(statement) ? this.unSaveButtonView() : this.saveButtonView()}
                    
                </div>`
    };

    private quickSearchView = async (recent: QueryStatement[], saved: QueryStatement[], ) => this.escape`
        $${saved.length > 0 ? `<div class="${labelStyle.smallLabel} ${panelStyle.quickViewLabel}">SAVED SEARCHES</div>`: ""}
        $${(await Promise.all(saved.map(statement => this.statementView(statement)))).join("")}
        $${recent.length > 0 ? `<div class="${labelStyle.smallLabel} ${panelStyle.quickViewLabel}">RECENT SEARCHES</div>`: ""}
        $${(await Promise.all(recent.map(statement => this.statementView(statement)))).join("")}
        
   `;

    private resultView = async (entityQueryStatements: QueryStatement[], searchResult: SearchResult, query: string) => this.escape`

        $${(await Promise.all(entityQueryStatements.map(statement => this.statementView(statement)))).join("")}

        $${searchResult.stacks.items.length > 0 ? `
            <div class="${labelStyle.smallLabel} ${panelStyle.quickViewLabel}">MATCHING STACKS</div>
        `: ""}
        <div class="${panelStyle.searchResult}" name=matching-stacks>
            $${searchResult.stacks.items.map(stack => this.escape`
                <a href="${stack.siteLocation}" route>
                    <progressive-image width=25 height=25 source-image="${stack.preview}"></progressive-image>
                    <div>
                        <div class="${panelStyle.heavy}">${stack.title}</div>
                    </div>
                </a>`).join("")}
        </div>`;

       

    private view = () => `
        <div class="${cardsStyle.cardContainer}">
            <div class="${cardsStyle.card}">
                <div class="${panelStyle.searchInput}">
                    ${require("!!raw-loader!image/search-20-v2.svg")}
                    <input type=text name=search-box  placeholder="Search Linkstacks" autocomplete="off">
                    <button type=button name=clear title="Clear">${require("!!raw-loader!image/close-icon.svg")}</button>
                </div>
                <div name=suggest></div>
                <div name=result-frame class="${panelStyle.resultFrame}"></div>
            </div>
        </div>
    `;
}

customElements.define("search-panel", SearchPanelElement);

interface SearchResult {
    entries: PagedResult<Entry & IHighlighted>;
    links: PagedResult<Link & IHighlighted>;
    stacks: PagedResult<Linkstack & IHighlighted>;
    feeds: PagedResult<Feed & IHighlighted>;
}