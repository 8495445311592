import "./StackHeaderElement-style";
import { StackStore } from "features/stacks/StackStore";
import { DialogController } from "features/dialog";
import { Routing } from "lib/Routing";
import { UrlProvider } from "lib/UrlProvider";
import { Formatting } from "lib/Formatting";
import { ShareActivator } from "features/sharing/ShareActivator";
import { TopMenuStore, TopMenuItem } from "features/top-panel/TopMenuStore";
import { BulkLinkStore } from "features/link-list/BulkLinkStore";
import { LinkSession } from "features/link-page/LinkSession";
import { AuthenticationStore } from "features/authentication";

type StackAction = "view" | "edit" | "create";

class StackHeaderElement extends HTMLElement {
    private stackTitle = () => this.getAttribute("stack-title");
    private collabCount = () => parseInt(this.getAttribute("collab-count"));
    private description = () =>  this.getAttribute("stack-description");
    private slug = () =>  this.getAttribute("stack-slug");
    private canEdit = () => this.getAttribute("can-edit") === "true";
    private action = () => this.getAttribute("stack-action") as StackAction;
    private rendered = () => this.hasAttribute("rendered");
    private stackPrivate = () => this.hasAttribute("stack-private");
    private urlProvider = new UrlProvider();

    get isMobile() {
        return window.innerWidth <= 850;
    }

    connectedCallback() {
        this.refresh();
    }

    disconnectedCallback() {
        TopMenuStore.instance.setGroup("links", []);
    }

    private refresh = () => {
        this.refreshContextMenu();
        if (this.action() === "edit" || this.action() === "create")
            this.edit();
        else {

            if (!this.rendered()) {
                if (this.action() === "view")
                    this.innerHTML = this.view();
            } else {
                this.querySelector("button-bar").innerHTML = this.buttonBarView();
            }

        }
        this.setupDOM();
    };

    private publicPrivateHandler = async () => {
        await this.togglePrivatePublic();
        this.refreshContextMenu()
    }

    private refreshContextMenu = () => {
        TopMenuStore.instance.setGroup("feed", []); // fix IE issue
        TopMenuStore.instance.setGroup("links", [
            {
                name: "select-all",
                title: "Select all links",
                side: "context",
                priority: 0,
                handler: () => BulkLinkStore.instance.selectAllRequest()
            },
            {
                name: "-",
                title: "-",
                priority: 1,
                side: "context"
            },
            this.canEdit() ? {
                name: "edit-stack",
                title: "Edit stack",
                priority: 2,
                side: "context",
                handler: this.edit
            } : undefined,
            this.canEdit() ? {
                name: "delete-stack",
                title: "Delete stack",
                priority: 3,
                side: "context",
                handler: this.delete
            } : undefined,
            this.slug() !== "inbox" ? {
                name: "share-stack",
                title: "Share stack",
                priority: 4,
                side: "context",
                handler: this.shareHandler
            } : undefined,
            (this.canEdit() && this.stackPrivate()) ? {
                name: "make-stack-public",
                title: "Make stack public",
                priority: 5,
                side: "context",
                handler: this.publicPrivateHandler
            } : undefined,
            (this.canEdit() && !this.stackPrivate()) ? {
                name: "make-stack-private",
                title: "Make stack private",
                priority: 5,
                side: "context",
                handler: this.publicPrivateHandler
            } : undefined,
            this.canEdit() ? {
                name: "-",
                title: "-",
                priority: 9,
                side: "context"
            } : undefined
        ].filter(Boolean) as TopMenuItem[]);
    }

    private shareHandler = () => {
        setTimeout(async () => await ShareActivator.shareStack(this.getAttribute("stack-id"), "social"));
    }

    private async leaveEdit() {
        if (this.action() === "create") {
            if (!Routing.instance.goBack()) {
                Routing.instance.go(await this.urlProvider.inboxSiteLocation());
            }
        }
        else {
            this.toggleAttribute("editing", false);
            this.innerHTML = this.view();
            this.setupDOM();
        }   
    }

    private setupDOM() {
        const shareButton = this.querySelector("[name=share]");
        if (shareButton)
            shareButton.addEventListener("click", async () => await ShareActivator.shareStack(this.getAttribute("stack-id"), "social"));

        const access = this.querySelector("[name=access]");
        if (access)
            access.addEventListener("click", () => this.togglePrivatePublic());

        const collab = this.querySelector("[name=collaborators]");
        if (collab)
            collab.addEventListener("click", async () => {
                await ShareActivator.shareStack(this.getAttribute("stack-id"), "collaborate");
            });
    }

    private async togglePrivatePublic() {
        const stack = await StackStore.instance.getStackBySlug((await AuthenticationStore.instance.currentUser()).userId, this.slug());
        stack.private = !stack.private;
        if (stack.private)
            this.setAttribute("stack-private", "")
        else
            this.removeAttribute("stack-private")
        
        await StackStore.instance.updateStack(stack);
        this.refresh();
    }

    private delete = async () => {
        const dialog = new DialogController();
        
        const stack = await StackStore.instance.getStackBySlug((await AuthenticationStore.instance.currentUser()).userId, this.slug());
        
        const option = await dialog.prompt({
            caption: "Delete stack",
            message: `Are you sure that you want to delete stack ${stack.title}?\nAll links will be deleted.`,
            options: ["Cancel", "Delete"]
        })

        if (option === "Delete") {
            if (await StackStore.instance.deleteStack(stack.slug))
                Routing.instance.go(await new UrlProvider().inboxSiteLocation());
        } 
    }

    private edit = () => {
        this.innerHTML = this.editView();
        this.toggleAttribute("editing", true);
        this.setupEditDOM();
    }

    private checkValidity() {
        const fields = ["input", "rich-editor"];
        let valid = true;
        fields.forEach(field => {
            [].slice.call(this.querySelectorAll(field)).forEach( (element: HTMLInputElement) => {
                valid = valid && element.validity.valid;
            });
        });

        [].slice.call(this.querySelectorAll("[name=save]")).forEach( (button: HTMLButtonElement) => {
            if (valid)
                button.removeAttribute("disabled");
            else
                button.setAttribute("disabled", "");
        });
    }

    private trackChanges() {
        const fields = ["input", "rich-editor"];
        
        fields.forEach(field => {
            [].slice.call(this.querySelectorAll(field)).forEach( (element: HTMLInputElement) => {
                element.addEventListener("change", () => this.checkValidity());
                element.addEventListener("keyup", () => this.checkValidity());
            });
        });
    }

    private setupEditDOM() {
        const cancelEdit = this.querySelector("[name=cancel]") as HTMLElement;
        if (cancelEdit)
            cancelEdit.addEventListener("click", event => this.leaveEdit());

        const leaveEdit = this.querySelector("[name=save]") as HTMLElement;
        
        if (leaveEdit) {
            leaveEdit.addEventListener("click", event => this.saveAndLeaveEdit());
        }

        ["input"].forEach(elementName => { // "textarea"
            const element = this.querySelector(elementName) as HTMLElement;
            element.addEventListener("keydown", async (event: KeyboardEvent) => {
                if (event.key === "Enter")
                    await this.saveAndLeaveEdit();
            });
        });

        this.trackChanges();
        setTimeout(() => this.checkValidity());
    }

    private editView = () => `
        <top>
            <h1>
                <input type="text" maxlength=200 required name="title" value="${this.stackTitle() || ""}" placeholder="Title" class="edit" ${this.action() === "create" ? "autofocus" : ""} />
            </h1>
        </top>
        <h3>
            <rich-editor field-name=description placeholder=Description class=edit maxlength=1000>${Formatting.htmlEncode(this.description() || "")}</rich-editor>
        </h3>
        <div class=button-panel>
            <button type=button class="cancel power-inactive" name="cancel">
                Cancel
            </button>
            <button type=button class="power" name="save">
                Save
            </button>
        </div>
    `;
    
    private view = () => { 
        const collaboratorCount = this.collabCount();

        return`

        ${collaboratorCount > 0 ? `
            <div name=collaborators>${require("!!raw-loader!image/collab-x-v1.svg")}Collaborating with ${collaboratorCount} other user${collaboratorCount > 1 ? "s" : ""}.</div>
        ` : ""}
        
        <top>
            <h1>
                <span class="view" name="title">${this.stackTitle() || ""}</span>
            </h1>

            <button-bar>${this.buttonBarView()}</button-bar>
        </top>
        <h3>
            <span class="view" name="description">${this.description() || ""}</span>
        </h3>
    `};

    private buttonBarView = () => `
        ${this.isMobile || this.slug() === "inbox" ? "" : `
            <button type=button class=icon name=share>
                ${require("!!raw-loader!image/icons/fa-share-alt.svg")}
                <span>Share</span>
            </button>
        
            ${this.canEdit() ? `
                <button type=button class=icon name=access>
                    ${this.stackPrivate() ? `${require("!!raw-loader!image/icons/header/locked-20-v1.svg")}<span>Private</span>` : 
                    `${require("!!raw-loader!image/icons/header/unlocked-20-v1.svg")}<span>Public</span>` }    
                </button>`  : ""}
            `
        }`;

    private async saveAndLeaveEdit() {
        let stack = LinkSession.instance.currentStack;
        if (this.action() === "create") {
            stack = {
                title: "",
                id: "",
                description: "",
                slug: "",
                private: false
            };
        }
        stack.description = (<HTMLInputElement>this.querySelector("[name=description]")).value;
        stack.title = (<HTMLInputElement>this.querySelector("input[name=title]")).value;
        try {
            this.setAttribute("busy", "");
            
            if (this.action() === "create")
                await StackStore.instance.createStack(stack);
            else
                await StackStore.instance.updateStack(stack);
        } finally {
            this.removeAttribute("busy");
        }

        Routing.instance.refresh();
    }
}

customElements.define("stack-header", StackHeaderElement);