import { Link } from "features/links"
import { LinkPageStore } from "features/link-page/LinkPageStore";

interface QueuedItem{
    link: Link,
    timer: number
}

//tslint:disable-next-line: no-unused-variable
class LinkFetchMonitor {
    private queue: { [id: string] : QueuedItem; } = {};

    //tslint:disable-next-line: no-unused-variable
    private static _instance = new LinkFetchMonitor();


    private constructor(){
        window.requestIdleCallback(() => this.registerEvents(), { timeout: 1000 });
    }

    private timeoutHandler(link: Link) : number {
        return window.setTimeout(async () => { 
            throw new Error(`Timeout waiting for link-enrich, url: ${link.url}, id: ${link.id} @${new Date()}.\n\n`);
        }, 15000); // 15 seconds
    }

    private registerEvents() {
        LinkPageStore.instance.addEventListener("link-added", (event: CustomEvent<Link>) => {
            const link = event.detail;
            if (!link.isCreated)
                return;
                
            if (this.queue[link.id])
                return;

            this.queue[link.id] = {
                link: link,
                timer: this.timeoutHandler(link)
            }
        });

        LinkPageStore.instance.addEventListener("link-updated", (event: CustomEvent<Link>) => {
            const link = event.detail;
            const item = this.queue[link.id];
            if (!item)
                return;

            delete this.queue[link.id];
            window.clearTimeout(item.timer);
        });
    }  
}

