import EventTarget from "@ungap/event-target";

export class ElementDataWatcher2<TEntity> {
    private element: HTMLElement;
    private changeObserver: MutationObserver;
    private eventTarget = new EventTarget();
    private cachedEntity: TEntity;
    private cachedChecksum: string;
  
    addEventListener(
        type: string = "changed",
        listener: EventListenerOrEventListenerObject,
        options?: boolean | AddEventListenerOptions
    ) {
        return this.eventTarget.addEventListener(type, listener, options);
    }
  
    removeEventListener(
        type: string = "changed",
        listener: EventListenerOrEventListenerObject,
        options?: boolean | AddEventListenerOptions
    ) {
        return this.eventTarget.removeEventListener(type, listener, options);
    }

    constructor(private rootElement: HTMLElement, entityContentType: string) {
        this.element = this.rootElement.querySelector(`script[type='application/json+${entityContentType}']`) as HTMLElement;

        if (!this.element)
            console.log("Element not found");
       
        this.cachedChecksum = this.checksum(this.json);
        this.changeObserver = new MutationObserver(() => {
            if (this.checksum(this.element.innerText) !== this.cachedChecksum) {
                this.flushCache();
                this.eventTarget.dispatchEvent(new CustomEvent("changed", { detail: this.data }));
            }
        });
    
        this.changeObserver.observe(this.element, { childList: true })
    }

    private flushCache() {
        this.cachedEntity = undefined;
    }

    private parseEntity() : TEntity {
        const json = this.json;
        if (json)
            return JSON.parse(json) as TEntity;
        
        return undefined;  
    }

    get json() {
        return this.element.innerText;
    }

    get data(): TEntity {
        if (this.cachedEntity)
            return this.cachedEntity;

        return this.cachedEntity = this.parseEntity();
    }

    set data(entity: TEntity) {
        this.element.innerText = JSON.stringify(entity);
        this.flushCache();
        this.eventTarget.dispatchEvent(new CustomEvent("changed", { detail: this.data }));
    }

    destroy() {
        this.changeObserver.disconnect();
    }

    private checksum(source = "") : string
    {
        let chk = 0x12345678;
        const len = source.length;
        for (let i = 0; i < len; i++) 
            chk += (source.charCodeAt(i) * (i + 1));
        
        return (chk & 0xffffffff).toString(16);
    }
}