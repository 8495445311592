import EventTarget from "@ungap/event-target";
import { cityline } from "features/cityline";

// simply unpacks generic trailevent format
export class TrailEventStore {
    private static _instance = new TrailEventStore();
    private eventTarget = new EventTarget();
    public static get instance()  { return TrailEventStore._instance; } 
    private constructor() {
        cityline.addEventListener("trail-event", this.handler);
    }

    addEventListener(
        type: string,
        listener: EventListenerOrEventListenerObject,
        options?: boolean | AddEventListenerOptions
    ) {
        return this.eventTarget.addEventListener(type, listener, options);
    }

    removeEventListener(
        type: string,
        listener: EventListenerOrEventListenerObject,
        options?: boolean | EventListenerOptions
    ) {
        this.eventTarget.removeEventListener(type, listener, options);
    }

    private handler = (event: CustomEvent<TrailEvent>) => {
        this.eventTarget.dispatchEvent(new CustomEvent(event.detail.extendedType, { detail: event.detail })); 
    }
}

export interface TrailEvent {
    area: "feeds",
    created: string,
    extended: any,
    extendedType: "subscribed-to-feed"
    id: string,
    message: string,
    moniker: string,
    success: boolean,
    userId: string
}