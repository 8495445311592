import { AdaptivePopup } from "features/authentication/AdaptivePopup";
import { AuthenticationStore, ValidationMessage, IdentityResult, LoginResponse } from "features/authentication";


class UserRegistrationElement2 extends AdaptivePopup {
    async connectedCallback() {
        this.setAttribute("slide", "");
        requestAnimationFrame(() => this.removeAttribute("slide"));
        this.innerHTML = this.view();
        super.connectedCallback();
        this.setupEvents()
    }

    private async register() {
        const usernameElement = this.querySelector("[name=username]") as HTMLInputElement;
        const emailElement = this.querySelector("[name=email]") as HTMLInputElement;
        const passwordElement = this.querySelector("[name=password]") as HTMLInputElement;
        const confirmPasswordElement = this.querySelector("[name=confirmPassword]") as HTMLInputElement;
        const formMessageElement = this.querySelector(`[name="form-message"]`) as HTMLSpanElement;

        formMessageElement.innerText = "";
        [].slice.call(this.querySelectorAll(".error")).forEach((element: HTMLElement) => {
            element.innerText = "";
        });

        if (passwordElement.value !== confirmPasswordElement.value){
            formMessageElement.innerText = "Password and repeated password must match.";
            return;
        }

        this.setAttribute("busy", "");

        if ((<any>window).TapticEngine) 
            (<any>window).TapticEngine.unofficial.weakBoom();

        let result;
        try {
            result = await AuthenticationStore.instance.register(usernameElement.value, emailElement.value, passwordElement.value);
        } finally {
            this.removeAttribute("busy");
        }
        
        
        if ((<ValidationMessage>result).modelState) {
            for(const key in (<ValidationMessage>result).modelState){
                const messageElement = this.querySelector(`[name="${key}"]`) as HTMLSpanElement;
                if (messageElement)
                    messageElement.innerText = (<ValidationMessage>result).modelState[key];
            }
        } else if (!(<IdentityResult>result).succeeded) {
            if (formMessageElement)
                formMessageElement.innerText = (<IdentityResult>result).errors.join("<br />");
        } else {
            AdaptivePopup.addAndslide(document.createElement("activationmail-info"));
            this.remove();
        }
    }

    private successHandler = async () => {
        this.dispatchEvent(new CustomEvent("success"));
        await this.closeHandler();

    };

    private setupEvents() {
        const registerButton = this.querySelector("[name=register]") as HTMLButtonElement;
        registerButton.addEventListener("click", async () => await this.register());

        this.addEventListener("keydown", async event => {
            if (event.key === "Enter")
                await this.register();
        });

        const socialProviders = this.querySelector("social-providers");

        socialProviders.addEventListener("success", this.successHandler);

        socialProviders.addEventListener("complete-external", (args: CustomEventInit) => {

            const login = <LoginResponse>args.detail;

            const externalUserRegistrationElement = document.createElement("external-user-registration-2");
            externalUserRegistrationElement.setAttribute("provider", login.provider);
            externalUserRegistrationElement.setAttribute("username", login.username || "");
            externalUserRegistrationElement.setAttribute("email", login.email || "");
            
            AdaptivePopup.addAndslide(externalUserRegistrationElement);

            externalUserRegistrationElement.addEventListener("success", this.successHandler);

        }); 
    }

    private view = () => `
        <div>
            <header>
                <div><button type=button name=back class=icon>${require("!!raw-loader!image/arrow-left-20-v1.svg")}</button></div>
                <div>Create a new account</div>
                <div></div>
            </header>
            <section>
                <span name="form-message" class="error"></span>
                <fieldset>
                    <legend for="username">Username</legend>
                    <div class=field>
                        <input autocapitalize="none" autocorrect="off" id="username" name="username" type="text" value="">
                    </div>
                </fieldset>
                <span name="model.Username" class="error"></span>

                <fieldset>
                    <legend for="email">Email</legend>
                    <div class=field>
                        <input autocapitalize="none" autocorrect="off" id="email" name="email" type="text" value="">
                    </div>
                    </fieldset>
                <span name="model.Email" class="error"></span>
                
                <fieldset>
                    <legend for="Password">Password</legend>
                    <div class=field>
                        <input id="password" name="password" type="password">
                    </div>
                </fieldset>
                <span name="model.Password" class="error"></span>
                
                <fieldset>
                    <legend for="confirmPassword">Repeat Password</legend>
                    <div class=field>
                        <input id="password" name="confirmPassword" type="password">
                    </div>
                </fieldset>
                <span name="model.ConfirmPassword" class="error"></span>


                <fieldset>
                    <legend>Or register via</legend>
                    <social-providers></social-providers>
                </fieldset>

               
            </section>

            <footer>
                <button type=button class=power name=register>Register</button>
            </footer>
        </div>
    `;
}

customElements.define("user-registration-2", UserRegistrationElement2);