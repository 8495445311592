import { EventEmitter } from "events";
import { DOM } from "lib/DOM";
import { Delay } from "lib/System";
import "url-polyfill";

export enum RouteChangeOrigin{
    Requested,
    PageLoad,
    Popstate,
    Pushstate
}

export type Direction = "none" | "forward" | "backward";

export interface IRoute {
    path: string[];
    parameters:{ [key: string]: string };
    origin?: RouteChangeOrigin;
    direction?: Direction;
    hash?: string;
    requester?: string;
}

export class Routing extends EventEmitter {
    private static _instance = new Routing();
    public static ClassName = "Routing";
    private currentRoute: IRoute;
    private isFileProtocol = window.location && window.location.protocol === "file:" || window.location.hostname === "localhost";

    public static emptyRoute() : IRoute {
        return { path: [], parameters: {}};
    }
    
    private async setup() {
        await DOM.ready();
        
        if (window.location.href.indexOf("%40") !== -1 && window.history && window.history.replaceState)
            history.replaceState(undefined, undefined, window.location.href.replace("%40", "@"));
    
    }

    private constructor() {
        super();

        this.setMaxListeners(100);

        setTimeout(() => this.setup());

        
        this.parseCurrent(); // todo: when 

        setTimeout(async () =>{
            await DOM.ready();
            await Delay.wait(20);
            this.currentRoute.origin = RouteChangeOrigin.PageLoad;
            this.emitRouteChanged( this.currentRoute, Routing.ClassName);         
        });

        
        window.addEventListener("popstate", (event: PopStateEvent) => {
            if (!event.state)
                return;

            const route = this.parse(event.state);
            if (route)
            {
                this.currentRoute = route;
                this.emitRouteChanged( this.currentRoute );
            }
        });

        window.addEventListener("keydown", async (event: KeyboardEvent) => {
            if (event.altKey && (event.key === "b" || event.keyCode === 66)) {
                this.goBack();
            }
        });
        
        window.addEventListener("backbutton", (event) => {
            event.preventDefault();
            this.goBack();
        });
    
        document.addEventListener("click", event => {
            try {

                const target = <HTMLElement>(event.target || event.currentTarget);

                if (!target)
                    return;

                const route = target.closest("[route]");

                if (route) {

                    let link: HTMLAnchorElement;
                    if (route.hasAttribute("href"))
                        link = route as HTMLAnchorElement;
                    else
                        link = route.querySelector("a[href]") as HTMLAnchorElement;

                    const url = link.getAttribute("href");
                    this.go(url, `${Routing.ClassName}-clickHandler`);
                    event.preventDefault();
                }
            }
            catch(e) {
                console.log(e);
            }
            
        });
    }


    private history:string[] = [];

    public publish(url: string, replace = false) {
        
        if (!this.isFileProtocol) 
            if (replace)
                window.history.replaceState(url, url, url);
            else
                window.history.pushState(url, url, url);
        else
        {
            this.history.push(url);
            window.location.hash = "";
        }

        this.currentRoute = this.parse(url);
    }

    public go(url: string, requester = "unknown") {
        this.publish(url);
        this.emitRouteChanged( this.currentRoute, requester ); 
    }
    
    public replace(url: string) {
        this.publish(url, true);
        this.emitRouteChanged( this.currentRoute ); 
    }

    public refresh() {
        this.emitRouteChanged( this.currentRoute ); 
    }

    public goBack() {
        if (this.history.length > 0) 
            this.history.pop(); // pop current

        if (this.history.length > 0) {
            const backUrl = this.history[this.history.length-1]; // previous
            
            this.currentRoute = this.parse(backUrl);
            this.emitRouteChanged( this.currentRoute );
            return true;
        } else {
            return false;
        }
    }

    get route() : IRoute {
        return this.currentRoute;
    }
    
    private emitRouteChanged(route: IRoute, requester = "unknown") {
        this.emit("RouteChanged", route, requester);
    }
    
    onChange(callback: (route: IRoute, requester: string) => void) {
        this.on("RouteChanged", callback);
    }

    offChange(callback: (route: IRoute, requester: string) => void) {
        this.removeListener("RouteChanged", callback);
    }


    static get instance() {
        return Routing._instance;
    }
    private parseCurrent() {
        if (this.isFileProtocol)
            this.currentRoute = Routing.emptyRoute();
        else
            this.currentRoute = this.parse(window.location.href);
    }

    public matches(url: string) {
        return this.isMatch(url, this.currentRoute);
    }

    public isMatch(url: string, route: IRoute) {
        const parsed = this.parse(url);

        let pos = 0;
        for (const part of parsed.path) {
            const routePart = route.path[pos];
            pos++;

            if (part === "@*" && routePart && routePart[0] === "@")
                continue;

            if (part === "-" && !routePart)
                continue;

            if (part === "*" && routePart)
                continue;
            
            if (!routePart || part !== routePart)
                return false;
        }

        return true;
    }

    public parse(url: string) : IRoute {
        const parsed = new URL(url, "https://not-used");
    
        if (!parsed)
            console.log("Could not parse", url);

        if (parsed.pathname[0] === "/")
            parsed.pathname = parsed.pathname.substring(1);

        const path = parsed.pathname.split("/").filter(i => i !== "");

        const parameters = {};
        parsed.search.substring(1).split("&").forEach(parameter => {
            if (!parameter)
                return;

            const parts = parameter.split("=");
            parameters[parts[0]] = parts.length > 1 ? decodeURIComponent(parts[1].replace(/\+/g, "%20")) : "";
        });

        const result = {
            path: path,
            parameters: parameters,
            hash: parsed.hash
        };


        return result;
    }
}