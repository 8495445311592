import { BasePackageElement } from "features/packages/BasePackageElement";
import { PackageStore } from "features/manage/PackageStore";

export class BaseManagePackageElement extends BasePackageElement {
    protected navView = () => `
    `;

    protected async getPackages() {
        return new PackageStore().all();
    }
}