import { Routing, RouteChangeOrigin } from "lib/Routing";
import { AppContextStore } from "features/application-context/AppContextStore";
import { Feed } from "features/feeds";
import { MyFeedStore, FeedChangeDetail } from "features/feeds/MyFeedStore";
import { HotkeyStore } from "features/help/HotkeyStore";

//tslint:disable-next-line: no-unused-variable
export class FeedActivator {
    //tslint:disable-next-line: no-unused-variable
    private static _instance = new FeedActivator();
   
    private constructor() {
        window.requestIdleCallback(this.setupFeedCountUpdate);
        
        Routing.instance.onChange(async route => {
            if (Routing.instance.matches("@*/feeds")) {
                if (route.origin === RouteChangeOrigin.PageLoad) {
                    return;
                }

                if (!route.path[2])
                    return; 
                else {
                    const feedViewer = document.createElement("feed-viewer");
                    feedViewer.setAttribute("feed-slug", route.path[2]);
                    feedViewer.setAttribute("my-feed", "");

                    if (route.path[3])
                        feedViewer.setAttribute("entry-slug", route.path[3]);
                    
                    setTimeout(async () => {
                        await AppContextStore.instance.present("", feedViewer, "Feeds", "Matching");
                    }, 0);
                    
                }
            
            }
        });

        HotkeyStore.instance
            .confirm("previousArticle")
            .confirm("nextArticle");
    }

    private setupFeedCountUpdate = async () => {
        const updateCount = (allFeed: Feed) => {
            const countElements = [].slice.call(document.querySelectorAll("app-switch button[name=feeds] .count")) as HTMLElement[];
            countElements.forEach(countElement => {
                if (!allFeed.unread || allFeed.unread === 0) 
                    return countElement.innerText = "";

                countElement.innerText = allFeed.unread > 999 ? "99+" : allFeed.unread.toString();
            });
        } 

        const allFeed = await MyFeedStore.instance.getFeed("all");
        updateCount(allFeed);

        MyFeedStore.instance.addEventListener("feeds-changed", (event: CustomEvent<FeedChangeDetail>) => {
            const allFeed = event.detail.feeds.filter(feed => feed.id === "all")[0];
            if (allFeed) {
                updateCount(allFeed);
            }
        })

        window.addEventListener("loaded", async () => {
            updateCount(await MyFeedStore.instance.getFeed("all"));
        }, { once: true });
    }
}