import { cityline } from "features/cityline";
import { DialogController } from "features/dialog";
import { SettingsStore } from "features/settings/SettingsStore";

class VersionBreakerElement extends HTMLElement {
    async connectedCallback() {
        const version = await cityline.getFrame<string>("minimum-client-version");
        
        const clientVersion = SettingsStore.instance.version.number();
    
        const result = this.compareVersions(version, clientVersion);

        while (result === 1) {
            await new DialogController().prompt({
                caption: "New version available",
                message: `Please go to the appstore and update to the newest version.`,
                options: ["Go"]
            })
            window.open("itms-apps://itunes.apple.com/app/id<app apple id>");
        }
    }

    private compareVersions (a, b) {
        const regExStrip0 = /(\.0+)+$/;
        const segmentsA = a.replace(regExStrip0, "").split(".");
        const segmentsB = b.replace(regExStrip0, "").split(".");
        const l = Math.min(segmentsA.length, segmentsB.length);
    
        for (let i = 0; i < l; i++) {
            const diff = parseInt(segmentsA[i], 10) - parseInt(segmentsB[i], 10);
            if (diff) {
                return diff;
            }
        }
        return segmentsA.length - segmentsB.length;
    }
}
customElements.define("version-breaker", VersionBreakerElement); 