import "./AnchorNavigatorElement-style.less";

interface menuItem {
    title: string;
    id: string;
    children?: menuItem[];
}


class AnchorNavigatorElement extends HTMLElement {
    connectedCallback() {
        const source = document.querySelector(this.getAttribute("target"));
        const items: menuItem[] = [];
        const h1s: HTMLElement[] = [].slice.call(source.querySelectorAll("h1, h2"));

        let current: menuItem;

        for (const element of h1s) {
            if (element.nodeName === "H1") {
                current = {
                    title: element.innerText,
                    children: [],
                    id: element.id
                };

                items.push(current);
            } else {
                if (current) {
                    current.children.push({
                        title: element.innerText,
                        id: element.id
                    });
                }
            }
        }


        this.innerHTML = `
            <ul>
                ${items.map(this.itemView).join("")}
            </ul>
        `;
    }

    private itemView = (item: menuItem) => `
        <li>
            <a href="#${item.id}">${item.title}</a>
            <ul>${item.children ? item.children.map(this.itemView).join("") : ""}</ul>
        </li>
    `;
}

customElements.define("anchor-navigator", AnchorNavigatorElement);