import "./RoundCheckElement-style";

class RoundCheckElement extends HTMLElement {
    connectedCallback() {
        this.innerHTML = require("!!raw-loader!image/check.svg");
        this.addEventListener("click", () => this.toggle());
    }

    private toggle() {
        if (this.hasAttribute("checked"))
            this.removeAttribute("checked");
        else
            this.setAttribute("checked", "");
        
        this.dispatchEvent(new Event("change"));
    }
}

customElements.define("round-check", RoundCheckElement);
