export class SidebarActivator {
    static toggleElement(tagname: string, factory: () => HTMLElement) {
        const sidebar = document.querySelector("side-bar") as HTMLElement;
        const existing = sidebar.querySelector(tagname) as HTMLElement;

        if (existing) {
            sidebar.removeAttribute("open");
        } else {
            this.showElement(factory());
        }
    }

    static async toggleElementAsync(tagname: string, factory: () => Promise<HTMLElement>) {
        const sidebar = document.querySelector("side-bar") as HTMLElement;
        const existing = sidebar.querySelector(tagname) as HTMLElement;

        if (existing) {
            sidebar.removeAttribute("open");
        } else {
            this.showElement(await factory());
        }
    }

    static showElement(element: HTMLElement) {
        const sidebar = document.querySelector("side-bar") as HTMLElement;
        sidebar.innerHTML = "";
        sidebar.appendChild(element);
        sidebar.setAttribute("open", "");
    }
}