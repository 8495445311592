
class HiddenSideMenuElement extends HTMLElement {
    connectedCallback() {
        document.body.removeAttribute("show-side-menu");
        document.body.setAttribute("no-side-menu", "");
        document.documentElement.style.setProperty("--side-menu-width", "0");
    }

    disconnectedCallback() {
        document.body.removeAttribute("no-side-menu");
    }
}

customElements.define("hidden-side-menu", HiddenSideMenuElement);