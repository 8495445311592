import { UrlProvider } from "lib/UrlProvider";
import { ILoginProvider } from "./ILoginProvider";
import { LoginResponse } from "../Model";
import { Pipeline, Request } from "lib/HTTP";
import { Plugins, PluginListenerHandle } from "@capacitor/core";
import { BrowserInfo } from "lib/Browser";
const { Browser } = Plugins;
import { App } from '@capacitor/app';

export class TwitterIOSLoginProvider implements ILoginProvider {
    getName = () => "TwitterNativeLoginProvider";

    async login(): Promise<LoginResponse> {
        const requestTokenUrl = `${new UrlProvider().root}/api/external-login/twitter${BrowserInfo.isApp() ? "?app=true" : ""}`;

        const response = await fetch(requestTokenUrl);

        if (!response.ok)
            throw new Error("Unable");

        const url = await response.json();


        return new Promise(async (resolve, reject) => {
            let appListener: PluginListenerHandle;
            let browserListener: PluginListenerHandle;
            let messageHandler;

            try {
                appListener = App.addListener("appUrlOpen", async (data) => {

                    appListener?.remove();

                    const prefix = "linkstacks://twitter/?callback=";
                    if (data.url.startsWith(prefix)) {
                        const url = data.url.substring(prefix.length);
                        try {
                            await Browser.close();
                        } catch (error) {
                            console.log("Call to close browser failed", error);
                        }
                        
                        const response = await fetch(`${new UrlProvider().root}/api/external-login/twitter`, {
                            method: "POST",
                            credentials: "include",
                            headers: {
                                "content-type": "text/json"
                            },
                            body: JSON.stringify({
                                callbackUrl: url
                            })
                        });

                        if (!response.ok)
                            reject("Unable to login");

                        const loginResponse = await response.json() as LoginResponse;
                        resolve(loginResponse);
                    }
                });


                messageHandler = async (event: MessageEvent) => {
                    if (typeof (event.data) !== "string") {
                        reject("Window closed");
                        return;
                    }

                    const result = event.data as String;

                    if (result.indexOf("denied") !== -1) {
                        reject("Window closed");
                        return;
                    }

                    const response = await fetch(`${new UrlProvider().root}/api/external-login/twitter`, {
                        method: "POST",
                        credentials: "include",
                        headers: {
                            "content-type": "text/json"
                        },
                        body: JSON.stringify({
                            callbackUrl: result
                        })
                    });

                    if (!response.ok)
                        reject("Unable to login");

                    const loginResponse = await response.json() as LoginResponse;
                    resolve(loginResponse);
                };

                window.addEventListener("message", messageHandler);

                browserListener = Browser.addListener("browserFinished", () => {
                    browserListener?.remove();
                    reject("Window closed");
                });

                await Browser.open({
                    url: url,
                    presentationStyle: "popover"
                });
                
                //reject("Window closed");
            }
            catch (error) {
                reject("Unable to login");
            }
            finally {
            
            }
        });
    }
}



