import "./AddExternalLinkElement-style";

import { Formatting } from "lib/Formatting";

class AddExternalLinkElement extends HTMLElement {
    private escape = Formatting.htmlEscape;
    
    private linkText() {
        return this.hasAttribute("link-text") ? this.getAttribute("link-text") : "";
    }

    private checkAndCloseKeyboard = (e : KeyboardEvent) => {
        if (e.key === "Escape")
            this.close();
    }

    connectedCallback() {
        this.classList.add("uni-popup");
        this.classList.add("uni-form");
        
        this.innerHTML = this.view();

        this.setupDOM();
        setTimeout(() => {
            document.body.addEventListener("keydown", this.checkAndCloseKeyboard);
            (this.querySelector("[name=link]") as HTMLInputElement).focus();
        });
        
       
    }

    disconnectedCallback() {
        document.body.removeEventListener("keydown", this.checkAndCloseKeyboard);
    }
    
    private close() {
        this.remove();
    }

    private setupDOM() {
        // [].slice.call(this.querySelectorAll("[name=add-link]")).forEach( (button: HTMLButtonElement) => {
        //     if (button.hasAttribute("disabled"))
        //         return;

        //     button.addEventListener("click", async () => this.addLink());
        // });

        [].slice.call(this.querySelectorAll("[name=cancel]")).forEach( (button: HTMLButtonElement) => {
            button.addEventListener("click", () => this.close());
        });
    }

    // private addLink() {
    //     const url = this.querySelector("[name=link]") as HTMLInputElement;
    //     const text = this.querySelector("[name=link-text]") as HTMLInputElement;

    //     this.dispatchEvent(new CustomEvent("link-added", { detail: {
    //         url: url.value,
    //         text: text.value
    //     }}));
    //     this.close();
    //     // this.dispatchEvent(new CustomEvent("link-added", { detail: "hej" }));
    // }

    private view = () => this.escape `
        <section>
            <header>
                <div>
                    <button type=button name=cancel>Cancel</button>
                </div>
                <div class=caption>Add link</div>
                <div><button forward type=button name=add-link>Add${require("!!raw-loader!image/arrow-right-20-v1.svg")}</button></div>
                
            </header>

            <fieldset>
                <legend>Destination link</legend>
                <div class="field">
                    <input type=text placeholder="https://www ..." name=link>
                </div>
            </fieldset>

            <fieldset>
                <legend>Link text</legend>
                <div class="field">
                    <input type=text placeholder="Link text" name=link-text value="${this.linkText()}">
                </div>
            </fieldset>
            

            <div class=action>
                <button type=button class=inactive name=cancel>Cancel</button>    
                <button type=button class=power name=add-link>Add link</button>
            </div>            
       
        </section>
    `;
}


customElements.define("add-external-link", AddExternalLinkElement);

