import "./SiteLoginElement-style";
import { AuthenticationStore } from "features/authentication";
import * as formsStyle from "../forms/forms.module.less";
import * as style from "./SiteRegisterElement-module.less"
import { Routing } from "lib/Routing";
import { DialogController } from "features/dialog";
class SiteSetPasswordElement extends HTMLElement {
    private named = <T extends HTMLElement> (name: string): T => this.querySelector(`[name=${name}]`) as T;
   
    private form: HTMLFormElement;
    private setPasswordButton: HTMLButtonElement;
    private passwordGroup: HTMLElement;
    private passwordRepeatGroup: HTMLElement;
    
    connectedCallback() {
        this.innerHTML = this.view();
        this.classList.add(formsStyle.loginControl);

        this.form = this.querySelector("form");
        this.setPasswordButton = this.named("set-password");
        this.passwordGroup = this.named("password");
        this.passwordRepeatGroup = this.named("repeat-password");
    
        const checkPassword = () => {
            if (!this.passwordGroup.hasAttribute("touched"))
                return;

            if (!this.passwordRepeatGroup.hasAttribute("touched"))
                return;

            if (this.passwordGroup.getAttribute("value") !== this.passwordRepeatGroup.getAttribute("value")) {
                this.passwordGroup.setAttribute("error-text", "Passwords must match.");
                this.passwordRepeatGroup.setAttribute("error-text", "Passwords must match.");
            } else {
                this.passwordGroup.setAttribute("error-text", "");
                this.passwordRepeatGroup.setAttribute("error-text", "");
            }   
        }

        this.passwordGroup.addEventListener("validate", checkPassword);
        this.passwordRepeatGroup.addEventListener("validate", checkPassword);
      

        this.addEventListener("blur", this.checkForm);
        this.addEventListener("validity", this.checkForm);
    
        this.form.addEventListener("submit", async (event) => {
            event.preventDefault();
            await this.resetPassword();
        });

        setTimeout(this.checkForm, 200);
    }

    private resetPassword = async () => {
        if (this.setPasswordButton.hasAttribute("disabled")) 
            return;

        try {
            this.setPasswordButton.setAttribute("state", "busy");
            await this.doResetPassword(this.passwordGroup.getAttribute("value"));
        } 
        finally{
            this.setPasswordButton.setAttribute("state", "done");
        }
    }

    private async doResetPassword(password: string) {
        const result = await AuthenticationStore.instance.resetPassword(this.getAttribute("user-id"), this.getAttribute("code"), password);
        
        if (result) {
            await new DialogController().prompt({
                caption: "New password set",
                message: "You can now login with your new password",
                options: ["Ok"]
            });
        } else {
            await new DialogController().prompt({
                caption: "Unable to set new password",
                message: "Please try again and contact us if the problem persists.",
                options: ["Ok"]
            });
        }
        

        Routing.instance.go("/login");
    }

    private isFormFullyFilled = () => this.querySelectorAll("input[required]").length === this.querySelectorAll("input[touched]").length;

    private checkForm = () => {
        if (!this.isFormFullyFilled())
            return;

        this.setPasswordButton.toggleAttribute("disabled", !this.form.checkValidity());
    };


    view = () => `
        <form autocapitalize=none>
            <div name=logo class="${style.logo}">
                <a href="/">${require("!!raw-loader!image/logo-solo.svg")}</a>
            </div>
            <header class="${style.registerHeader}">
                <h1>Please enter a new password</h1>
            </header>
            <section>
        
                <form-group 
                    name=password
                    label=Password 
                    type=password
                    autocomplete=new-password></form-group>

                <form-group 
                    name=repeat-password
                    label="Repeat password" 
                    type=password
                    autocomplete=new-password></form-group>

                <progress-button 
                    disabled 
                    name=set-password 
                    busy-text="Setting password ..."
                    success-text="Finished" 
                    disable-query="input, button">Set password</progress-button>
            </section>
            <footer>
             
            </footer>
        </form>
    `;
}

customElements.define("site-set-password", SiteSetPasswordElement);