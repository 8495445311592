import "./Dialog-style"; 
import { BaseDialogViewController } from "features/dialog";
import { Formatting } from "lib/Formatting";

export interface ExtendedOption {
    name: string;
    href?: string;
    target?: string;
}

export interface DialogContent {
    caption: string;
    message: string;
    options?: string[];
    extendedOptions?: ExtendedOption[];
}

export class DialogController extends BaseDialogViewController {
    private escape = Formatting.htmlEscape;
    private content: DialogContent;
    private resolve: (string) => void;
    protected targetLocation: InsertPosition = "beforeend";
    constructor() {
        super();
    }

    getTitle(): string {
        return this.content.caption;
    }

    cancel() {
        this.resolve("cancel");
        super.cancel();
    }

    public async prompt(content: DialogContent) : Promise<string> {
        this.content = content;
        await this.show();
        return await new Promise<string>((resolve, reject) => {
            this.resolve = resolve;               
        });
    }

    afterRender() {
        super.afterRender();

        [].slice.call(this.dialog.querySelectorAll("button")).forEach((element: HTMLButtonElement) => {
            element.addEventListener("click", (event) => {
                super.close();
                const button = <HTMLButtonElement>event.target;
                this.resolve(button.name);
            });
        });
    }

    private inactive = (text: string) => text.toLocaleLowerCase() === "cancel";

    renderSection = () => this.escape`${this.content.message.split("\n").map(m => `<p>${m}</p>`)}`

    renderFooter = () => this.content.extendedOptions ? this.renderFooterExtended() : this.escape`${this.content.options.map(m => `<button type=button class="${this.inactive(m) ? "power-inactive" : "power"}" name="${m}">${m}</button>`)}`;
    renderFooterExtended = () => this.escape`${this.content.extendedOptions.map(m => m.href ?
        `<a href="${m.href}" target="${m.target}" class="${this.inactive(m.name) ? "power-inactive" : "power"}" name="${m.name}">${m.name}</a>`
        : `<button type=button class="${this.inactive(m.name) ? "power-inactive" : "power"}" name="${m.name}">${m.name}</button>`)}`;
}
