import "./UserLoginElement-style";
import { AuthenticationStore } from "./AuthenticationStore";


class SocialProvidersElement extends HTMLElement {
    
    async connectedCallback() {
        this.innerHTML = this.view();
        this.setupEvents()
    }
   
    private setupEvents() {
        [].slice.call(this.querySelectorAll("[name=provider]")).forEach( (button: HTMLButtonElement) => {
            button.addEventListener("click", async () => {
                const providerId = button.getAttribute("value");
                const result = await AuthenticationStore.instance.loginExternal(providerId);
                if (result.status === "success")
                    this.dispatchEvent(new CustomEvent("success", { detail: result }));
                else if (result.status === "failure")
                    this.dispatchEvent(new CustomEvent("complete-external", { detail: result }));
            });
        }); 
    }

    private view = () => `
        <button type="button" class="light google" id="google" name="provider" value="Google" title="Log in using your Google account">
            ${require("!!raw-loader!image/icons/google.svg")}Google
        </button>
        <button type="button" class="light facebook" id="facebook" name="provider" value="Facebook" title="Log in using your Facebook account">
            ${require("!!raw-loader!image/icons/facebook.svg")}Facebook
        </button>
        <button type="button" class="light twitter" id="twitter" name="provider" value="Twitter" title="Log in using your Twitter account">
            ${require("!!raw-loader!image/icons/twitter.svg")}Twitter
        </button>        
    `;
}

customElements.define("social-providers", SocialProvidersElement);