import "./AddAnythingElement-module.less";
import { AuthenticationStore } from "features/authentication";
import { ClassTypename, Typename } from "lib/Types";
import { DOM } from "lib/DOM";
import { DialogController } from "features/dialog";
import { HotkeyStore } from "features/help/HotkeyStore";
import { IntentPayload } from "lib/Phonegap/IntentPlugin";
import { LinkStore } from "features/links";
import { Routing } from "lib/Routing";
import { SidebarActivator } from "features/side-bar/SidebarActivator";
import { UrlProvider } from "lib/UrlProvider";

import { Plugins } from "@capacitor/core";

const { SendIntent } = Plugins;

import { App } from '@capacitor/app';

@ClassTypename("AddAnythingActivator")
//tslint:disable-next-line: no-unused-variable
class AddAnythingActivator {
    //tslint:disable-next-line: no-unused-variable
    private static _instance = new AddAnythingActivator();
    public static get instance() { return AddAnythingActivator._instance; }

    private constructor() {
        this.registerHotkeys();
        this.registerPaste();

        // these registration has to be synchronous to be able to watch for added bookmarks
        this.registerPhonegapIOS();
        this.registerPhonegapAndriod();

        window.requestIdleCallback(this.registerBookmarklet, { timeout: 100 });


        console.log("Subscribe");
        App.addListener("appUrlOpen", this.handleAddUrl);
          
    }

    private handleAddUrl = async (data: any) => {
        console.log("App url open", data);
        if (!data.url) 
            return;

        const route = Routing.instance.parse(data.url);
        if (!route.parameters)
            return;

        if (route.parameters.url === undefined) // google login sdk will make this happen
            return;

        if (this.handlePotentialUrl(route.parameters.url, route.parameters.title))
            Routing.instance.go(await new UrlProvider().inboxSiteLocation(), AddAnythingActivator[Typename]);
    }

    private registerPhonegapIOS() {
        (<any>window).handleOpenURL = async (url) => {
            if (url) {
                const route = Routing.instance.parse(url);

                if (!route.parameters)
                    return;

                if (route.parameters.url === undefined) // google login sdk will make this happen
                    return;

                if (this.handlePotentialUrl(route.parameters.url, route.parameters.title))
                    Routing.instance.go(await new UrlProvider().inboxSiteLocation(), AddAnythingActivator[Typename]);

            }
        };
    }

    private async registerPhonegapAndriod() {
        await DOM.ready();
        await AuthenticationStore.instance.whenSignedIn();
        await AuthenticationStore.instance.isAuthenticated();

        if (SendIntent) {
            SendIntent.addListener("appSendActionIntent", async intent => await this.handleIntent(intent));
        }
    }


    async handlePotentialUrl(url: string, title?: string) {
        await DOM.ready();
        await AuthenticationStore.instance.whenSignedIn();
        await AuthenticationStore.instance.isAuthenticated();

        // only show one dialog
        if (document.querySelector("add-link"))
            return false;


        const element = document.createElement("add-anything");
        element.setAttribute("url", url);
        element.setAttribute("title", title ? title : "");

        setTimeout(async () => { // async to avaoid double-paste in chrome
            SidebarActivator.showElement(element);
        }, 0);

        return true;
    }

    private registerPaste = () => {
        document.addEventListener("paste", (event: ClipboardEvent) => {
            const targetElement = event.target as HTMLElement;

            if (!event || !event.clipboardData)
                return;

            const item = event.clipboardData.getData("text/plain");

            if (!item)
                return;

            const url = item.trim();

            if (url.toLowerCase().indexOf("http") !== 0)
                return;

            if (["INPUT", "TEXTAREA"].indexOf(targetElement.tagName) !== -1)
                return;

            const element = document.createElement("add-anything");
            element.setAttribute("url", url);

            setTimeout(async () => { // async to avaoid double-paste in chrome
                SidebarActivator.showElement(element);
            }, 0);
        });
    }

    private async handleIntent(intent: IntentPayload) {
        console.log("Intent", intent);

        if (!intent)
            return;

        if (!intent.extras)
            return;

        const urlData = intent.extras["com.miui.share.extra.url"] || intent.extras["android.intent.extra.TEXT"];
        const title = intent.extras["android.intent.extra.SUBJECT"] || intent.extras["android.intent.extra.TEXT"];

        const matches = urlData?.match(/\bhttps?:\/\/\S+/gi);

        if (matches && matches.length > 0) {
            const url = matches[0];

            if (url)
                if (await this.handlePotentialUrl(url, title))
                    Routing.instance.go(await new UrlProvider().inboxSiteLocation(), AddAnythingActivator[Typename]);
        }
    }

    private directClose(linkAdded: boolean) {
        if (window.opener) {
            if (linkAdded)
                window.opener.postMessage("link-added", "*");
            else
                window.opener.postMessage("cancel", "*");
        }
        window.close();
    }


    private registerBookmarklet = async () => {
        const route = Routing.instance.route;

        if (!route.parameters)
            return;

        if (route.parameters.url === undefined)
            return;

        if (route.parameters.url.substring(0, 4) === "http") {
            const option = await new DialogController().prompt({
                caption: "Add this link?",
                message: route.parameters.url,
                options: ["Cancel", "Add as ...", "Add link"]
            })

            if (option === "Add as ...") {
                await this.handlePotentialUrl(route.parameters.url, "");
            } else if (option === "Add link") {
                await LinkStore.instance.add(<any>{ url: route.parameters.url }, "inbox");
                this.directClose(true);
            } else {
                this.directClose(false);
            }
        }
    }

    private registerHotkeys = () => {
        HotkeyStore.instance.registerGlobal("addUrl", () => SidebarActivator.toggleElement("add-anything", () => document.createElement("add-anything")));

        window.addEventListener("keydown", async (event: KeyboardEvent) => {
            if (event.altKey && (event.keyCode === 77)) {
                await this.handlePotentialUrl(`https://www.apple.com?source=dimmer&source=dimmer&source=dimmer&source=dimmer&source=dimmer&source=dimmer&source=dimmer&source=dimmer&source=dimmer&source=dimmer&source=dimmer&source=dimmer&source=dimmer&source=dimmer&source=dimmer&source=dimmer&source=dimmer&source=dimmer&source=dimmer&source=dimmer&source=dimmer&source=dimmer&source=dimmer&${new Date().getTime()}`, "Linkstacks");
            }
        });
    }
}

// this is important to force quick creation of the instance, able to handle incoming urls
//tslint:disable-next-line: no-unused-variable
const _ = AddAnythingActivator.instance;
// (<any>window).handleOpenURL("app://linkstacks?url=http://www.test.dk");
