import { Pipeline, Request } from "lib/HTTP"
import { UrlProvider } from "lib/UrlProvider";
import * as emailStyle from "./EmailSettingsElement-module.less";
import { UserPreferenceStore, UserPreferenceFlag } from "features/user-preferences/UserPreferenceStore";

class EmailSettingsElement extends HTMLElement {
    private urlProvider = new UrlProvider();

    async connectedCallback() {
        const emailSetting = await UserPreferenceStore.instance.isSet(UserPreferenceFlag.DigestEmail);
        const newsletterSetting = await UserPreferenceStore.instance.isSet(UserPreferenceFlag.Newsletter);
        this.innerHTML = this.view(emailSetting, newsletterSetting);
        this.registerEvents();
    }

    private registerEvents() {
        const digest = <HTMLInputElement>this.querySelector("[name=backup-email]");

        if (digest)
            digest.addEventListener("click", async (event) => {
                digest.disabled = true;

                try {
                    await new Pipeline().fetch(`${this.urlProvider.root}/api/account/digest`, Request.post.authenticate().setJSON({ enabled: digest.checked }));
                    digest.disabled = false;
                } catch(e){
                    digest.checked = !digest.checked;
                    digest.disabled = false;
                }
            });

        const newsletter = <HTMLInputElement>this.querySelector("[name=newsletter-email]");

        if (newsletter)
        newsletter.addEventListener("click", async (event) => {
                newsletter.disabled = true;

                try {
                    await new Pipeline().fetch(`${this.urlProvider.root}/api/account/newsletter`, Request.post.authenticate().setJSON({ enabled: newsletter.checked }));
                    newsletter.disabled = false;
                } catch(e){
                    newsletter.checked = !newsletter.checked;
                    newsletter.disabled = false;
                }
            });
    }

    view = (emailEnabled, newsletterEnabled) => `
        <div class="box browser-notifications ${emailStyle.emailSettings}">
            <header>Emails</header>
            <div class=description>
                Select what emails you wish to recieve 
            </div>

            <label for=backup-email>
                <input type="checkbox" id="backup-email" name="backup-email"${emailEnabled ? " checked" : ""} />
                Weekly backup mail
            </label>

            <label for=newsletter-email>
                <input type="checkbox" id="newsletter-email" name="newsletter-email"${newsletterEnabled ? " checked" : ""} />
                Newsletter
            </label>

           
        </div>
    `;
}

customElements.define("email-settings", EmailSettingsElement);
