import "./ProgressiveImageElement-style";
import "intersection-observer";
import ResizeObserver from "resize-observer-polyfill";

interface Size {
    width: number;
    height: number;
}
/*

    # Attributes:
    
        - source-image (required)
            this is the original (large) image

        - width (required)
            wanted width, can be one of number, "screen"

        - height (required)
            wanted height
            
        - pico-image (optional)
            this is a base 64 encoded representation of the image
        
        - source-width (optional)
            the width of the original image
        
        - source-height (optional)
            the height of the original image

        - allow-zoom (optional)
            when set, user can click to zoom

        - lazy-load (optional)
            only load when within viewport

        - show-loader (optional)
            show loader if pico-image is not available

    # Renders images - supports:

        - pico image (small replacement image before real image is shown)

        - click to zoom to fullscreen

        - scaling with cache-friendly rounding

    # Soon:
    
        - lazy loading (will observe when image is within reach and load)
    
        - loader

        - fill color

        - random fill based on url


    # Server side rendered html is expected to render like this for fallback:
    
    ```html
        <progressive-image ....>
            <img src="fallback.jpg" />
        </progressive-image>
    ```
    
*/
class ProgressiveImageElement extends HTMLElement {
    private resizeObserver: ResizeObserver;
    private picoImage: string;
    private loader: HTMLElement;
    private sourceImage = () => this.getAttribute("source-image");
    private sourceWidth = () => parseInt(this.getAttribute("source-width"));
    private sourceHeight = () => parseInt(this.getAttribute("source-height"));
    private clickToZoom = () => this.hasAttribute("allow-zoom");
    private targetSize: Size;
    private maxHeightPercent = .30;
    private imageUrl: string;
    private imageElement: HTMLImageElement;
    private lazyLoad = false;
    private devicePixelRatio = 2; // we hardcode since it is jpg and we don't want too many sizes
    private observer: IntersectionObserver;
    private overlay: HTMLElement;

    private parseTargetSize() : Size {
        if (this.hasAttribute("auto-size")) {
            const size = {
                width: this.offsetWidth,
                height: this.offsetWidth
            }

            this.style.setProperty("--progressive-image-width", `${size.width}px`);
            this.style.setProperty("--progressive-image-height", `${size.height}px`)
            return size;
        }


        if (this.hasAttribute("width") && this.hasAttribute("height")) {

            this.style.setProperty("--progressive-image-width", `${this.getAttribute("width")}px`);
            this.style.setProperty("--progressive-image-height", `${this.getAttribute("height")}px`);

            return { width: parseInt(this.getAttribute("width")), height: parseInt(this.getAttribute("height")) };
        }

        const style = window.getComputedStyle(this);
        const width = parseInt(style.getPropertyValue("--progressive-image-width"));
        const height = parseInt(style.getPropertyValue("--progressive-image-height"));

        if (!isNaN(width) && !isNaN(height))
            return { width: width, height: height };
    }

    connectedCallback()  {
        this.picoImage = this.getAttribute("pico-image");
        this.lazyLoad = this.hasAttribute("lazy-load");

        if (this.lazyLoad) {
            this.observer = new IntersectionObserver(this.loadImageHandler);
            this.observer.observe(this);
        }

        this.removeAttribute("pico-image");

        this.targetSize = this.parseTargetSize();
        if (!this.targetSize)
            this.targetSize = this.setSizeFromViewport();
        
        this.imageUrl = this.translateUrl(this.sourceImage());
        this.innerHTML = this.view();

        // grab elements
        this.loader = this.querySelector("[name=loader]");
        this.overlay  = this.querySelector("[name=overlay]");
        this.imageElement = this.querySelector("img") as HTMLImageElement;
        
        if (!this.lazyLoad)
            this.loadImage();

        if (this.clickToZoom())
            this.addEventListener("click", this.enlargeHandler);

        (<any>IntersectionObserver).prototype.POLL_INTERVAL = 1000;

        if (this.hasAttribute("auto-size")) {
            this.resizeObserver = new ResizeObserver(() => window.requestAnimationFrame(this.resizeHandler));
            this.resizeObserver.observe(this);
        }
    }

    private resizeHandler = () => {
        this.parseTargetSize();
    }

    setSizeFromViewport() : Size {
        // calculate acutal size
        const sizingElement = document.body;
        const widthRatio = (sizingElement.offsetWidth - 30) / this.sourceWidth();
        const heightRatio = sizingElement.offsetHeight * this.maxHeightPercent / this.sourceHeight();
        let ratio = Math.min(widthRatio, heightRatio);

        if (ratio > 1) {
            ratio = 1;
            return { width: this.sourceWidth() * ratio, height: this.sourceHeight() * ratio };
        }


        const width = this.sourceWidth() * ratio;
        const height = this.sourceHeight() * ratio;
        
        // round numbers to ease caching
        // width = Math.floor(width/10)*10;
        // height = Math.floor(height/10)*10;
        return { width, height };
    }

    private translateUrl(sourceUrl: string) {
        // todo: serve original if same size

        if (!sourceUrl)
            return sourceUrl;

        const width = this.makeSizeCachefriendly(this.targetSize.width*this.devicePixelRatio).toString();
        const height = this.makeSizeCachefriendly(this.targetSize.height*this.devicePixelRatio).toString();
        

        return sourceUrl
            .replace("{method}", "scaled")
            .replace("{width}", width)
            .replace("{height}", height) 
            .replace("/raw/", `/scaled/${width}/${height}/`)
            .replace("/cropped/240/240/", `/cropped/${width}/${height}/`);
    } 

    private makeSizeCachefriendly = (size: number) => Math.ceil(size/10)*10;
    

    disconnectedCallback() {
        this.removeEventListener("click", this.enlargeHandler);
        this.imageElement?.removeEventListener("load", this.imageLoadedHandler);
        this.imageElement?.removeEventListener("error", this.imageLoadedHandler);
        this.observer?.disconnect();
        this.resizeObserver?.disconnect();
    }

    private imageErrorHandler = () => {
        this.setAttribute("loaded", "");
        this.imageElement.remove();
        setTimeout(() => {
            window.requestIdleCallback(() => {
                this.loader?.remove();
                this.overlay?.remove();
            });
        }, 1000);
    };

    private imageLoadedHandler = () => {
        this.setAttribute("loaded", "");
        setTimeout(() => {
            window.requestIdleCallback(() => {
                this.loader?.remove();
                this.overlay?.remove();
            });
        }, 1000);
    };

    private enlargeHandler = () => {
        const mediaViewer = document.createElement("media-viewer");
        mediaViewer.setAttribute("pico-image", this.picoImage);
        mediaViewer.setAttribute("scaled-url", this.imageUrl);
        mediaViewer.setAttribute("original-url", this.sourceImage());
        document.body.appendChild(mediaViewer);
    }

    private showLoader = () => {
        if (this.picoImage)
            return false;

        return this.hasAttribute("show-loader");
    }

    private loadImageHandler = (entries: IntersectionObserverEntry[]) => {
        if (!entries[0].isIntersecting)
            return;
        
        this.loadImage();
        this.observer.disconnect();
    }

    private loadImage = () =>{
        if (!this.imageUrl)
            return;

        this.imageElement = document.createElement("img");
        this.imageElement.addEventListener("load", this.imageLoadedHandler, { once: true});
        this.imageElement.addEventListener("error", this.imageErrorHandler, { once: true});
        this.imageElement.src = this.imageUrl;
        this.append(this.imageElement);
    }

    private view = () => `
        ${this.showLoader() ? `
            <div name=loader></div> 
        ` : ""}
        ${this.picoImage && this.picoImage !== "undefined" ? `
            <div name=overlay style="background-image:url(data:${this.picoImage});"></div>
        ` : ""}
    `;
}



customElements.define("progressive-image", ProgressiveImageElement);