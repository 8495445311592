
import "./UserFeedbackElement-style";
import { Formatting } from "lib/Formatting";
import { DialogController } from "features/dialog";
import { cityline } from "features/cityline";
import { Pipeline, Request } from "lib/HTTP";
import { UrlProvider } from "lib/UrlProvider";
import { SettingsStore } from "features/settings/SettingsStore";

class UserFeedbackElement extends HTMLElement {
    private escape = Formatting.htmlEscape;
    private screenshot: string;
    private includeScreenshot = false;
    private urlProvider = new UrlProvider();
    private screenshotAvailable = () => navigator.userAgent.indexOf("Trident") === -1;
   
    private checkAndCloseKeyboard = (e : KeyboardEvent) => {
        if (e.key === "Escape")
            this.close();
    }

    connectedCallback() {
        this.innerHTML = this.view();
        this.setAttribute("data-html2canvas-ignore", "");

        this.setupDOM();
        this.checkValidity();
        setTimeout(() => document.body.addEventListener("keydown", this.checkAndCloseKeyboard));
    }

    disconnectedCallback() {
        document.body.removeEventListener("keydown", this.checkAndCloseKeyboard);
    }

    private async toggleScreenshot() {
        this.includeScreenshot = !this.includeScreenshot;

        if (this.includeScreenshot && !this.screenshot) 
            this.screenshot = await this.takeScreenshot();

        const screenshotField = this.querySelector("[name=screenshot]") as HTMLElement;
        if (this.includeScreenshot) {
            const image = screenshotField.querySelector("img") as HTMLImageElement;
            image.src = this.screenshot;
            screenshotField.style.display = "flex";
        } else {
            screenshotField.style.display = "none";
        }
    }

    private async send() {
        const commentField = this.querySelector("[name=comment]") as HTMLTextAreaElement;

        if (commentField.value.toLocaleLowerCase().indexOf("mode=") === 0)
        {
            this.urlProvider.setTestMode(commentField.value.toLocaleLowerCase().substring(5));
            this.close();
            return;
        }
        
        const feedback: any = {
            userAgent: navigator.userAgent,
            cookieEnabled: navigator.cookieEnabled,
            clientVersion: SettingsStore.instance.version.toString(),
            comment: commentField.value
        };

        try {
            feedback.serverVersion = JSON.stringify(await cityline.getFrame("version"));
        } catch(error) {

        }

        if (this.includeScreenshot)
            feedback.screenshot = this.screenshot;

        const section = this.querySelector("section");
        let response;
        try {
            section.setAttribute("busy", "");
            response = await new Pipeline().fetch(`${this.urlProvider.root}/api/feedback/send`, Request.post.authenticate().setJSON(feedback));
        }
        finally {
            section.removeAttribute("busy");
        }
        if (!response.ok) {
            await new DialogController().prompt({
                caption: "Unable to send feedback",
                message: "Sorry we were unable to send feedback at this time, please try again or contact us via Twitter or email.",
                options: ["Ok"]
            });    
        } else {
            this.close();
            await new DialogController().prompt({
                caption: "Thank you!",
                message: "We appreciate your feedback.",
                options: ["Ok"]
            });    
        }

    }

    private close() {
        this.remove();
    }

    private async takeScreenshot() {
        // start import
        const html2canvasPromise = import( /* webpackChunkName: "html2canvas" */ "html2canvas");

        await new DialogController().prompt({
            caption: "Taking screenshot",
            message: "Please wait while we grab a screenshot.",
            options: ["Go"]
        });
        try {
            (<any>window).cloning = true
            this.setAttribute("screenshotting", "");
            
            document.body.insertAdjacentHTML("beforeend", `<msg data-html2canvas-ignore name="please-wait"><c class=loader data-html2canvas-ignore></c><c data-html2canvas-ignore>Please wait ...</c></msg>`);
            
            const html2canvas = await html2canvasPromise;

            const canvas = await html2canvas.default(document.body, {
                backgroundColor: "white"
            });
            const imageData = canvas.toDataURL("image/png");
            canvas.remove();
            
            return imageData;
        } catch(error) {
            await new DialogController().prompt({
                caption: "Taking screenshot",
                message: "Unable to take screenshot at this time.",
                options: ["Ok"]
            });
        } finally {
            (<any>window).cloning = false;
            this.removeAttribute("screenshotting");
            document.querySelector("[name=please-wait]").remove();
        }
    }

    private checkValidity() {
        const commentField = this.querySelector("[name=comment]") as HTMLTextAreaElement;

        [].slice.call(this.querySelectorAll("[name=send]")).forEach( (button: HTMLButtonElement) => {
            if (commentField.validity.valid)
                button.removeAttribute("disabled");
            else
                button.setAttribute("disabled", "");
        });

        
    }

    private setupDOM() {
        const screenshot = this.querySelector("[name=include-screenshot] switch-button") as HTMLElement;
        if (screenshot)
            screenshot.addEventListener("change", async () => await this.toggleScreenshot());
        
        [].slice.call(this.querySelectorAll("[name=send]")).forEach( (button: HTMLButtonElement) => {
            if (button.hasAttribute("disabled"))
                return;

            button.addEventListener("click", async () => await this.send());
        });

        [].slice.call(this.querySelectorAll("[name=cancel]")).forEach( (button: HTMLButtonElement) => {
            button.addEventListener("click", () => this.close());
        });

        [].slice.call(this.querySelectorAll("input, textarea")).forEach( (button: HTMLButtonElement) => {
            button.addEventListener("keyup", () => this.checkValidity());
        });
    }

    private view = () => this.escape `
        <section>
            <header>
                <div>
                    <button type=button name=cancel>Cancel</button>
                </div>
                <div class=caption>Let's get in touch</div>
                <div>
                    <button type=button name=send>Send</button>
                </div>
                
            </header>

            <p>We're always open for any suggestions or just to have a chat.</p>

            <fieldset>
                <div class="field">
                    <textarea name=comment placeholder="Enter comment ..." required></textarea>
                </div>

                $${this.screenshotAvailable() ? `
                <div class="field" name="include-screenshot">
                    <label>Include screenshot</label>
                    <switch-button></switch-button>
                </div>` : ""}
                
                <div class="field" name=screenshot>
                    <img height=200 />
                </div>
            
            </fieldset>
            
            <p>Linkstacks version ${SettingsStore.instance.version.number()}.</p>


            <div class=action>
                <button type=button class=inactive name=cancel>Cancel</button>    
                <button type=button class=power name=send>Send feedback</button>
            </div>            
        </div>
        </section>
    `;
}


customElements.define("user-feedback", UserFeedbackElement);

