

class StyleGuideElement extends HTMLElement{
    connectedCallback() {
        this.classList.add("pop-frame");
        this.innerHTML = this.view([100,300,400,500,600]);
    }

    private view = (weights: number[]) => `
        <section>
            ${weights.map(weight => `
                <div style="font-weight:${weight}">
                    <div>${weight}</div>
                    <div style="font-family:-apple-system, BlinkMacSystemFont">Bogmærkelinje (system)</div>
                    <div style="font-family:helvetica">Bogmærkelinje (helvetica)</div>
                    <div style="font-family:'Open Sans'">Bogmærkelinje (open sans)</div>
                    <div style="font-family: sans-serif">Sans serif (sans-serif)</div>
                </div>
            `).join("")}


            <span style="font-weight:300; font-family:-apple-system, BlinkMacSystemFont, 'Open sans', sans-serif">300</span>
            <span style="font-weight:400; font-family:-apple-system, BlinkMacSystemFont, 'Open sans', sans-serif">400</span>
            <span style="font-weight:500; font-family:-apple-system, BlinkMacSystemFont, 'Open sans', sans-serif">500</span>
            <span style="font-weight:600; font-family:-apple-system, BlinkMacSystemFont, 'Open sans', sans-serif">600</span>
            
            
            <div>
            <span style="color: black; font-weight:400; font-family:-apple-system, BlinkMacSystemFont, 'Open sans', sans-serif">Lets see if this works:</span>
            <span style="color: black; font-weight:600; font-family:-apple-system, BlinkMacSystemFont, 'Open sans', sans-serif">Yes it does.</span>
            </div>

        </section>
    `;

}

customElements.define("style-guide", StyleGuideElement);

// ContentManager.instance.showBottom(document.createElement("style-guide"));

