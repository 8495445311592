import "./AnimationPlayStyle";
import { DOM } from "lib/DOM";

class AnimationPlayViewController {
    private clickHandler = (event) => {
        setTimeout(() => {
            const target = <HTMLElement>event.target;
            if (!target.classList || !target.classList.contains("anim"))
                return;
    
            target.classList.toggle("playing");
            target.blur();
    
            const img = target.querySelector("img");
            const sourceAttribute = img.attributes.getNamedItem("data-src");

            if (!sourceAttribute)
                return;

            const originalAttribute = img.attributes.getNamedItem("src");

            if (!originalAttribute)
                return;

            const original = originalAttribute.value;
            originalAttribute.value = sourceAttribute.value;
            sourceAttribute.value = original;
        });
        
    };

    constructor() {
        setTimeout(async () => this.setup());
    }

    private async setup() {
        await DOM.ready();
        document.body.addEventListener("click", this.clickHandler);
    }
}

//tslint:disable-next-line: no-unused-variable
const animationPlayViewController = new AnimationPlayViewController();
