import "./HotkeyViewerElement-style";
import { Formatting } from "lib/Formatting";
import { HotkeyStore, KeySet, Hotkey } from "./HotkeyStore";

class HotkeyViewerElement extends HTMLElement {
    public static ClassName = "HotkeyViewerElement";
    // protected titleText: string = "Keyboard shortcuts";
    private escape = Formatting.htmlEscape;
    private _areas = [
        { area: "global", descritpion: "Global" },
        { area: "bookmarks", descritpion: "Bookmarks" },
        { area: "feeds", descritpion: "Feeds" }
    ]

    async connectedCallback() {
        // super.connectedCallback();
        const keysets = HotkeyStore.instance.keySets;
        this.insertAdjacentHTML("beforeend", this.view(keysets));
    }   

    private hotKeyView = (hotkey: Hotkey) => this.escape `
        $${hotkey.altKey ? "<span class=hotkey>Alt</span> + " : ""}
        $${hotkey.ctrlKey ? "<span class=hotkey>Ctrl</span> + " : ""}
        <span class=hotkey>${hotkey.key?.toUpperCase() || hotkey.code.substring(3)}</span>`;


    private keyView = (keySet: KeySet) => this.escape`
        <div class=key-set>
            <div class=keys>$${keySet.hotkeys.map(this.hotKeyView).join(" or ")}</div>
            <div class=desc>${keySet.description}</div>
        </div>`;

    private areaView = (keySets: KeySet[], description: string) => this.escape`
        <h1>${description}</h1>
        $${keySets.map(this.keyView).join("")}   
    `;

    private view = (hotkeys: { [key: string]: KeySet[]  }  ) => this.escape`
        <div name=window-area>
            $${this._areas.map(a => this.areaView(hotkeys[a.area], a.descritpion)).join("")}
        </div>
    `;
}

customElements.define("hotkey-viewer", HotkeyViewerElement)
