import { cityline } from "features/cityline";
import EventTarget from "@ungap/event-target";
import { ClassTypename } from "lib/Types";
import { JsonFeedEntry } from "./JsonFeed";


interface EventMap {
    "available": JsonFeedEntry[];
}

@ClassTypename("UniversalLogStore")
export class UniversalLogStore {
    private eventTarget = new EventTarget();
    private static _instance = new UniversalLogStore();
    private _initializer: Promise<void>;
    private constructor() {
        this._initializer = this.initializer();
    }
    public static get instance() { return UniversalLogStore._instance; }
    private entries: JsonFeedEntry[];

    //private entries:{ [key: string]: JsonFeedEntry } = {};

    private async initializer() : Promise<void> {
        this.entries = await cityline.getFrame<JsonFeedEntry[]>("universal-log");
        cityline.addEventListener("universal-log", (event: CustomEvent<JsonFeedEntry[]>) => {

            const keys = {};
            for(const entry of this.entries)
                keys[entry.id] = entry;

            const newEntries = event.detail.filter(m => !keys[m.id]);

            this.entries.unshift(...newEntries);
            this.emit("available", newEntries);
        });
    }

    async getInitial() : Promise<JsonFeedEntry[]> {
        await this._initializer;
        return this.entries;
    }

    addEventListener<K extends Extract<keyof EventMap, string>>(type: K, listener: (this: EventSource, ev: CustomEvent<EventMap[K]>) => any, options?: boolean | AddEventListenerOptions) {
        return this.eventTarget.addEventListener(type, listener, options);
    }
    
    removeEventListener<K extends Extract<keyof EventMap, string>>(type: K, listener: (this: EventSource, ev: CustomEvent<EventMap[K]>) => any, options?: boolean | AddEventListenerOptions) {
        return this.eventTarget.removeEventListener(type, listener, options);
    }
    
    private emit<K extends Extract<keyof EventMap, string>, T extends EventMap>(type: K, value: T[K]) {
        return this.eventTarget.dispatchEvent(new CustomEvent(type, { detail:  value } )); 
    }
}