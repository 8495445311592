import { Recent } from "./Model";
import "./RecentItemElement-style";

export class RecentItemElement extends HTMLElement {
    public recent: Recent;

    connectedCallback() {
        this.innerHTML = this.view(this.recent);
    }

    private view = (recent: Recent) => `
            ${recent.type === "article" ? `
                <a href="${recent.url}" route>
            ` : `
                <a target="_blank" rel="noopener noreferrer" href="${recent.url}" data-track="${recent.id}">
            `}
    
            <progressive-image show-loader lazy-load pico-image="${recent.picoImage}" source-image="${recent.image}"></progressive-image>
            <div class=title>${recent.title || ""}</div>
            <div class=type>${recent.type}</div>
            <auto-time datetime="${recent.published}" as-text></auto-time>
        </a>
    `;

}

customElements.define("recent-item", RecentItemElement);