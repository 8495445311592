import "./RichEditorElement-style";
import { Formatting } from "lib/Formatting";

class RichEditorElement extends HTMLElement {
    private escape = Formatting.htmlEscape;
    private editor: HTMLDivElement;

    private fieldName() {
        if (!this.hasAttribute("field-name"))
            throw Error("Field-name attribute must be set");

        return this.getAttribute("field-name");
    }

    private maxLength() {
        if (this.hasAttribute("maxlength")) {
            const count = parseInt(this.getAttribute("maxlength"));
    
            if (count > 0)
                return count;    
        }
        
        return 1000;
    }

    get validity(): ValidityState{
        if (this.charsLeft() < 0)
            return {
                badInput: false,
                customError: false,
                patternMismatch: false,
                rangeOverflow: false,
                rangeUnderflow: false,
                stepMismatch: false,
                tooLong: true,
                typeMismatch: false,
                valid: false,
                valueMissing: false
            } as ValidityState;
            
        return {
            badInput: false,
            customError: false,
            patternMismatch: false,
            rangeOverflow: false,
            rangeUnderflow: false,
            stepMismatch: false,
            tooLong: false,
            typeMismatch: false,
            valid: true,
            valueMissing: false
        } as ValidityState;
    }
    
    private charCountElement: HTMLElement;

    connectedCallback() {
        if (this.querySelector(".editor"))
            return;

        const value = this.innerText;

        this.innerHTML = this.view(value);

        this.charCountElement = this.querySelector(".char-count") as HTMLDivElement;

        this.editor = this.querySelector(".editor") as HTMLDivElement;
        this.editor.contentEditable = "true";
        
        const toolbar = this.querySelector(".toolbar") as HTMLDivElement;
        [].slice.call(toolbar.querySelectorAll("button")).forEach( (button: HTMLButtonElement) => {
            button.addEventListener("click", () => document.execCommand(button.getAttribute("exec"), false));
        });

        const valueElement = this.querySelector("textarea") as HTMLTextAreaElement;
        this.editor.addEventListener("input", () => {
            const value = this.editor.innerHTML;
            valueElement.value = value;
            this.renderCharCount();
        });

        this.editor.addEventListener("keydown", event => {
            // allow tab in editor
            if (event.which === 9) 
                event.preventDefault()
        });

        this.editor.addEventListener("keyup", event => {
            this.dispatchEvent(new KeyboardEvent(event.type));
            //(<any>this).validity = this.validity;
        });

        this.renderCharCount();

        const addLinkButton = toolbar.querySelector("[name=add-link]") as HTMLButtonElement;

        addLinkButton.addEventListener("click", () => {
            const selectionRange = this.saveSelection();
            const selection = document.getSelection();
            
            const selectionText = selection.toString();            
            
            const addLinkElement = document.createElement("add-external-link");
            addLinkElement.setAttribute("link-text", selectionText);
            document.body.insertAdjacentElement("beforeend", addLinkElement);
            addLinkElement.addEventListener("link-added", (event: CustomEvent) => {
                this.restoreSelection(selectionRange);

                if (event.detail && event.detail.url) {
                    document.execCommand("insertHTML", false,  `<a href="${event.detail.url}" target="_blank" rel="noopener noreferrer">${event.detail.text}</a>`);
                }
                    
            });
        });
    }

    saveSelection() {
        const selection = (<any>document).selection;
        if (window.getSelection) {
            const sel = window.getSelection();
            if (sel.getRangeAt && sel.rangeCount) {
                const ranges = [];
                for (let i = 0, len = sel.rangeCount; i < len; ++i) {
                    ranges.push(sel.getRangeAt(i));
                }
                return ranges;
            }
        } else if (selection && selection.createRange) {
            return selection.createRange();
        }
        return undefined;
    }
    
    restoreSelection(savedSel) {
        const selection = (<any>document).selection;
        if (savedSel) {
            if (window.getSelection) {
                const sel = window.getSelection();
                sel.removeAllRanges();
                for (let i = 0, len = savedSel.length; i < len; ++i) {
                    sel.addRange(savedSel[i]);
                }
            } else if (selection && savedSel.select) {
                savedSel.select();
            }
        }
    }

    private charsLeft() {
        if (!this.editor)
            return -1;

        return this.maxLength() - this.editor.innerText.length;
    }

    private renderCharCount(){
        const charsLeft = this.charsLeft();
        this.charCountElement.innerHTML = this.escape `${charsLeft.toString()} characters left`;
        
        if (charsLeft < 0)
            this.charCountElement.classList.add("warn");
        else
            this.charCountElement.classList.remove("warn")
    }

    private view = (value: string) => this.escape`
        <textarea name="${this.fieldName()}">$${value}</textarea>        
        <editor class="editor needsclick">$${value}</editor>
        <div class=toolbar>
            <button type=button exec="bold" title="Bold">${require("!!raw-loader!image/bold.svg")}</button>
            
            <button type=button exec="link" name="add-link" title="Add link">${require("!!raw-loader!image/link.svg")}</button>
            <div class="char-count">300 characters left</div>
        </div>`;
}

customElements.define("rich-editor", RichEditorElement);
