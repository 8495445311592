import { Routing } from "lib/Routing";
import { UrlProvider } from "lib/UrlProvider";
import { ClassTypename, Typename } from "lib/Types";
import { StackStore } from "features/stacks/StackStore";
import { Formatting } from "lib/Formatting";
import { MyFeedStore } from "features/feeds/MyFeedStore";
import { Pipeline, Request } from "lib/HTTP";
import EventTarget from "@ungap/event-target";
import { cityline } from "features/cityline";

export interface QueryStatement {
    stackId?: string;
    feedId?: string;
    query: string;
    entityType?: "link" | "stack" | "feed" | "article",
    id?: string;
}

export interface UserSearch {
    id: string;
    spec: QueryStatement
    created: Date;
    updated: Date;
}

export interface StatementText {
    primary: string;
    secondary?: string;
}

interface SavedSearches {
    "saved-searches": QueryStatement[],
    "recent-searches": QueryStatement[]
}

@ClassTypename("SearchStore")
export class SearchStore {
    private urlProvider = new UrlProvider();
    private eventTarget = new EventTarget();
    private static _instance = new SearchStore();
    private escape = Formatting.htmlEscape;
    public static get instance() { return SearchStore._instance; }
    private recent: QueryStatement[] = [];
    private saved: QueryStatement[] = [];
    private initializer: Promise<void>;
    
    private constructor() {
        this.initializer = new Promise(r => {
            setTimeout(async () => {
                await this.oneTimeInitialize()
                r();
            });
        });

        window.requestIdleCallback(async () => await this.initializer);   
    }

    async oneTimeInitialize() : Promise<void> {
        const search = await cityline.getFrame<SavedSearches>("search");

        this.saved = search["saved-searches"] || [];
        this.recent = search["recent-searches"] || [];
    }

    private emitRecentChanged(statements: QueryStatement[]) {
        this.eventTarget.dispatchEvent(new CustomEvent("recent-changed", { detail: statements}));
    }
    
    public onRecentChanged(callback: (event: CustomEvent<QueryStatement[]>) => void) {
        this.eventTarget.addEventListener("recent-changed", callback);
    }
    
    public offRecentChanged(callback: (event: CustomEvent<QueryStatement[]>) => void) {
        this.eventTarget.removeEventListener("recent-changed", callback);
    }

    private emitSavedChanged(statements: QueryStatement[]) {
        this.eventTarget.dispatchEvent(new CustomEvent<QueryStatement[]>("saved-changed", { detail: statements}));
    }

    public onSavedChanged(callback: (event: CustomEvent<QueryStatement[]>) => void) {
        this.eventTarget.addEventListener("saved-changed", callback);
    }

    public offSavedChanged(callback: (event: CustomEvent<QueryStatement[]>) => void) {
        this.eventTarget.removeEventListener("saved-changed", callback);
    }

    async savedSearches() : Promise<QueryStatement[]> {
        await this.initializer;
        return this.saved.slice();
    }

    async recentSearches() : Promise<QueryStatement[]> {
        await this.initializer;
        return this.recent.slice();
    }

    async search(queryStatement: QueryStatement) {
        const url = await this.buildUrl(queryStatement);
        Routing.instance.go(url.toString(), SearchStore[Typename]);

        this.lowPriority(async () => {
            await this.addRecent(queryStatement);
        });
    }

    lowPriority(func: () => Promise<void>) {
        setTimeout(() => {
            window.requestIdleCallback(async () => {
                await func();
            }, { timeout: 5000 });
        }, 2000);
    }

    public isSaved(queryStatement: QueryStatement) {
        return this.saved.some(m => this.isSame(m, queryStatement));
    }

    private async addRecent(queryStatement: QueryStatement) {
        const response = await new Pipeline().fetch(`${new UrlProvider().root}/api/search/recent`, Request.post.setJSON(queryStatement).authenticate());

        if (response.ok) {
            this.recent = await response.json();
            this.emitRecentChanged(this.recent);
        }

    }

    public async save(queryStatement: QueryStatement) : Promise<Boolean> {
        const response = await new Pipeline().fetch(`${new UrlProvider().root}/api/search/saved`, Request.post.setJSON(queryStatement).authenticate());

        
        if (response.ok) {
            const statement = await response.json()
            this.saved.push(statement);
            this.emitSavedChanged(this.saved);

            return true;
        } 

        return false;
    }

    public isSame(queryStatementA: QueryStatement, queryStatementB: QueryStatement) : boolean {
        if (queryStatementA.entityType !== queryStatementB.entityType)
            return false;
        
        if (queryStatementA.feedId !== queryStatementB.feedId)
            return false;
        
        if (queryStatementA.query !== queryStatementB.query)
            return false;
        
        if (queryStatementA.stackId !== queryStatementB.stackId)
            return false;
        
        return true;        
    }

    public async unSave(queryStatement: QueryStatement) : Promise<Boolean> {
        const savedStatement = this.saved.filter(m => this.isSame(m, queryStatement))[0];

        if (!savedStatement)
            return true;

        const response = await new Pipeline().fetch(`${new UrlProvider().root}/api/search/saved/${savedStatement.id}`, Request.delete.authenticate());

        if (response.ok) {
            this.saved = this.saved.filter(m => m !== savedStatement);
            this.emitSavedChanged(this.saved);

            return true;
        } 

        return false;
    }

    private async buildUrl(queryStatement: QueryStatement) : Promise<URL> {
        const url = new URL("", await this.urlProvider.userHome());

        

        switch(queryStatement.entityType) {
            case "link": 
                if (queryStatement.stackId)
                    url.href += `stacks/${queryStatement.stackId.split("/")[1]}`;
                else
                    url.href += "links"
                break;
            case "stack": 
                url.href += "stacks";
                break;
            case "feed":
                url.href += "feeds"
                break;
            case "article":
                if (queryStatement.feedId)
                    url.href += `feeds/${queryStatement.feedId}`;
                else
                    url.href += "feeds/all";
                break;
            default:
                url.href = `${this.urlProvider.root}/search`

        }

        url.searchParams.append("query", queryStatement.query.trim());
        return url;
    }

    async translateStatement(queryStatement: QueryStatement) : Promise<StatementText> {
        switch(queryStatement.entityType) {
            case "link": 
                let stackTitle = undefined;
                if (queryStatement.stackId) {
                    const stack = await StackStore.instance.getStackById(queryStatement.stackId);
                    if (stack)
                        stackTitle = stack.title;
                }
            
                return { primary: `Links with <em>${queryStatement.query}</em>`, secondary: stackTitle ? `in ${stackTitle}` : ""};
                
            case "article": 
                let feedTitle = undefined;
                if (queryStatement.feedId) {
                    const feed = await MyFeedStore.instance.getFeed(queryStatement.feedId);
                    if (feed)
                        feedTitle = feed.title;
                }
                
                return { primary: this.escape `Articles with <em>${queryStatement.query}</em>`, secondary: feedTitle ? `in ${feedTitle}` : "in all feeds"};
            
            case "stack": 
                return { primary: this.escape `Stacks with <em>${queryStatement.query}</em>`};

            
            case "feed":
                return { primary: this.escape `Feeds with <em>${queryStatement.query}</em>`};
            
            default: 
                return { primary: this.escape `Everything with <em>${queryStatement.query}</em>`};
            
        }

    }
}
