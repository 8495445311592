import "./BookmarkletPluginElement-style";
import { AppContextStore } from "features/application-context/AppContextStore";

import { BasePackageElement } from "features/packages/BasePackageElement";
import { Pipeline } from "lib/HTTP";
import { Routing } from "lib/Routing";
import { UrlProvider } from "lib/UrlProvider";

class BookmarkletPluginElement extends BasePackageElement {
    protected slug = "bookmarklet";    
    private static bookmarklet;
    private urlProvider = new UrlProvider();

    protected async initialize() {
        if (!BookmarkletPluginElement.bookmarklet) {
            const response = await new Pipeline().fetch(`${this.urlProvider.root}/api/bookmarklet/script`); 
            BookmarkletPluginElement.bookmarklet = await response.json();
        }
    }

    protected detailView = () => `
        <p>
            The Linkstacks Bookmarklet allows you to add links to Linkstacks when browsing other sites with just a press of a button in your browser toolbar.
        </p>
        <p>&nbsp;</p>
        <p>
            <div class="anim">
                <img src="${require("image/bookmarklet-firstframe.png")}" data-src="${require("image/bookmarklet.gif")}"/>
                <div></div>
                <noscript>
                    <img src="${require("image/bookmarklet.gif")}"/>
                </noscript>
            </div>
        </p>

        <h2>Adding the Bookmarklet</h2>

        <p>Adding the Linkstacks bookmarklet is easy. You simply drag this button to your bookmarks/favorites browser toolbar.</p>

        <p class="distance">
            <a class="button-link" href="${BookmarkletPluginElement.bookmarklet.url}">${BookmarkletPluginElement.bookmarklet.name}</a>
        </p>

        <p>&nbsp;</p>
        <h2>Advanced</h2>


        <p>If you need to add the bookmarklet on for instance a mobile device it can be easier to copy-paste the bookmarklet from the box below.</p>

        <textarea>${BookmarkletPluginElement.bookmarklet.url}</textarea>

        <p>&nbsp;</p>
        <p>
            Read more about bookmarklets in general <a target="_blank" a href="http://www.howtogeek.com/189358/beginner-geek-how-to-use-bookmarklets-on-any-device">here</a>.
        </p>
    `;      
}

customElements.define("bookmarklet-plugin", BookmarkletPluginElement);

Routing.instance.onChange(async route => {
    if (route.path[0] === "apps" && route.path[1] === "bookmarklet") {
        await AppContextStore.instance.present("", document.createElement("bookmarklet-plugin"), "Bookmarks", "Apps", "Bookmarklet");
    }
});