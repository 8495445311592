import { Routing } from "lib/Routing";
import { AppContextStore } from "features/application-context/AppContextStore";

//tslint:disable-next-line: no-unused-variable
class SearchActivator {
    //tslint:disable-next-line: no-unused-variable
    private static _instance = new SearchActivator();

    private constructor() {
        Routing.instance.onChange(route => {
            if (Routing.instance.matches("search")) {

                setTimeout(async () => {
                    const searchElement = document.createElement("search-control");

                    const query = route.parameters.query || "";
                    if (searchElement.getAttribute("query") !== query || query === "")
                        searchElement.setAttribute("query", query);

                    await AppContextStore.instance.present("", searchElement, "Everything", query ? `Matching ${query}` : undefined);
                });
            }
        });
    }
}