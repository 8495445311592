import { BrowserInfo } from "lib/Browser";
import { ILoginProvider } from "./ILoginProvider";
import { GenericWebLoginProvider } from "./GenericWebLoginProvider";
import { GenericNativeLoginProvider } from "./__GenericNativeLoginProvider";
import { FacebookNativeLoginProvider } from "./FacebookNativeLoginProvider";
import { GoogleNativeLoginProvider } from "./GoogleNativeLoginProvider";
import { AppleWebLoginProvider } from "./AppleWebLoginProvider";
import { AppleIOSLoginProvider } from "./AppleIOSLoginProvider"
import { AppleAndroidLoginProvider } from "./AppleAndroidLoginProvider"
import { TwitterIOSLoginProvider } from "./TwitterNativeLoginProvider";

export class LoginProviderFactory {
    static async resolve(name: string) : Promise<ILoginProvider> {

        switch(name.toLowerCase()) {
            case "apple": 
                if (BrowserInfo.isApp() && BrowserInfo.isIOSApp())
                    return new AppleIOSLoginProvider();
                else if (BrowserInfo.isApp())
                    return new AppleAndroidLoginProvider();
                else
                    return new AppleWebLoginProvider();
            
            case "facebook":
                if (BrowserInfo.isApp())
                    return new FacebookNativeLoginProvider();
                break;

            case "google":
                if (BrowserInfo.isApp())
                    return new GoogleNativeLoginProvider();
                break;       

            case "twitter":
                if (BrowserInfo.isApp())
                    return new TwitterIOSLoginProvider();
                break;       
        }
        
        return new GenericWebLoginProvider(name);
    }
}