import { cityline } from "features/cityline";
import EventTarget from "@ungap/event-target";
import { ClassTypename, Typename } from "lib/Types";


interface EventMap {
    "changed": UserPreference;
}

@ClassTypename("UserPreferenceStore")
export class UserPreferenceStore {
    private initializer: Promise<void>;
    private _preference: UserPreference;
    private static _instance = new UserPreferenceStore();
    private eventTarget = new EventTarget();

    private constructor() {
        this.initializer = this.doInitialize();
        setTimeout(async () => await this.initializer);
    }
    static get instance() { return UserPreferenceStore._instance; }

    private async doInitialize() {
        this._preference = await cityline.getFrame("user-preferences");
        cityline.addEventListener("user-preferences", async (event: CustomEvent<any>) => {
            this._preference = event.detail;
            this.emit("changed", this._preference);
        });
    }

    public addEventListener<K extends Extract<keyof EventMap, string>>(type: K, listener: (this: EventSource, ev: CustomEvent<EventMap[K]>) => any, options?: boolean | AddEventListenerOptions) {
        return this.eventTarget.addEventListener(type, listener, options);
    }
    
    public removeEventListener<K extends Extract<keyof EventMap, string>>(type: K, listener: (this: EventSource, ev: CustomEvent<EventMap[K]>) => any, options?: boolean | AddEventListenerOptions) {
        return this.eventTarget.removeEventListener(type, listener, options);
    }
    
    private emit<K extends Extract<keyof EventMap, string>, T extends EventMap>(type: K, value: T[K]) {
        return this.eventTarget.dispatchEvent(new CustomEvent(type, { detail:  Object.assign({}, value, { [Typename] : UserPreferenceStore[Typename] })})); 
    }
    
    public async isSet(flag: UserPreferenceFlag) : Promise<boolean> {
        await this.initializer;
        return flag === (this._preference.flags & flag);
    }

    public async get<T>(name: string, throwOnMissing: boolean) : Promise<T> {
        await this.initializer;

        const preference = this._preference[name];

        if (throwOnMissing && preference === undefined)
            throw new Error(`${name} not found in user-preferences`);

        return preference;
    }
}

interface UserPreference {
    flags: UserPreferenceFlag;
    "startup-time": string;
}

export enum UserPreferenceFlag
{
    None = 0x0,
    AutomaticTagging = 0x1,
    Deactivated = 0x2,
    DigestEmail = 0x4,
    IsConfirmed = 0x8,
    Newsletter = 0x10,
    TwoFactorAuthenticationEnabled = 0x20,
    HasPassword = 0x40,
    IsAlphaTester = 0x80,
    IsBetaTester = 0x100,
    HideTips = 0x200,
    Promotion = 0x400
}