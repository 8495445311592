import { Link } from "features/links";
import EventTarget from "@ungap/event-target";
import { LinkPageStore } from "features/link-page/LinkPageStore";

interface EventMap {
    "selected": CustomEvent<Link[]>;
    "deselected": CustomEvent<Link[]>;
    "select-started": Event;
    "select-ended": Event;
    "select-all-requested": Event;
}

export class BulkLinkStore {   
    private eventTarget = new EventTarget();
    private static _instance = new BulkLinkStore();
    static get instance() { return BulkLinkStore._instance; }
    private selected: Link[] = [];
    
    private constructor() {
        LinkPageStore.instance.addEventListener("link-updated", this.updateHandler);
        LinkPageStore.instance.addEventListener("link-removed", this.removeHandler);
        document.body.addEventListener("app-changed", this.deselectAll);
    }

    private updateHandler = (event: CustomEvent<Link>) => {
        const link = event.detail;
        if (!this.isSelected(link.id))
            return;

        this.selected = this.selected.filter(i => i.id !== link.id);
        this.selected.push(link);
    }

    private removeHandler = (event: CustomEvent<Link>) => {
        const link = event.detail;
        if (!this.isSelected(link.id))
            return;
        
        this.selected = this.selected.filter(i => i.id !== link.id);
        this.deselect(link);
    }

    // private emit<K extends Extract<keyof EventMap, string>>(type: K, ev: (type: K) => EventMap[K]) {
    //     return this.eventTarget.emit(ev(type));
    // }

    addEventListener<K extends Extract<keyof EventMap, string>>(type: K, listener: (this: EventSource, ev: EventMap[K]) => any, options?: boolean | AddEventListenerOptions) {
        return this.eventTarget.addEventListener(type, listener, options);
    }

    removeEventListener<K extends Extract<keyof EventMap, string>>(type: K, listener: (this: EventSource, ev: EventMap[K]) => any, options?: boolean | AddEventListenerOptions) {
        return this.eventTarget.removeEventListener(type, listener, options);
    }

    get length() { return this.selected.length };

    select(link: Link) {
        this.selectMany([link]);
    }

    selectMany(links: Link[]) {
        if (this.selected.length === 0)
            this.eventTarget.dispatchEvent(new CustomEvent("select-started"));

        const missing = links.filter(m => !this.selected.some(s => m.id === s.id));
        this.selected = this.selected.concat(missing);
        this.eventTarget.dispatchEvent(new CustomEvent("selected", { detail: missing }));
    }

    deselect(link: Link) {
        this.selected = this.selected.filter(m => m.id !== link.id);
        
        if (this.selected.length === 0)
            this.eventTarget.dispatchEvent(new CustomEvent("select-ended"));
        
        this.eventTarget.dispatchEvent(new CustomEvent("deselected", { detail: [link] }));
    }

    deselectAll = () => {
        const deselected = this.selected.slice(0);
        this.selected.length = 0;
        if (this.selected.length === 0)
        this.eventTarget.dispatchEvent(new CustomEvent("select-ended"));
        this.eventTarget.dispatchEvent(new CustomEvent("deselected", { detail: deselected }));
    }


    selectAllRequest() {
        this.eventTarget.dispatchEvent(new CustomEvent("select-all-requested"));
    }

    isSelected(id: string) {
        return this.selected.some(m => m.id === id);
    }

    all() {
        return this.selected.slice(0);
    }
}
