
import { DateTime } from "lib/DateTime";
import { Formatting } from "lib/Formatting";

class AutoTimeElement extends HTMLElement {
    private valueUTC: Date;
    private timer;

    connectedCallback() {
        const datetime = this.getAttribute("datetime");
        if (datetime) {
            try {
                this.valueUTC = new Date(datetime);
            }
            catch(error) {
                console.log(`Unable to parse date '${datetime}'`);
                return;
            }
            
        }

        this.refresh();
        this.timer = setInterval(this.refresh, 1000*60); // every minute 
    }

    disconnectedCallback() {
        clearInterval(this.timer);
    }

    private nowUtc(): Date {
        return new Date();
        // const now = new Date();
        // return new Date(now.getTime() + now.getTimezoneOffset() * 60000);
    }

    refresh = () => {
        const localDate = DateTime.UTCToLocal(this.valueUTC);
        const localNow = DateTime.UTCToLocal(new Date());

        if (this.hasAttribute("date-as-text"))
            this.innerText = this.dayDifference(localNow, localDate);
        else if (this.hasAttribute("as-text"))
            this.innerText = this.timeDifference(this.nowUtc(), this.valueUTC);
        else
            this.innerText = Formatting.shortDateTime(localDate);
    }

    private pad(source: number){
        return source < 10 ? `0${source}` : source.toString();
    }

    private timeDifference(current, previous) {
        const msPerMinute = 60 * 1000;
        const msPerHour = msPerMinute * 60;
        const msPerDay = msPerHour * 24;
        const elapsed = current - previous;
        
        if (elapsed < msPerMinute) {
            return "just now";
        }

        if (elapsed < msPerMinute*2) {
            return "1 minute ago";
        }

        else if (elapsed < msPerHour) {
            return `${Math.floor(elapsed / msPerMinute)} minutes ago`;
        }

        // Less than 2 hours ago.
        else if (elapsed < msPerHour*2) {
            return "1 hour ago";
        }

        else if (elapsed < msPerDay) {
            return `${Math.floor(elapsed / msPerHour)} hours ago`;
        }

        else if (elapsed < msPerDay * 2) {
            return "yesterday";
        }

        
        else if (elapsed < msPerDay * 7) {
            return `${Math.floor(elapsed / msPerDay)} days ago`;
        }

        else if (elapsed < msPerDay * 31) {
            const weeks = Math.floor(elapsed / (msPerDay*7));
            return `${weeks} week${weeks > 1 ? "s" : ""} ago`;
        }
        
        return `${previous.getUTCFullYear()}/${this.pad(previous.getUTCMonth()+1)}/${this.pad(previous.getUTCDate())}`;
    }



    private dayDifference(previous: Date, date: Date) {
        const days = this.daysBetween(date, previous);

        if (days === 0)
            return `Today`;
        if (days === 1)
            return `Yesterday`;
        if (days < 7)
            return `${days} days ago`;
        else
            return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    }

    private daysBetween = (date1: Date, date2: Date) => {
        date1 = new Date(date1.toDateString());
        date2 = new Date(date2.toDateString());

        let days = Math.floor((date2.getTime() - date1.getTime()) / 86400000);
        if (days < 0) 
            days = 0;
        return days;
    };
}

customElements.define("auto-time", AutoTimeElement);