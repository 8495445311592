import { AppContextStore } from "features/application-context/AppContextStore";
import { BaseManagePackageElement } from "features/manage/BaseManagePackageElement";
import { Package } from "features/packages/Package";

import { Routing } from "lib/Routing";

export class EmailsNotificationsElement extends BaseManagePackageElement {
    protected static path = ["manage", "emails-notifications"];
    public static tag = "emails-notifications";
    protected slug = "notifications";

    protected detailView = (thePackage: Package) => `
        <email-settings></email-settings>
        <notification-settings></notification-settings>
    `; 
}

customElements.define(EmailsNotificationsElement.tag, EmailsNotificationsElement);

Routing.instance.onChange(async route => {
    if (route.path[0] === "manage" && route.path[1] === "notifications") {
        setTimeout(async () => {
            await AppContextStore.instance.present("", document.createElement("emails-notifications"), "Manage", "Emails & notifications");
        });
    }
});