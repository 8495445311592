import * as cardsStyle from "../../styles/cards-module.less"
import { Formatting } from "lib/Formatting";
import "./FeedListElement-module.less";
import ResizeObserver from "resize-observer-polyfill";
import { MyFeedStore } from "features/feeds/MyFeedStore";
import { Feed } from "features/feeds";
import { UrlProvider } from "lib/UrlProvider";
import { ImageUrlProvider } from "lib/ImageUrlProvider"

class FeedListElement extends HTMLElement {
    private escape = Formatting.htmlEscape;
    private query = () => this.getAttribute("query");
    private resizeObserver: ResizeObserver;
    static get observedAttributes() { return ["query"]; }
    private isRendered = false;

    async connectedCallback() {
        this.className += cardsStyle.cardContainer;

        this.resizeHandler(this.clientWidth);

        await this.refresh(); 

        this.resizeObserver = new ResizeObserver((entries) => {
            requestAnimationFrame(() => this.resizeHandler(entries[0].contentRect.width));
        });
        this.resizeObserver.observe(this);
        this.isRendered = true;
    }

    disconnectedCallback() {
        console.log("cleanup");
        if (this.resizeObserver)
            this.resizeObserver.disconnect();
    }

    async attributeChangedCallback(attrName, oldVal, newVal) {
        if (attrName === "query" && this.isRendered) {
            await this.refresh();
        }    
    }

    private resizeHandler = (containerWidth: number) => {
        const desiredCount = Math.floor(containerWidth / 250);
        const width = (containerWidth / desiredCount) - 17;
        this.style.setProperty("--card-width", `${width}px`); 
    };

    private async refresh() {
        const query = this.query();

        const feeds = await MyFeedStore.instance.find(query);
       
        const userHome = await new UrlProvider().userHome();
        this.innerHTML = this.view(feeds, userHome);
    }

    private view = (feeds: Feed[], userHome: string) => this.escape`
        $${feeds.map(feed => this.escape`
            <a route href="${userHome}feeds/${feed.id}" class="${cardsStyle.card}">
                $${feed.unread ? `<div class="${cardsStyle.bubble}" title="${feed.unread} link${feed.unread > 1 ? "s" : ""}">${feed.unread}</div>`: ""}
                <div class="${cardsStyle.figureFrame}">
                    <figure>
                        <img src="${ImageUrlProvider.translate(feed.icon, 450, 300)}" >        
                    </figure>
                </div>
                <div class="${cardsStyle.title}">
                    <h1>${feed.title || ""}</h1>
                </div>
                <div class="${cardsStyle.description}">
                    <p>
                        $${feed.description || ""}
                    </p>
                </div>
            </a>
        `).join("")}
    `;
}

customElements.define("feed-list", FeedListElement);