import { LogStore } from "./LogStore";
import { AuthenticationStore } from "features/authentication";
import { SettingsStore } from "features/settings/SettingsStore";
import { cityline } from "features/cityline";
import { IVersion } from "lib/Model";
import { UrlProvider } from "lib/UrlProvider"; 

const logStore = LogStore.instance;

export { logStore };

const apiKeys = {
    "production": "4e19343f8f6d4b2db8bbbbfaee5970ae",
    "test": "a3730069979e4bffa331a451708fbb31",
    "local": "e5ddb2c8eb0a43f9ad19e528088f6e32"
}

logStore.configure({
    apiKey: apiKeys[UrlProvider.environment],
    logServer: "https://status.linkstacks.io/logging"
    //logServer: "http://localhost:5100/logging"
});

window.addEventListener("error", async (event: ErrorEvent) => {
    await logStore.logError(event.error);
    console.log(event);
});        

window.addEventListener("unhandledrejection", async (event: PromiseRejectionEvent) => {
    await logStore.logError(event.reason, { event: "unhandledrejection" });
    console.log(event);
});

logStore.decorate(async (properties: { [id: string] : any; }) => {
    const user = await AuthenticationStore.instance.currentUser();
    properties["_userId"] = user?.userId;
});

logStore.decorate(async (properties: { [id: string] : any; }) => {
    properties["clientVersion"] = SettingsStore.instance.version.toString();
});

logStore.decorate(async (properties: { [id: string] : any; }) => {
    const version = await cityline.getFrame<IVersion>("version");
    properties["serverVersion"] = version?.textual;
});

logStore.decorate(async (properties: { [id: string] : any; }) => {
    const _navigator = {
        appName: window.navigator.appName,
        appCodeName: window.navigator.appCodeName,
        appVersion: window.navigator.appVersion,
        cookieEnabled: window.navigator.cookieEnabled,
        languages: window.navigator.languages,
        oscpu: (<any>window.navigator).oscpu,
        platform: window.navigator.platform,
        product: window.navigator.product,
        webdriver: window.navigator.webdriver
    };
 
    properties["navigator"] = _navigator;
});

