const constants = require("../../constants");

export class SemanticVersion {
    constructor(public major: number, public minor: number, public patch: number, public buildDate: Date) {

    }

    static parse(source: string): SemanticVersion {
        const parts = source.split("+");
        const version = parts[0].split(".");

        return new SemanticVersion(parseInt(version[0]), parseInt(version[1]), parseInt(version[2]), new Date(parseInt(parts[1])));
    }

    number = () => `${this.major}.${this.minor}.${this.patch}`;

    toString() {
        return `${this.major}.${this.minor}.${this.patch}+${this.buildDate.getTime()}`;
    }
} 


export class SettingsStore {
    private static _instance = new SettingsStore();
    private _version: SemanticVersion; 

    private constructor() {
        this._version = SemanticVersion.parse(constants.version);
    } 

    public static get instance() {
        return SettingsStore._instance;
    }
    
    get version(): SemanticVersion {
        return this._version;
    }
}
