import "./AddAnythingElement-style";
import * as addStyle from "./AddAnythingElement-module.less";
import * as cardsStyle from "../../styles/cards-module.less"
import { AuthenticationStore } from "features/authentication";
import { BaseSidebarChildElement } from "features/side-bar/BaseSidebarChildElement";
import { Feed } from "features/feeds";
import { Formatting } from "lib/Formatting";
import { LinkStore, LinkAddStatus } from "features/links";
import { Linkstack } from "../stacks/StackStore";
import { MetadataStore, FeedSpecification } from "./MetadataStore";
import { MixinPopup } from "features/mixin/MixinPopup";
import { MyFeedStore, SubscribeStatus } from "features/feeds/MyFeedStore";
import { Routing } from "lib/Routing";
import { StackStore } from "features/stacks/StackStore";
import { Template } from "lib/System";
import { TitleFromUrlGenerator } from "../../library/TitleFromUrlGenerator";
import { TopMenuStore } from "features/top-panel/TopMenuStore";
import { ClassTypename, Typename } from "lib/Types";
import { LinkSession } from "features/link-page/LinkSession";

/**
 * Add urls or feeds
 * @element add-anything
 * @attribute {String} url - url to be prefilled
 * @cssprop [--add-anything-icon-size]
 */
@ClassTypename("AddAnythingElement")
class AddAnythingElement extends BaseSidebarChildElement {
    protected titleText: string = "Add url";
    private escape = Formatting.htmlEscape;
    private mixinPopup: MixinPopup;
    private urlInput: HTMLInputElement;
    private linkArea: HTMLElement;
    private feedsArea: HTMLElement;
    private abortController: AbortController;
    private timer;
    private currentUrl: string;
    private currentStack: Linkstack;
    private currentFeedCategory: Feed;
    private errorMessageElement: HTMLElement;
    private clearButton: HTMLButtonElement;
    private externalUrl = () => this.getAttribute("url");
    private clearExternalUrl = () => this.removeAttribute("url");
    private iconSize: number;
    private tagEditor: HTMLElement;

    async connectedCallback() {
        super.connectedCallback();
        this.className += ` ${addStyle.addAnything}`;
        this.insertAdjacentHTML("beforeend", this.view());
        TopMenuStore.instance.toggle("add-anything");
       
        // catch elements
        this.urlInput = this.querySelector("[name=url]") as HTMLInputElement;
        this.linkArea = this.querySelector("[name=link-area]") as HTMLHtmlElement;
        this.feedsArea = this.querySelector("[name=feeds-area]") as HTMLHtmlElement;
        this.errorMessageElement = this.querySelector("[name=error-message]") as HTMLElement;
        this.clearButton = this.querySelector("[name=clear]") as HTMLButtonElement;
        this.tagEditor = this.querySelector("tag-editor");

        this.urlInput.addEventListener("focus", () => {
            this.urlInput.select();
        });

        // render stack
        window.requestIdleCallback(() => this.updateStack(LinkSession.instance.currentStack), { timeout: 500 });
        window.requestIdleCallback(() => this.feedCategoryChangeHandler(), { timeout: 500 });

        // setup events
        this.urlInput.addEventListener("input", this.inputHandler);
        this.urlInput.addEventListener("keypress", this.keypressHandler);
        this.addEventListener("click", this.clickHandler);
        this.clearButton.addEventListener("click", this.userClearRequest);
        
        LinkSession.instance.addEventListener("stack-changed", this.stackChangedHandler);
        MyFeedStore.instance.addEventListener("active-feed-category-changed", this.feedCategoryChangeHandler);
      
        
        if (this.externalUrl()) {
            this.urlInput.value = this.externalUrl();
            this.clearExternalUrl();
            requestAnimationFrame(this.inputHandler);
        } else {
            this.setAttribute("empty", "");
        }

        window.requestIdleCallback(() => {
            const size = window.getComputedStyle(this).getPropertyValue("--add-anything-icon-size");
            this.iconSize = parseInt(size);
        });

        window.addEventListener("resize", this.inputHandler);

        if (!this.externalUrl())
            this.doFocus();
    }   

    disconnectedCallback() {
        this.urlInput.removeEventListener("input", this.inputHandler);
        this.urlInput.removeEventListener("keypress", this.keypressHandler);
        this.removeEventListener("click", this.clickHandler);
        this.clearButton.removeEventListener("click", this.userClearRequest);
        //StackStore.instance.offActiveStackChanged(this.stackChangedHandler);

        LinkSession.instance.removeEventListener("stack-changed", this.stackChangedHandler);

        MyFeedStore.instance.removeEventListener("active-feed-category-changed", this.feedCategoryChangeHandler);
        
        TopMenuStore.instance.toggle("add-anything", false);

        if (this.mixinPopup)
            this.mixinPopup.dispose();

        
        window.removeEventListener("resize", this.inputHandler);
    }

    protected doFocus = () => {
        setTimeout(async () => {
            this.urlInput.focus();
        }, 300);
    }

    private feedCategoryChangeHandler = async (event?: CustomEvent<Feed>) => {
        this.currentFeedCategory = event?.detail;

        if (!this.currentFeedCategory)
            this.currentFeedCategory = MyFeedStore.instance.activeCategory;

        if (!this.currentFeedCategory || this.currentFeedCategory.id === "all")
            this.currentFeedCategory = await MyFeedStore.instance.getFeed("uncategorized");

        const categoryTitleElement = this.querySelector("[name=feed-title]") as HTMLElement;
        if (categoryTitleElement)
            categoryTitleElement.innerText = this.currentFeedCategory.title;
    };

    private updateStack = async (stack: Linkstack) => {
        this.currentStack = stack;
        
        if (!stack) {
            const user = await AuthenticationStore.instance.currentUser();
            this.currentStack = await StackStore.instance.getStackBySlug(user.userId, "inbox");
        }
        
        const stackTitleElement = this.querySelector("[name=stack-title]") as HTMLElement;
        if (stackTitleElement)
            stackTitleElement.innerText = this.currentStack.title;
    } 
    
    private stackChangedHandler = async (event: CustomEvent<Linkstack>) => this.updateStack(event.detail);

    private keypressHandler = async (event: KeyboardEvent) => {
        if (event.key === "Enter") {
            event.preventDefault();
            await this.addUrl(this.urlInput.value);    
            return false;
        }
    };

    private async addUrl(url: string) {
        const tags = this.tagEditor.getAttribute("tags");

        const link: any = {
            url: url,
            tags: tags ? tags.split(";") : undefined,
            tagsChanged: this.tagEditor.hasAttribute("changed")
        };

        const addStatus = await LinkStore.instance.add(link, this.currentStack.id);

        switch(addStatus) {
            case LinkAddStatus.Ok: 
                await this.closeHandler();
                break;
            case LinkAddStatus.InvalidUrl: 
                this.showError("Sorry, invalid url.");
                break;
            case LinkAddStatus.UrlAlreadyExists: 
                this.showError("Link already exists.");
                break;
            default: 
                this.showError("Unknown error.");
                break;               
        }   
    }

    private async subscribe(url: string) {
        const currentUserTask = AuthenticationStore.instance.currentUser();
        const result = await MyFeedStore.instance.subscribe(url, this.currentFeedCategory.id);
        const status = result[0];
       

        switch(status) {
            case SubscribeStatus.Ok: 
                const feed = result[1];
                Routing.instance.go(`/@${(await currentUserTask).userId}/feeds/${feed.id}`, AddAnythingElement[Typename]);
                await this.closeHandler();
                break;
            case SubscribeStatus.InvalidUrl: 
                this.showError("Sorry, invalid feed url.");
                break;
            case SubscribeStatus.Conflict: 
                this.showError("Already subscribed to this feed.");
                break;
            default: 
                this.showError("Unknown error.");
                break;               
        }   
    }

    private clickHandler = async (event: UIEvent) => {
        const linkElement = (event.target as HTMLElement).closest("[link]");
        if (linkElement) 
            await this.addUrl(linkElement.getAttribute("link"));
        

        const feed = (event.target as HTMLElement).closest("[feed]");
        if (feed) {
            await this.subscribe(feed.getAttribute("feed"));
        }
    }

    private showError(message: string) {
        this.errorMessageElement.innerText = message;
    }

    private userClearRequest = async () => {
        await this.clear();
        this.urlInput.focus();
    }

    private clear = async () => {
        this.urlInput.value = "";
        this.errorMessageElement.textContent = "";
        this.feedsArea.innerHTML = "";
        this.linkArea.innerHTML = "";
        this.setAttribute("empty", "");
        await this.inputHandler();
    };

    private metadataFetcher = async (url: string) => {
        if (this.abortController)
            this.abortController.abort();

        if ("AbortController" in window)
            this.abortController = new AbortController();

        this.tagEditor.setAttribute("detected-tags", "");

        const metadata = await MetadataStore.instance.getMetadata(url, this.abortController);
        
        if (!metadata) // abort
            return; 

        if (url !== this.currentUrl)
            return;
        
        this.linkArea.innerHTML = this.linkView(metadata.title, metadata.preview, metadata.url);

        this.tagEditor.setAttribute("detected-tags", metadata?.detectedTags?.join(";") || "");

        if (!metadata.feeds || metadata.feeds.length === 0)
            this.feedsArea.innerHTML = "";
        else {
            this.feedsArea.innerHTML = this.feedView(metadata.feeds);
        }            
    };

    private inputHandler = async () => {
        this.errorMessageElement.innerText = "";
        
        window.clearTimeout(this.timer);

        if (this.urlInput.value === "")
            this.setAttribute("empty", "");
        else
            this.removeAttribute("empty");

        if (this.currentUrl !== this.urlInput.value) {

            this.currentUrl = this.urlInput.value;

            const title = TitleFromUrlGenerator.create(this.currentUrl);
            this.linkArea.innerHTML = this.linkView(title, undefined, this.currentUrl);
            this.feedsArea.innerHTML = "";
            this.timer = setTimeout(async () => this.metadataFetcher(this.urlInput.value), 60); 
        }

        this.urlInput.style.height = "";
        let inputHeight = this.urlInput.scrollHeight; // padding
        inputHeight = Math.max(inputHeight, 20);
        inputHeight = Math.min(inputHeight, 300);
        this.urlInput.style.height = `${inputHeight}px`;
    };

    private infoView = (title: string, preview: string) => this.escape`
        <div class=info>
            <div class=img-container>
                $${preview ? `<img src="${Template.replace(preview, { method: "cropped", width: this.iconSize*2, height: this.iconSize*2 })}" />` : ""}
            </div>
            <div class=title>${title}</div>
            <div class=button-area>
                $${require("!!raw-loader!image/add-button.svg")}
            </div>
        </div>
    `;

    private linkView = (title: string, preview: string, url: string) => { 
        if (!title)
            return "";
        
        return this.escape`
            <div class="${cardsStyle.header}">Add as link</div>
            <div class="${cardsStyle.subHeader}">Into stack <span name=stack-title>${this.currentStack ? this.currentStack.title : ""}</span></div>
            <div class="${cardsStyle.card} ${addStyle.card}" name=link link="${url}">  
                $${this.infoView(title, preview)}  
            </div>
            `
    };

    private feedView = (feedSpecs: FeedSpecification[]) => {
        
        return this.escape`
            <div class="${cardsStyle.header}">Subscribe to feed</div>
            <div class="${cardsStyle.subHeader}">Into category <span name=feed-title>${this.currentFeedCategory ? this.currentFeedCategory.title : ""}</span></div>
            $${feedSpecs.map(feedSpec => this.escape`
                <div class="${cardsStyle.card} ${addStyle.card}" name=link feed="${feedSpec.url}">
                    $${this.infoView(feedSpec.title, feedSpec.icon)}
                </div>
            `).join("")}  
          `;
    };

    // note: autofocus on textarea will cause jank in ui
    private view = () => this.escape`
        <div name=window-area class="${cardsStyle.cardContainer} ${addStyle.cardContainer}">
            <div class="${cardsStyle.card} ${addStyle.editorCard}">
                <div class="${cardsStyle.textArea}">
                    ${require("!!raw-loader!image/link.svg")}
                    <textarea type=url id=url name="url" inputmode="url" placeholder="Enter url, e.g. www.example.com" tabindex=0 required accesskey="u" autocorrect="off" autocapitalize="none" spellcheck="false" value=""></textarea>
                    <button type=button name=clear title="Clear">${require("!!raw-loader!image/close-icon.svg")}</button>
                </div>
                <div name=error-message></div>
                
                
                <tag-editor tester="asdf"></tag-editor>
            </div>
            

            <div style="display:contents" name=link-area class=list></div>
            <div style="display:contents" name=feeds-area class="list ${addStyle.area}"></div>
        </div>
    `;
    //<div name=tip>Enter a url to add it to a stack or subscribe to a feed. (Press enter to add as url)</div>
}

customElements.define("add-anything", AddAnythingElement)
