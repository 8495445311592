import { TaskStore } from "lib/Tasks"

import "./ProgressBarElement-style"


class ProgressBarElement extends HTMLElement {
    private inProgress = false;

    connectedCallback() {
        if (!this.firstElementChild) {
            const bar = document.createElement("div");
            bar.setAttribute("class", "bar");
            this.appendChild(bar);
        }

        TaskStore.instance.onInProgress(this.progressHandler);
        TaskStore.instance.onComplete(this.completeHandler);
    }

    disconnectedCallback() {
        TaskStore.instance.offInProgress(this.progressHandler);
        TaskStore.instance.offComplete(this.completeHandler);
    }

    private progressHandler = () => {
        if (this.inProgress)
                return;

        this.inProgress = true;

        this.classList.add("active");
    };

    private completeHandler = () => {
        this.classList.remove("active");
        this.classList.add("done");
        this.style.transitionTimingFunction = "cubic-bezier";
        this.style.width = "100%";
        window.setTimeout(() => this.reset(), 200);
    };

    private reset() {
        this.classList.remove("active");
        this.classList.remove("done");
        this.inProgress = false;
    }
}

customElements.define("progress-bar", ProgressBarElement);

