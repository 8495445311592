import "./RecentWidgetElement-style";
import * as cardsStyle from "../../../../styles/cards-module.less"
import * as widgetStyle from "../Widgets-module.less";
import { BaseWidget, PreferSize, TabButton } from "../BaseWidget";
import { BoxLoaderElement } from "features/box-loader";
import { Entry } from "features/feeds";
import { Link } from "features/links";
import { Recent } from "./Model";
import { RecentItemElement } from "./RecentItemElement";
// import { cityline } from "features/cityline";
import { RecentStore } from "../../../recent/RecentStore";

interface RecentFrame {
    links: Link[];
    articles: Entry[];
}

type Mode = "all" | "links" | "articles";

class RecentWidgetElement extends BaseWidget {
    private recentElements: { [id: string]: RecentItemElement; } = {};
    private recentArea: HTMLElement;
    private mode: Mode = "all";


    mapLink(link: Link): Recent {
        return ({
            id: link.id,
            picoImage: link.picoImage,
            title: link.title,
            image: link.preview,
            published: link.created,
            url: link.url,
            type: "link"
        });
    }

    mapArticle(article: Entry): Recent {
        return ({
            id: article.id,
            picoImage: article.picoImage,
            title: article.title,
            image: article.image,
            published: article.publishDate,
            url: `/@modec/feeds/${article.feedId}/${article.slug}`,
            type: "article"
        });
    }

    public get preferredWidth(): PreferSize {
        return "wide";
    }

    async connectedCallback() {
        this.className += ` ${cardsStyle.cardContainer}`;
        this.innerHTML = this.view();
        this.recentArea = this.querySelector("[name=recent-area]");
        this.recentArea.append(new BoxLoaderElement());

        setTimeout(async () => {
            await this.initialRender();
            RecentStore.instance.addEventListener("added", this.recentChangeHandler);
        });

        super.connectedCallback();
    }

    disconnectedCallback() {
        RecentStore.instance.removeEventListener("added", this.recentChangeHandler);
        
        super.disconnectedCallback();
    }

    private recentChangeHandler = (event: CustomEvent<RecentFrame>) =>  this.renderItems(event.detail, true);
    
    private initialRender = async () => {
        const frame = await RecentStore.instance.getAll();
        
        this.recentArea.innerHTML = "";

        if (frame.articles.length === 0 && frame.links.length === 0) {
            this.recentArea.innerHTML = this.emptyView();
            return;
        }

        this.renderItems(frame, false);
    };

    protected modeChange(mode: string) {
        this.mode = <Mode>mode;
        switch (this.mode) {
            case "all":
                Array.from(this.querySelectorAll("recent-item")).forEach(m => m.toggleAttribute("hidden", false));
                break;
            case "articles":
                Array.from(this.querySelectorAll("recent-item[recent-type=article]")).forEach(m => m.toggleAttribute("hidden", false));
                Array.from(this.querySelectorAll("recent-item[recent-type=link]")).forEach(m => m.toggleAttribute("hidden", true));
                break;
            case "links":
                Array.from(this.querySelectorAll("recent-item[recent-type=link]")).forEach(m => m.toggleAttribute("hidden", false));
                Array.from(this.querySelectorAll("recent-item[recent-type=article]")).forEach(m => m.toggleAttribute("hidden", true));
                break;
        }
    }

    private renderItems = (frame: RecentFrame, segregated: boolean) => {
        const recents = frame.links
            .map(link => this.mapLink(link))
            .concat(frame.articles
                .map(article => this.mapArticle(article)));

        recents.sort((a, b) => a.published > b.published ? 1 : -1);

        for (const recent of recents) {
            if (this.recentElements[recent.id])
                continue;

            const recentItemElement = new RecentItemElement();
            recentItemElement.recent = recent;

            recentItemElement.setAttribute("recent-type", recent.type);
            recentItemElement.setAttribute("recent-id", recent.id);

            if (this.mode !== "all") {
                if (this.mode === "articles" && recent.type !== "article")
                    recentItemElement.toggleAttribute("hidden", true);
                
                if (this.mode === "links" && recent.type !== "link")
                    recentItemElement.toggleAttribute("hidden", true);
            }

            if (segregated) {
                recentItemElement.toggleAttribute("reveal", true);
                requestAnimationFrame(() => {
                    requestAnimationFrame(() => {
                        recentItemElement.toggleAttribute("reveal", false);
                    });
                });
            }

            this.recentElements[recent.id] = recentItemElement;
            this.recentArea.insertAdjacentElement("afterbegin", recentItemElement);
        }

        // ensure max 10 of each
        for (const type of ["link", "article"]) {
            const elements = Array.from(this.querySelectorAll(`[recent-type=${type}]`));
            const overflow = elements.slice(10);

            for (const element of overflow) {
                requestAnimationFrame(() => {
                    requestAnimationFrame(() => {
                        delete this.recentElements[element.getAttribute("recent-id")];
                        element.toggleAttribute("reveal", true);
                        setTimeout(() => {
                            element.remove();
                        }, 1000);
                    });
                });
            }
        }
    }

    private tabButtons: TabButton[] = [
        { label: "All", value: "all" },
        { label: "Links", value: "links" },
        { label: "Articles", value: "articles" }
    ];


    private view = () => `
        ${this.headerView("Recent", this.tabButtons, this.mode)}
        <div class="${cardsStyle.card} ${widgetStyle.card}" name=recent-area></div>
    `;

    private emptyView = () => `
        <div name=empty class="${widgetStyle.emptyText}">No recent links or articles.</div>
    `
}

customElements.define("recent-widget", RecentWidgetElement);