import "./TabBarElement-style";


interface TabItem {
    title: string;
    icon: string;
    template: HTMLTemplateElement,
    element: HTMLElement;
    isDefault: boolean;
}

class TabBarElement extends HTMLElement {
    private items:TabItem[]  = [];
    private buttons: HTMLButtonElement[];
    private contentTarget: HTMLElement;
    private currentTab: HTMLElement;
    private icons = {
        "explore": require("!!raw-loader!image/today.svg"),
        "settings": require("!!raw-loader!image/gear-25-v2.svg"),
        "my bookmarks": require("!!raw-loader!image/link.svg"),
        "my feeds": require("!!raw-loader!image/feed-symbol.svg")
    };

    connectedCallback() {
        this.items = [].slice.call(this.querySelectorAll("template")).map( (template: HTMLTemplateElement) => ({
            title: template.getAttribute("title"),
            icon:  this.icons[template.getAttribute("title").toLocaleLowerCase()],
            template: template,
            isDefault: template.hasAttribute("default")
        }));

        this.innerHTML = this.view();
        this.contentTarget = document.querySelector("tab-content") as HTMLElement;
        this.buttons =  [].slice.call(this.querySelectorAll("button")) as HTMLButtonElement[];
        this.buttons.forEach(button => button.addEventListener("click", this.clickHandler));

        const persisted = this.items.filter(i => i.title === this.persistedActiveTab)[0];
        if (persisted)
            this.showTabItem(persisted);
        else
            this.showTabItem(this.items.filter(i => i.isDefault)[0]);
    }

    private get persistedActiveTab() {
        try {
            return localStorage.getItem("active-tab");
        } catch(error) {
            return undefined;
        }
    }

    private set persistedActiveTab(value: string) {
        try {
            localStorage.setItem("active-tab", value);
        } catch(error) {
        }
    }

    private clickHandler = (event: UIEvent) => {
        const target = event.target as HTMLElement;
        const button = target.closest("button");

        const item = this.items.filter(i => i.title === button.getAttribute("name"))[0];

        this.showTabItem(item);
    };

    private showTabItem(item: TabItem) {
        if (!item.element) {
            item.element = <any>item.template.content.firstElementChild as HTMLElement;
            this.contentTarget.appendChild(item.element);
        }

        requestAnimationFrame(() => {
            if (this.currentTab)
                this.currentTab.style.display = "none"; // = "hidden"

            this.currentTab = item.element;
            this.currentTab.style.display = "block"; //visibility = "visible";

            this.buttons.forEach(button => button.removeAttribute("active"));

            const activeButton = this.buttons.filter(i => i.name === item.title)[0] as HTMLButtonElement;
            activeButton.setAttribute("active", "");
            this.persistedActiveTab = item.title;
        });
    }

    private view = () => `
        ${this.items.map(item => `<button type=button name="${item.title}">${item.icon}${item.title}</button>`).join("")}
    `;
}

customElements.define("tab-bar", TabBarElement);