
import { MyFeedStore, FeedChangeDetail } from "features/feeds/MyFeedStore";
import { Feed } from "features/feeds";
import { BaseSideMenuElement, MenuCategory, MenuItem } from "features/side-menu/BaseSideMenuElement";
import { DragEffect } from "./BaseSideMenuElement";

class FeedSideMenuElement extends BaseSideMenuElement {
    private icons = {
        all: require("!!raw-loader!image/all-20-v1.svg")
    };
    
    private mapFeed(feed: Feed, children: Feed[], root = true, selectedEvaluator: (item: MenuItem) => void) : MenuItem {
        const item: MenuItem = {
            id: feed.id,
            title: feed.title,
            count: feed.unread,
            imgIcon: root ? undefined : feed.icon,
            svgIcon: this.icons[feed.id] ? this.icons[feed.id] : (root ? require("!!raw-loader!image/arrow-down-light-20-v1.svg") : undefined),
            draggable: !root,
            dropTarget: root,
            items: !root || !children ? undefined : children.map(m => this.mapFeed(m, [], false, selectedEvaluator)),
            path: `/@${this.user.userId}/feeds/${feed.id}`,
            expandable: feed.isCategory && feed.id !== "all" || false
        };

        selectedEvaluator(item);

        if (item.items)
            if (item.items.some(i => i.selected))
                item.selected = true;

        return item;
    }

    protected async rootClick(id: string) {
        MyFeedStore.instance.changedActiveCategory(id);
    }

    async connectedCallback() {
        await super.connectedCallback();
        MyFeedStore.instance.addEventListener("feeds-changed", this.feedChangeHandler); 
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        MyFeedStore.instance.removeEventListener("feeds-changed", this.feedChangeHandler); 
    }



    private mapFeeds(entries: ({feed: Feed, children: Feed[] }[]), selectedEvaluator: (item: MenuItem) => void) {
        const base: MenuItem[] = [{
            id: "feeds",
            title: "All feeds",
            svgIcon: require("!!raw-loader!image/feed.svg"),
            path: `/@${this.user.userId}/feeds`}];
            
        return base.concat(entries.map(m => this.mapFeed(m.feed, m.children, undefined, selectedEvaluator)));
    }
    
    protected async getMenuCategories(selectedEvaluator: (item: MenuItem) => void) : Promise<MenuCategory[]> {
        const categoryFeeds = await MyFeedStore.instance.allFeedsCategorized(); 

        const categories: MenuCategory[] = [
            { items: this.mapFeeds(categoryFeeds, selectedEvaluator)}
        ]; 

        return categories;
    }

    protected dragHover(itemId: string, data: string, ctrlKey: boolean) : DragEffect {
        console.log(data);
        return "move";
    }

    protected async drop(itemId: string, data: string, ctrlKey: boolean) : Promise<void> {
        await MyFeedStore.instance.changeCategory(data, itemId);
    }

    private feedChangeHandler = async (event: CustomEvent<FeedChangeDetail>) => {
        if (event.detail.categoryChanges) {
            const selected = this.selectedElementId();
            await this.refresh();
            if (selected)
                this.selectElementId(selected);

        } else {
            event.detail.feeds.forEach(feed => {
                const mapped = this.mapFeed(feed, undefined, feed.isCategory, () => {});
                this.updateItem(mapped);
            });
        }
    }; 
}

customElements.define("feed-side-menu", FeedSideMenuElement);
