import "./WordCloudElement-style";
import ResizeObserver from "resize-observer-polyfill";
import {scaleSymlog,  } from "d3-scale";  //scaleLinear, scalePow, scaleLog, scaleRadial

interface Word {
    text: string;
    frequency: number;
}

interface DOMWord extends Word {
    size?: Size;
    element?: HTMLElement;
}

interface Size {
    width: number;
    height: number;
}

interface Point {
    y: number;
    x: number;
}

interface CachedFontSizeDetails {
    minFrequency?: number,
    maxFrequency?: number,
    maxFont?: number,
    minFont?: number,
    count?: number
}

export class WordCloudElement extends HTMLElement {
    private aspectRatio: Point;
    private colorSelector: (frequency: number) => string;
    private enrichedTags: DOMWord[] = [];
    private fontSizeCalculator: (frequency: number) => number;
    private limit = 360 * 5;
    private resizeObserver: ResizeObserver;
    private resolution = .4;
    private startingPoint: Point;
    private wordBoundaries = [];
    private cachedFontSizeDetails: CachedFontSizeDetails = {};
    
    static get observedAttributes() { return ["words"]; }

    async connectedCallback() {
        this.style.opacity = "0";
        this.parseWords();
        this.refresh();
        this.resizeObserver = new ResizeObserver(this.refresh);
        this.resizeObserver.observe(this);
        this.addEventListener("click", this.refresh);
    }

    disconnectedCallback() {
        this.resizeObserver?.disconnect();
    }

    private ensureColorSelector = () => {
        const frequencies = this.enrichedTags.map(m => m.frequency);
        const minFrequency = Math.min( ...frequencies);
        const maxFrequency = Math.max( ...frequencies);
        
        this.colorSelector = scaleSymlog()
            .domain([minFrequency, maxFrequency])
             .range(["#7c7c7c", "black"]);
            //.range([0,1,2,3,4])
            //.range([0, this.numberOfColors-1]);
    }

    private parseWords = () => {
        const wordsAttribute = this.getAttribute("words");

        if (!wordsAttribute) 
            return;

        const words = wordsAttribute
                        .split(";")
                        .map(m => m.split(":"))
                        .map(pair => ({
                            text: pair[0],
                            frequency: parseFloat(pair[1])
                        }));

        // remove gonners
        for(const tag of this.enrichedTags) {
            if (words.filter(pair => pair.text.toLowerCase() === tag.text.toLowerCase()).length === 0) {
                tag.element.remove();
                this.enrichedTags.filter(m => m.text.toLowerCase() === tag.text.toLowerCase());
            }
        } 

        // add and update
        for(const word of words) {
            const existing = this.enrichedTags.filter(m => m.text === word.text)[0]; 
            if (existing) {
                existing.frequency = word.frequency;
            } else {
                this.enrichedTags.push({
                    text: word.text,
                    frequency: word.frequency
                });
            }
        }

        this.enrichedTags.sort(this.tagSort);
        this.enrichedTags.splice(30);
    }

    async attributeChangedCallback(attrName, oldVal, newVal) {
        if (attrName === "words") {
            this.style.opacity = "0";
            this.parseWords();
            this.refresh();
        }    
    }

    private refresh = () => {
        window.requestAnimationFrame(() => {
            this.ensureColorSelector();
            this.calculateAspectRatio();
            this.calculateStartPoint();
            this.ensureFontSizeCalculator();
            this.createElements();
            this.calculateSizes();    
            window.requestAnimationFrame(this.redraw);
        });
    }

    private tagSort = (a: Word, b: Word) => {
        if (a.frequency > b.frequency)
            return -1;

        if (a.frequency < b.frequency)
            return 1;

        return 0;
    };

    private calculateStartPoint = () => {
        this.startingPoint = {
            x: this.offsetWidth / 2,
            y: this.offsetHeight / 2
        };
    }

    private calculateAspectRatio = () => {
        const width = this.offsetWidth;
        const height = this.offsetHeight;

        if (width > height) {
            this.aspectRatio = {
                y: 1,
                x: width / height
            }
        } else {
            this.aspectRatio = {
                y: height / width,
                x: 1
            }
        }
    }
    
    private ensureFontSizeCalculator = () => {
        const frequencies = this.enrichedTags.map(m => m.frequency);
        const minFrequency = Math.min( ...frequencies);
        const maxFrequency = Math.max( ...frequencies);
        const style = window.getComputedStyle(this);
        const maxFont = parseInt(style.getPropertyValue("--font-size-max"));
        const minFont = parseInt(style.getPropertyValue("--font-size-min"));
        
        if (minFrequency === this.cachedFontSizeDetails.minFrequency &&
            maxFrequency === this.cachedFontSizeDetails.maxFrequency &&
            minFont === this.cachedFontSizeDetails.minFont &&
            maxFont === this.cachedFontSizeDetails.maxFont && 
            this.enrichedTags.length === this.cachedFontSizeDetails.count)
            return;

        // this is caching to be able to values have changed
        this.cachedFontSizeDetails = {
            minFrequency: minFrequency,
            maxFrequency: maxFrequency,
            minFont: minFont,
            maxFont: maxFont,
            count: this.enrichedTags.length
        }

        this.fontSizeCalculator = scaleSymlog()
            .domain([minFrequency, maxFrequency])
            .range([minFont, maxFont]);

        this.resetCalculatedSizes();
    }

    createElements = () => {
        this.enrichedTags.filter(tag => !tag.element).forEach(tag => {
            tag.element = this.createTagElement(tag);
            this.appendChild(tag.element);
        });
    }

    calculateSizes = () => {
        this.enrichedTags.filter(tag => !tag.size).forEach(tag => {
            const { width, height } = tag.element.getBoundingClientRect();
            tag.size = { width, height };
        });
    }

    resetCalculatedSizes = () => {
        this.enrichedTags.forEach(tag => {
            tag.size = undefined;
        });
    }

    private createTagElement(word: Word) {
        const wordContainer = document.createElement("a");
        wordContainer.toggleAttribute("route", true);
        wordContainer.setAttribute("href", this.getAttribute("url-pattern").replace("{word}", encodeURIComponent(word.text)));
        wordContainer.setAttribute("title", `${word.text} - ${word.frequency}`);
        wordContainer.style.position = "absolute";
        wordContainer.setAttribute("color", this.colorSelector(word.frequency).toString());
        wordContainer.style.fontSize = `${this.fontSizeCalculator(word.frequency)}px`;
        //wordContainer.style.color = `var(--font-color-${this.colorSelector(word.frequency)})`;
        wordContainer.style.color =  this.colorSelector(word.frequency);
        
        wordContainer.appendChild(document.createTextNode(word.text));
        return wordContainer;
    }

    private spiral(i: number) {
        const angle = this.resolution * i;
        const x = (1 + angle) * Math.cos(angle);
        const y = (1 + angle) * Math.sin(angle);
        return ({ x: x * this.aspectRatio.x, y: y * this.aspectRatio.y }); // aspect ratio!
    }
    
    private intersect(tag: DOMWord, offset: Point) : Point {
        const position = {
            x: (this.startingPoint.x + offset.x) - tag.size.width / 2,
            y: (this.startingPoint.y + offset.y) - tag.size.height / 2
        };
        
        const wordBoundary = {
            left: position.x,
            top: position.y,
            right: position.x + tag.size.width,
            bottom: position.y + tag.size.height
        };

        // find availble spot
        for (let i = 0; i < this.wordBoundaries.length; i += 1) {
            const comparisonBoundary = this.wordBoundaries[i];

            if (!(wordBoundary.right  < comparisonBoundary.left ||
                  wordBoundary.left > comparisonBoundary.right ||
                  wordBoundary.bottom < comparisonBoundary.top ||
                  wordBoundary.top > comparisonBoundary.bottom)) {

                return undefined;
            }
        }

        this.wordBoundaries.push(wordBoundary);
        return position;
    }

    private redraw = () => {
        this.wordBoundaries = [];
        for (const tag of this.enrichedTags) {
            for (let j = 0; j < this.limit; j++) {
                const offset = this.spiral(j);
                const position = this.intersect(tag, offset);
            
                if (position) {
                    requestAnimationFrame(() => {
                        tag.element.style.left = `${position.x}px`;
                        tag.element.style.top = `${position.y}px`;
                    });
                    
                    break;
                }
            }
        }
        this.style.opacity = "1";
    }
}

customElements.define("word-cloud", WordCloudElement);