import { DOM } from "lib/DOM"
import { IApplicationRegistration } from "features/manage/authorized-applications/IApplicationRegistration"
import { DialogController } from "features/dialog";
import { AuthorizedApplicationsStore } from "features/manage/authorized-applications/AuthorizedApplicationsStore";
import { Routing } from "lib/Routing";
import "./AuthorizeApplicationViewController-style";

class AuthorizeApplicationViewController extends DialogController {
    private registration: IApplicationRegistration;
    private appStore: AuthorizedApplicationsStore = AuthorizedApplicationsStore.instance;

    constructor(private approve: IAppApprove) {
        super();
        setTimeout(async () => await this.setup());
    }

    private async setup() {
        await DOM.ready();

        this.registration = (<any>window).applicationRegistration as IApplicationRegistration;

        if (this.registration) {
            const option = await this.prompt({
                caption: "Authorize application",
                message: undefined,
                options: ["Cancel", "Allow"]
            });
            
            if (option === "Allow") {
                await this.appStore.approveApp(this.registration, this.approve.appId, this.approve.appKey);
            }
        }
    }

    renderSection = () => `
        <div class=allow-app>
            <div class=app-name>Allow ${this.registration.name}</div>
            To ${this.registration.rights.map(right => `${right.toLocaleLowerCase()}`)}?
        </div>`;
}

interface IAppApprove {
    appId: string;
    installationId?: string;
    appKey: string;
}

Routing.instance.onChange(route => {
    if (route.path[0] === "authorized-applications" && route.path[1] === "register"){
        const approve = {
            appId: route.path[2],
            appKey: route.path[3]
        }
        
        //tslint:disable-next-line: no-unused-variable
        const _ = new AuthorizeApplicationViewController(approve);
    }
});
