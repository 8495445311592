import "./TabMenuElement-style";


interface TabItem {
    title: string;
    name: string;
    icon: string;
    template: HTMLTemplateElement,
    element: HTMLElement;
    isDefault: boolean;
}

class TabMenuElement extends HTMLElement {
    private items:TabItem[]  = [];
    private buttons: HTMLButtonElement[];
    private contentTarget: HTMLElement;
    private currentTab: HTMLElement;
    private allowPersist = false;
    
    connectedCallback() {
        this.items = [].slice.call(this.querySelectorAll("template")).map( (template: HTMLTemplateElement) => ({
            title: template.getAttribute("title"),
            name: template.getAttribute("name") || template.getAttribute("title"),
            template: template,
            isDefault: template.hasAttribute("default")
        }));

        if (!this.items || this.items.length === 0)
            return;

        this.innerHTML = this.view();
        this.contentTarget = this.querySelector("content-target") as HTMLElement;
        this.buttons =  [].slice.call(this.querySelectorAll("button")) as HTMLButtonElement[];
        this.buttons.forEach(button => button.addEventListener("click", this.clickHandler));

        const persisted = this.items.filter(i => i.name === this.persistedActiveTab)[0];
        if (persisted && this.allowPersist)
            this.showTabItem(persisted);
        else
            this.showTabItem(this.items.filter(i => i.isDefault)[0]);
    }

    private get persistedActiveTab() {
        try {
            return localStorage.getItem("active-tab");
        } catch(error) {
            return undefined;
        }
    }

    private set persistedActiveTab(value: string) {
        try {
            localStorage.setItem("active-tab", value);
        } catch(error) {
        }
    }

    private clickHandler = (event: UIEvent) => {
        const target = event.target as HTMLElement;
        const button = target.closest("button");

        const item = this.items.filter(i => i.name === button.getAttribute("name"))[0];

        this.showTabItem(item);
    };

    private showTabItem(item: TabItem) {
        if (!item)
            return;

        if (!item.element) {
            item.element = <any>item.template.content.firstElementChild as HTMLElement;
            this.contentTarget.appendChild(item.element);
        }

        requestAnimationFrame(() => {
            if (this.currentTab)
                this.currentTab.style.display = "none"; // = "hidden"

            this.currentTab = item.element;
            this.currentTab.style.display = "block"; //visibility = "visible";

            this.buttons.forEach(button => button.removeAttribute("active"));

            const activeButton = this.buttons.filter(i => i.name === item.name)[0] as HTMLButtonElement;
            activeButton.setAttribute("active", "");
            this.persistedActiveTab = item.name;
        });
    }

    private view = () => `
        <div name=tabs>
            ${this.items.map(item => `<button type=button name="${item.name}">${item.title}</button>`).join("")}
        </div>
        <content-target></content-target>
    `;
}

customElements.define("tab-menu", TabMenuElement);