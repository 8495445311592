import "./ThemeSelectorElement-style";

class ThemeSelectorElement extends HTMLElement {
    connectedCallback() {
        this.innerHTML = `
            <div>
                <button type=button name="lines">Horizontal Lines</button>
                <button type=button name="line-boxes">Line Boxes</button>
                <button type=button name="gray">Gray</button>
                <button type=button name="ddd">3D</button>
            </div>
        `;

        this.addEventListener("click", (event: MouseEvent) => {
            if (!(event.target instanceof Element))
                return;

            const button = (<Element>event.target).closest("button");
            if (!button)
                return;

            document.documentElement.setAttribute("theme", button.name);
            this.remove();
        });
    }
}

customElements.define("theme-selector", ThemeSelectorElement);