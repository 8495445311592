import "./HistoryWidget-style";
import * as cardsStyle from "../../../../styles/cards-module.less"
import * as widgetStyle from "../Widgets-module.less";
import { BaseWidget, PreferSize } from "../BaseWidget";

class HistoryWidgetElement extends BaseWidget {
    public get preferredWidth(): PreferSize {
        return "narrow";
    }
    
    async connectedCallback() {
        this.className += ` ${cardsStyle.cardContainer}`;
        this.innerHTML = this.view();
        super.connectedCallback();
    }

    private view = () => `
        ${this.headerView("History")}
        <div class="${cardsStyle.card} ${widgetStyle.card}">
            <universal-log></universal-log>
        </div>
    `;
}

customElements.define("history-widget", HistoryWidgetElement);