import * as formsStyle from "../forms/forms.module.less";   
import * as confirmStyle from "./SiteConfirmEmailElement-module.less";
import * as buttonStyle from "../forms/buttons-module.less";

class SiteEmailConfirmedElement extends HTMLElement {

    connectedCallback() {
        this.innerHTML = this.view();
        this.classList.add(formsStyle.loginControl);
        this.style.border = "none";
    }

    private view = () => `
        <header class=${confirmStyle.header}>
            <h1>Email confirmed</h1>
        </header>
        <section>
            <p>Thank you for confirming your email.</p>
            <p>&nbsp;</p>
            <a class="${buttonStyle.cleanButton}" href="/login">Continue</a>
        </section>
    `;
}

customElements.define("site-email-confirmed", SiteEmailConfirmedElement);