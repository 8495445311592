export interface IVersion {
    major: number;
    minor: number;
    build: number;
    date: Date;
    textual: string;
}

export interface PagedResult<T> {
    items: T[];
    total: number;
}

export interface IValidity {
    valid: boolean;
    reason?: string;
}


export interface IHighlighted {
    highlights: { [field: string]: string; };
}

export class Highlight {
    public static property<T>(item: T & IHighlighted, propertySelector: (T) => string) {
        if (item.highlights) {
            const value = propertySelector(item.highlights);
                        
            if (value)
                return value;
        }
        
        return propertySelector(item) || "";
    }
}