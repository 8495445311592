import { Link } from "features/links";

export type ErrorReason = "unknown" | "documentAlreadyExists"
export type Type = "link" | "stack";
export enum Simplified { Unknown, Updated, Created, Deleted };
export type CommandType = AddTagsToLinkCommand | MoveFromStackToStackCommand | CreateCommand | AddingLinkCommand |
    EnrichedCommand | DeleteCommand | CopyFromStackToStackCommand | UpdateCommand | RefreshedMetadataCommand | 
    EnrichedManyCommand;

interface AddTagsToLinkCommand {
    $type: "addTagsToLinkCommand";
    tags: string[];
}
interface MoveFromStackToStackCommand  {
    $type: "moveFromStackToStackCommand";
    sourceStack: string;
    destinationStack: string;
    before: Link;
}

interface CopyFromStackToStackCommand {
    $type: "copyFromStackToStackCommand";
    sourceStack: string;
    destinationStack: string;
    before: Link;
}

interface CreateCommand {
    $type: "createCommand";
}

interface DeleteCommand {
    $type: "deleteCommand";
}

interface EnrichedCommand {
    $type: "enrichedCommand";
}

interface EnrichedManyCommand {
    $type: "enrichedManyCommand";
    count: number;
}

interface UpdateCommand {
    $type: "updateCommand";
}


interface AddingLinkCommand {
    $type: "addingLinkCommand";
}

interface RefreshedMetadataCommand{
    $type: "refreshedMetadataCommand";
}

export interface IRevision {
    type: Type;
    sequence: number;
    id: string;
    action: CommandType;
    when: string;
    whenDate?: Date;
    actor: string;
    succeeded: boolean;
    reason: ErrorReason;
    what: string;
    entity: Link;
    simplified: Simplified;
    initiator?: AuthorViewModel
}

export interface AuthorViewModel {
    userId?: string;
}

export interface IEventViewModel {
    stackCounts: { [key: string]: number };
    latest: number;
    isEmpty: boolean;
    revisions: IRevision[];
    eventstream: { [key: string]: EventPayload }
}

export interface EventPayload {
    marker: any;
    payload: any;
}