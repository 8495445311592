import { Entry, Feed } from "features/feeds";
import { PagedResult, IHighlighted } from "lib/Model";
import { Link } from "features/links";
import { Pipeline, Request } from "lib/HTTP";
import { Formatting } from "lib/Formatting";
import { StackHelper } from "features/stacks/StackHelper";
import { Linkstack } from "features/stacks/StackStore";
import "./SearchControlElement-style";
import { UrlProvider } from "lib/UrlProvider";
import { MixinField } from "features/mixin/MixinField";

interface SearchResult {
    entries: PagedResult<Entry & IHighlighted>;
    links: PagedResult<Link & IHighlighted>;
    stacks: PagedResult<Linkstack & IHighlighted>;
    feeds: PagedResult<Feed & IHighlighted>;
}

class SearchControlElement extends HTMLElement {
    private escape = Formatting.htmlEscape;
    private _urlProvider = new UrlProvider();
    private centered: HTMLElement;
  
    async connectedCallback() {
        const query = this.getAttribute("query");

        this.innerHTML = this.view(query);

        //tslint:disable-next-line: no-unused-variable
        const _ = new MixinField(this, 2);

        this.centered = this.querySelector("[name=center]") as HTMLElement;
       

        setTimeout(async () => {
       
            if (query) 
                await this.search(query);
        });
    }
    
    static get observedAttributes() { return ["query"]; }

    private async search(query: string) {

        //this.searchInput.blur();
        const searchParams = new URLSearchParams();
        searchParams.set("query", query);

        
        const searchResponse = await new Pipeline().fetch(`${this._urlProvider.root}/api/search?${searchParams.toString()}`, Request.get.authenticate());

        if (!searchResponse.ok)
            return;
            
        const searchResult = await searchResponse.json() as SearchResult;
    
        const existing = this.centered.querySelector("tab-menu");
        if (existing)
            existing.remove();

        this.centered.insertAdjacentHTML("beforeend", this.resultView(searchResult))

        StackHelper.resolveStacks();
    }

    private view = (query: string) => this.escape`
        <div name=center>
           
            
        </div>
    `;

    private countView = (items: any[]) => {
        if (!items || items.length === 0)
            return "";
        
        return ` (${items.length})`;
    }

    private resultView = (searchResult: SearchResult) => this.escape`
        <tab-menu>
            <template title="Links${this.countView(searchResult.links.items)}" name="links" default>
                <div>
                    $${searchResult.links.items.map(highlighedLink => this.escape`
                        <link-control show-image show-stack allow-edit>
                            <script type="application/json+link">
                                $${JSON.stringify(highlighedLink)}
                            </script>
                        </link-control>
                    `)}
                </div>
            </template>
            <template title="Stacks${this.countView(searchResult.stacks.items)}" name="stacks">
                <div>
                    $${searchResult.stacks.items.map(stack => this.escape`
                        <stack-teaser>
                            <script type="application/json+stack">
                                $${JSON.stringify(stack)}
                            </script>
                        </stack-teaser>
                    `)}
                </div>
            </template>
            <template title="Articles${this.countView(searchResult.entries.items)}" name="entries">
                <div>
                    $${searchResult.entries.items.map(entry => this.escape`
                        <entry-control>
                            <script type="application/json+entry">
                                $${JSON.stringify(entry)}
                            </script>
                        </entry-control>
                    `).join("")}
                </div>
            </template>
            <template title="Feeds${this.countView(searchResult.feeds.items)}" name="feeds">
                <div>
                    $${searchResult.feeds.items.map(feed => this.escape`
                        <feed-control>
                            <script type="application/json+feed">
                                $${JSON.stringify(feed)}
                            </script>
                        </feed-control>
                    `)}
                </div>
            </template>
        </tab-menu>`;
}



customElements.define("search-control", SearchControlElement);
