
import { Routing } from "lib/Routing";
import { Package } from "features/packages/Package";
import { BasePackageElement } from "features/packages/BasePackageElement";
import { AppContextStore } from "features/application-context/AppContextStore";

interface FirefoxPackage extends Package {
    url: string;
    version: string;
}

class FirefoxPluginElement extends BasePackageElement {
    protected slug = "firefox-plugin";    

    protected detailView = (thePackage: FirefoxPackage) => `
        <form method=get action="${thePackage.url}">
        <button type="submit" class="power">+ Add to Firefox</button>
        </form>
    `;      
}

customElements.define("firefox-plugin", FirefoxPluginElement);

Routing.instance.onChange(async route => {
    if (route.path[0] === "apps" && route.path[1] === "firefox-plugin") {
        await AppContextStore.instance.present("", document.createElement("firefox-plugin"), "Bookmarks", "Apps", "Firefox plugin");
    }
});