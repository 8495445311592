import "./BoxLoaderElement-style";

export  class BoxLoaderElement extends HTMLElement {
    connectedCallback() {
        this.append(document.createElement("div"));
        this.append(document.createElement("div"));
        this.append(document.createElement("div"));
    }
}

customElements.define("box-loader", BoxLoaderElement);