import { CitylineClient } from "./CitylineClient";
import { UrlProvider } from "lib/UrlProvider";
import { Request } from "lib/HTTP";

export class CitylineSingleton {
    private static _client: CitylineClient = new CitylineClient(`${new UrlProvider().root}/api/cityline`, () => Promise.resolve(Request.post.authenticate()));
    static get client() {
        return CitylineSingleton._client;
    }
}

const cityline = CitylineSingleton.client;
export { cityline };


// setTimeout(async () => {
//     const initialUsername = await cityline.getFrame<string>("username");
//     cityline.addEventListener("username", (event:CustomEvent<string>) => {
//         if (event.detail !== initialUsername)
//             console.log("Username changed", event.detail);
//     });
// });

// cityline.addEventListener("frame-received", (event:CustomEvent) => {
//     if (event.detail.event === "feeds") {
//         console.log("Event is", event.detail);
//         console.log("Id", event.detail.id.length);

//     }

// });


//cityline.addEventListener("frame-received", (event:CustomEvent) => console.log(`${event.detail.event}:`, event.detail.data));
cityline.addEventListener("error", (event:CustomEvent) => console.log("ERROR", event.detail));
//cityline.addEventListener("log", (event:CustomEvent) => console.log(event.detail));
