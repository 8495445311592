import { BaseDialogViewController, IViewController } from "features/dialog";
import { DOM } from "lib/DOM";
import { FiltersStore, IFilter, IFilterActionAssignTags, IFilterActionAssignStacks } from "./FiltersStore";
import { Formatting } from "lib/Formatting";
import { Routing } from "lib/Routing";
import "./FilterEditorViewController-style";
import { DialogController } from "features/dialog";

export class FilterEditorViewController extends BaseDialogViewController {
    private currentFilter: IFilter;
    private escape = Formatting.htmlEscape;

    constructor() {
        super();
        this.clazz += "filters segmented";
    }

    getFormAction() {
        return "";
    }

    private actionKinds = [
        {
            title: "Add tags to links",
            tab: {
                title: "Perform action",
                viewController: () => ({
                    view: () => `
                            <fieldset>
                                <legend>Apply tags</legend>
                                <input type=hidden name=action.tags required value="${(<IFilterActionAssignTags>this.currentFilter.action).tags.join(",")}" />
                                <tag-editor always-open tags="${(<IFilterActionAssignTags>this.currentFilter.action).tags.join(";")}"></tag-editor>
                            </fieldset>
                        `,
                    load: (section: HTMLElement) => {
                        const tagEditor = section.querySelector("tag-editor");
                        tagEditor.addEventListener("tags-changed", (event: CustomEvent<string>) => {
                            const setTags = section.querySelector("input[name='action.tags']") as HTMLInputElement;
                            setTags.value = event.detail.split(";").join(",");
                            setTags.dispatchEvent(new Event("input", {
                                bubbles: true,
                                cancelable: true,
                            }));
                        });
                    },
                    unload: (section: HTMLElement) => {
                        DOM.serializeInto(section, this.currentFilter);
                    }
                })
            },
            type: "filterActionAssignTags",
            action: <IFilterActionAssignTags>{
                $type: "filterActionAssignTags",
                tags: []
            }
        },
        {
            title: "Copy or move links into stacks",
            tab: {
                title: "Perform action",
                viewController: () => ({
                    view: () => `
                             <fieldset>
                                <legend>Copy links to these stacks</legend>

                                <stack-selector field-name="action.stackIds" stack-slugs="${(<IFilterActionAssignStacks>this.currentFilter.action).stackIds.join(",")}"></stack-selector>
                               
                                <div class="checkbox">
                                    <input type=checkbox name="action.deleteFromSourceStack" ${(<IFilterActionAssignStacks>this
                            .currentFilter.action)
                            .deleteFromSourceStack
                            ? "checked"
                            : ""}>
                                    <label for=remove>Remove link from source stack</label>
                                </div>
                            </fieldset>
                        `,
                    load: (section: HTMLElement) => { },
                    unload: (section: HTMLElement) => {
                        DOM.serializeInto(section, this.currentFilter);
                    }
                })
            },
            type: "filterActionAssignStacks",
            action: <IFilterActionAssignStacks>{
                $type: "filterActionAssignStacks",
                stackIds: [],
                deleteFromSourceStack: false
            }
        }
    ];

    private isActionTypeSelected() {
        return this.currentFilter.action && this.currentFilter.type;
    }

    ////type: "filterConditionStacksAndSearch",

    public async new(stackIds: string[] = [], search = "", navigation = false) {
        this.currentFilter = {
            enabled: true,
            condition: {
                $type: "filterConditionStacksAndSearch",
                search: search,
                stackIds: stackIds
            }
        };

        // if (!navigation)
        //     Routing.instance.publish("/filters/new");

        await this.show();
    }

    public async editNoRoute(filter: IFilter) {
        this.currentFilter = filter;
        await this.show();
    }

    close() {
        super.close();
        if (Routing.instance.matches("/filters/*"))
            Routing.instance.publish("/filters");
    }

    afterRender() {
        super.afterRender();

        this.dialog
            .querySelector(".cancel")
            .addEventListener("click", () => this.close());

        const form = <HTMLFormElement>this.dialog.querySelector("form");

        const submit = async () => {
            if (this.currentTab.viewController().unload)
                this.currentTab
                    .viewController()
                    .unload(<HTMLElement>this.dialog.querySelector("section"));

            if (!this.isActionTypeSelected()) {
                this.showTab({
                    title: "",
                    viewController: () => this.actionTypeSelector
                });
            } else {
                this.currentFilter.runNow = (<HTMLInputElement>this.dialog.querySelector(
                    "input[name=runnow]"
                )).checked;

                try {
                    this.dialog.setAttribute("busy", "");
                    await FiltersStore.instance.upsert(this.currentFilter);
                } finally {
                    this.dialog.removeAttribute("busy");
                }


                this.close();
            }
        };

        form.addEventListener("submit", (event) => {
            event.preventDefault();
            //submit();
        });

        this.dialog.querySelector("footer").addEventListener("click", async event => {
            const element = <HTMLButtonElement>event.target;
            if (element.type && element.type === "submit") {
                event.preventDefault();
                await submit();
            }

            if (element.name === "delete") {

                const response = await new DialogController().prompt({
                    caption: "Delete filter",
                    message: `Are you sure that you want to delete this filter?`,
                    options: ["Cancel", "Delete"]
                });

                if (response === "Delete") {
                    await FiltersStore.instance.delete(this.currentFilter);
                    this.close();
                }
            }
        });
    }

    getTabs() {
        return [
            {
                title: "Condition",
                viewController: () => ({
                    view: () => this.escape`
                            <fieldset class=modern>
                                <legend>Links going into stacks</legend>
                                <stack-selector field-name="condition.stackIds" stack-slugs="${this.currentFilter.condition.stackIds.join(",")}"></stack-selector>
                            </fieldset>
                            <fieldset class=modern>
                                <legend>Matching this search</legend>
                                <div class=group>
                                <input type=text id="condition-search" name="condition.search" required placeholder="Search query" value="${this.currentFilter.condition.search}" />
                                    <label for="condition-search"></label>
                                </div>
                            </fieldset>
                        `,
                    load: (root: HTMLElement) => {
                        const searchElement = <HTMLInputElement>root.querySelector("input[name='condition.search']");
                        const stackIdsElement = <HTMLInputElement>root.querySelector("input[name='condition.stackIds']");

                        const formCheck = () => {
                            if (
                                searchElement.checkValidity() &&
                                searchElement.value !== ""
                            )
                                stackIdsElement.required = false;
                            else
                                stackIdsElement.required = true;

                            if (
                                stackIdsElement.checkValidity() &&
                                stackIdsElement.value !== ""
                            )
                                searchElement.required = false;
                            else
                                searchElement.required = true;

                            const affectCountElement = <HTMLSpanElement>this.dialog.querySelector(
                                ".affect-count"
                            );
                            setTimeout(async () => {
                                DOM.serializeInto(root, this.currentFilter);
                                const count = await FiltersStore.instance.getAffectCount(
                                    this.currentFilter
                                );
                                affectCountElement.innerText = count.toString();
                            }, 1000);
                        };


                        if (searchElement) {
                            searchElement.addEventListener("keyup", formCheck);
                            searchElement.addEventListener("change", formCheck);
                        }
                        if (stackIdsElement) {
                            stackIdsElement.addEventListener("keyup", formCheck);
                            stackIdsElement.addEventListener("change", formCheck);
                        }

                        // check form on load
                        setTimeout(() => {
                            formCheck();
                            super.validateCurrentTab();
                        }, 10);

                    },
                    unload: (root: HTMLElement) => {
                        DOM.serializeInto(root, this.currentFilter);
                    }
                })
            },
            {
                title: "Perform action",
                viewController: () =>
                    this.determineViewControllerBasedOnCurrentState()
            }
        ];
    }

    private determineViewControllerBasedOnCurrentState(): IViewController {
        if (!this.isActionTypeSelected()) return this.actionTypeSelector;
        else {
            const action = this.actionKinds.filter(
                actionKind =>
                    actionKind.type === this.currentFilter.type
            )[0];
            return action.tab.viewController();
        }
    }

    private actionTypeSelector: IViewController = {
        view: () => `
                <fieldset>
                   <input type=hidden name=actionKind required />
                   ${this.actionKinds
                .map(
                    actionKind => `
                        <button type=button class="actionKind power" id=${actionKind.type}>${actionKind.title}</button>
                   `
                )
                .join("")}
                </fieldset>
            `,
        load: (root: HTMLElement) => {
            (<HTMLButtonElement>this.dialog.querySelector(
                "footer button[type=submit]"
            )).disabled = true;
            this.dialog.querySelector("header a").classList.remove("active");

            Array.prototype.slice
                .call(root.querySelectorAll(".actionKind"))
                .forEach((button: HTMLButtonElement) => {
                    button.addEventListener("click", event => {
                        event.preventDefault();
                        event.stopPropagation();
                        const action = this.actionKinds.filter(
                            actionKind => actionKind.type === button.id
                        )[0];

                        (<HTMLInputElement>root.querySelector(
                            "input[name=actionKind]"
                        )).value =
                            button.id;

                        // hack
                        (<HTMLButtonElement>this.dialog.querySelector(
                            "footer button[type=submit]"
                        )).innerText =
                            "Save";

                        this.currentFilter.action = action.action;
                        (<any>this.currentFilter).action.$type = action.type;
                        (<any>this.currentFilter).type = action.type;
                        this.showTab(action.tab);
                    });
                });
        },
        unload: (root: HTMLElement) => {
            (<HTMLButtonElement>this.dialog.querySelector(
                "footer button[type=submit]"
            )).innerText =
                "Save";
        }
    };

    renderFooter = () => `
            <div class=extra>
                <input type="checkbox" name=runnow id=runnow /> 
                <label for=runnow>Also apply this filter to <span class="affect-count"></span> matching links.</label>
            </div>
            <div>
                <button class="btn" type=button name=delete>Delete filter</button>
                <button class="btn power-inactive cancel" type=button>Cancel</button>
                <button class="btn btn-default power" type=submit default disabled>${this.isActionTypeSelected()
            ? "Save"
            : "Next"}</button>
            </div>`;
}

// Routing.instance.onChange(route => {
//    if (route.path[0] === "filters") {
//        if (route.path[1]) {
//             if (route.path[1] === "new") {
//                 return new FilterEditorViewController().new(
//                         route.parameters.stacks || route.parameters.stacks === ""
//                         ? route.parameters.stacks.split(",")
//                         : ["inbox"],
//                         route.parameters.search,
//                     true
//                 );

//             } else {
//                 return new FilterEditorViewController().edit(
//                     route.path[1]
//                 );
//             }
//        }
//    } 
// });
