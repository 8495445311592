import { AuthenticationStore } from "./AuthenticationStore";
import "./AuthSwitcherElement-style";

class AuthSwitcherElement extends HTMLElement {
    private authElement: HTMLElement;
    private nonAuthElement: HTMLElement;
    private currentState;

    async connectedCallback() {
        AuthenticationStore.instance.onStateChanged(this.stateHandler);
       
        if (!this.authElement && !this.nonAuthElement)
            setTimeout(async () => {
                await this.stateHandler();
            });
            
    }

    disconnectedCallback() {
        AuthenticationStore.instance.offStateChanged(this.stateHandler);
    }

    private stateHandler = async () => {
        console.log("getting state");
        const authenticated = await AuthenticationStore.instance.isAuthenticated();
        console.log("got state");
        if (this.currentState === authenticated)
            return;

        //window.location.reload();
        
        //await cityline.reset();
        this.currentState = authenticated;

        if (authenticated) {
            if (this.nonAuthElement && this.nonAuthElement.parentElement)
                this.nonAuthElement.remove();
            
            if (!this.authElement)
                this.authElement = this.createFromTemplate("authenticated");
            
            this.appendChild(this.authElement);
        } else {
            if (this.authElement && this.authElement.parentElement)
                this.authElement.remove();
            
            if (!this.nonAuthElement)
                this.nonAuthElement = this.createFromTemplate("not-authenticated");
            
            this.appendChild(this.nonAuthElement);
        }
    }

    private createFromTemplate(name: string) {
        const template = this.querySelector(`template[name=${name}]`) as HTMLTemplateElement;
        return template.content.firstElementChild as HTMLElement;
    }
}

customElements.define("auth-switcher", AuthSwitcherElement);

