
import { UrlProvider } from "lib/UrlProvider";
import { MixinPopup } from "../mixin/MixinPopup";
import { MixinHeader } from "../mixin/MixinHeader";

import "./ShareStackElement-style";

class ShareStackElement extends HTMLElement {
    private stackId = () => this.getAttribute("stack-id");
    private activeTab = () => this.getAttribute("active-tab") || "social";
    private isCurrentUserOwner = () => this.hasAttribute("is-owner");
    private stackSlug: string;

    connectedCallback() {
        this.stackSlug = new UrlProvider().slugPart(this.stackId());
        this.innerHTML = this.view();

        //tslint:disable-next-line: no-unused-variable
        const _ = new MixinPopup(this);
        //tslint:disable-next-line: no-unused-variable
        const _2 = new MixinHeader(this.querySelector("header"));
    }

    private view = () => `
        <div>
            <header>
                <div></div>
                <div class=caption>Share stack</div>
                <button popup-close>Done</button>
            </header>
            <section>
                <tab-menu>
                <template title="Social" ${this.activeTab() === "social" ? "default" : ""}>
                    <social-share stack-id="${this.stackId()}"></social-share>
                </template>
                ${this.isCurrentUserOwner() ? `
                    <template title="Collaborate" ${this.activeTab() === "collaborate" ? "default" : ""}>
                        <collaboration-editor stack-slug="${this.stackSlug}"></collaboration-editor>
                    </template>
                ` : ""}
            </section>
        </div>
    `;
}

customElements.define("share-stack", ShareStackElement)
