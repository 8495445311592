import "./LoginProvidersElement-style";
import { AddProviderResult, AuthenticationStore, Provider, ProviderName } from "features/authentication";

import { DOM } from "lib/DOM";
import { DialogController } from "features/dialog";
import { Request } from "lib/HTTP";

export class LoginProvidersElement extends HTMLElement {
    protected static path = ["manage", "login-providers"];
    public static tag = "login-providers";

    private async render(providers: Provider[]) {
        const hasPassword = await AuthenticationStore.instance.hasPassword();
        const lastProvider = (hasPassword === false) && (providers.filter(i => i.providerKey).length <= 1);

        this.innerHTML = this.view(providers, lastProvider);
        await this.setupDOM();
    }

    private async setupDOM() {
        [].slice.call(this.querySelectorAll(".add")).forEach( (element:HTMLElement) => {
            element.addEventListener("click", async () => {
                const providerName = element.getAttribute("name") as ProviderName;
                await AuthenticationStore.instance.addProvider(providerName);
            });
        });

        [].slice.call(this.querySelectorAll(".remove")).forEach( (element:HTMLElement) => {
            element.addEventListener("click", async () => {
                 const providerName = element.getAttribute("name") as ProviderName;
                 const name = element.querySelector(".big").innerHTML;
                 const response = await new DialogController().prompt({
                    caption: "Remove provider",
                    message: `Are your sure you want to remove your ${name}-login from linkstacks?`,
                    options: ["Cancel", "Remove login"]
                });

                if (response === "Remove login") {
                    await AuthenticationStore.instance.removeProvider(providerName);
                }
            });
        });


        const error = DOM.getCookie("errors");
        if (error) {
            DOM.removecookie("errors");
            await new DialogController().prompt({
                caption: "Unable to link provider",
                message: error,
                options: ["OK"]
            });
        }
    }

    private addProviderMessages: { [result: string] : string; } = {
        "unableToGetInfoFromProvider": "Unable to retrieve information from provider",
        "unableToAddLogin": "Unable to add login, please try later",
        "anotherUserWithThisLogin": "Another user is already assigned to this external login"
    }

    private handleAddProviderError = async (error: AddProviderResult) => {
        let message = this.addProviderMessages[error];
        if (!message)
            message = "Unknown error";

        await new DialogController().prompt({
            caption: "Unable to add provider",
            message: message,
            options: ["OK"]
        });
    }

    private handleProvidersChanged = async (providers: Provider[]) => await this.render(providers);

    async connectedCallback(){
        const providers = await AuthenticationStore.instance.providers();

        if (!providers)
            return;
            
        await this.render(providers);

        AuthenticationStore.instance.onAddProviderError(this.handleAddProviderError);
        AuthenticationStore.instance.onProvidersChanged(this.handleProvidersChanged);
    }

    disconnectedCallback() {
        AuthenticationStore.instance.removeOnAddProviderError(this.handleAddProviderError);
        AuthenticationStore.instance.removeOnProvidersChanged(this.handleProvidersChanged);
    }

    private logos = {
        facebook: require("!!raw-loader!image/icons/facebook.svg"),
        google: require("!!raw-loader!image/icons/google.svg"),
        twitter: require("!!raw-loader!image/icons/twitter.svg"),
    };

    private names = {
        facebook: "Facebook",
        google: "Google",
        twitter: "Twitter",
    };

    private providerView = (provider: Provider, lastProvider: boolean) => `
        <form action="/manage/linklogin?_token=${Request.getSourceToken()}" method=post>
            <input type=hidden value="${provider.loginProvider}" name=provider />
            <div class="provider ${provider.loginProvider.toLocaleLowerCase()} ${provider.providerKey ? (lastProvider ? "sole" : "remove") : "add"}" name="${provider.loginProvider}">
                <input type=hidden value="${provider.loginProvider}" name=loginProvider />
                <input type=hidden value="${provider.providerKey}" name=providerKey />
                <div class=icon>${this.logos[provider.loginProvider.toLocaleLowerCase()]}</div>
                <div class=big>${this.names[provider.loginProvider.toLocaleLowerCase()]}</div>
                <div class=action>
                    ${provider.providerKey ? (lastProvider ? "" : `<a>Remove&nbsp;</a>`) :  `<a>Add ${require("!!raw-loader!image/icons/1461951122_forward.svg")}</a>`}
                </div>
            </div>
        </form>
    `;

    private view = (providers: Provider[], lastProvider: boolean) => `
        <div class="box password case">
            <header>Login providers</header>
            <div class=description>
                Use existing login from external provider to access Linkstacks.
            </div>
            <div class=validation-message name=""></div>
            ${providers.map(provider => this.providerView(provider, lastProvider)).join("")}
        </div>
    `;
}

customElements.define(LoginProvidersElement.tag, LoginProvidersElement);
