import "./ActionPanel-style";
import { LinkStore } from "features/links";

class ActionPanelElement extends HTMLElement {
    connectedCallback() {
        const button = this.querySelector("[name=favorite]");
        button.addEventListener("click", async () => {
            const isFavorite =  this.closest("link-control").hasAttribute("favorite");
            if (isFavorite)
                await LinkStore.instance.unFavorite(this.getAttribute("link-slug"), this.getAttribute("stack-slug"));
            else
                await LinkStore.instance.favorite(this.getAttribute("link-slug"), this.getAttribute("stack-slug"));

            this.closest("link-control").toggleAttribute("favorite");
            
        });
    }
}

customElements.define("action-panel", ActionPanelElement);