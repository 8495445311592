import { UrlProvider } from "lib/UrlProvider";
import { ILoginProvider } from "./ILoginProvider";
import { LoginResponse } from "../Model";
import { Pipeline, Request } from "lib/HTTP";
// import { AppleAuthConfig } from "apple-auth";
// import AppleAuth from "apple-auth";
import { Plugins, PluginListenerHandle } from "@capacitor/core";
import { BrowserInfo } from "lib/Browser";
const { Browser } = Plugins;
import { App } from '@capacitor/app';

export class AppleWebLoginProvider implements ILoginProvider {
    getName = () => "AppleWebLoginProvider";
    //private appleLogin: AppleLogin = (<any>window).AppleID;

    private initializeApple() {
        return new Promise<void>((resolve) => {
            const script = document.createElement("script");
            document.head.appendChild(script);
            script.src = "https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js";
            script.onload = () => resolve();
        });
    }

    static guid() {
        const s4 = () => Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
        return s4() + s4() + s4() + s4() + s4() + s4() + s4() + s4();
    }

    login(): Promise<LoginResponse> {
        return new Promise(async (resolve, reject) => {
            try {
                await this.initializeApple();
 
                (<any>window).AppleID.auth.init({
                    clientId: "net.monzoom.linkstacks",
                    scope: "email",
                    redirectURI: `${new UrlProvider().root}/api/external-login/apple`,
                    state: "",
                    nonce: AppleWebLoginProvider.guid(),
                    usePopup: true //or false defaults to false
                });


                document.addEventListener("AppleIDSignInOnSuccess", (event: CustomEvent<Authorization>) => {

                }, { once: true });
                //Listen for authorization failures
                document.addEventListener("AppleIDSignInOnFailure", (error) => {
                    reject(error);
                }, { once: true });


                const data = await (<any>window).AppleID.auth.signIn() as AuthResponse;

                const response = await fetch(`${new UrlProvider().root}/api/external-login/apple`, {
                    method: "POST",
                    credentials: "include",
                    headers: {
                        "content-type": "text/json"
                    },
                    body: JSON.stringify({
                        tokenId: data.authorization.id_token
                    })
                });

                if (!response.ok)
                    reject("Unable to login");

                const loginResponse = await response.json() as LoginResponse;
                resolve(loginResponse);
            }
            catch (error) {
                reject("Unable to login");
            }
        });
    }
}

interface AuthResponse {
    authorization: Authorization
}

interface Authorization {
    code: string;
    id_token: string;
}