import { Routing } from "lib/Routing";
import { AppContextStore } from "features/application-context/AppContextStore";
import { AuthenticationStore } from "features/authentication";
import {HubComponentElement } from  "./HubComponentElement";

 //tslint:disable-next-line: no-unused-variable
class DashboardActivator {
     //tslint:disable-next-line: no-unused-variable
    private static _instance = new DashboardActivator();

    private constructor() {
        Routing.instance.onChange(async route => {
            if (Routing.instance.isMatch("@*/-", route)) {
                // const { HubComponentElement } = await import( /* webpackChunkName: "hub", webpackPrefetch: true */ "./HubComponentElement");


                const element = new HubComponentElement();

                const user = await AuthenticationStore.instance.currentUser();
                await AppContextStore.instance.present("hub", element, `${user.username}'s Hub`);
            }
        });
    }
}
