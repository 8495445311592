
import "./CollaborationEditorElement-style"
import { CollaborationStore, Collaborator } from "features/collaboration/CollaborationStore";
import { DialogController } from "features/dialog";
import { AuthenticationStore } from "features/authentication";

class CollaborationEditorElement extends HTMLElement
{
    private collaborators: Collaborator[];
    private isValidEmail = email => /^.+@.+\..+$/.test(email);
    private stackSlug = () => this.getAttribute("stack-slug");
    private currentUserIsOwner: boolean;
    private currentUser;

    private invitations: HTMLElement;

    async connectedCallback() {
        this.collaborators = await CollaborationStore.instance.getStackCollaborators(this.stackSlug());

        this.currentUser = await AuthenticationStore.instance.currentUser();
        const owner = this.collaborators.filter(i => i.status === "owner")[0]

        if (owner)
            this.currentUserIsOwner = this.currentUser.userId === owner.username;

        this.innerHTML = this.view(this.collaborators);

        this.invitations = this.querySelector("[name=invitations]") as HTMLElement;
        this.setupInviteSection();

        this.addEventListener("click", this.clickHandler)

        const breakConnectionButton = this.querySelector("[name=break-connection]");
        if (breakConnectionButton)
            breakConnectionButton.addEventListener("click", this.breakConnectionHandler)
    }

    private breakConnectionHandler = async () => {
        const response = await new DialogController().prompt({
            caption: "Break connection to master?",
            message: "Your stack will become an ordinary stack with no connections. Break connection?",
            options: ["Cancel", "Break connection"]
        });

        if (response !== "Break connection")
            return;
        
        
        try {
            this.setAttribute("busy", "");
            const result = CollaborationStore.instance.breakConnection(this.stackSlug());
            if (result) {
                this.remove();
            } else {
                await new DialogController().prompt({
                    caption: "Unable to break connection",
                    message: "Please try again and contact us if the problem persists.",
                    options: ["OK"]
                });
            }    
        } finally {
            this.removeAttribute("busy");
        }            
    };

    private clickHandler = async (event: UIEvent) => {
        const removeButton = (event.target as HTMLElement).closest("[name=remove]");

        if (removeButton) {
            const isInvitation = removeButton.closest("[name=invitations]") !== undefined; // note only one =

            const kind = isInvitation ? "invitation" : "connection";
            const response = await new DialogController().prompt({
                caption: `Remove ${kind}`,
                message: `${isInvitation ? "Are you sure you want to remove the invitation?" : "Are you sure you want to break the connection to this stack?"}`,
                options: ["Cancel", `Remove ${kind}`]
            });

            if (response === "Cancel")
                return;

            const email = removeButton.getAttribute("email");
            try {
                this.setAttribute("busy", "");
                const result = await CollaborationStore.instance.removeInvitation(this.stackSlug(), email);
                if (result) {
                    const element = removeButton.closest("div.field");
                    if (element)
                        element.remove();
                }
            } finally{
                this.removeAttribute("busy");
            }
        }
    };
    

    private setupInviteSection() {

        // only master has an email field
        const inviteSection = this.querySelector("[name=invite]") as HTMLInputElement;

        if (!inviteSection)
            return;

        const emailField = inviteSection.querySelector("[type=email]") as HTMLInputElement;
        
        const sendButton = inviteSection.querySelector("button");

        emailField.addEventListener("focus", () => {
            sendButton.setAttribute("visible", "");
        });

        emailField.addEventListener("blur", () => {
            if (emailField.value === "")
                sendButton.removeAttribute("visible");
        });

        const send = async () => {

            if (!sendButton.hasAttribute("active"))
                return;

            try {
                this.setAttribute("busy", "");
                const result = await CollaborationStore.instance.addInvitation(this.stackSlug(), emailField.value);

                if (result === "confict") {
                    await new DialogController().prompt({
                        caption: "Email already invited",
                        message: `If you wish to invite this person again, please delete the old invitation first.`,
                        options: ["OK"]
                    });
                    return;
                }

                if (result === "invalid") {
                    await new DialogController().prompt({
                        caption: "Invalid email",
                        message: `Email does not seem to be valid.`,
                        options: ["OK"]
                    });
                    return;
                }
                

                    
                if (result) {
                    emailField.value = "";
                    this.invitations.insertAdjacentHTML("afterbegin", this.collabView(result, true));
                    this.invitations.removeAttribute("empty");
                    checkEmail();
                } else {
                    await new DialogController().prompt({
                        caption: "Unable to send invite",
                        message: `Please ensure correct email and try again later. If the problem persists, please contact us.`,
                        options: ["OK"]
                    });
                }
            } 
            finally {
                this.removeAttribute("busy");
            }
            
        };

        const checkEmail = () => {
            const email = emailField.value;
            if (this.isValidEmail(email)) {
                sendButton.setAttribute("active", "")
                return true;
            } else {
                sendButton.removeAttribute("active");
                return false;
            }
        };

        emailField.addEventListener("keydown", async (event: KeyboardEvent) => {
            if (checkEmail() && event && event.key && event.key === "Enter")
                await send();
        });
        emailField.addEventListener("keyup", checkEmail);
        emailField.addEventListener("change", checkEmail);  
        emailField.addEventListener("paste", () => setImmediate(checkEmail));
        sendButton.addEventListener("click", send);
    }

    private collabView = (collaborator: Collaborator, canRemove: boolean) => `
        <div class=field>
            <img src="${collaborator.avatarLocation}">
            <div>
                ${collaborator.username ? `<label>${collaborator.username}</label>` : ""}    
                <label name=email>${collaborator.email}</label>
            </div>
            ${canRemove ? `<button email="${collaborator.email}" name=remove>${require("!!svg-inline-loader!image/icons/close-icon.svg")}</button>` : ""}  
            ${collaborator.username === this.currentUser.userId ? "(you)" : ""}  
        </div>
    `;

    private view = (collaborators: Collaborator[]) => {
        
        const owner = collaborators.filter(i => i.status === "owner")[0];
        const invitations = collaborators.filter(i => i.status === "awaiting");
        const editors = collaborators.filter(i => i.status === "active");

        return `<div class=mixin-field>
                <section>
                    <fieldset name=owner>
                        <legend>Owner</legend>
                        ${this.collabView(owner, false)}
                    <fieldset>

                    ${this.currentUserIsOwner ? `
                        <fieldset name=invite>
                            <legend>Invite via email</legend>
                            <div class=field>
                                <input type=email placeholder="Enter email address ..." autocapitalize=off autocorrect=off />
                                <button>Send</button>
                            </div>
                        </fieldset>` : 
                        `<button name=break-connection class=power>Break connection to master</button>`}
                    
                    
                    <fieldset name=invitations ${invitations.length === 0 ? "empty" : ""}>
                        <legend>Sent invitations</legend>
                        ${invitations.map(c => this.collabView(c, this.currentUserIsOwner)).join("")}
                    </fieldset>
                    
                    ${editors.length > 0 ? `
                    <fieldset name=editors>
                        <legend>Editors</legend>
                        ${editors.map(c => this.collabView(c, this.currentUserIsOwner)).join("")}
                    </fieldset>
                    ` : ""}

                </section>
            </div>
        `
    };
}

customElements.define("collaboration-editor", CollaborationEditorElement);