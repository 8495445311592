import { StackStore } from "features/stacks/StackStore";
import { UrlProvider } from "lib/UrlProvider";
import { Formatting } from "lib/Formatting";

/*
    A style-less element that performs translations of entities in log statements
*/
class LogEntityElement extends HTMLElement {

    connectedCallback() {
        setTimeout(() => {
            window.requestIdleCallback(this.refresh, { timeout: 100 });  
        });
    }

    private refresh = async () => {
        if (this.hasAttribute("rendered"))
            return;
        
        this.toggleAttribute("rendered", true);

        switch (this.getAttribute("entity-type")) {
            case "linkstack": 
                const stack = await StackStore.instance.getStackBySlug(await new UrlProvider().displayUserId(), this.innerText);
                if (stack)
                    this.innerText = stack.title;
                break;

            case "link": 
                const linkUrl = `${this.innerText}`;
                if (linkUrl === "")
                    return;
                
                this.setAttribute("title", linkUrl);
                this.innerHTML = `<a target="_blank" rel="noopener noreferrer" href="${linkUrl}" data-track="${this.getAttribute("link-id")}">${Formatting.prettyfyUrl(linkUrl)}</a>`;
                break;

            case "url": 
                const url = this.innerText;
                this.setAttribute("title", url);
                this.innerHTML = `<a target="_blank" rel="noopener noreferrer" href="${url}">${Formatting.prettyfyUrl(url)}</a>`;
                break;
        }
    }

}

customElements.define("log-entity", LogEntityElement);