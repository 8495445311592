import "./AppSwitchElement-style";
import { AuthenticationStore, User } from "features/authentication";

import { Routing, IRoute } from "lib/Routing";
import { UrlProvider } from "lib/UrlProvider";
import { HotkeyStore, KeySet } from "features/help/HotkeyStore";
import { SidebarActivator } from "features/side-bar/SidebarActivator";

export interface App {
    name: string;
    title: string;
    template: HTMLTemplateElement,
    element: HTMLElement;
    isDefault: boolean;
    pathPatterns: string[];
    defaultPath: string;
    namedIcon: string;
    hotkey?: KeySet;
    hotkeyIds?: string[]
}

class AppSwitchElement extends HTMLElement {
    public static ClassName = "AppSwitchElement";
    private items: App[] = [];
    private contentArea: HTMLElement;
    private sideButtons: HTMLElement;
    private bottomButtons: HTMLElement;
    private currentApp: App;
    private user: User;

    async connectedCallback() {
        if ((<any>window).cloning)
            return;

        this.user = await AuthenticationStore.instance.currentUser();


        this.items = [].slice.call(this.querySelectorAll("template")).map((template: HTMLTemplateElement) => <App>({
            name: template.getAttribute("name"),
            title: template.getAttribute("title"),
            template: template,
            isDefault: template.hasAttribute("default"),
            pathPatterns: template.getAttribute("path-patterns").split(","),
            defaultPath: template.getAttribute("default-path"),
            namedIcon: template.getAttribute("named-icon"),
            hotkey: template.hasAttribute("hot-key") ? JSON.parse(template.getAttribute("hot-key")) : undefined,
            hotkeyIds: (template.getAttribute("hotkey-ids") ?? "").split(",")
        }));


        const defaultItem = this.items.filter(i => i.isDefault)[0];

        this.insertAdjacentElement("afterbegin", this.sideButtons = this.createAppBar("app-buttons-side"));

        this.contentArea = document.querySelector("app-content") as HTMLElement;

        if (!this.contentArea) {
            this.contentArea = document.createElement("app-content");
            this.insertAdjacentElement("beforeend", this.contentArea);
        } else {
            defaultItem.element = this.contentArea.firstElementChild as HTMLElement;
        }

        this.insertAdjacentElement("beforeend", this.bottomButtons = this.createAppBar("app-buttons-bottom"));

        let match = this.appByRoute(Routing.instance.route);

        if (match === undefined && await AuthenticationStore.instance.isAuthenticated()) {
            Routing.instance.go(await new UrlProvider().userHome());
            // Routing.instance.publish(await new UrlProvider().stackSiteLocation("inbox"));
            match = this.appByRoute(Routing.instance.route);
        }

        // so we server-render defaultItem, hence less work    
        if (match && match !== defaultItem) {
            this.showApp(match);
        } else {
            this.showSelected(match);
            this.currentApp = match;
        }

        this.setAttribute("rendered", "");

        Routing.instance.onChange(this.routeChangeHandler)

        window.requestIdleCallback(() => {
            this.items.forEach(item => {
                for (const hotkeyId of item.hotkeyIds) {
                    HotkeyStore.instance.registerGlobal(hotkeyId, () => this.showApp(item, true));
                    // HotkeyStore.instance.registerGlobal(item.hotkey, () => this.showApp(item, true));
                }


            });
        });

    }

    private routeChangeHandler = (route: IRoute) => {
        const item = this.appByRoute(route);
        if (item)
            this.showApp(item);
    };

    private appByRoute(route: IRoute): App {
        return this.items.filter(m => m.pathPatterns.filter(f => Routing.instance.isMatch(f, route)).length > 0)[0];
    }

    private showApp(item: App, updateRoute = false) {

        //   // search hack
        //   if (item.name === "search") {
        //     //SidebarActivator.toggleElement("search-panel", () => document.createElement("search-panel"));
        //     return;
        // }

        // IE must clone every time!
        if (!item.template.content) {
            item.element = <any>item.template.firstElementChild.cloneNode(true) as HTMLElement; // IE 11
        } else {
            if (!item.element)
                item.element = <any>item.template.content.firstElementChild as HTMLElement;
        }

        if (item === this.currentApp)
            return;

        this.currentApp = item;
        this.contentArea.innerHTML = "";
        this.contentArea.appendChild(item.element);
        this.showSelected(item);

        if (updateRoute) {
            const url = item.defaultPath.replace("{userId}", this.user.userId);
            Routing.instance.publish(url);
        }

    }

    private showSelected(item: App) {
        if (!item) {
            document.body.removeAttribute("show-side-menu");
            return;
        }

        //  // search hack
        //  if (item.name === "search") {
        //     //SidebarActivator.toggleElement("search-panel", () => document.createElement("search-panel"));
        //     return;
        // }

        this.sideButtons.setAttribute("selected", item.name);
        this.bottomButtons.setAttribute("selected", item.name);

        setTimeout(() => {
            window.dispatchEvent(new CustomEvent("loaded"));
        }, 0);
    }

    private changeAppHandler = (event: CustomEvent) => {
        const item = this.items.filter(i => i.name === event.detail)[0];
        if (!item)
            return;

        // search hack
        if (item.name === "search") {
            SidebarActivator.toggleElement("search-panel", () => document.createElement("search-panel"));
            return;
        }

        const url = item.defaultPath.replace("{userId}", this.user.userId);
        Routing.instance.go(url, `${AppSwitchElement.ClassName}-changeAppHandler`);
        this.dispatchEvent(new CustomEvent("app-changed", { bubbles: true }));
    };

    private createAppBar(tagName: string) {
        const element = document.createElement(tagName);
        element.addEventListener("change-app", this.changeAppHandler);
        const itemsScript = document.createElement("script");
        itemsScript.setAttribute("type", "application/json+tab-items")
        itemsScript.innerHTML = JSON.stringify(this.items);
        element.appendChild(itemsScript);
        return element;
    }
}


customElements.define("app-switch", AppSwitchElement);
