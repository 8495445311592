import { Pipeline, Request } from "lib/HTTP";
import { UrlProvider } from "lib/UrlProvider";
import EventTarget from "@ungap/event-target";
import { AuthenticationStore, User } from "features/authentication";
import { cityline } from "features/cityline";
import { UserPreferenceStore, UserPreferenceFlag } from "features/user-preferences/UserPreferenceStore";
import { ClassTypename, Typename } from "lib/Types";


export interface AlternateEmail {
    email: string;
    isVerified: boolean;
    isPrimary?: boolean;
}

export interface ChangeResult {
    message?: string;
    status: "invalid-token" | "ok" | "conflict" | "not-found" | "not-verified";
}

interface EventMap {
    "changed": User;
}

@ClassTypename("AlternateEmailsStore")
export class AlternateEmailsStore {
    private static _instance = new AlternateEmailsStore();
    private _urlProvider: UrlProvider = new UrlProvider();
    private eventTarget = new EventTarget();

    private constructor() {
        cityline.addEventListener("account", (event: CustomEvent<User>) => {
            this.emit("changed", event.detail);
        });    
    }

    static get instance() : AlternateEmailsStore { return AlternateEmailsStore._instance; }
    
    public addEventListener<K extends Extract<keyof EventMap, string>>(type: K, listener: (this: EventSource, ev: CustomEvent<EventMap[K]>) => any, options?: boolean | AddEventListenerOptions) {
        return this.eventTarget.addEventListener(type, listener, options);
    }

    public removeEventListener<K extends Extract<keyof EventMap, string>>(type: K, listener: (this: EventSource, ev: CustomEvent<EventMap[K]>) => any, options?: boolean | AddEventListenerOptions) {
        return this.eventTarget.removeEventListener(type, listener, options);
    }

    private emit<K extends Extract<keyof EventMap, string>, T extends EventMap>(type: K, value: T[K]) {
        return this.eventTarget.dispatchEvent(new CustomEvent(type, { detail:  Object.assign({}, value, { [Typename] : AlternateEmailsStore[Typename] })})); 
    }

    public async getUser() : Promise<User> {
        return await cityline.getFrame<User>("account");
    }

    async getPrimaryEmail() : Promise<AlternateEmail> {
        const account = await AuthenticationStore.instance.currentAccount();
        const isConfirmed = await UserPreferenceStore.instance.isSet(UserPreferenceFlag.IsConfirmed);

        return {
            email: account.email,
            isVerified: isConfirmed
        };
    }

    async setPrimary(email: string) {
        const param = new URLSearchParams(<any>{
            email: email
        });
        
        const response = await new Pipeline().fetch(`${this._urlProvider.root}/api/manage/emails/set-primary?${param.toString()}`, Request.post.authenticate());

        if (response.ok) {
            const alternateEmail = await response.json() as AlternateEmail;
            const account = await cityline.getFrame<User>("account");
            account.email = alternateEmail.email;
            this.emit("changed", account);
            return { status: "ok"};
        }
            
        if (response.status === 409)
            return { status: "conflict"};

        if (response.status === 404)
            return { status: "not-found"};
    
        if (response.status === 400) 
            return await response.json();
    }

    async add(email: string) : Promise<ChangeResult> {
        const param = new URLSearchParams(<any>{
            email: email
        });
        
        const response = await new Pipeline().fetch(`${this._urlProvider.root}/api/manage/emails?${param.toString()}`, Request.post.authenticate());

        if (response.ok) {
            const alternateEmail = await response.json() as AlternateEmail;
            const account = await cityline.getFrame<User>("account");
            account.alternateEmails.push(alternateEmail);
            this.emit("changed", account);
            return { status: "ok"};
        }
            
        if (response.status === 409)
            return { status: "conflict"};

        if (response.status === 404)
            return { status: "not-found"};

        if (response.status === 400) 
            return await response.json();
    }

    async remove(email: string) : Promise<ChangeResult> {
        const param = new URLSearchParams(<any>{
            email: email
        });
        
        const response = await new Pipeline().fetch(`${this._urlProvider.root}/api/manage/emails?${param.toString()}`, Request.delete.authenticate());

        if (response.ok) {
            const account = await cityline.getFrame<User>("account");
            account.alternateEmails = account.alternateEmails.filter(m => m.email !== email);
            this.emit("changed", account);
            return { status: "ok"};
        }

        if (response.status === 409)
            return { status: "conflict"};

        if (response.status === 404)
            return { status: "not-found"};
    
        if (response.status === 400) 
            return await response.json();
    }

    async verify(token: string) : Promise<ChangeResult> {
        const param = new URLSearchParams(<any>{
            token: token
        });
        
        const response = await new Pipeline().fetch(`${this._urlProvider.root}/api/manage/emails/verify?${param.toString()}`, Request.post.authenticate());


        if (response.ok) {
            const alternateEmail = await response.json() as AlternateEmail;
            const account = await cityline.getFrame<User>("account");

            account.alternateEmails = account.alternateEmails.filter(m => m.email !== alternateEmail.email)
            account.alternateEmails.push(alternateEmail);
            this.emit("changed", account);

            return { status: "ok"};
        }
        
        if (response.status === 409)
            return { status: "conflict"};

        if (response.status === 404)
            return { status: "not-found"};
     
        if (response.status === 400) 
            return await response.json();
        
    }
}
