import "./AppContextStore-style";
import { EventEmitter } from "events";

export interface Context {
    titlePath: string[];
} 

export class AppContextStore extends EventEmitter {
    private static _instance = new AppContextStore();

    private constructor() {
        super();
    }
    
    public static get instance() {
        return AppContextStore._instance;
    }
    
    public get currentLayer() : HTMLElement {
        return document.querySelector(".content-destination");
    }

    private emitContextChange(context: Context) {
        this.emit("context", context);
    }
    
    onContextChange(callback: (context: Context) => void) {
        this.on("context", callback);
    }

    offContextChange(callback: (context: Context) => void) {
        this.removeListener("context", callback);
    }

    scrollTo(position: number) {
        const target = document.querySelector(".content-destination");
        target.scrollTop = position;
        // target.scrollTo({ top: position }); // Removed smooth, it failed
    }

    update(... titlePath: string[]) {
        document.title = `${titlePath.join(" - ")} - Linkstacks`
        this.emitContextChange({ titlePath: titlePath });
    }

    presentTitle(pageName: string, ... titlePath: string[]) {
        document.body.setAttribute("app-context", pageName);
        if (document.querySelector(".content-destination"))
            this.update(... titlePath.filter(Boolean));
        else
            window.addEventListener("loaded", () => this.update(... titlePath.filter(Boolean)), { once: true });
        
    }

    async present(pageName: string, element: HTMLElement, ... titlePath: string[]) {
        document.body.setAttribute("app-context", pageName);

        let target = document.querySelector(".content-destination");

        const updater = () => {
            target = document.querySelector(".content-destination");
            target.innerHTML = "";
            target.appendChild(element);
            this.update(... titlePath.filter(Boolean));
        }

        if (target)
            updater();
        else
            window.addEventListener("loaded", updater, { once: true });
        
    }


    static ready(): Promise<any> {
        return new Promise((resolve) => {
            if(document.querySelector(".content-destination")) {
                resolve(undefined);
            } else {
                window.addEventListener("loaded", resolve);
            }
        });
    }
}