import "./UniversalLogElement-style";
import { BoxLoaderElement } from "features/box-loader";
import { DOM } from "lib/DOM";
import { DateTime } from "lib/DateTime";
import { JsonFeedEntry } from "./JsonFeed";
import { UniversalLogStore } from "./UniversalLogStore";

interface IDateGroup {
    whenUTC: Date;
    entries: JsonFeedEntry[];
}

class UniversalLogElement extends HTMLElement {
    private logArea: HTMLElement;
    private dateGroupElements: { [id: string]: Element; } = {};

    async connectedCallback() {
        this.innerHTML = this.view();
        this.logArea = this.querySelector("[name=history]");
        this.logArea.append(new BoxLoaderElement());

        setTimeout(async () => {
            await this.initialRender();
            UniversalLogStore.instance.addEventListener("available", this.renderAvailable);
        });
    }

    disconnectedCallback() {
        UniversalLogStore.instance.removeEventListener("available", this.renderAvailable);
    }

    private initialRender = async () => {
        const entries = await UniversalLogStore.instance.getInitial();
        this.logArea.innerHTML = "";
        this.doRender(entries);
    }

    renderAvailable = (event: CustomEvent<JsonFeedEntry[]>) => {
        requestAnimationFrame(() => {
            for (const entry of event.detail) {

                const localDate = this.datePart(DateTime.UTCToLocal(new Date(entry.date_published)));

                // const date = this.dateFromISO(entry.date_published);

                const key = this.dateKey(localDate);


                let groupElement = this.dateGroupElements[key];
                if (!groupElement) {
                    groupElement = DOM.parse(this.groupView({ whenUTC: new Date(entry.date_published), entries: [] }, key));
                    this.dateGroupElements[key] = groupElement;
                    this.logArea.insertAdjacentElement("afterbegin", groupElement);
                }

                groupElement
                    .querySelector("[name=entries]")
                    .insertAdjacentHTML("afterbegin", this.entryView(entry, true));
            }

            requestAnimationFrame(() => {
                for (const element of Array.from(this.querySelectorAll("[hidden]"))) {
                    element.removeAttribute("hidden");
                }
            });
        });
    }

    private doRender = (entries: JsonFeedEntry[]) => {

        const dateKeys = entries
            .map(entry => this.dateKey(DateTime.UTCToLocal(new Date(entry.date_published)))) // this.datePart(new Date(entry.date_published)).getTime())
            .filter((x, i, a) => a.indexOf(x) === i);

            for (const dateKey of dateKeys) {

            const groupEntries = entries.filter(entry =>  this.dateKey(DateTime.UTCToLocal(new Date(entry.date_published))) === dateKey);

            const groupElement = DOM.parse(this.groupView({
                entries: groupEntries,
                whenUTC: new Date(groupEntries[0].date_published)
            }, dateKey));

            this.dateGroupElements[dateKey] = groupElement;
            this.logArea.insertAdjacentElement("beforeend", groupElement);
        }
    }

    private dateKey = (date: Date) => `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}`;

    private datePart = (date: Date) => {
        //return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
        return new Date(date.getFullYear(), date.getMonth(), date.getDate());
    };

    private groupView = (group: IDateGroup, key: string) => `
        <div class=group>
            <header>
                <auto-time date-as-text datetime="${group.whenUTC.toISOString()}" key="${key}"></auto-time>
            </header>
            <div name=entries>
                ${group.entries.map(entry => this.entryView(entry)).join("")}
            </div>
        </div>
    `;
    private entryView = (entry: JsonFeedEntry, hidden = false) => `
        <div ${hidden ? "hidden" : ""} class=entry>
            <div class=primary>
                <div>${entry._title_html}</div>
                <auto-time as-text datetime="${entry.date_published}"></auto-time>
            </div>
            <div class=secondary title="${entry.content_text || ""}">${entry.content_html || ""}</div>
        </div>
    `

    ///<progressive-image auto-size show-loader lazy-load source-image="${entry.image}"></progressive-image>
    private view = () => `
        <div name="history"></div>
    `;
}

customElements.define("universal-log", UniversalLogElement);