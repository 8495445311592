import { AuthenticationStore } from "features/authentication";

export type Environment = "production" | "test" | "local";



export class UrlProvider {
    // when _isLocal is triggered, infrastucture switches to localStorage
    private _isLocal = window.location.protocol === "file:" || window.location.hostname === "localhost";
    private isLocal = () => this._isLocal; 
    private static testMode: string;
    private static modeKey = "urlprovider-mode";

    public get root() {
        if (UrlProvider.testMode === "local")
            return "https://local.linkstacks.io";

        if (UrlProvider.testMode === "test")
            return "https://test.linkstacks.io";  
 
        if (this.isLocal())
            return "https://linkstacks.io";
        else
            return window.location.origin; // e.g. https://test.linkstacks.io
    } 

    public static get environment() : Environment {
        if (UrlProvider.testMode === "local")
            return "local";
        
        if (UrlProvider.testMode === "test")
            return "test";

        switch(window.location.host) {
            case "local.linkstacks.io": return "local";
            case "test.linkstacks.io": return "test";
            case "linkstacks.io": return "production";
            default:
                return "production";
        }
        
    }


    public static initalizeTestMode() {
        if (localStorage) {
            UrlProvider.testMode = localStorage.getItem(UrlProvider.modeKey);
            if (UrlProvider.testMode)
                document.body.setAttribute(UrlProvider.modeKey, UrlProvider.testMode); 
        }
    }

    public setTestMode(source: string) {
        if (localStorage)
            localStorage.setItem(UrlProvider.modeKey, source);
        
        UrlProvider.testMode = source;
        document.body.setAttribute(UrlProvider.modeKey, UrlProvider.testMode);
    }

    public async authenticatedUserId() : Promise<string>
    {
        if (this.isLocal()) {
            const user = await AuthenticationStore.instance.currentUser();
            if (user)
                return user.userId;
            
                return undefined;
        }

        return (<any>window).userId;
    }

    public async displayUserId(): Promise<string> {
        if (this.isLocal()) 
            return await this.authenticatedUserId();

        const segments = window.location.pathname.toLocaleLowerCase().split("/");

        const potentialusername = segments[1];
        if (potentialusername[0] === "@")
            return potentialusername.substring(1);

        return await this.authenticatedUserId();
    }

    public async currentUserIsDisplayUser() : Promise<boolean> {
        return await this.displayUserId() === await this.authenticatedUserId();
    }

    public async stackLocation(stackSlug: string): Promise<string> {
        return `${this.root}/api/@${await this.authenticatedUserId()}/stacks/${stackSlug}`;
    }

    public stackLocationUser(userId: string, stackSlug: string): string {
        return `${this.root}/api/@${userId}/stacks/${stackSlug}`;
    }

    public async userHome(): Promise<string> {
        return `${this.root}/@${await this.authenticatedUserId()}/`;
    }

    public async userHomeLocation(): Promise<string> {
        return `${this.root}/api/@${await this.authenticatedUserId()}/`;
    }

    public async allStacksLinksLocation(): Promise<string> {
        return `${this.root}/api/@${await this.authenticatedUserId()}/links`;
    }

    public async stackSiteLocation(stackSlug: string): Promise<string> {
        return `${this.root}/@${await this.displayUserId()}/stacks/${stackSlug}`;
    }

    public async inboxSiteLocation(): Promise<string> {
        return await this.stackSiteLocation("inbox");
    }

    public async stacksLocation(): Promise<string> {
        return `${this.root}/api/@${await this.authenticatedUserId()}/stacks`;
    }

    public async linksLocation(): Promise<string> {
        return `${this.root}/api/@${await this.authenticatedUserId()}/links`;
    }

    public async linkLocation(slug: string): Promise<string> {
        return `${this.root}/api/@${await this.authenticatedUserId()}/links/${slug}`;
    }

    public async filtersLocation(): Promise<string> {
        return `${this.root}/api/@${await this.authenticatedUserId()}/filters`;
    }

    public async linkLocationInStack(stackSlug: string, linkSlug: string): Promise<string> {
        return `${this.root}/api/@${await this.authenticatedUserId()}/stacks/${stackSlug}/links/${linkSlug}`;
    }

    public async linkStackAssignLocation(stackSlug: string, linkSlug: string): Promise<string> {
        return `${this.root}/api/@${await this.displayUserId()}/stacks/${stackSlug}/links/${linkSlug}/_copy`;
    }

    public async linksStackLocation(stackSlug: string): Promise<string> {
        if (stackSlug === undefined || stackSlug === "inbox")
            return await this.linksLocation();
        else
            return `${await this.stackLocation(stackSlug)}/links`;
    }

    public async searchLocation(userId: string, query: string, stackSlug?: string): Promise<string> {
        if (stackSlug === undefined || stackSlug === "links")
            return `${this.root}/api/@${userId}/_suggest?query=${query}`;
        else
            return `${this.stackLocationUser(userId, stackSlug)}/_suggest?query=${query}`;
    }
    
    public slugPart(id: string) {
        if (id[0] === "/")
            id = id.substring(1);

        const pos = id.indexOf("/");
        if (pos === -1)
            return id;

        return id.substring(pos+1);

    }

    public static split(id: string): string[] {
        if (id.indexOf("/") === -1)
            throw new Error(`Can't split on id, no slash: ${id}`);
        
        const parts = id.split("/");
        if (parts.length !== 2)
            throw new Error(`Can't split on id, invalid slash count: ${id}`);
        
        return parts;
    }
}

UrlProvider.initalizeTestMode();