
import { Feed } from "features/feeds";
import "./FeedControlElement-style";
import { Highlight, IHighlighted } from "lib/Model";
import { AuthenticationStore } from "features/authentication";

class FeedControlElement extends HTMLElement {
    async connectedCallback() {
        const jsonElement = this.querySelector("script[type='application/json+feed']") as HTMLElement;

        if (!jsonElement)
            return;

        const feed = JSON.parse(jsonElement.innerText) as Feed & IHighlighted;
        this.innerHTML = this.view(feed);

        const user = await AuthenticationStore.instance.currentUser();

        this.setAttribute("href", `/@${user.userId}/feeds/${feed.id}`);
        this.setAttribute("route", "");
    }

    private view = (feed: Feed & IHighlighted) => `
        
            <img src="${feed.icon}" />
            <div class=details>
                <h2>${feed.title || ""}</h2>
                <div class=description>${Highlight.property(feed, feed => feed.description)}</div>
            </div>
        
    `;
}

customElements.define("feed-control", FeedControlElement)