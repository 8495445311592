import "./TipDisplayElement-style";
import * as buttonStyle from "../forms/buttons-module.less"
import { DOM } from "lib/DOM";
import { Formatting } from "lib/Formatting";
import { Pipeline, Request } from "lib/HTTP";
import { UrlProvider } from "lib/UrlProvider";
import { UserPreferenceStore, UserPreferenceFlag } from "features/user-preferences/UserPreferenceStore";

interface Tip {
    caption?: string
    text: string;
    image?: string;
}

class TipDisplayElement extends HTMLElement {
    private singleMessage = false;
    private stats: { [id: number] : number; };
    private tips = [{ 
        text: `Get started by <a href="/apps/import" route>importing</a> any feeds or bookmarks you may have.
               <p>&nbsp;</p>You can also add links by using the plus button at the top right corner`, 
        image: require("image/tip-add-link.jpg")},
        { text: `Add the <a href="/apps#plugin" route>plugin</a> or <a href="/apps/bookmarklet" route>bookmarklet</a> to your browser of choice.`, image: require("image/bookmarklet-teaser.jpg")},
        { text: `You can <a href="/apps/import" route>import</a> links from other sites or your browser's bookmarks.`, image: require("image/import-teaser.jpg")},
        { text: `Use <a href="/filters" route>filters</a> to automatically assign tags and stacks to links as they arrive.`, image: require("image/tip-filters.jpg")},
        { text: `Wondering what just happened?<br />Use the history button under profile to see how your links was changed and moved around.`, image: require("image/history-tip.jpg")},
        { text: "If your clipboard contains a valid link you can add it by pasting anywhere. (CTRL-V or ⌘-V)" }
    ];
    private keyCountKey = this.tips.length;
    private esc = Formatting.htmlEscape;
    private nextButton: HTMLButtonElement;
    private dismissButton: HTMLButtonElement;
    private hideTips: HTMLInputElement;
    private tipSection: HTMLElement;

    private closeHandler = async () => {
        await DOM.fadeOut(this);
        this.remove();
    }

    async connectedCallback() {
        const hide = await UserPreferenceStore.instance.isSet(UserPreferenceFlag.HideTips);
        if (hide) {
            return this.remove();
        }
            
        this.readStatsFromStorage();

        const render = (getter = () => this.getTip()) => { 
            this.innerHTML = this.view(getter(), hide); 
        };
        render();

        this.tipSection = this.querySelector("[name=tip]");
        this.nextButton = this.querySelector("[name=next]");
        this.nextButton?.addEventListener("click", this.showNext);

        this.dismissButton = this.querySelector("[name=dismiss]");
        this.dismissButton?.addEventListener("click", this.closeHandler); 

        this.hideTips = this.querySelector("[name=hide]");
        this.hideTips?.addEventListener("click", this.toggleTipDisplay);
    }

    disconnectedCallback() { 
        this.nextButton?.removeEventListener("click", this.showNext);
        this.dismissButton?.removeEventListener("click", this.closeHandler);
        this.hideTips?.removeEventListener("click", this.toggleTipDisplay);
    }

    private toggleTipDisplay = async () => {
        const hideTips = this.hideTips.checked;
        await new Pipeline().fetch(`${new UrlProvider().root}/api/account/tip-display`, Request.post.authenticate().setJSON({ hideTips: hideTips }));

    }

    private showNext = () => {
        this.tipSection.innerHTML = this.tipView(this.getTip());
    }

    /*
        [] = localStorage not working, pick a random
        undefined = localStorage working, first time, pick first
        [ ... ] = pick random lowest count
    */ 
    private readStatsFromStorage() {
        try {
            const json = localStorage.getItem(`stats${this.keyCountKey}`);
            this.stats = JSON.parse(json);
        } catch(error) {
            this.stats = {};
        }
    }

    private writeStatsToStorage() {
        try {
            localStorage.setItem(`stats${this.keyCountKey}`, JSON.stringify(this.stats));
        } catch(error) {
            console.log(error);
        }
    }

    private randomInt = (min, max) => Math.floor(Math.random() * (max - min)) + min;
   
    private getTip() : Tip {

        const textAttribute = this.innerHTML;
        if (this.hasAttribute("caption")) {
            this.singleMessage = true;

            return {
                text: textAttribute,
                caption: this.getAttribute("caption"),
                image: this.getAttribute("image")
            };
        }
           

        let tipNumber: number;
        if (!this.stats) {
            tipNumber = 0;
            this.stats = { 0:0 };
        } else {

            // initialize
            this.tips.forEach( (tip, index) => {
                if (!this.stats[index])
                    this.stats[index] = 0;
            });

            // pick lowest show numbers
            const values =  Object.keys(this.stats).map((key) => this.stats[key]);
            const lowest = Math.min(...values);
            const lowestKeys = Object.keys(this.stats).filter((key) => this.stats[key] === lowest).map(key => parseInt(key));
            const key = this.randomInt(0, lowestKeys.length);
            tipNumber = lowestKeys[key];
        }

        this.stats[tipNumber]++;
        //this.current = tipNumber;
        this.writeStatsToStorage();
        return this.tips[tipNumber];
    }

    private tipView =  (tip: Tip) => this.esc `
        <header>${require("!raw-loader!image/bulb-24.svg")}${tip.caption ? tip.caption : "Tip!"}</header>
        <section>
            $${tip.text}
            $${tip.image ? this.esc `<div><img src="$${tip.image}" /></div>` : ""}
        </section>  
    `;

    private view = (tip: Tip, hide: boolean) => this.esc `
        <div>
            <div name=tip>$${this.tipView(tip)}</div>
            <div class=buttons>
                $${this.singleMessage ? "": `
                    <label>
                        <input type=checkbox name=hide${hide? " checked" : ""} />
                        Don't show again
                    </label>
                    <button name=next type=button class="${buttonStyle.cleanButton}" secondary>Next</button>`}

                <button name=dismiss type=button class="${buttonStyle.cleanButton}">Dismiss</button>
            </div>
        </div>`;
}

customElements.define("tip-display", TipDisplayElement);