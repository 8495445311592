
import { Linkstack, StackStore } from "features/stacks/StackStore";
import { NotifyViewController } from "features/notification/NotifyViewController";
import * as clipboard from "clipboard-polyfill"
import "./SocialShareElement-style.less";

class SocialShareElement extends HTMLElement {
    private shortUrl: string;
    private shortUrlField: HTMLInputElement;
    async connectedCallback() {

        const stack = await StackStore.instance.getStackById(this.getAttribute("stack-id"));

        if (!stack)
            alert("Unable to fetch current stack.")

        this.innerHTML = this.view(stack);

        this.shortUrlField = this.querySelector("[name=short-url]") as HTMLInputElement;
        this.shortUrlField.addEventListener("focus", () => {
            const range = document.createRange();  
            range.selectNode(this.shortUrlField);  
            window.getSelection().addRange(range);
        });

        setTimeout(async () => await this.getShortUrl(stack), 0);
        
        const copyButton = this.querySelector("[name=copy]") as HTMLButtonElement;
        if (copyButton)
            copyButton.addEventListener("click", this.copyUrlHandler);
    }

    private async getShortUrl(stack: Linkstack) {
        

        this.shortUrl = await StackStore.instance.getShortUrl(stack);
        if (this.shortUrlField) 
            this.shortUrlField.value = this.shortUrl;

    }

    private copyUrlHandler = async () => {
        
        try {  
            await clipboard.writeText(this.shortUrlField.value);
            new NotifyViewController().showWeb({ title: "Url copied", showOk: true, showSeconds: 4 });
        } catch(err) {  
            new NotifyViewController().showWeb({ title: "Unable to copy url", showSeconds: 4 });
        } 
    }

    private view = (stack: Linkstack) => `
        <div class=mixin-field>
            <section>
                <div name=social-buttons>

                    ${stack.private ? `
                        <div>
                            ⚠️ This stack is currently marked private<br />and will not be visible!
                        </div>
                    ` : ""}
                    <div>
                        
                        <a href="https://twitter.com/intent/tweet?text=Check out my stack ${stack.title}&url=${stack.siteLocation}" target=_blank class=round title=Twitter>
                            <div class=twitter>
                                ${require("!!raw-loader!image/fa-twitter.svg")}
                            </div>
                        </a>

                        <a href="https://www.facebook.com/sharer/sharer.php?u=${stack.siteLocation}" target=_blank class=round title=Facebook>
                            <div class=facebook>
                                ${require("!!raw-loader!image/fa-facebook.svg")}
                            </div>
                        </a>

                        <a href="https://www.linkedin.com/shareArticle?mini=true&title=${stack.title}&summary=Check out my stack ${stack.title}&url=${stack.siteLocation}&source=Linkstacks" target=_blank class=round title="Linked in">
                            <div class=linked-in>
                                ${require("!!raw-loader!image/fa-linkedin.svg")}
                            </div>
                        </a>

                        <a href="${stack.feedSiteLocation}" target=_blank class=round title=Feed>
                            <div class=feed>
                                ${require("!!raw-loader!image/feed-symbol.svg")}
                            </div>
                        </a>
                    </div>
                </div>

                <fieldset name=short-url-area>
                    <legend>Or use the Short url</legend>
                    <div class=field>
                        <input type=text id=shorturl class=shorturl name=short-url value="" placeholder="getting short url ..." readonly />
                        <button type=button name=copy>${require("!!raw-loader!image/clipboard.svg")}</button>
                    </div>
                </fieldset>
            </section>
        </div>
        `;
}

customElements.define("social-share", SocialShareElement)
