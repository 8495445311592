import "./UserRegistrationElement-style";
import { AdaptivePopup } from "features/authentication/AdaptivePopup";


class ActivationmailInfoElement extends AdaptivePopup {

    async connectedCallback() {
        this.innerHTML = this.checkEmailView();
        super.connectedCallback();
    }

    private checkEmailView = () => `
        <div>
        <header>
            <div><button type=button name=back class=icon>${require("!!raw-loader!image/arrow-left-20-v1.svg")}</button></div>
            <div>Activation mail</div>
            <div></div>
        </header>
        <section>
            <p>We have now sent you a message containing a verification link.</p>
            <p>
                We do this to make sure that your email is valid and active, so that we know where to get a message to you in the future. 
            </p>
        </section>
        </div>
    `;
}

customElements.define("activationmail-info", ActivationmailInfoElement);