import { EventsStore } from "./EventsStore";
import { IRevision } from "./EventsModel";
import { StackStore } from "features/stacks/StackStore";
import { NotifyNativeViewController } from "features/notification";
import { DateTime } from "lib/DateTime";
// import { UserHistoryStore } from "../event-history/EventHistoryStore";
import { EventMessage } from "./EventMessage";
import { UserPreferenceStore } from "features/user-preferences/UserPreferenceStore";

//tslint:disable-next-line: no-unused-variable
class LinkAndStackEventPublisher {
    public static ClassName = "LinkAndStackEventPublisher";
    //tslint:disable-next-line: no-unused-variable
    private static instance = new LinkAndStackEventPublisher();
    private notificationTypes = ["addTagsToLinkCommand", "moveFromStackToStackCommand", "enrichedCommand"];
    private constructor() {
        window.requestIdleCallback(async () => {
            const revisions = await EventsStore.instance.getHistoricRevisions();
            await this.revisionsHandler(revisions);
        });
        EventsStore.instance.onRevision(this.revisionsHandler);
    }

    private revisionsHandler = async (revisions: IRevision[]) => {

        revisions = revisions.filter(m => this.notificationTypes.indexOf(m.action.$type) !== -1); 

        const messages = await this.formatMany(revisions);

        const startup = await UserPreferenceStore.instance.get<string>("startup-time", false);

        let startupTime: Date;
        if (startup)
            startupTime = new Date(startup);
        else
            startupTime = DateTime.LocalToUTC(new Date());
        
        // UserHistoryStore.instance.publish(messages, LinkAndStackEventPublisher.ClassName);

        const recent = messages
            .filter(m => new Date(m.when) > startupTime);

        if (recent.length > 0)
            recent.forEach(message => {
                new NotifyNativeViewController().show({ title: message.title, body: message.body, icon: message.icon, showSeconds: 6.5 });
            });

    };


    async formatMany(revisions: IRevision[]): Promise<EventMessage[]> {

        const stackSlugs = [];
        const collector = (slug) => {
            if (stackSlugs.indexOf(slug) === -1)
                stackSlugs.push(slug);
        }; 

        // collect stackids
        await Promise.all(revisions.map(async revision => await this.format(revision, collector)))

        const stacks = await StackStore.instance.getStacks(stackSlugs);
            
        const resolver = (slug) => {
            const stack = stacks.filter(s => s.slug === slug)[0];
            if (stack)
                return stack.title;

            return slug;
        };

        return (await Promise.all(revisions.map(async revision => await this.format(revision, resolver))))
                .filter(i => i);
    }

    async format(revision: IRevision, resolver = (stackId) => stackId): Promise<EventMessage> {
        const command = revision.action;

        const message: EventMessage = {
            author: "",
            body: "",
            bodyHtml: "",
            icon: "",
            title: "",
            titleHtml: "",
            when: revision.when
        };

        if (revision.entity.preview && revision.succeeded)
            message.icon = revision.entity.preview;

        if (revision.entity.title && revision.entity.title !== "") {
            message.bodyHtml += `${revision.entity.title}<br />`;
            message.body += `${revision.entity.title}\n`;
        }

        if (revision.entity.prettyUrl && revision.entity.prettyUrl !== "") {
            message.bodyHtml += `
                <a target="_blank" rel="noopener noreferrer" href="${revision.entity.url}" data-track="${revision.entity.id}">
                    ${revision.entity.prettyUrl}
                </a>`;
            message.body += `${revision.entity.prettyUrl}`;
        }
        
        switch (command.$type) {
            case "addTagsToLinkCommand":
                if (!command.tags || command.tags.length === 0)
                    return undefined;
                if (command.tags.length === 1) {
                    message.title = `Link assigned tag ${command.tags[0]}.`;
                    message.titleHtml = `Link assigned tag <span class=tag>${command.tags[0]}</span>.`;
                }
                else
                    message.title =  `Link assigned tags ${command.tags.join(",")}`;
                break;

            case "moveFromStackToStackCommand":
                message.title = `Link moved from stack ${resolver(command.sourceStack)} to ${resolver(command.destinationStack)}.`;
                
                message.titleHtml = `Link moved from stack 
                                 <span data-stack="${command.sourceStack}" data-user="${revision.entity.userId}" data-link="${revision.entity.slug}" class=stack-ref>
                                    <span class=stack-title>${command.sourceStack}</span>
                                 </span> to 
                                 <span data-stack="${command.destinationStack}" data-user="${revision.entity.userId}" data-link="${revision.entity.slug}" class=stack-ref>
                                    <span class=stack-title>${command.destinationStack}</span></span>.`;

                
                break;

            case "copyFromStackToStackCommand":
                message.title = `Link copied from stack ${resolver(command.sourceStack)} to ${resolver(command.destinationStack)}.`;

                message.titleHtml = `Link copied from stack 
                                 <span data-stack="${command.sourceStack}" data-user="${revision.entity.userId}" data-link="${revision.entity.slug}" class=stack-ref>
                                    <span class=stack-title>${command.sourceStack}</span>
                                 </span> to 
                                 <span data-stack="${command.destinationStack}" data-user="${revision.entity.userId}" data-link="${revision.entity.slug}" class=stack-ref>
                                    <span class=stack-title>${command.destinationStack}</span></span>.`;


                break;

            case "addingLinkCommand":
                if (revision.succeeded) {
                    message.titleHtml = message.title = `Adding new link ...`;
                    message.icon = undefined;
                } else {
                    if (revision.reason === "documentAlreadyExists")
                        message.titleHtml = message.title = "Link already exists";
                    else
                        message.titleHtml = message.title = "Unable to add link";
                }
                break;

            case "createCommand":
            case "enrichedCommand":
                if (revision.succeeded)
                    message.titleHtml = message.title = `Added new link`;
                else {
                    if (revision.reason === "documentAlreadyExists")
                        message.titleHtml = message.title = "Link already exists";
                    else
                        message.titleHtml = message.title = "Unable to add link";
                }
                break;

            case "refreshedMetadataCommand":
                if (revision.succeeded)
                    message.titleHtml = message.title = `Refreshed meta data`;
                else 
                    message.titleHtml = message.title = "Failed to refresh meta data";
                
                break;

            case "updateCommand":
                message.titleHtml = message.title = `Changed link`;
                break;
            case "deleteCommand":
                message.icon = undefined; 
                message.titleHtml = message.title = `Deleted link`;
                break;
            default:
                message.titleHtml = message.title = `Unknown: ${(<any>command).$type}`;
                break;
        }
        

        return message;
    }

}