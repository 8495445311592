import * as batchPanelStyle from "./BulkPanel-module.less";
import { BulkLinkStore } from "./BulkLinkStore";
import { BulkController } from "./BulkController";

class BulkPanelElement extends HTMLElement {
    static get observedAttributes() { return ["count"]; }

    async attributeChangedCallback(attrName, oldVal, newVal) {
        if (attrName === "count") {
            this.refresh();
        }    
    }

    connectedCallback() {
        this.className += batchPanelStyle.bulkPanel;
        this.refresh();
        this.addEventListener("click", this.clickHandler);
        BulkLinkStore.instance.addEventListener("selected", this.refresh);
        BulkLinkStore.instance.addEventListener("deselected", this.refresh);
    }

    disconnectedCallback() {
        this.removeEventListener("click", this.clickHandler);
        BulkLinkStore.instance.removeEventListener("selected", this.refresh);
        BulkLinkStore.instance.removeEventListener("deselected", this.refresh);
    }

    private clickHandler = async (event: PointerEvent) => {
        const element = event.target as HTMLElement;
        const button = element.closest("button");
        if (!button)
            return;
        
        switch (button.name) {
            case "cancel-select":
                BulkLinkStore.instance.deselectAll();
                break;
            case "select-all":
                BulkLinkStore.instance.selectAllRequest();
                break;
            case "move-to-stack":
                BulkController.move();
                break;
            case "copy-to-stack":
                BulkController.copy();
                break;
            case "tag":
                BulkController.tag();
                break;
            case "delete":
                await BulkController.delete();
                break;
            break;
        }
    }

    refresh = () => {
        this.innerHTML = this.view();
    }

    view = () => `
        <button title="Cancel selection" name=cancel-select>${require("!!raw-loader!image/back-arrow.svg")}</button>
        <button title="Mark all" name=select-all>${require("!!raw-loader!image/mark-all.svg")}</button>
        <div>${BulkLinkStore.instance.length}</div>
        <button title="Add tag ..." name=tag>${require("!!raw-loader!image/label.svg")}</button>
        <button title="Copy to stack" name=copy-to-stack>${require("!!raw-loader!image/copy-to-stack.svg")}</button>
        <button title="Move to stack" name=move-to-stack>${require("!!raw-loader!image/move-to-stack.svg")}</button>
        <button title="Delete" name=delete>${require("!!raw-loader!image/trash-outline.svg")}</button>
    `;

}

customElements.define("bulk-panel", BulkPanelElement);