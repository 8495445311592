import "./LoginFlowElement-style";
import { AuthenticationStore } from "./AuthenticationStore";
import { DOM } from "lib/DOM";
import { DialogController } from "features/dialog";
import { LoginResponse, State } from "features/authentication/Model";

class LoginFlowElement extends HTMLElement {
    private contentElement: HTMLElement;
    async connectedCallback() {
        this.innerHTML = this.view();
        this.contentElement = this.querySelector(".content") as HTMLElement;
        this.setupEvents()    
        await this.initialize();
    }

    private async initialize() {        
        const miniProgress = this.querySelector("mini-progress") as HTMLElement;
        miniProgress.classList.add("active");
        
        miniProgress.setAttribute("class", "done");
        miniProgress.style.opacity = "0";
        const background = this.querySelector("background-image") as HTMLElement;
        background.classList.add("unfocus");
        
        setTimeout(() => {
            miniProgress.remove();
            this.contentElement.innerHTML = "<user-login></user-login>";   
        }, 1500);
    }

    private view = () => `
        <logo></logo>
        <background-image></background-image>
        <div class=content>
            <mini-progress><div></div></mini-progress>
        </div>
    `;

    async updateElementState(state: State, response: LoginResponse) {
        switch (state) {
            case "signed-in":
                // setTimeout(() => {
                //     this.innerHTML = "";
                // }, 200); 
                break;
            case "not-signed-in":
                this.contentElement.innerHTML = `<user-login></user-login>`;
                break;

            case "locked-out":
                await new DialogController().prompt({
                    caption: "Locked out",
                    message: "Sorry, this account is currently locked out - please try again later.",
                    options: ["Ok"]
                });
                
                break;
            case "require-verification": 
                await new DialogController().prompt({
                    caption: "Not verified",
                    message: "This account has not yet been verified, please check your email.",
                    options: ["Ok"]
                });
                break;
            case "complete-external":
                if (response)
                    this.contentElement.innerHTML =  `<external-user-registration username="${response.username ? response.username : ""}" email="${response.email ? response.email : ""}" provider="${response.provider}"></external-user-registration>`;
                break;
        }

        if (state === "signed-in")
            await DOM.fadeOut(this);
        else
            await DOM.fadeIn(this, "flex");

       
    }

    private setupEvents() {
        AuthenticationStore.instance.onStateChanged( (state, response) => this.updateElementState(state, response));
    }
}

customElements.define("login-flow", LoginFlowElement);
