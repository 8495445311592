
import { Routing } from "lib/Routing";
import { Package } from "features/packages/Package";
import { BasePackageElement } from "features/packages/BasePackageElement";
import { AppContextStore } from "features/application-context/AppContextStore";


class AndroidAppElement extends BasePackageElement {
    protected slug = "android";    

    protected detailView = (thePackage: Package) => `
        <a name="android" target="_blank" href="http://play.google.com/store/apps/details?id=io.linkstacks.app">
            <img src="${this.appsStore.ressources["google-play-badge"]}">
        </a>
    `;      
}

customElements.define("android-app", AndroidAppElement);

Routing.instance.onChange(async route => {
    if (route.path[0] === "apps" && route.path[1] === "android") {
        await AppContextStore.instance.present("", document.createElement("android-app"), "Bookmarks", "Apps", "Android");
    }
});