import "./LinkHistogramWidget-style";
import "features/context-menu";
import * as cardsStyle from "../../../../styles/cards-module.less"
import * as widgetStyle from "../Widgets-module.less";
//import Plotly from "plotly.js-basic-dist";
import ResizeObserver from "resize-observer-polyfill";
import { BaseWidget, PreferSize, TabButton } from "../BaseWidget";
import { BoxLoaderElement } from "features/box-loader";
import { Formatting } from "lib/Formatting";
import { cityline } from "features/cityline";
import { DateTime } from "lib/DateTime";
import { Plotly } from "features/plotly";

// var Plotly = require('plotly.js/lib/core');

// // Load in the trace types for pie, and choropleth
// Plotly.register([
//     require('plotly.js/lib/bar')
// ]);

interface LinkHistogram {
    last7Days: { [id: number]: number; };
    last24Hours: { [id: number]: number; };
    lastYear: { [id: number]: number; };
    totalPerDay: { [id: number]: number; };
    totalPerHour: { [id: number]: number; };
}


type GraphType = "scatter" | "bar";


interface LinkStat {
    label: string;
    count: number;
}

type Mode = "last7Days" | "last24Hours" | "lastYear" | "totalPerDay" | "totalPerHour";

class LinkHistogramWidget extends BaseWidget {
    private graphArea: HTMLElement;
    private mode: Mode = "last24Hours";
    private range = (size, startAt = 0) => [...Array(size).keys()].map(i => i + startAt);
    private resizeObserver: ResizeObserver;

    private tabButtons: TabButton[] = [
        { label: "Last 24 hours", value: "last24Hours" },
        { label: "Last 7 days", value: "last7Days" },
        { label: "Last year", value: "lastYear" },
        { label: "Total per day", value: "totalPerDay" },
        { label: "Total per hour", value: "totalPerHour" }
    ];

    public get preferredWidth(): PreferSize {
        return "wide";
    }

    private updateGraph = (stats: LinkStat[], graphType: GraphType) => {

        const data = [{
            x: stats.map(m => m.label),
            y: stats.map(m => m.count),
            type: graphType,
            line: {
                color: "#007cfe",
                width: 2
            },
            marker: {
                color: "rgb(178, 178, 178)"
            }
        }];

        // remove loader
        this.querySelector("box-loader")?.remove();

        Plotly.react(this.graphArea, data, {
            height: 200,
            showlegend: false,
            margin: {
                l: 30,
                r: 20,
                t: 10,
                b: 40,
                autoexpand: true
            },
            font: {
                family: "var(--roboto)",
                size: 11,
                color: "#656565"
            }
        }, {
            displayModeBar: false,
            //responsive: true NOTE: We use our own resizeobserver to handle this
        });
    }

    async connectedCallback() {
        this.className += ` ${cardsStyle.cardContainer}`;
        this.innerHTML = this.view();
        this.graphArea = this.querySelector("[name=graph]");
        this.graphArea.append(new BoxLoaderElement());
        setTimeout(async () => {
            await this.render();
            cityline.addEventListener("link-histogram", this.citylineEventHandler);
        }, 0); //{ timeout: 15 });

        this.resizeObserver = new ResizeObserver(() => {
            Plotly.Plots.resize(this.graphArea);
        });

        this.resizeObserver.observe(this);

        // we render every minute to ensure that hour-rollover is correct
        this.intervalHandler = window.setInterval(this.render, 1000 * 60);
        super.connectedCallback();
    }

    private intervalHandler: number;

    disconnectedCallback() {
        cityline.removeEventListener("link-histogram", () => this.citylineEventHandler);
        this.resizeObserver?.disconnect();
        window.clearInterval(this.intervalHandler);
        super.disconnectedCallback();
    }

    private citylineEventHandler = async (event: CustomEvent<LinkHistogram>) => await this.doRender(event.detail);

    private render = async () => {
        const histogram = await cityline.getFrame<LinkHistogram>("link-histogram");
        await this.doRender(histogram);
    };

    private doRender = async (histogram: LinkHistogram) => {
        let stats: LinkStat[] = [];
        let graphType: GraphType;

        switch (this.mode) {
            case "last24Hours":
                graphType = "scatter";
                stats = DateTime.getTheLast24HoursInLocal().map(m => ({
                    label: `${Formatting.pad(m.toString(), 2, "0")}:00`,
                    count: histogram.last24Hours[DateTime.adjustHourFromLocalToUTC(m)] || 0 // Lookup table is in UTC
                }));
                break;
            case "last7Days":
                graphType = "scatter";
                stats = DateTime.getTheLast7DaysInLocal().map(m => ({
                    label: DateTime.days[m],
                    count: histogram.last7Days[m] || 0
                }));
                break;
            case "lastYear":
                graphType = "scatter";
                stats = DateTime.getTheLast12MonthsInLocal().map(m => ({
                    label: DateTime.months[m],
                    count: histogram.lastYear[m] || 0
                }));
                break;
            case "totalPerHour":
                graphType = "bar";
                stats = this.range(24, 0).map(m => ({
                    label: `${Formatting.pad(m.toString(), 2, "0")} - ${Formatting.pad((m === 23 ? 0 : m + 1).toString(), 2, "0")}`,
                    count: histogram.totalPerHour[DateTime.adjustHourFromLocalToUTC(m)] || 0
                }));
                break;
            case "totalPerDay":
                graphType = "bar";
                stats = this.range(7, 1).map(m => ({
                    label: DateTime.days[m],
                    count: histogram.totalPerDay[m] || 0
                }));
                break;
        }

        this.updateGraph(stats, graphType);
    }

    protected async modeChange(mode: string) {
        this.mode = <Mode>mode;
        await this.render();
    }

    private view = () => `
        ${this.headerView("Incoming links", this.tabButtons, this.mode)}
        <div class="${cardsStyle.card} ${widgetStyle.card}" name=facts>
            <div name=graph>
                
            </div>
        </div> 
    `;
}

customElements.define("link-histogram-widget", LinkHistogramWidget);