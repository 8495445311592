export class BrowserInfo {
    public static getQueryParam(param) : string {
        const result =  window.location.search.match(
            new RegExp( `(\\?|&)${param}(\\[\\])?=([^&]*)`)
        );

        return result ? result[3] : undefined;
    }

    public static isMobileLike() {
         // this value must follow the value in SidePanelStyle
        return  (window.innerWidth <= 850);
    }

    public static isApp = () => (<any>window).Capacitor.isNative as boolean;

    public static isIOSApp() {
        return this.isApp() && (<any>window).Capacitor.getPlatform() === "ios";
    } 

    public static isTouch = (("ontouchstart" in window) || (navigator.maxTouchPoints > 0));
}