import { UrlProvider } from "../../library/UrlProvider"
import { Request, Pipeline } from "../../library/HTTP"
import { Collection } from "../../library/System";
import { LinkPage, LinkPageCriteria } from "./LinkPageModel";
import { Link } from "../links/LinkStore";
import { EventsStore } from "features/events/EventsStore";
import { ClassTypename, Typename } from "lib/Types";
import { Simplified } from "features/events/EventsModel";
import EventTarget from "@ungap/event-target";
import { cityline } from "features/cityline";
import { valueStoreFactory } from "features/valueStore"

interface EventMap {
    "link-added": Link;
    "link-removed": Link;
    "link-updated": Link;
}

@ClassTypename("LinkPageStore")
export class LinkPageStore {
    private eventTarget = new EventTarget();
    private static _instance = new LinkPageStore();
    private _sessionId: number;
    private _valueStore = valueStoreFactory.resolve();
    private constructor() {
        this._sessionId = new Date().getTime();
        this.registerEvents();
        this.setupInternalLinkUpdater();
    }
    static get instance() { return LinkPageStore._instance; }

    public addEventListener<K extends Extract<keyof EventMap, string>>(type: K, listener: (this: EventSource, ev: CustomEvent<EventMap[K]>) => any, options?: boolean | AddEventListenerOptions) {
        return this.eventTarget.addEventListener(type, listener, options);
    }

    public removeEventListener<K extends Extract<keyof EventMap, string>>(type: K, listener: (this: EventSource, ev: CustomEvent<EventMap[K]>) => any, options?: boolean | AddEventListenerOptions) {
        return this.eventTarget.removeEventListener(type, listener, options);
    }

    private emit<K extends Extract<keyof EventMap, string>, T extends EventMap>(type: K, value: T[K]) {
        return this.eventTarget.dispatchEvent(new CustomEvent(type, { detail: Object.assign({}, value, { [Typename]: LinkPageStore[Typename] }) }));
    }

    // If anything is stale, all is stale
    // when stale we rerun and raise an event
    // if not stale we trust what we have
    // stacks comes with a checksum, if said checksum is a match we update sesisonid == not stale
    public async getPage(linkId: string): Promise<number> {
        const params = new URLSearchParams();
        params.set("linkId", linkId);

        const url = `${new UrlProvider().root}/api/link-page/index?${params.toString()}`;
        const response = await new Pipeline().fetch(url, Request.get.authenticate());

        if (!response.ok) // how do we do this?
            return undefined;

        const index = await response.json() as IndexResponse;
        return index.page;
    }

    // in the future we might get more clever and update views, for now we just throw them away
    private setupInternalLinkUpdater = () => {
        this.addEventListener("link-added", (event: CustomEvent<Link>) => {
            this._sessionId = new Date().getTime();
        });
        this.addEventListener("link-updated", (event: CustomEvent<Link>) => {
            this._sessionId = new Date().getTime();
        });
        this.addEventListener("link-removed", (event: CustomEvent<Link>) => {
            this._sessionId = new Date().getTime();
        });
    }

    // If anything is stale, all is stale
    // when stale we rerun and raise an event
    // if not stale we trust what we have
    // stacks comes with a checksum, if said checksum is a match we update sesisonid == not stale
    public async getView(criteria: LinkPageCriteria): Promise<LinkPage> {
        const key = this.getKey(criteria);
        
        let view = await this._valueStore.get<LinkPage>(key) as LinkPage;

        let dehydrateSuccess: boolean;
        if (view && view.sessionId === this._sessionId) 
        dehydrateSuccess = await this.dehydrateLinks(view);
        
        if (!dehydrateSuccess || !view || view.sessionId !== this._sessionId || (view.links || []).length !== (view.linkIds || []).length) {
            const params = new URLSearchParams();
            params.set("user", criteria.user);
            params.set("page", criteria.page.toString());

            if (criteria.stack)
                params.set("stack", criteria.stack);

            if (criteria.query)
                params.set("query", criteria.query);

            if (criteria.tags)
                params.set("tags", criteria.tags.join(","));

            const url = `${new UrlProvider().root}/api/link-page?${params.toString()}`;
            const response = await new Pipeline().fetch(url, Request.get.authenticate());

            if (!response.ok) // how do we do this?
                return undefined;

            view = await response.json() as LinkPage;

            const hydrated = await this.hydrateLinks(view);

            hydrated.sessionId = this._sessionId;

            await this._valueStore.set(key, hydrated);
        }

        return view;
    }

    private async dehydrateLinks(linkPage: LinkPage) : Promise<boolean> {
        linkPage.links = [];

        const specs = linkPage.linkIds.map(linkId => {
            const parts = linkId.split("/");
            return {
                stack: parts[1],
                linkId: linkId
            };
        });

        const stacks = Collection.distinctBy(specs.map(m => m.stack), t => t);
        for (const stack of stacks) {
            const key = `stack-${stack}`;
            const storedLinks: StoredLinks = await this._valueStore.get<StoredLinks>(key) || { user: "modec", stack: stack, links: {}, sessionId: undefined };

            if (storedLinks?.sessionId !== this._sessionId) {
                return false;
            }

            for (const linkId of specs.filter(m => m.stack === stack).map(m => m.linkId)) {
                if (storedLinks.links[linkId])
                    linkPage.links.push(storedLinks.links[linkId]);
            }
        }

        return true;
    }

    // only if own?
    // If we don't seperate links out, can we still update them?
    // how?
    // If sessionId != current sessionid then stale
    private async hydrateLinks(linkPage: LinkPage): Promise<LinkPage> {
        const linkPageClone = JSON.parse(JSON.stringify(linkPage)) as LinkPage;
        const links = linkPageClone.links;

        const stacks = Collection.distinctBy(links.map(m => m.stack), t => t);

        for (const stack of stacks) {
            const key = `stack-${stack}`;
            let storedLinks: StoredLinks = await this._valueStore.get<StoredLinks>(key);

            if (!storedLinks || storedLinks.sessionId !== this._sessionId)
                storedLinks = { user: "modec", stack: stack, links: {}, sessionId: this._sessionId }

            for (const link of links) {
                storedLinks.links[link.id] = link;
            }

            storedLinks.sessionId = this._sessionId;
            await this._valueStore.set(key, storedLinks);
        }

        linkPageClone.linkIds = linkPageClone.links.map(m => m.id);
        linkPageClone.links = undefined;
        return linkPageClone;
    }

    private getKey(statement: LinkPageCriteria) {
        return `link-view-${statement.stack}-${statement.page}${statement.query ? `-${statement.query}` : ""}${statement.tags ? `-${statement.tags.join(";")}` : ""}`;
    }

    private registerEvents() {
        cityline.addEventListener("links", (event: CustomEvent<LinkMessage>) => {
            for(const link of event?.detail?.created || []) 
                this.emit("link-added", link);

            for(const link of event?.detail?.updated || []) 
                this.emit("link-updated", link);

            for(const link of event?.detail?.deleted || []) 
                this.emit("link-removed", link);
        });

        EventsStore.instance.onRevision(revisions => {
            revisions.forEach(revision => {
                if (revision.type !== "link" || !revision.succeeded || !revision.entity)
                    return;

                if (revision.action.$type === "moveFromStackToStackCommand") {
                    this.emit("link-removed", revision.action.before);
                    this.emit("link-added", revision.entity);
                    return;
                }

                if (revision.action.$type === "copyFromStackToStackCommand") {
                    this.emit("link-added", revision.entity);
                    return;
                }

                switch (revision.simplified) {
                    case Simplified.Deleted:
                        this.emit("link-removed", revision.entity);
                        break;
                    case Simplified.Updated:
                        this.emit("link-updated", revision.entity);
                        break;
                    case Simplified.Created:
                        this.emit("link-added", revision.entity);
                }
            });
        });
    }
}


export interface StoredLinks {
    user: string;
    stack: string;
    links: { [id: string]: Link; };  // but we typically only have first page?

    checksum?: string;
    sessionId: number;
}

interface IndexResponse {
    index: number;
    page: number;
}

interface LinkMessage {
    created?: Link[];
    updated?: Link[];
    deleted?: Link[];
}