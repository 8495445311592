import "features/site-header";
import "features/site-footer";
import "features/anchor-navigator";
import "features/info-viewer";
import "./InAppHelpElement-style";
import "./HotkeyViewerElement";
import "./GestureViewerElement";

export class InAppHelpElement extends HTMLElement {
    static get observedAttributes() { return ["help-topic"]; }

    connectedCallback() {
        this.innerHTML = this.view();
        // this.querySelector("[name=close]").addEventListener("click", this.closeHandler);

        // const highlight = "sharing";
        if (this.hasAttribute("help-topic"))
            this.highlight(this.getAttribute("help-topic"));
    }

    disconnectedCallback() {
        this.querySelector("[name=close]")?.removeEventListener("click", this.closeHandler);
    }

    async attributeChangedCallback(attr, oldValue, newValue) {
        if (attr === "help-topic") {
            this.highlight(newValue);
        }
        
    }

    private highlight = (id: string) => {
        for(const existing of Array.from(this.querySelectorAll("[highlight]"))) {
            existing.toggleAttribute("highlight", false);
        }

        if (id) {
            const anchorElement = this.querySelector(`[id="${id}"]`) as Element;
            
            if (!anchorElement)
                return;
            
            if (anchorElement.scrollIntoView) {
                const options: unknown = { behavior: "smooth" };
                setTimeout(() => {
                    anchorElement.scrollIntoView(options as ScrollIntoViewOptions);
                }, 1000);
            }
                
            let element = anchorElement;
            while (element) {
                element = element.nextElementSibling;

                if (element.tagName === anchorElement.tagName)
                    break;

                element.toggleAttribute("highlight", true);
            }    
        }
    }

    private closeHandler = () => {

        const sidebar = this.closest("side-bar");
       
        sidebar?.toggleAttribute("open", false);
    }

    // <header>
    //         Help
    //         <button type=button name=close>Hide</button>
    //     </header>
        

    private view = () => `
        
        <a href="/help">⧉ Show full screen help</a>
        
        <info-viewer>
            <div name="content">
                ${require("!!html-loader!markdown-loader!../../pages/help/help.md")}
            </div>
        </info-viewer>
    `;
}

customElements.define("in-app-help", InAppHelpElement);