import EventTarget from "@ungap/event-target";
import { AuthenticationStore, User } from "features/authentication";
import { ClassTypename, Typename } from "lib/Types";
import { DateTime } from "lib/DateTime";
import { NotifyNativeViewController } from "features/notification";
import { Pipeline, Request } from "lib/HTTP";
import { TrailEventStore, TrailEvent } from "../events/TrailEventStore";
import { UrlProvider } from "lib/UrlProvider";
import { UserPreferenceStore } from "features/user-preferences/UserPreferenceStore";
import { cityline } from "features/cityline";
import { EntriesReadUnreadQueue } from "./EntriesReadUnreadQueue";
import { FeedViewSettings, EntryMarker, Feed, Entry } from ".";
import { valueStoreFactory } from "features/valueStore"

const sender = Symbol("sender");

interface EventMap {
    "feed-added": Feed;
    "feeds-changed": FeedChangeDetail;
    "feeds-unread-change": FeedChangeDetail;
    "articles-read": FeedSequenceDetail;
    "articles-unread": FeedSequenceDetail;
    "articles-available": Feed;
    "active-feed-category-changed": Feed;
}

export interface FeedSequenceDetail {
    feedId: string;
    sequence: number[];
}

export interface FeedChangeDetail {
    feeds: Feed[];
    categoryChanges: boolean;
}

@ClassTypename("MyFeedStore")
export class MyFeedStore {
    private static _instance: MyFeedStore = new MyFeedStore();
    private eventTarget = new EventTarget();
    private user: User;
    private feeds: { [id: string] : Feed } = {};
    private urlProvider: UrlProvider = new UrlProvider();
    private _initializer: Promise<void>;
    private feedsAsArray = () => Object.keys(this.feeds).map(i => this.feeds[i]) as Feed[];
    private _activeCategory: Feed;
    private _valueStore = valueStoreFactory.resolve();
    
    constructor() {
        this._initializer = this.doInitialize();
        window.requestIdleCallback(async () => this._initializer, {timeout: 10000});    

        window.addEventListener("user-switch", () => {
            this.feeds = {};
            this._initializer = this.doInitialize();
            window.requestIdleCallback(async () => this._initializer, {timeout: 10000});    
        });

        cityline.addEventListener("entries-read", async (event: CustomEvent<{[id:string]:number[]}>) => {
            const entriesRead = event.detail;
            const keys = Object.keys(entriesRead);
            await Promise.all(keys.map(key => this.doUpdateReadStatusOnLocalItems(key, entriesRead[key])));

            Object.keys(event.detail).forEach(key => {
                this.emit("articles-read", { feedId: key, sequence: event.detail[key] });
            });
            
        });

        cityline.addEventListener("feeds", async (event: CustomEvent<Feed[]>) => {
            const changes = this.mergeIntoFeeds(event.detail, false, false);
            this.emit("feeds-changed", changes);
        });

        TrailEventStore.instance.addEventListener("subscribed-to-feed", async (event: CustomEvent<TrailEvent>) => {
            const trailEvent = event.detail;

            const startup = await UserPreferenceStore.instance.get<string>("startup-time", false);

            let startupTime: Date;
            if (startup)
                startupTime = new Date(startup);
            else
                startupTime = DateTime.LocalToUTC(new Date());

            if (new Date(trailEvent.created) > startupTime) {
                new NotifyNativeViewController().show({
                    title: "Feed subscription",
                    body: trailEvent.message,
                    icon: trailEvent.extended.icon,
                    showSeconds: 6.5
                });
            }
        });
    }

    public addEventListener<K extends Extract<keyof EventMap, string>>(type: K, listener: (this: EventSource, ev: CustomEvent<EventMap[K]>) => any, options?: boolean | AddEventListenerOptions) {
        return this.eventTarget.addEventListener(type, listener, options);
    }

    public removeEventListener<K extends Extract<keyof EventMap, string>>(type: K, listener: (this: EventSource, ev: CustomEvent<EventMap[K]>) => any, options?: boolean | AddEventListenerOptions) {
        return this.eventTarget.removeEventListener(type, listener, options);
    }

    private emit<K extends Extract<keyof EventMap, string>, T extends EventMap>(type: K, value: T[K]) {
        return this.eventTarget.dispatchEvent(new CustomEvent(type, { detail:  Object.assign({}, value, { [sender] : MyFeedStore[Typename] })})); 
    }

    async find(query: string) : Promise<Feed[]> {
        await this._initializer;
        return new Promise<Feed[]>(resolve => {

            if (!query)
                resolve(this.feedsAsArray());

            const normalizedQuery = query.toLocaleLowerCase();
        
            resolve(this.feedsAsArray()
                .filter(i => 
                    (i.title && i.title.toLocaleLowerCase().indexOf(normalizedQuery) !== -1) ||
                    (i.description && i.description.toLocaleLowerCase().indexOf(normalizedQuery) !== -1)))
        });
    }

    get activeCategory() : Feed {
        return this._activeCategory;
    }

    changedActiveCategory(feedId) {
        const feed = this.feeds[feedId];
        if (feed)
            setTimeout(() => {
                this._activeCategory = feed;
                this.emit("active-feed-category-changed", feed);
            });
    }

    updateReadStatusOnLocalItems = async (event: CustomEvent<FeedSequenceDetail>) => 
        this.doUpdateReadStatusOnLocalItems(event.detail.feedId, event.detail.sequence);


    doUpdateReadStatusOnLocalItems = async (feedId: string, sequence: number[]) => {
        await this._initializer;
        const candidates = this.feedsAsArray().filter(feed => feed.id === feedId || feed.isCategory);

        await Promise.all(candidates.map( async feed => {
            const entryFeed = await this.getEntriesFromStorage(feed.id);

            if (!entryFeed || entryFeed.entries.length === 0)
                return; // nothing to update

            const toUpdate = entryFeed.entries.filter(entry => entry.feedId === feedId && sequence.indexOf(entry.sequence) !== -1);

            if (toUpdate.length === 0)
                return;
            
            toUpdate.forEach(entry => {
                entry.read = true;
            });

            await this.addEntriesToStorage(feed.id, entryFeed);
        }));
    }

    async myFeedsSortedByTitle() : Promise<Feed[]> {
        await this._initializer;
        const feeds = this.feedsAsArray();
        feeds.sort( (a,b) => a.title > b.title ? 1 : -1 )
        return feeds;
    }

    private entryKey(feedId: string) {
        return `${this.user.userId}:${feedId}:entries`; 
    }

    filterAndSortByViewMode(feed: Feed, viewSettings: FeedViewSettings) {

        if (viewSettings.hideReadEntries)
            feed.entries = feed.entries.filter(i => i.read === false);
        
        return feed;
    }

    private async getEntriesFromStorage(feedId: string): Promise<Feed> {
        try { 
            return await this._valueStore.get(this.entryKey(feedId)) as Feed;
        } catch (error) { 
            console.log(`Unable to get from storage: ${error}`);
        }

        return undefined;
        
    }

    private async addEntriesToStorage(feedId: string, feed: Feed): Promise<void> {
        try {
            await this._valueStore.set(this.entryKey(feedId), feed);
        } catch (error) { 
            console.log(`Unable to add to storage: ${error}`);
        }
    }

    static get instance() {
        return MyFeedStore._instance;
    }

    markRead = async (feedId: string, sequence: number)  => {
        this.emit("articles-read", { feedId, sequence: [sequence] });
        await EntriesReadUnreadQueue.instance.markRead(feedId, sequence);
    }

    markUnread = async (feedId: string, sequence: number) => {
        this.emit("articles-unread", { feedId, sequence: [sequence] });
        await EntriesReadUnreadQueue.instance.markUnread(feedId, sequence);
    }
   
    async subscribe(url: string, category: string) : Promise<[SubscribeStatus, Feed?]> {

        const response = await new Pipeline().fetch( `${this.urlProvider.root}/api/my-feeds/subscription`, Request.post.authenticate().setJSON({ url, category }));

        if (response.ok) {
            const feed = await response.json() as Feed;

            this.mergeIntoFeeds([feed], false, false);

            return [SubscribeStatus.Ok, feed];
        }
            
        
        if (response.status === 409)
            return [SubscribeStatus.Conflict];
        
        if (response.status === 400)
            return [SubscribeStatus.InvalidUrl];

        return [SubscribeStatus.InternalError];

    }

    async unsubscribe(feedId: string) : Promise<boolean> {
        if (!feedId || feedId === "")
            throw new Error("FeedId has to be provided");
        
        const url = `${this.urlProvider.root}/api/my-feeds/${feedId}`;

        const response = await new Pipeline().fetch(url, Request.delete.authenticate());     
        
        return response.ok;       
    }

    async changeCategory(feedId: string, categorySlug: string) : Promise<boolean> {
        if (!feedId || feedId === "")
            throw new Error("FeedId has to be provided");
        
        const urlParams = new URLSearchParams();
        urlParams.set("categorySlug", categorySlug);

        const url = `${this.urlProvider.root}/api/my-feeds/${feedId}/_category?${urlParams.toString()}`;

        const response = await new Pipeline().fetch(url, Request.post.authenticate());        
        return response.ok;       
    }

    async setHideReadEntries(hide: boolean) {
        await this._valueStore.set("hide-read-entries", hide);
    }

    
    async hideReadEntries() {
        const response = await this._valueStore.get("hide-read-entries") as boolean;
        if (response !== undefined && typeof response === "boolean")
            return response;
        
        return true;  // default
    }

    public async markAllRead(feedId: string) {
        const url = `${this.urlProvider.root}/api/my-feeds/${feedId}/_read`;

        const response = await new Pipeline().fetch(url, Request.post.authenticate());
        
        if (response.ok) {
            const entrySequences = await response.json() as { [id: string] : number[] };
            for(const feedId in entrySequences){
                window.requestIdleCallback(() => {
                    this.emit("articles-read", {feedId: feedId, sequence: entrySequences[feedId]});
                }, { timeout: 10000 })
            }
                
        }
        return response.ok;  
    }

    public async markManyRead(entryMarkers: EntryMarker[]) : Promise<boolean> {
        const url = `${this.urlProvider.root}/api/my-feeds/_read`;

        
        const items = {};

        entryMarkers.forEach(entryMarker => {

            const item = items[entryMarker.feedId];
            if (item)
                item.push(entryMarker.sequence);
            else
                items[entryMarker.feedId] = [entryMarker.sequence];

        });

        const response = await new Pipeline().fetch(url, Request.post.authenticate().setJSON({ items: items }));
        
        if (response.ok) {
            Object.keys(items).forEach(feedId => {
                this.emit("articles-read", {feedId: feedId, sequence: items[feedId]});
            });
        }
        return response.ok;       
    }

    async storeEntries(feed: Feed) : Promise<void>{
        let existing = await this.getEntriesFromStorage(feed.id);

        if (!feed.entries)
            feed.entries = [];

        if (existing)
        {
            if (!existing.entries)
                existing.entries = [];

            const existingKeys = existing.entries.map(i => i.id);
            feed.entries.forEach(entry => {
                if (existingKeys.indexOf(entry.id) === -1)
                    existing.entries.push(entry);
            });
        } else {
            existing = feed;
        }

        //TODO: what about read/unread here?
        if (existing.entries.length > 100) {
            existing.entries.sort( (a, b) => a.sequence-b.sequence );
            existing.entries.splice(100);
        }

        await this.addEntriesToStorage(feed.id, existing);
    }

    async removeLocalEntry(feedId: string, entryId: string) : Promise<void> {
        const feed = await this.getEntriesFromStorage(feedId);
        if (!feed)
            return; 

        feed.entries = feed.entries.filter(i => i.id !== entryId);
        await this.addEntriesToStorage(feedId, feed);
    }

    async count() : Promise<number> {
        await this._initializer;
        return Object.keys(this.feeds).length;
    } 

    async allFeeds() : Promise<Feed[]> {
        await this._initializer;
        return this.feedsAsArray();
    }

    async allFeedsSorted() : Promise<Feed[]> {
        await this._initializer;
        return this.feedsAsArray();
    }
    
    async allFeedsCategorized() : Promise<({feed: Feed, children: Feed[] }[])> {
        await this._initializer;
        const feeds = await this.myFeedsSortedByTitle();
        
        let categoryFeeds = feeds
            .filter(i => i.isCategory)
            .map(category => ({ 
                feed: category, 
                children: feeds.filter(i => i.categories.map(m => m.slug).indexOf(category.id) !== -1)}));
        
        // move all to beginning
        const all = categoryFeeds.filter(i => i.feed.id === "all")[0];

        if (all) {
            categoryFeeds = categoryFeeds.filter(i => i.feed.id !== "all");
            categoryFeeds.unshift(all);
        }
        
        return categoryFeeds;
    }

    
    async getEntries(feedId: string, criteria?: { refresh?, from?, to?, entrySlug?, query? }, viewSettings: FeedViewSettings = {}) : Promise<Feed> {
        await this._initializer;
        
        const feedHolder = this.feeds[feedId];

        if (!feedHolder)
            return undefined;

        return await  this.getRemoteEntries({ 
            feedId: feedId, 
            from: criteria ? criteria.from : undefined, 
            query: criteria ? criteria.query : undefined,
            entrySlug: criteria ? criteria.entrySlug : undefined 
        }, viewSettings);
    }
    
    async getEntriesFilteredAndSorted(feedId: string, criteria?: { refresh?, from?, to? }, viewSettings: FeedViewSettings = {}) : Promise<Feed> {
        await this._initializer;
        
        const feedHolder = this.feeds[feedId];

        if (!feedHolder)
            return undefined;

        const a = this.getRemoteEntries({ feedId: feedId, from: criteria ? criteria.from : undefined }, viewSettings);
        
        const b = new Promise<Feed>(async (resolve) => {
            const feed = await this.getEntriesFromStorage(feedId);
            
            if (feed) {
                this.filterAndSortByViewMode(feed, viewSettings);
                this.emit("articles-available", feed);
                resolve(feed);
            }
        });

        try {
            return await a;
        } catch(error) {
            console.log("Fetch error, returning stored", error);
            return await b;
        }
    }

    async getFeed(feedId: string): Promise<Feed>{
        await this._initializer;
        
        const feed = this.feeds[feedId];

        if (!feed) {
            throw new Error(`Feed with id '${feedId}' not found.`); 
        }

        return feed;
    }

    async getSpecificEntry(feedId: string, entrySlug: string) : Promise<Feed> {
        const feedWithEntries = await this.getEntriesFromStorage(feedId);
        if (feedWithEntries) {
            const entry = feedWithEntries.entries.filter(i => i.slug === entrySlug)[0];
            if (entry)
                return feedWithEntries; 
        }

        return await this.getRemoteEntries({ feedId: feedId, entrySlug: entrySlug});

    }

    async hasNewerEntry(feedId: string, entryId: string) : Promise<boolean> {
        await this._initializer;
        
        const feed = this.feeds[feedId];

        if (!feed) {
            throw new Error(`Feed with id '${feedId}' not found.`); 
        }

        const feedWithEntries = await this.getEntriesFromStorage(feedId);
        if (feedWithEntries){
            const current = feedWithEntries.entries.filter(i => i.id === entryId)[0];
            if (current) {
                this.filterAndSortByViewMode(feedWithEntries, feedWithEntries.viewSettings);

                // last item is length-1, has to be second last or better
                return feedWithEntries.entries.indexOf(current) > 0;
            }
        }
       
        return false;
    }

    async newerEntry(feedId: string, entryId: string) : Promise<{feed: Feed, entry: Entry}> {
        await this._initializer;
        
        const feed = this.feeds[feedId];

        if (!feed) {
            throw new Error(`Feed with id '${feedId}' not found.`); 
        }

        const feedWithEntries = await this.getEntriesFromStorage(feedId);
        if (feedWithEntries){
            const current = feedWithEntries.entries.filter(i => i.id === entryId)[0];
            if (current) {
                this.filterAndSortByViewMode(feedWithEntries, feedWithEntries.viewSettings);
                const currentIndex = feedWithEntries.entries.indexOf(current);

                if (currentIndex > 0)
                    return { feed: feedWithEntries, entry: feedWithEntries.entries[currentIndex-1] }; 
            }
        }
       
        return undefined;
    }

    async olderEntry(feedId: string, entryId: string) : Promise<{feed: Feed, entry: Entry}> {
        await this._initializer;
        
        const feed = this.feeds[feedId];

        if (!feed) {
            throw new Error(`Feed with id '${feedId}' not found.`); 
        }

        const feedWithEntries = await this.getEntriesFromStorage(feedId);
        if (feedWithEntries){
            const current = feedWithEntries.entries.filter(i => i.id === entryId)[0];
            if (current) {
                this.filterAndSortByViewMode(feedWithEntries, feedWithEntries.viewSettings);
                const currentIndex = feedWithEntries.entries.indexOf(current);

                if (currentIndex <= feedWithEntries.entries.length - 2)
                    return { feed: feedWithEntries, entry: feedWithEntries.entries[currentIndex+1] }; 
                else {
                    if (feedWithEntries.previousPageMarker) {
                        const nextPage = await this.getRemoteEntries({ 
                            feedId: feedId, 
                            from: feedWithEntries.previousPageMarker });

                        if (!nextPage || !nextPage.entries)
                            return undefined;

                        this.filterAndSortByViewMode(nextPage, feedWithEntries.viewSettings);

                        return { feed: nextPage, entry: nextPage.entries[0] };
                    }
                        
                }
            }
        }
       
        return undefined;
    }

    async hasOlderEntry(feedId: string, entryId: string) : Promise<boolean> {
        await this._initializer;
        
        const feed = this.feeds[feedId];

        if (!feed) {
            throw new Error(`Feed with id '${feedId}' not found.`); 
        }

        const feedWithEntries = await this.getEntriesFromStorage(feedId);
        if (feedWithEntries){
            if (feedWithEntries.previousPageMarker) // if we can move to next page, we always have more items
                return true;
        
            const current = feedWithEntries.entries.filter(i => i.id === entryId)[0];
            if (current) {
                this.filterAndSortByViewMode(feedWithEntries, feedWithEntries.viewSettings);

                // last item is length-1, has to be second last or better
                return feedWithEntries.entries.indexOf(current) <= feedWithEntries.entries.length - 2;
            }
        }
       
        return false;
    }

    async getRemoteEntries(request: {feedId, from?, refresh?, entrySlug?, query?}, viewSettings: FeedViewSettings = {}) : Promise<Feed> {
        const parms = new URLSearchParams();

        if (viewSettings.hideReadEntries !== undefined)
            parms.append("viewSettings.hideReadEntries", viewSettings.hideReadEntries.toString());

        if (request.entrySlug)
            parms.append("entryslug", request.entrySlug);

        if (request.from)
            parms.append("from", request.from);

        if (request.refresh)
            parms.append("refresh", "true");

        if (request.query)
            parms.append("query", request.query);

        const response = await new Pipeline().ignoreLoggedInStatus().fetch(`${this.urlProvider.root}/api/my-feeds/${request.feedId}?${parms.toString()}`, Request.get.authenticate());
        const feed =  await response.json() as Feed;
        
        this.emit("articles-available", feed);
        
        await this.storeEntries(feed);
        
        return feed;
    }

    async doInitialize() {
        await AuthenticationStore.instance.whenSignedIn();
        this.user = await AuthenticationStore.instance.currentUser();
        // const savedFeeds = await idbcache.get(`${this.user.userId}:my-feeds`);
        // if (savedFeeds) 
        //     this.mergeIntoFeeds(savedFeeds)

        const feedsFromEventStream = await cityline.getFrame<Feed[]>("feeds");
        this.mergeIntoFeeds(feedsFromEventStream);

        await this._valueStore.set(`${this.user.userId}:my-feeds`, this.feedsAsArray());
    }

    private mergeIntoFeeds(incomingFeeds: Feed[], overwrite = false, fullUpdate = true) : FeedChangeDetail {
        const changes: Feed[] = [];
        let categoryChanges = false;
        let doRaiseEvents = true;

        if (Object.keys(this.feeds).length === 0) {
            doRaiseEvents = false;
        }

        incomingFeeds.forEach( (feed: Feed) => {
            if (!feed.isCategory) {
                if (!feed.categories || feed.categories.length === 0)
                    feed.categories = [{slug: "uncategorized", title: "Uncategorized"}];
            }
            
            if (overwrite)
                this.feeds[feed.id] = feed;
            else {
                if (this.feeds[feed.id]) {

                    let hasChanged = false;

                    if (this.feeds[feed.id].title !== feed.title) {
                        this.feeds[feed.id].title = feed.title;
                        hasChanged = true;
                    }

                    if (this.feeds[feed.id].icon !== feed.icon) {
                        this.feeds[feed.id].icon = feed.icon;
                        hasChanged = true;
                    }
                    
                    if (this.feeds[feed.id].unread !== feed.unread) {
                        this.feeds[feed.id].unread = feed.unread;
                        hasChanged = true;
                    }

                    this.feeds[feed.id].categories = this.feeds[feed.id].categories || [];
                    feed.categories = feed.categories || [];

                    
                    if (this.feeds[feed.id].categories.map(m => m.slug).sort().toString() !== feed.categories.map(m => m.slug).sort().toString()) {
                        this.feeds[feed.id].categories = feed.categories;
                        hasChanged = true;
                        categoryChanges = true;
                    }

                    if (this.feeds[feed.id].description !== feed.description) {
                        this.feeds[feed.id].description = feed.description;
                        hasChanged = true;
                    }

                    if (hasChanged)
                        changes.push(this.feeds[feed.id]);

                } else {
                    this.feeds[feed.id] = feed
                    categoryChanges = true;
                    if (doRaiseEvents)
                        this.emit("feed-added", feed);
                }
            }
        });

        if (fullUpdate)
            for(const feedId in this.feeds) {
                if (!incomingFeeds.some(f => f.id === feedId))
                {
                    delete this.feeds[feedId];
                    categoryChanges = true;
                }
            }

        return { feeds: changes, categoryChanges: categoryChanges };
    }
}

export enum SubscribeStatus {
    Ok,
    InvalidUrl,
    InternalError,
    Conflict
}