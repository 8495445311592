import { Feed, Category, CategoryType } from "features/feeds";
import { Collection } from "lib/System";
import { Pipeline, Request } from "lib/HTTP";
import { UrlProvider } from "lib/UrlProvider";
import { cityline } from "features/cityline";

export class CategoryStore {
    private static _instance = new CategoryStore();
    private _urlProvider = new UrlProvider();
    private categories: Category[];
    private initializer: Promise<void>;

    private constructor() {
        this.initializer = new Promise(async r => {
            const feeds = await cityline.getFrame<Feed[]>("feeds");
            
            const unfiltered = feeds.filter(i => i.isCategory).map(i => ({ slug: i.id, title: i.title}));
            const distinct = Collection.distinctBy(unfiltered, c => c.title);
            this.categories = distinct.filter(m => m.slug !== "all"); 
            r();  
        });
    }

    async create(categoryType: CategoryType, title: string) {
        const url = `${this._urlProvider.root}/api/categories/${categoryType}`;
        const response = await new Pipeline().fetch(`${url}?_token=${Request.getSourceToken()}`, Request.post.authenticate().setJSON({ title: title }));
        
        if (response.ok) {
            const newCategory = await response.json() as Category;
            this.categories.push(newCategory);
        }
    }

    async delete(categoryType: CategoryType, slug: string) {
        const url = `${this._urlProvider.root}/api/categories/${categoryType}/${slug}`;
        const response = await new Pipeline().fetch(`${url}?_token=${Request.getSourceToken()}`, Request.delete.authenticate());
        
        if (response.ok) {
            this.categories = this.categories.filter(m => m.slug !== slug);
        }
    }
    
    static get instance() : CategoryStore {
        return CategoryStore._instance;
    } 

    async all() : Promise<Category[]> {
        await this.initializer;
        return this.categories;
    }

    async filtered(text: string) : Promise<Category[]> {
        await this.initializer;
        text = text.toLocaleLowerCase();
        return this.categories.filter(m => m.title.toLocaleLowerCase().indexOf(text) !== -1);
    }
}