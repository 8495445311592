import "./UserLoginElement-style";
import { ValidationMessage, IdentityResult } from "./Model";

import { AuthenticationStore } from "./AuthenticationStore";

class ExternalUserRegistrationElement extends HTMLElement {
    async connectedCallback() {
    this.innerHTML = this.view();
        this.classList.add("form");
        this.setupEvents()
    }

    private setupEvents() {
        const usernameElement = this.querySelector("[name=username]") as HTMLInputElement;
        const emailElement = this.querySelector("[name=email]") as HTMLInputElement;

        const loginButton = this.querySelector("[name=register]") as HTMLButtonElement;
        loginButton.addEventListener("click", async () => {
            let result;
            this.setAttribute("busy", "");
            try {
                result = await AuthenticationStore.instance.registerExternal(usernameElement.value, emailElement.value);
            } finally{
                this.removeAttribute("busy");
            }

            if ((<ValidationMessage>result).modelState) {
                for(const key in (<ValidationMessage>result).modelState){
                    const messageElement = this.querySelector(`[name="${key}"]`) as HTMLSpanElement;
                    if (messageElement)
                        messageElement.innerText = (<ValidationMessage>result).modelState[key];
                }
            } else if (!(<IdentityResult>result).succeeded) {
                const formMessageElement = this.querySelector(`[name="form-message"]`) as HTMLSpanElement;
    
                if (formMessageElement)
                    formMessageElement.innerText = (<IdentityResult>result).errors.join(" ");
            }

        });
    }

    private get provider() {
        return this.getAttribute("provider");
    }

    private get username() {
        return this.getAttribute("username");
    }

    private get email() {
        return this.getAttribute("email");
    }

    private view = () => `
        <header>
            <a>Login with ${this.provider}</a>
        </header>
        <section>
            <p>You've successfully authenticated with ${this.provider}.</p>
            
            <p>Please enter a user name and/or email below to finish logging in.</p>
            <span name="form-message" class="error"></span>
            <div class=form-group>
                <label for="email">Email</label>
                <div>
                    <input autocapitalize="none" autocorrect="off" class="form-control" id="email" name="email" placeholder="Email" type="text" value="${this.email}">
                    <span name="model.Email" class="error"></span>
                </div>
            </div>
            <div class=form-group>
                <label for="username">Username</label>
                <div>
                    <input autocapitalize="none" autocorrect="off" class="form-control" id="username" name="username" placeholder="Username" type="text" value="${this.username}">
                    <span name="model.Username" class="error"></span>
                </div>
            </div>
            <button type=button class=power name=register>Register</button>
        </section>
    `;
}



customElements.define("external-user-registration", ExternalUserRegistrationElement);