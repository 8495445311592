// 
import "./MiniProgressElement-style";

class MiniProgressElement extends HTMLElement {
    private percent = () => this.getAttribute("value");
    private completedPart: HTMLElement;

    static get observedAttributes() {
        return ["value"];
    }

    attributeChangedCallback() {
        if (this.completedPart)
        this.completedPart.style.width = `${this.percent()}%`;
    }

    connectedCallback() {
        this.innerHTML = this.view();
        this.completedPart = this.querySelector(".completed") as HTMLElement;

        requestAnimationFrame(() => {
            this.completedPart.style.width = `${this.percent()}%`;
        });
    }

    private view = () => `
        <div>
            <div class=completed style="width:0%"></div>
        </div>`;
}


customElements.define("mini-progress", MiniProgressElement);

//customElements.define("mini-progress", MiniProgressElement);