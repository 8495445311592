import { Link } from "features/links";
import { BaseDialogViewController } from "features/dialog";
import "./ImageSelector-style";

export class ImageSelectorViewController extends BaseDialogViewController{
    private id = `is-${new Date().getTime().toString()}`;
    private panel: HTMLElement;
    private currentImage: HTMLImageElement;
    private link: Link;
    private hiddenPreview: HTMLInputElement;
    protected renderFrame = false;
    
    constructor() {
        super();
        this.clazz = "image-selector arrow top-left";
        this.targetLocation = "afterend";
        this.targetSelector = () => <HTMLElement>document.querySelector(`#${this.id}`);
        this.headerView = () => "";
        this.renderSection = () => this.selectorView();
    }

    public setLink(link: Link): ImageSelectorViewController {
        this.link = link;
        return this;
    }

    public render() {
        setTimeout(() => this.localAfterRender(), 0);
        return `<div class="preview" id="${this.id}">
                    <div class=hint>Change<br />image</div>
                    <img src="${this.link.preview}"/>
                    <input type=hidden name=preview value="${this.link.preview}" />
                </div>`;
    }

    localAfterRender() {
        this.panel = document.getElementById(this.id);
        this.currentImage = <HTMLImageElement>this.panel.querySelector("img");
        this.panel.querySelector(".hint").addEventListener("click", () => this.show());
        this.hiddenPreview = <HTMLInputElement>this.panel.querySelector("input[name=preview]");
    }

    afterRender() {
        super.afterRender();

        const images = Array.prototype.slice.call(this.dialog.querySelectorAll("img"));

        images.forEach(image => {
            image.addEventListener("click", (event) => {
                const image = <HTMLImageElement>event.target;
                this.hiddenPreview.value = image.src;
                this.currentImage.src = image.src;
                this.close();
            });
        });
    }

    private selectorView = () => `${this.link.availablePreviews.map(url => `<div class=img-holder><div></div><img src="${url}"  onload="this.parentElement.classList.add('loaded')"  onerror="this.parentElement.classList.add('error')" /></div>`).join("")}`;
}
