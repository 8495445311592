import * as cardsStyle from "../../../../styles/cards-module.less"
import "./TagsWidgetElement-style";
import { WordCloudElement } from "./WordCloudElement";
import { WordTableElement } from "./WordTableElement";
import * as widgetStyle from "../Widgets-module.less";
import { BaseWidget, PreferSize, TabButton } from "../BaseWidget";
import { BoxLoaderElement } from "features/box-loader";
import { TagStore } from "features/tags/TagStore";
import { UrlProvider } from "lib/UrlProvider";

type Mode = "cloud" | "table";

class TagsWidgetElement extends BaseWidget {
    private displayArea: HTMLElement;
    private mode: Mode = "cloud";
    private wordCloudElement: WordCloudElement;
    private wordTableElement: WordTableElement;


    public get preferredWidth(): PreferSize {
        return "narrow";
    }

    async connectedCallback() {
        this.className += ` ${cardsStyle.cardContainer}`;
        this.innerHTML = this.view();
        this.displayArea = this.querySelector("[name=display-area]");
        this.displayArea.append(new BoxLoaderElement());
        setTimeout(async () => {
            await this.renderCloud();
            TagStore.instance.addEventListener("tag-frequencies-changed", this.tagFrequencyChangeHandler);
        });


        super.connectedCallback();
    }

    disconnectedCallback() {
        TagStore.instance.removeEventListener("tag-frequencies-changed", this.tagFrequencyChangeHandler);
        super.disconnectedCallback();
    }

    private tagFrequencyChangeHandler = async (event: CustomEvent<{ [id: string]: number; }>) => {
        const frequencies = event.detail;
        switch (this.mode) {
            case "cloud":
                if (!this.wordCloudElement) {
                    await this.renderCloud();
                    return;
                }
                
                console.log(frequencies);
                
                this.wordCloudElement.setAttribute("words", Object.keys(frequencies).map(key => `${key}:${frequencies[key]}`).join(";"));
                break;
            case "table":

                if (!this.wordTableElement) {
                    await this.renderTable();
                    return;
                }

                this.wordTableElement.setAttribute("words", Object.keys(frequencies).map(key => `${key}:${frequencies[key]}`).join(";"));
                break;
        }

    }

    protected async modeChange(mode: string) {
        this.mode = mode as Mode;
        switch (this.mode) {
            case "cloud":
                await this.renderCloud();
                break;
            case "table":
                await this.renderTable();
                break;
        }
    }

    private renderCloud = async () => {
        const frequencies = await TagStore.instance.tagFrequencites();
        
        this.displayArea.innerHTML = "";
        
        if (Object.keys(frequencies).length === 0) {
            if (this.wordCloudElement)
                this.wordCloudElement.remove();

            if (this.wordTableElement)
                this.wordTableElement.remove();

            this.displayArea.innerHTML = this.emptyView();
            return;
        }

        this.wordCloudElement = new WordCloudElement();
        this.wordCloudElement.setAttribute("url-pattern", `${await new UrlProvider().userHome()}/links?query=%22{word}%22`);
        this.wordCloudElement.setAttribute("words", Object.keys(frequencies).map(key => `${key}:${frequencies[key]}`).join(";"));
        this.wordCloudElement.className += ` ${cardsStyle.card} ${widgetStyle.card}`;
        this.displayArea.append(this.wordCloudElement);
    };

    private renderTable = async () => {
        const frequencies = await TagStore.instance.tagFrequencites();
        this.wordTableElement = new WordTableElement();
        this.wordTableElement.setAttribute("url-pattern", `${await new UrlProvider().userHome()}/links?query=%22{word}%22`);
        this.wordTableElement.setAttribute("words", Object.keys(frequencies).map(key => `${key}:${frequencies[key]}`).join(";"));
        this.wordTableElement.className += ` ${cardsStyle.card} ${widgetStyle.card}`;
        this.displayArea.innerHTML = "";
        this.displayArea.append(this.wordTableElement);
    };

    private tabButtons: TabButton[] = [
        { label: "Cloud", value: "cloud" },
        { label: "Table", value: "table" }
    ];

    private view = () => `
        ${this.headerView("Tags", this.tabButtons, "cloud")}
        <div name=display-area></div>
    `;
    
    private emptyView = () => `
        <div name=empty class="${widgetStyle.emptyText}">Tag a link to get started</div>
    `
}

customElements.define("tags-widget", TagsWidgetElement);