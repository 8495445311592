import "./SiteLoginElement";
import "./SiteRegisterElement";
import "./FormGroupElement";
import "./SiteConfirmEmailElement";
import "./SiteExternalRegistrationElement";
import "./SiteEmailConfirmed";
import "./SiteForgotPasswordElement";
import "./SiteSetPasswordElement";

require("!!file-loader?name=[name].[ext]!../../images/back-arrow.svg");  

