import { UrlProvider } from "lib/UrlProvider"
import "./HowToAddLinksElement-style";
import { LinkPageStore } from "features/link-page/LinkPageStore";


class HowToAddLinksElement extends HTMLElement {
    static get observedAttributes() { return ["name"]; }
    private linkAddedHandler = () => this.remove();

    connectedCallback() {
        this.innerHTML = this.view();
        LinkPageStore.instance.addEventListener("link-added", this.linkAddedHandler);
    }

    disconnectedCallback() {
        LinkPageStore.instance.removeEventListener("link-added", this.linkAddedHandler);
    }

    private view = () => `
        <header><span>TIP: 3 easy ways to add links</span></header>
        <div class="component help-box direct">
            <header>Direct</header>
            <p>
                Use the + button at the top right corner.
            </p>
        </div>
        <div class="component help-box bookmarklet">
            <header>Plugin or bookmarklet</header>
            <p>
                Add the <a href="/integrations" route>plugin or bookmarklet</a> to
                your browser of choice.
            </p>
        </div>
        <div class="component help-box import">
            <header>Import</header>
            <p>
                <a href="${new UrlProvider().userHome()}import">Import</a> your links from other
                sites or your browser.
            </p>
        </div>`;

}

//customElements.define("how-to-add-links", HowToAddLinksElement);
customElements.define("how-to-add-links", HowToAddLinksElement);