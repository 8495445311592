import { Request } from "lib/HTTP"
import { DialogController } from "features/dialog";
import { UrlProvider } from "lib/UrlProvider";
import { cityline } from "features/cityline";

declare global {
    class Paylike {
        constructor(publicKey: string);
        popup(args: any, callback: (err: any, response: any) => void);
    }
}

export class PaymentController {
    private urlProvider = new UrlProvider();
    private static paylike: Paylike;

    private initializePaylike() {
        return new Promise<Paylike>((resolve) => {
            if (PaymentController.paylike) {
                resolve(PaymentController.paylike);
                return;
            }

            const script = document.createElement("script");
            document.head.appendChild(script);
            script.src = "https://sdk.paylike.io/3.js";
            script.onload = async () => {
                const paylikeId = await cityline.getFrame<string>("paylike-id");
                PaymentController.paylike = new Paylike(paylikeId);
                resolve(PaymentController.paylike);
            }
        });
    }

    async pay() {
        const paylike = await this.initializePaylike();
        
        paylike.popup({
            currency: "USD",
            amount: 1500,
            locale: "en",
            title: "One year premium",
            description: "Linkstacks one year premium subscription",
            custom: {
                username: (window as any).userId
            }
        },
        async (err, response: PaylikeResponse) => {
            if (err){
                if (err !== "closed") {
                    await new DialogController().prompt({
                        caption: "Unable to complete payment",
                        message: "Unable to complete payment, please contact us.",
                        options: ["Ok"]
                    });
                    throw new Error(`Unable to complete payment: ${err}`);
                } 
            } else {
                const waitDialog = new DialogController();
                try{
                    // dont await dialog
                    setTimeout(async () => await waitDialog.prompt({
                        caption: "Please wait",
                        message: "Processing payment ...",
                        options: []
                    }));

                    const registerResponse = await fetch(`${this.urlProvider.root}/api/payment/transactions`, new Request().setJSON({transactionId: response.transaction.id}));
                    Request.throwOnServerError(registerResponse);

                    waitDialog.close();

                    document.body.classList.remove("promotion");

                    await new DialogController().prompt({
                        caption: "Thank you for signing up",
                        message: "We appreciate your support. Thank you.",
                        options: ["Proceed"]
                    });

                    window.location.reload();

                } catch(err) {
                    waitDialog.close();

                    await new DialogController().prompt({
                        caption: "Unable to register payment",
                        message: "Unable to register payment, please contact us.",
                        options: ["Ok"]
                    });

                    throw new Error(`Unable to register payment: ${err}.`);
                }
                
            }
        });
    }
}

interface PaylikeTransaction{
    id: string;
}

interface PaylikeResponse {
    transaction: PaylikeTransaction;
}