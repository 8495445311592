import { Routing } from "lib/Routing";
import { AppContextStore } from "features/application-context/AppContextStore";

 //tslint:disable-next-line: no-unused-variable
class FiltersListActivator {
     //tslint:disable-next-line: no-unused-variable
    private static _instance = new FiltersListActivator();

    private constructor() {
        Routing.instance.onChange(async route => {
            if (Routing.instance.isMatch("filters/-", route)) {
                const { FiltersListElement } = await import( /* webpackChunkName: "filters", preload: true */ "./FiltersListElement");
                const element = new FiltersListElement();
                
                await AppContextStore.instance.present("filters", element, `Filters`);
            }
        });
    }
}