import * as dialogStyle from "../../styles/dialog-module.less";
import * as tagStyle from "./TagSelector-module.less";
import { LinkTagChangeGram } from "features/links";
import { TagStore } from "features/tags/TagStore";

class TagSelectorElement extends HTMLElement {
    private named = <T extends HTMLElement> (name: string): T => this.querySelector(`[name=${name}]`) as T;
    private searchBox: HTMLInputElement;
    private createText: HTMLElement;
    private tagList: HTMLElement;
    private applyButton: HTMLButtonElement;
    private changeGram: LinkTagChangeGram = {
        added: [],
        removed: []
    }

    private newTags: string[] = [];
    
    static get observedAttributes() { return ["state"]; }

    private fullySelected() {
        return this.getAttribute("fully-selected") ? this.getAttribute("fully-selected").split(",") : [];
    }

    private partialSelected() {
        return this.getAttribute("partial-selected") ? this.getAttribute("partial-selected").split(",") : [];
    }

    async attributeChangedCallback(attrName, oldVal, newVal) {
        if (attrName === "state") {
            if (this.applyButton)
                this.applyButton.setAttribute("state", newVal);
        }    
    }

    async connectedCallback() {
        if (!this.hasAttribute("as-popup"))
            this.className += ` ${dialogStyle.frame}`;

        // render
        this.innerHTML = this.view();

        // grab
        this.searchBox = this.named("search-box");
        this.createText = this.named("create-text");
        this.tagList = this.named("tag-list");
        this.applyButton = this.named("apply");

        // events
        this.addEventListener("click", this.clickHandler);
        this.searchBox.addEventListener("input", this.searchInputHandler);
        this.addEventListener("selected", this.tagSelectedHandler);
        this.addEventListener("partial-selected", this.tagPartialSelectedHandler);
        this.addEventListener("deselected", this.tagDeselectedHandler);
        this.applyButton.addEventListener("click", this.applyButtonHandler);
        this.createText.addEventListener("click", this.addTagHandler);

        setTimeout(async () => {
            await this.suggest("");
        });
    }

    disconnectedCallback() {
        // events
        this.removeEventListener("click", this.clickHandler);
        this.searchBox.removeEventListener("input", this.searchInputHandler);
    }

    private addTagHandler = async () => {
        const tag = this.searchBox.value.trim();
        this.newTags.push(tag);
        this.changeGram.added.push(tag);
        this.searchBox.value = "";
        await this.searchInputHandler();
        this.checkForChanges();
    }

    private applyButtonHandler = () => {
        this.applyButton.setAttribute("state", "busy");
        this.dispatchEvent(new CustomEvent("apply", {detail: this.changeGram }));
    }

    private checkForChanges = () => {
        if (this.changeGram.added.length === 0 && this.changeGram.removed.length === 0)
            this.applyButton.setAttribute("disabled", "");
        else
            this.applyButton.removeAttribute("disabled");
    }

    private tagPartialSelectedHandler = (event: CustomEvent<string>) => {
        // remove from potential removed
        this.changeGram.removed = this.changeGram.removed.filter(m => m !== event.detail);
        
        // remove from potential added
        this.changeGram.added = this.changeGram.added.filter(m => m !== event.detail);
        
        this.checkForChanges();
    }


    private tagSelectedHandler = (event: CustomEvent<string>) => {
        // remove from potential removed
        this.changeGram.removed = this.changeGram.removed.filter(m => m !== event.detail);
        
         // check for change
        if (this.fullySelected().indexOf(event.detail) === -1)
            this.changeGram.added.push(event.detail);
        
        this.checkForChanges();
    }

    private tagDeselectedHandler = (event: CustomEvent<string>) => {
        // remove from potential added
        this.changeGram.added = this.changeGram.added.filter(m => m !== event.detail);

        // check for change
        if (this.fullySelected().indexOf(event.detail) !== -1 || this.partialSelected().indexOf(event.detail) !== -1)
            this.changeGram.removed.push(event.detail);
        
        this.checkForChanges();
    }

    private tagListView = (tags: string[], fullySelected: string[], partialSelected: string[]) => `${tags.map(tag => this.tagView(tag, fullySelected, partialSelected)).join("")}`;

    private searchInputHandler = async () => {
        const value = this.searchBox.value;

        if (value.length === 0) {
            this.createText.innerHTML = "";
        } else {
            this.createText.innerHTML = this.createTextView(value);
        }
            
        await this.suggest(value);
    }

    private async suggest(value) {
        const tags = await TagStore.instance.suggest(value, [""]);
        const allTags = this.newTags.concat(tags);
        this.tagList.innerHTML = this.tagListView(allTags, this.fullySelected(), this.partialSelected());
    }

    private createTextView = (value: string) => `Create new tag ${value}`;

    private clickHandler = (event: UIEvent) => {
        if (!(event.target instanceof Element))
            return;
        
        const button = (event.target as Element).closest("button");
        if (button && button.name === "close") {
            this.remove();
            return;
        }

        const tag = (event.target as Element).closest("[name=tag]");
        if (!tag)
            return

        if (tag.hasAttribute("three-state")) {
            if (tag.hasAttribute("selected")) {
                tag.setAttribute("title", this.getTitle("off"));
                tag.removeAttribute("selected");
                this.dispatchEvent(new CustomEvent("deselected", { detail: tag.getAttribute("tag-value") }));
            } else if (tag.hasAttribute("partial-selected")) {
                tag.setAttribute("selected", "");
                tag.removeAttribute("partial-selected");
                tag.setAttribute("title", this.getTitle("on"));
                this.dispatchEvent(new CustomEvent("selected", { detail: tag.getAttribute("tag-value") }));
            } else {
                tag.setAttribute("partial-selected", "");
                tag.setAttribute("title", this.getTitle("partial"));
                this.dispatchEvent(new CustomEvent("partial-selected", { detail: tag.getAttribute("tag-value") }));
            }
        } else {
            if (tag.hasAttribute("selected")) {
                tag.setAttribute("title", this.getTitle("off"));
                tag.removeAttribute("selected");
                this.dispatchEvent(new CustomEvent("deselected", { detail: tag.getAttribute("tag-value") }));
            } else {
                tag.setAttribute("title", this.getTitle("on"));
                tag.setAttribute("selected", "");
                this.dispatchEvent(new CustomEvent("selected", { detail: tag.getAttribute("tag-value") }));
            }
        }
    }

    private getTitle(state: "on" | "partial" | "off") {
        switch(state) {
            case "on": return "Add tag";
            case "off": return "Remove tag";
            case "partial": return "Leave as is";
        }
    }

    private tagView = (tag: string, fullySelected: string[], partialSelected: string[]) => {
        
        const isPartial = partialSelected.some(f => f.toLowerCase() === tag.toLowerCase());
        let isSelected = fullySelected.some(f => f.toLowerCase() === tag.toLowerCase());
        let isPartialSelected = isPartial;
        

        if (this.changeGram.removed.some(f => f === tag)) {
            isSelected = false;
            isPartialSelected = false;
        }

        if (this.changeGram.added.some(f => f === tag)) {
            isSelected = true;
            isPartialSelected = false;
        }        

        return `
        <div class="${dialogStyle.listItem} ${dialogStyle.selectable}" 
             tag-value="${tag}"
             name="tag"
             ${isSelected ? "selected" : ""} 
             ${isPartialSelected ? "partial-selected" : ""}
             ${isPartial ? "three-state" : ""}
             title="${this.getTitle(isPartial ? "partial" : (isSelected ? "on" : "off"))}">
            <div class="${dialogStyle.titleField} ${tagStyle.titleField}">${tag}</div>
            <div class="${dialogStyle.checkField}">
                ${require("!!raw-loader!image/fa-check.svg")}
            </div>
        </div>
    `};

    private view = () => `
        <div class="${dialogStyle.window}">
            <header class="${dialogStyle.header}">
                <div>Tag</div>
                <div>${this.getAttribute("sub-title")}</div>
                <button type=button class="${dialogStyle.closeButton}" name=close>${require("!!raw-loader!image/close-icon.svg")}</button>
            </header>
            <div class="${dialogStyle.fixedArea}">
                <div class="${dialogStyle.listItem}" name="search-field">
                    <div class="${dialogStyle.imageField} ${dialogStyle.faded}">
                        ${require("!!raw-loader!image/search-20-v2.svg")}
                    </div>
                    <div class="${dialogStyle.inputField}">
                        <input placeholder="Search tags" name=search-box />
                    </div>       
                </div>
                <div class="${dialogStyle.listItem}" name="create-field">
                    <div class="${tagStyle.createText}" name=create-text></div>
                </div>
            </div>
            <div class="${dialogStyle.scrollArea}" name="tag-list">
                
            </div>
            <div class="${dialogStyle.fixedArea} ${tagStyle.buttonArea}">
                <progress-button 
                    disabled 
                    name=apply
                    busy-text="Applying ..." 
                    success-text="Applied"
                    disable-query="">Apply</progress-button>
                
            </div>
        </div>
    `;
}

customElements.define("tag-selector", TagSelectorElement);