import { SidebarActivator } from "features/side-bar/SidebarActivator";

//tslint:disable-next-line: no-unused-variable
class HelpActivator {
    //tslint:disable-next-line: no-unused-variable
    private static _instance = new HelpActivator();

    private constructor() {
        document.addEventListener("click", async event => {
            try {

                const target = <HTMLElement>(event.target || event.currentTarget);

                if (!target)
                    return;

                const helpLink = target.closest("[help-link]");

                if (helpLink) {
                    event.preventDefault();
                    const sidebar = document.createElement("help-sidebar");
                    sidebar.setAttribute("help-topic", helpLink.getAttribute("help-link"));
                    SidebarActivator.showElement(sidebar);
                }
            }
            catch (e) {
                console.log(e);
            }

        });
    }
}
