import { UrlProvider } from "lib/UrlProvider"
import { IApplicationRegistration } from "features/manage/authorized-applications/IApplicationRegistration";
import { Pipeline, Request } from "lib/HTTP";
import { EventEmitter } from "events";

export class AuthorizedApplicationsStore extends EventEmitter {
    private static _instance: AuthorizedApplicationsStore = new AuthorizedApplicationsStore();
    private urlProvider: UrlProvider;

    private constructor() {
        super();
        this.urlProvider = new UrlProvider();
    }
    
    public static get instance(): AuthorizedApplicationsStore {
        return AuthorizedApplicationsStore._instance;
    }

    async getAll(): Promise<IApplicationRegistration[]> {
        const response = await new Pipeline().fetch(`${this.urlProvider.root}/api/authorized-applications`, Request.get.authenticate());
        return await response.json() as IApplicationRegistration[];
    }

    async approveApp(app: IApplicationRegistration, appId: string, appKey: string) {
        const url = `${this.urlProvider.root}/api/authorized-applications?appId=${appId}&appKey=${appKey}`;

        const response = await new Pipeline().fetch(url, Request.post.authenticate());
        const updatedApp = await response.json() as IApplicationRegistration;

        this.emitAppUpserted(updatedApp);
        
        if (app.name.toLocaleLowerCase().indexOf("bookmarklet") !== -1)
            window.location.href = "/bookmarklet/afterlogin";
        else
            window.location.href = "/manage/authorized-applications";
    }

    async revoke(installationId: string) {
        const url = `${this.urlProvider.root}/api/authorized-applications/${installationId}/_revoke`;

        const response = await new Pipeline().fetch(url, Request.post.authenticate());
        const updatedApp = await response.json() as IApplicationRegistration;

        this.emitAppUpserted(updatedApp);
    }

    private emitAppUpserted(app: IApplicationRegistration) {
        this.emit("AppUpserted", app);
    }

    public onAppUpserted(callback: (app: IApplicationRegistration) => void) {
        this.on("AppUpserted", callback);
    }

}
