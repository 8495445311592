
export class LinkSkeletonElement extends HTMLElement {
    connectedCallback() {
        this.innerHTML = this.skeletonView();
    }

    private skeletonView = () => `
        <div class="preview">
            <div class="bone"></div>
        </div>
        <div class="detail">
            <div class="upper">
                <div class="top-info">
                    <div class="bone" style="width:70px;height:14px;margin-bottom:8px;margin-top:3px"></div>
                </div>
                <div>
                    <h2>
                        <div class="bone" style="width:100%;height:21px;background-color:#ececec;margin-bottom:4px;"></div>
                    </h2>

                
                    <div class="url">
                        <div class="bone" style="width:300px;height:12px;background-color:#f9d8d8"></div>
                    </div>
                </div>
            </div>

            <div class="description">
                <div class="bone" style="width:100%;height:40px;;margin-top:8px;margin-bottom: 8px;"></div>
            </div>
        </div>
    `;
}

customElements.define("link-skeleton", LinkSkeletonElement);

