// import { AppsStore } from "features/apps-index/AppsStore";

import { Package } from "features/packages/Package";
import { PackageStore } from "features/manage/PackageStore";
import "./BasePackageElement-style";
import "./BasePackageElementForms-style";
export class BasePackageElement extends HTMLElement {
    protected slug: string;
    protected appsStore: import("features/apps-index/AppsStore").AppsStore;

    async connectedCallback() {
        this.appsStore = (await import( /* webpackChunkName: "stacks" */ "features/apps-index/AppsStore")).AppsStore.instance;

        this.classList.add("package");
        await this.initialize();
        const packages = (await this.appsStore.all()).concat(new PackageStore().all());
        const thePackage = packages.filter(p => p.slug === this.slug)[0];
        this.innerHTML = this.view(thePackage);
        this.setupDOM()
    }

    protected async initialize() {
        
    }
    
    protected setupDOM() {

    }

    protected detailView = (thePackage: Package) => ``; 
    
    

    protected navView = () => `
        <nav><a href="/apps" route>${require("!!raw-loader!image/icons/header/arrow-left-20-v1.svg")} Apps</a></nav>
    `;

    private view = (thePackage: Package) => `       
        ${this.navView()}
    
        <header style="background-image:url('data:image/svg+xml;utf8,${encodeURIComponent(this.appsStore.logo(thePackage.logo) ? this.appsStore.logo(thePackage.logo) : thePackage.logo)}')">
        
        
                
        </header>
        <div class=content>
            <h1>${thePackage.name}</h1>
            ${thePackage.version ? `<div class="version">${thePackage.version}</div>` : ""}
            
            <p>${thePackage.description}</p>
            
            <div class=detail>
                ${this.detailView(thePackage)}
            </div>
        </div>
    `;
}
