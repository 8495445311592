import { NotifyViewController, NotificationMessage } from "features/notification/NotifyViewController"

export class NotifyNativeViewController extends NotifyViewController {
    

    show(message: NotificationMessage) {

        if (!this.showNative(message))
            if (window)
                this.showWeb(message);
    }

    showNative(message: NotificationMessage): boolean {
        const notification = (<any>window).Notification;
        
        const show = () => {
            try {
                const n = new notification(message.title, { body: message.body, icon: message.icon });
                if (message.showSeconds)
                    setTimeout(() => n.close(), message.showSeconds * 1000);
            } catch (e) {
                if (e.name === "TypeError") // see https://stackoverflow.com/questions/29774836/failed-to-construct-notification-illegal-constructo
                    return false;
            }
            
            return true;
        };
            
        if (!("Notification" in window)) 
            return false;

        if (!notification) {
            return false;
        } else if (notification.permission === "granted") {
            return show();
        } else if (notification.permission !== "denied") {
            notification.requestPermission(permission => { }); // for next run
            return false;
        }

        return false;
    }


}
