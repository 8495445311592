import { Routing } from "lib/Routing";
import { DialogController } from "features/dialog";
import { CollaborationStore, Invite } from "features/collaboration/CollaborationStore";
import { AuthenticationStore } from "features/authentication";
import { StackStore } from "features/stacks/StackStore";
import { AlternateEmailsStore } from "features/manage/alternate-emails/AlternateEmailsStore";

//tslint:disable-next-line: no-unused-variable
class CollaborationActivator {
    //tslint:disable-next-line: no-unused-variable
    private static _instance = new CollaborationActivator()
    constructor() {
        Routing.instance.onChange(async route => {
            if (route.path[0] === "collaboration" && route.path[1] === "accept-invite") {

                const stackId = decodeURIComponent(route.parameters.stackId);
                const token = decodeURIComponent(route.parameters.token);
                const invite = await CollaborationStore.instance.getInvite(stackId, token);

                if (!invite || invite.status !== "ok") {
                    await this.presentError(invite);
                    return;
                }

                const response = await new DialogController().prompt({
                    caption: "Accept invitation to edit",
                    message: `Accept inivitation and add the stack '${invite.title}' to your stacks?`,
                    options: ["Cancel", "Accept invitation"]
                });

                if (response === "Accept invitation") {
                    const acceptResponse = await CollaborationStore.instance.acceptInvite(stackId, token);

                    if (!acceptResponse || acceptResponse.status !== "ok") {
                        await this.presentError(acceptResponse);
                        return;
                    }

                    const user = await AuthenticationStore.instance.currentUser();
                    
                    const stack = await StackStore.instance.getStackBySlug(user.userId, acceptResponse.stackSlug);
                    StackStore.instance.signalCreated(stack);
                }
            }
        });
    }


    private presentError = async (invite: Invite) => {
        if (!invite || invite.status === "invitation-not-found") {
            await new DialogController().prompt({
                caption: "Error accepting invitation",
                message: `The invitation does not exist.`,
                options: ["OK"]
            });
            return;
        }

        switch (invite.status) {
            case "invalid-token":
                await new DialogController().prompt({
                    caption: "Invalid invitation",
                    message: `Provided invitation does not seem to be valid. Please try again.`,
                    options: ["OK"]
                });
                break;
            case "email-does-not-match":
                const response = await new DialogController().prompt({
                    caption: "Accept invitation to edit",
                    message: `Provided invitation does not match your registered email but you can add ${invite.emailAddress} to your account`,
                    options: ["Cancel", "Add email"]
                });

                if (response === "Add email") {
                    const result = await AlternateEmailsStore.instance.add(invite.emailAddress);

                    const showError = async (message) => {
                        await new DialogController().prompt({
                            caption: "Add alternate email",
                            message: message,
                            options: ["OK"]
                        });
                    }

                    switch(result.status) {
                        case "ok": 
                            await showError("Please check your email.");
                            break;
                        case "conflict": 
                            await showError("That address is already on your account.");
                            break;
                        default:
                            await showError("Unable to add address.");
                            break;
                    }
                }

                break;
            default:
                await new DialogController().prompt({
                    caption: "Error accepting invitation",
                    message: `Unknown error, please try again.`,
                    options: ["OK"]
                });
                return;
        }
    }
}


