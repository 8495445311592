import * as cardsStyle from "../../../../styles/cards-module.less"
import "./InfoWidgetElement-style";
import { BaseWidget, PreferSize } from "../BaseWidget";
import { HubStore } from "features/hub/HubStore";

class InfoWidgetElement extends BaseWidget {
    static Tag = "info-widget";
    
    public get preferredWidth(): PreferSize {
        return "wide";
    }
    
    async connectedCallback() {
        this.className += ` ${cardsStyle.cardContainer}`;
        this.innerHTML = this.view();
        super.connectedCallback();
    }

    disconnectedCallback() {
        super.disconnectedCallback();
    }

    protected async modeChange(mode: string) {
        if (mode === "hide") {
            await HubStore.instance.hide(InfoWidgetElement.Tag);
        }
    }
    
    private view = () => `
        ${this.headerView("Welcome to your hub!", [{ value: "hide", label: "Hide" }])}
    
        <div class="info">
                ${require("!!raw-loader!image/robot_lean.svg")}

                <p>
                    The hub is your starting point and will populate as you use Linkstacks. 
                    Besides showing your newest additions it will show your usage patterns and  tell you when it is the best time to check on your feed subscriptions. 
                </p>
                <p>New to Linkstacks? See getting started <a href="/help#what-is-linkstacks" help-link="what-is-linkstacks">here</a>.</p>
            
        </div>
    `;
}

customElements.define(InfoWidgetElement.Tag, InfoWidgetElement);