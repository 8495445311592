import "./EntryControlElement-style";
import { MyFeedStore } from "features/feeds/MyFeedStore"; 
import { Feed, Entry } from "features/feeds";
import { IHighlighted, Highlight } from "lib/Model";
import { Formatting } from "lib/Formatting";
import { DOM } from "lib/DOM";
import { LinkStore } from "features/links";
import ResizeObserver from "resize-observer-polyfill";

class EntryControlElement extends HTMLElement {
    private escape = Formatting.htmlEscape;
    private entry: Entry & IHighlighted;
    private lazy = () => this.hasAttribute("lazy");
    private bodyElement: HTMLElement;
    private footerElement: HTMLElement;
    private isOpen = false;
    private timer; 
    private hammer: HammerManager;
    private resizeObserver: ResizeObserver;

    static get observedAttributes() { return ["open"]; }

    async attributeChangedCallback(attrName, oldVal, newVal) {
        if (attrName === "open") {
            if (!this.hasAttribute("rendered"))
                return;

            if (this.isOpen)
                await this.doClose();
            else
                await this.doOpen();
        }    
    }

    async connectedCallback() {
        const jsonElement = this.querySelector("script[type='application/json+entry']") as HTMLElement;
        this.entry = JSON.parse(jsonElement.innerText) as Entry & IHighlighted;
        

        requestAnimationFrame(async () => {
            this.insertAdjacentHTML("beforeend", this.view(this.entry));
            const fullEntry = this.querySelector(".full-entry");
            const img = fullEntry.querySelector("img");
            img.addEventListener("load", () => fullEntry.setAttribute("loaded", ""));
            
            if (this.hasAttribute("open"))
                await this.doOpen();

            this.scheduleTitleUpdate();
        });
        
        this.setAttribute("sequence", this.entry.sequence.toString());
        this.setAttribute("entry-feed-id", this.entry.feedId);
        this.setAttribute("entry-slug", this.entry.slug);

        if (this.entry.read)
            this.setAttribute("read", "");
        else
            this.removeAttribute("read");

        this.addEventListener("click", this.clickHandler);

        
        window.requestIdleCallback(this.setupHammerJS);
        window.requestIdleCallback(() => this.resizeHandler(this.clientWidth));
        this.resizeObserver = new ResizeObserver((entries) => {
            requestAnimationFrame(() => this.resizeHandler(entries[0].contentRect.width));
        });
        this.resizeObserver.observe(this);    
        
        this.toggleAttribute("rendered", true);
    }

    private setupHammerJS = async () => {
        const HammerJS = await import( /* webpackChunkName: "hammer" */ "hammerjs");

        const Hammer = (<any>HammerJS).default

        //const { Hammer } = await import( /* webpackChunkName: "hammer" */ "hammerjs");

        this.hammer = new Hammer.Manager(this, {
            touchAction: "auto"
        });

        this.hammer.add(new Hammer.Pan({ threshold: 2 })); //, direction: Hammer.DIRECTION_VERTICAL
        
        this.hammer.on("panleft", this.leftPanHandler);
        this.hammer.on("panright", this.rightPanHandler);
    }

    disconnectedCallback() {
        this.removeEventListener("click", this.clickHandler);

        if (this.hammer) {
            this.hammer.off("panleft", this.leftPanHandler);
            this.hammer.off("panright", this.rightPanHandler);
            this.hammer.destroy();
        }

        if (this.resizeObserver)
            this.resizeObserver.disconnect();
    }

    private resizeHandler = (containerWidth: number) => {
        this.style.setProperty("--max-width", `${containerWidth}px`); 
    };

    private async doOpen() {
        this.isOpen = true;
        
        window.clearTimeout(this.timer);

        if (!this.bodyElement) {
            const template = this.querySelector("template[name=description]") as HTMLTemplateElement;
            this.bodyElement = document.createElement("div");
            this.bodyElement.classList.add("body");


            let clone: DocumentFragment;
            if (template.content) {
                clone =  document.importNode(template.content, true);
            } else {
               const temp = template.cloneNode(true) as HTMLElement; // IE 11
               clone = document.createDocumentFragment();
               [].slice.call(temp.childNodes).forEach(child => clone.appendChild(child))
            }

            this.bodyElement.appendChild(clone)
            this.appendChild(this.bodyElement); 
            this.footerElement = DOM.parse(this.footerView());
            this.appendChild(this.footerElement);
            
        }
        

        if (!this.hasAttribute("open")){
            requestAnimationFrame(() => {
                this.setAttribute("open", "");
            });
        }
            
        this.dispatchEvent(new CustomEvent("open", { bubbles:true }));
        await MyFeedStore.instance.markRead(this.entry.feedId, this.entry.sequence);
    }

    private async doClose() {
        this.isOpen = false;
        this.timer = setTimeout(() => {
            if (this.bodyElement) {
                this.removeChild(this.bodyElement);
                this.bodyElement = undefined;
            }

            if (this.footerElement) {
                this.removeChild(this.footerElement);
                this.footerElement = undefined;
            }
                
        }, 0);

        this.dispatchEvent(new CustomEvent("close", { bubbles:true }));
    }

    private scheduleTitleUpdate() {
        window.requestIdleCallback(this.updateFeedTitle);
    }

    private updateFeedTitle = async () => {
        if (!this.entry)
            return;

        const feedTitleElement = this.querySelector("[name=feed-title]") as HTMLElement;
        if (!feedTitleElement)
            return;

        const entryFeed = await MyFeedStore.instance.getFeed(this.entry.feedId);
        if (!entryFeed)
            return;
            
        feedTitleElement.innerText = entryFeed.title;
    }

    

    private async throttle(action: () => Promise<any>) {
        if (this.throttler)
            return;

        this.throttler = true;

        setTimeout(() => this.throttler = false, 100);
        await action();
    }

    private throttler = false;
    private leftPanHandler = async () => {
        if (this.isOpen)
            return;

        await this.throttle(async () => await MyFeedStore.instance.markRead(this.entry.feedId, this.entry.sequence));
    }

    private rightPanHandler = async () => {
        if (this.isOpen)
            return;
            
        await this.throttle(async () => await MyFeedStore.instance.markUnread(this.entry.feedId, this.entry.sequence));
    }

    private clickHandler = async (event: UIEvent) => {
        if ((event.target as HTMLElement).hasAttribute("save-url")) {
            await LinkStore.instance.addAndForget(this.entry.url);
            return;
        }

        if (this.hasAttribute("open") && (event.target as HTMLElement).closest("a"))
            return;

        event.preventDefault();

        const parent = (event.target as HTMLElement).closest(".summary") || (event.target as HTMLElement).closest("header") || (event.target as HTMLElement).closest("[name=expand-entry]");
        if (parent) {
            if (this.hasAttribute("open")) {
                this.removeAttribute("open");
            }
            else {
                this.setAttribute("open", "");
            }  
        }
    };

    private footerView = () => `
        <footer class=forms3>
            <button save-url class=low>Save article link</button>
        </footer>
    `;

    private view = (entry: Entry & IHighlighted, entryFeed: Feed = undefined) => this.escape`
        <template name=description>
            $${entry.description}
        </template>
        <div class=full-entry>
            <div name=overlay style="${entry.picoImage ? `background-image:url(data:${entry.picoImage}` : ""}); width:40px; height:40px; ${!entry.picoImage ? `background-color:#${entry.picoColor};` : ""}"></div>
            <img $${this.lazy() ? `data-src="${entry.image}"` : `src="${entry.image}"`}"  />
            <div class=entry>
                <div name=top-part>
                    
                    <div class=intro>
                        <header>
                            <active-anchor>
                                <a rel="noopener noreferrer" target=_blank href="${entry.url}">$${Highlight.property(entry, e => e.title)}</a>
                            </active-anchor>
                        </header>
                        <div class=meta><span name=feed-title>${entryFeed ? entryFeed.title : "missing feed title"}</span> ○ <auto-time datetime="${entry.publishDate}"></auto-time></div>
                    </div>
                    <button name="expand-entry">${require("!!raw-loader!image/arrow-down-light-20-v1.svg")}</button>
                </div>
                $${entry.highlights && entry.highlights.description ? `<div class="highlights">… ${entry.highlights.description} …</div>` : ""}
                <!-- summery can be added here for other views -->
            </div>
        </div>`;
}

customElements.define("entry-control", EntryControlElement);