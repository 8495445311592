import { StackStore } from "features/stacks/StackStore"

export class StackHelper {
    static resolveStacks() {
        window.requestIdleCallback(async () => await this.doResolveStacks(), { timeout: 500 });
    }

    static async doResolveStacks() {
        const missingElements = Array.prototype.slice.apply(document.querySelectorAll(".stack-ref:not(.resolved)"));
        const slugs = missingElements.map(m => m.attributes.getNamedItem("data-stack").value);
        const stackStore = StackStore.instance;
        const stacks = await stackStore.getStacks(slugs);
            
        missingElements.forEach(missingElement => {
            const stackSlug = missingElement.getAttribute("data-stack");
            const stack = stacks.filter(m => m.slug === stackSlug)[0];
            const stackTitleElement = <HTMLElement>missingElement.querySelector(".stack-title");

            if (stack && stackTitleElement) {
                stackTitleElement.innerText = stack.title;
                missingElement.classList.add("resolved");
            } else {
                missingElement.classList.add("resolved");
            }
        });
    }
}