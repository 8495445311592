import { UrlProvider } from "lib/UrlProvider";
import { ILoginProvider } from "./ILoginProvider";
import { LoginResponse } from "../Model";
import { Pipeline, Request } from "lib/HTTP";
// import { AppleAuthConfig } from "apple-auth";
// import AppleAuth from "apple-auth";
import { Plugins, PluginListenerHandle } from "@capacitor/core";
import { BrowserInfo } from "lib/Browser";
import { Browser } from '@capacitor/browser';
import { InAppBrowser } from '@capgo/inappbrowser'
import { App } from '@capacitor/app';

export class AppleAndroidLoginProvider implements ILoginProvider {
    getName = () => "AppleAndroidLoginProvider";
    appSendActionIntent
    login(): Promise<LoginResponse> {
        let appListener: PluginListenerHandle;
        let browserListener: PluginListenerHandle;

        return new Promise(async (resolve, reject) => {
            try {

                appListener = App.addListener("appUrlOpen", async (data) => {

                    console.log("App url open", data);

                    appListener?.remove();

                    console.log("Url open", data);

                    const prefix = "linkstacks://apple/?token=";
                    if (data.url.startsWith(prefix)) {
                        const tokenId = data.url.substring(prefix.length);
                        try {
                            await InAppBrowser.close();
                        } catch (error) {
                            console.log("Call to close browser failed", error);
                        }
                        
                        const response = await fetch(`${new UrlProvider().root}/api/external-login/apple`, {
                            method: "POST",
                            credentials: "include",
                            headers: {
                                "content-type": "text/json"
                            },
                            body: JSON.stringify({
                                TokenId: tokenId
                            })
                        });

                        if (!response.ok)
                            reject("Unable to login");

                        const loginResponse = await response.json() as LoginResponse;
                        resolve(loginResponse);
                    }
                });

                browserListener = (<any>InAppBrowser).addListener("browserFinished", () => {
                    browserListener?.remove();
                    reject("Window closed");
                });

                await InAppBrowser.open({
                    url: `${new UrlProvider().root}/api/external-login/apple`,
                    //presentationStyle: "popover" // well, not on android really
                });

                // const response = await fetch(`${new UrlProvider().root}/api/external-login/apple`, {
                //     method: "POST",
                //     credentials: "include",
                //     headers: {
                //         "content-type": "text/json"
                //     },
                //     body: JSON.stringify({
                //         //tokenId: data.authorization.id_token
                //     })
                // });

                // if (!response.ok)
                //     reject("Unable to login");

                // const loginResponse = await response.json() as LoginResponse;
                // resolve(loginResponse);
            }
            catch (error) {
                reject("Unable to login");
            }
        });
    }
}

interface AuthResponse {
    authorization: Authorization
}

interface Authorization {
    code: string;
    id_token: string;
}


console.log("launch");

const checkAppLaunchUrl = async () => {
    const { url } = await App.getLaunchUrl();
  
    console.log('App opened with URL: ' + url);
};
  
checkAppLaunchUrl();