import "./LinkControlElement-style";
import { DOM } from "lib/DOM";
import { DialogController } from "features/dialog";
import { ElementDataWatcher2 } from "lib/ElementDataWatcher2";
import { Formatting } from "lib/Formatting";
import { Highlight, IHighlighted } from "lib/Model";
import { ImageSelectorViewController } from "features/image-selector/ImageSelectorViewController";
import { Link, LinkStore, LinkAddStatus } from "features/links";
import { StackStore } from "features/stacks/StackStore";
import { BulkLinkStore } from "./BulkLinkStore";


class LinkControlElement extends HTMLElement {
    private escape = Formatting.htmlEscape;
    private isEditing = () => this.hasAttribute("editing");
    private setEditing = () => this.setAttribute("editing", "");
    private unsetEditing = () => this.removeAttribute("editing");
    private isRendered = () => this.hasAttribute("rendered");
    private setRendered = () => this.setAttribute("rendered", "");
    private showImage = () => this.hasAttribute("show-image");
    private showStack = () => this.hasAttribute("show-stack");
    private allowEdit = () => this.hasAttribute("allow-edit");
    private linkWatcher: ElementDataWatcher2<Link & IHighlighted>; 
   
    private removeChildrenButScripts() {
        [].slice.call(this.children).forEach( (element: HTMLElement) => {
            if (element.localName !== "script")
                element.remove();
        });
    }

    private refreshHandler = () => {
        let tagEditor = this.querySelector("tag-editor");
        tagEditor?.removeEventListener("tags-changed", this.tagsChangedHandler);
        const link = this.linkWatcher.data;
        const html = this.isEditing() ? this.editView(link) : this.viewView(link, this.showImage(), this.showStack(), this.allowEdit()); 
        this.setAttribute("draggable", (!this.isEditing()).toString());

        this.toggleAttribute("favorite", !!link.favorite);

        this.removeChildrenButScripts();
        this.insertAdjacentHTML("beforeend", html);
        tagEditor = this.querySelector("tag-editor");
        tagEditor?.addEventListener("tags-changed", this.tagsChangedHandler);
        this.setRendered();
    };

    connectedCallback() {
        if (this.hasAttribute("static"))
            return;

        this.classList.add("link");
        this.setAttribute("draggable", "true");
        this.addEventListener("click", this.clickHandler);
        this.addEventListener("context-item-click",  this.contextMenuHandler);
        this.addEventListener("dragend",  this.dragEndHandler);
        this.addEventListener("dragstart", this.dragStartHandler);
        
        this.linkWatcher = new ElementDataWatcher2<Link & IHighlighted>(this, "link");
        this.linkWatcher.addEventListener("changed", this.refreshHandler);   
        
        if (!this.isRendered()) 
           this.refreshHandler();

        if (BulkLinkStore.instance.isSelected(this.id))
            this.setAttribute("marked", "");       
    }

    private tagsChangedHandler = (event: CustomEvent<string>) => {
        const setTags = this.querySelector("input[name=setTags]") as HTMLInputElement;
        if (setTags)
            setTags.value = `;${event.detail}`;
    }

    disconnectedCallback() {
        this.removeEventListener("click", this.clickHandler);
        this.removeEventListener("context-item-click", this.contextMenuHandler);
        this.removeEventListener("dragend",  this.dragEndHandler);
        this.removeEventListener("dragstart", this.dragStartHandler);

        this.linkWatcher?.removeEventListener("changed", this.refreshHandler);
        this.linkWatcher?.destroy();
    }

    private dragEndHandler = (event: DragEvent) => {
        this.classList.remove("dragging");
    };

    private dragStartHandler = (event: DragEvent) => {
        event.stopPropagation(); 
        this.classList.add("dragging");
        const link = this.linkWatcher.data;
        event.dataTransfer.effectAllowed = `copyMove`;
        event.dataTransfer.setData("text", JSON.stringify(link));
    };

    private mark = () => {
        if (this.isEditing())
            return;

        this.toggleAttribute("marked");

        const link = this.linkWatcher.data;

        if (this.hasAttribute("marked"))
            this.dispatchEvent(new CustomEvent("selected", { detail: link, bubbles: true }));
        else
            this.dispatchEvent(new CustomEvent("deselected", { detail: link, bubbles: true }));
        
        
        requestAnimationFrame(() => {
            const element = this.querySelector(".select-overlay svg") as SVGElement;
            element.style.animation = "scale-in .15s";
            const remove = () => {
                element.style.animation = "";
                element.removeEventListener("animationend", remove);
            };
            element.addEventListener("animationend", remove, { once: true });
        });
    }

    private clickHandler = async (event: UIEvent) => {
        const preview = (event.target as HTMLElement).closest(".preview");
        if (preview) {
            this.mark();
            return;
        }

        const button = (event.target as HTMLElement).closest("button");
        if (!button)
            return;

        switch(button.name) {
            case "leave-edit":
                await this.leaveEdit();
                break;

            case "cancel": 
                this.cancelEdit();
                break;
        }

    };

    private cancelEdit() {
        this.unsetEditing();
        this.refreshHandler();
    }

    private async leaveEdit() {
        const form = this.querySelector("form") as HTMLFormElement;

        const newLink = DOM.serialize<Link>(form);

        newLink.stack = this.linkWatcher.data.stack;
        newLink.slug = this.linkWatcher.data.slug;

        this.unsetEditing();

        try {
            this.setAttribute("busy", "");
            const link = await LinkStore.instance.update(newLink) as Link & IHighlighted;
            this.linkWatcher.data = link;
        } catch (error) {
            await new DialogController().prompt({
                caption: "Unable to save changes",
                message: "Sorry we are unable to save changes. The error has been logged and we will look into it.",
                options: ["Ok"]
            });
        } finally{
            this.removeAttribute("busy");
        }
    }

    private contextMenuHandler = async (event: CustomEvent) => {
        switch (event.detail) {
            case "edit": 
                this.setEditing();
                this.refreshHandler();
                break;
            case "move-to-stack": 
                await this.moveLinkToStack();
                break;
            case "copy-to-stack": 
                await this.copyLinkToStack();
                break;
            case "refresh-metadata": 
                try {
                    this.setAttribute("refreshing", "");
                    await LinkStore.instance.refreshMetadata(this.linkWatcher.data);
                } finally {
                    this.removeAttribute("refreshing");
                }
                break;
            case "delete":
                try {
                    this.setAttribute("deleting", "");
                    await LinkStore.instance.delete(this.linkWatcher.data);
                } catch(error) {
                    this.removeAttribute("deleting");
                }
                break;
            default:
                throw new Error(`Unknown item '${event.detail}'.`);
        }
    };

    private async copyLinkToStack() {
        const link = this.linkWatcher.data;
        const allLinks = await LinkStore.instance.getLink(link.slug);
        const stackSelector = document.createElement("stack-selector-2");
        stackSelector.setAttribute("value", allLinks.map(i => i.stack).join(","));
        stackSelector.setAttribute("title", "Copy link to stack(s)");
        stackSelector.setAttribute("select-only", "");
        stackSelector.setAttribute("exclude", link.stack);
        stackSelector.setAttribute("description", link.title);
        document.body.insertAdjacentElement("beforeend", stackSelector);
        stackSelector.addEventListener("deselected", async (arg: CustomEvent) => await LinkStore.instance.removeFromStack(link.slug, arg.detail));
        stackSelector.addEventListener("selected", async (arg: CustomEvent) => await LinkStore.instance.copyToStack(link.slug, link.stack, arg.detail));
    }

    private async moveLinkToStack() {
        const link = this.linkWatcher.data;
        const currentStack = await StackStore.instance.lookupStack(link.stack);
        const stackSelector = document.createElement("stack-selector-2");
        stackSelector.setAttribute("exclude", this.linkWatcher.data.stack);
        stackSelector.setAttribute("select-only", "");
        stackSelector.setAttribute("title", "Move link to stack");
        stackSelector.setAttribute("description", currentStack.title);
        document.body.insertAdjacentElement("beforeend", stackSelector);
        stackSelector.addEventListener("selected", async (arg: CustomEvent) => {
            stackSelector.remove();
            try {
                this.setAttribute("deleting", "");
                const result = await LinkStore.instance.moveToStack(link.slug, link.stack, arg.detail);

                if (result === LinkAddStatus.UrlAlreadyExists) {
                    this.removeAttribute("deleting");
                    await new DialogController().prompt({
                        caption: "Unable to move link",
                        message: "The link already exists in target stack.",
                        options: ["Ok"]
                    });
                }
            } catch(error) {
                this.removeAttribute("deleting");
            }
        });
    }

    private  viewView = (link: Link & IHighlighted, showImage = true, showStack = false, allowEdit = true) => this.escape`
        $${showStack ? `<stack-link stack-id="${link.userId}/${link.stack}" link-id="${link.id}"></stack-link>` : ""}

        <div class="preview">
            <div class="img-holder">
                <div class="select-overlay">
                    $${require("!!raw-loader!image/check-marked.svg")}
                </div>
                <progressive-image show-loader lazy-load pico-image="${link.picoImage}" source-image="${link.preview}">
                   
                </progressive-image>

             
            </div>
        </div>
        <div class="detail">
            <form class="link-form" method="post" action="links/${link.slug}" autocomplete="off">
                <input type="hidden" name="slug" value="${link.slug}" />
                <div class="upper">
                    <div class="top-info">
                        <auto-time as-text datetime="${link.created}">${link.age}</auto-time>
                        <span>${link.visitCount ? (link.visitCount === 1 ? "1 visit" : `${link.visitCount} visits`) : ""}</span>
                    </div>
                    <div>
                        <h2>
                            <a target="_blank" rel="noopener noreferrer" href="${link.url}" class="title" data-track="${link.id}" draggable=false>
                                $${Highlight.property(link, l => l.title)}&nbsp;
                            </a>
                        </h2>

                       
                        <div class="url">
                            <a target="_blank" rel="noopener noreferrer" href="${link.url}" title="${link.prettyUrl}" data-track="${link.id}" draggable=false>
                                ${link.prettyUrl}
                            </a>
                        </div>
                    </div>
                </div>

                $${allowEdit ? `
                    <action-panel link-slug="${link.slug}" stack-slug="${link.stack}">
                        <button type=button name=favorite>${require("!!raw-loader!image/favorite.svg")}</button>
                        <context-menu>
                            <item id="edit" title="Edit"></item>
                            <item id="move-to-stack" title="Move to stack ..."></item>
                            <item id="copy-to-stack" title="Copy to stack ..."></item>
                            <item id="refresh-metadata" title="Refresh metadata"></item>
                            <item id="-"></item>
                            <item id="delete" title="Delete"></item>
                            <button type=button class=icon>
                                ${require("!!raw-loader!image/icons/context-menu.svg")}
                            </button>
                        </context-menu>
                    </action-panel>
                ` : ""}
                
                <div class="description rich">
                    <p>
                        $${Highlight.property(link, l => l.description)}
                    </p>
                </div>
                <div class="status"></div>
            </form>
        </div>
       
        $${this.tagsView(link)}
        
        $${link.highlights && link.highlights.texts ? `<div class="highlights">… ${link.highlights.texts} …</div>` : ""}
        `;

        private tagsView = (link: Link) => `
            <div class=tagging>
                ${(link.tags || []).map(tag => `<tag-button route>${tag}</tag-button>`).join("")}
            </div>
        `;

    private editView = (link: Link) => this.escape`
       <form method="post" action="links/${link.slug}" autocomplete="off"> 
            $${new ImageSelectorViewController().setLink(link).render()}	
            <div class="detail">
                <input type="hidden" name="slug" value="${link.slug}" />
                <div class="upper">
                    <div class="time">
                        <time class="auto-update" datetime="${link.created}">${link.age}</time>
                    </div>
                    <div>
                        <h2>
                            <input type="text" name="title" value="${link.title}" placeholder="Title" class="edit" maxlength="250" />
                        </h2>

                        <div class="url">
                            <a target="_blank" rel="noopener noreferrer" href="${link.url}" title="${link.prettyUrl}" data-track="${link.id}">
                                ${link.prettyUrl}
                            </a>
                        </div>
                    </div>
                </div>

                <div class="context"></div>

                <div class="description">
                    <p>
                        <rich-editor field-name=description placeholder=Description class=edit maxlength=1000>${link.description || ""}</rich-editor>
                    </p>
                </div>

                <div class="status"></div>
            </div>

            
            <input type=hidden name=setTags value="${link.tags.join(";")}" />
            <tag-editor always-open tags="${link.tags.join(";")}" link-id="${link.id}"></tag-editor>

            <div class=button-panel>
                <button type=button name=cancel class="cancel power-inactive">
                    Cancel
                </button>
                <button type=button name=leave-edit class="leave-edit power">
                    Save
                </button>
            </div>
        </form>
    `;
} 

customElements.define("link-control", LinkControlElement);