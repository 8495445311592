import "intersection-observer";

/*
Just ensures that images are loaded, maybe in the future we will prioritireze and stuff
*/ 
export class LazyImageHandler {
    private static enableDebugging = false;
    private imageElements: HTMLImageElement[] = [];
    private idleLoader;
    private observer;
    private intersectionObserver:  IntersectionObserver;
    private _destroyed = false;

    constructor(element: HTMLElement, idleLoad = true) {
        this.intersectionObserver = new IntersectionObserver(this.intersectionHandler, {});

        this.observer = new MutationObserver( (records: MutationRecord[]) => { 
            records.forEach(record => {
                if (!record.addedNodes)
                    return;

                for(let j=0; j<record.addedNodes.length; ++j) {
                    const node = record.addedNodes[j];

                    if (!(node instanceof HTMLElement))
                        continue;

                    const element = node as HTMLElement;
                    this.discover(element);
                };
            });     
        });

        this.observer.observe(element, {childList: true, subtree: true });

        if (idleLoad){
            if (LazyImageHandler.enableDebugging)
                console.log("Enabling background loader");

            this.idleLoader = window.requestIdleCallback(this.backgroundLoader);
        } else {
            if (LazyImageHandler.enableDebugging)
                console.log("Not enabling background loader");
        }

        this.discover(element);
        
            
    }

    private discover(root: HTMLElement) {
        const imageElements = [].slice.call(root.querySelectorAll("img[data-src]"));

        imageElements.forEach( (imageElement: HTMLImageElement)  => {
            if (!imageElement)
                return;

            if (this.imageElements.indexOf(imageElement) === -1) {
                this.imageElements.push(imageElement);
                this.intersectionObserver.observe(imageElement);
            }    
        });

        if (LazyImageHandler.enableDebugging)
            console.log(`Discovered ${this.imageElements.length} tags`)
    }
   
    private backgroundLoader = async (deadline: any) => {
        if (this._destroyed)
            return;

        while(deadline.timeRemaining() > 0 && this.imageElements.length > 0 && !this._destroyed) {
            const element = this.imageElements[0];
            
            // somehow element may have been removed, in which case we simply remove it from array
            if (!element) {
                this.imageElements.splice(0, 1); 
                continue;
            }
                
            const src = element.getAttribute("data-src");
            if (src) {
                await this.tryLoadImage(src);
            }
                
            this.registerLoaded(element);
        }

        this.idleLoader = window.requestIdleCallback(this.backgroundLoader);
    }

    private async tryLoadImage(url: string): Promise<{}> {
        if (LazyImageHandler.enableDebugging)
            console.log("Trying to load", url);

        return new Promise(resolve => {
            const image = new Image();

            image.onerror = () => resolve(undefined);
            image.onload = () => resolve(undefined);
            image.src = url;
        });
        
    }

    private intersectionHandler = (entries: IntersectionObserverEntry[]) => {
        entries.forEach( (entry: IntersectionObserverEntry) => {
            // isIntersecting can be undefined, see https://caniuse.com/#feat=intersectionobserver
            if ( (<any>entry).isIntersecting === undefined || (<any>entry).isIntersecting) {
                this.registerLoaded(entry.target as HTMLElement);
            }
        });
    }

    private registerLoaded(imageElement: HTMLElement) {
        const src = imageElement.getAttribute("data-src");
        if (src)
            imageElement.setAttribute("src", src);
        
        imageElement.removeAttribute("data-src");
        this.imageElements = this.imageElements.filter(i => i !== imageElement);
        this.intersectionObserver.unobserve(imageElement);
    }
   
    destroy() {
        this.imageElements.length = 0;
        window.cancelIdleCallback(this.idleLoader);
        this.observer.disconnect();
        this.intersectionObserver.disconnect();
        this._destroyed = true;
    }
}