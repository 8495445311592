
import { Routing } from "lib/Routing";
import { Package } from "features/packages/Package";
import { BasePackageElement } from "features/packages/BasePackageElement";
import "./ChromePluginElement-style";
import { AppContextStore } from "features/application-context/AppContextStore";

class ChromePluginElement extends BasePackageElement {
    protected slug = "chrome-plugin";    

    protected detailView = (thePackage: Package) => `
        <a name="chrome" target="_blank" href="https://chrome.google.com/webstore/detail/linkstacks/fpcokiakkmbkibdafmnkadlmaodggfhl">
            <img src="${require("image/ChromeWebStoreBadge.svg")}">
        </a>
    `;      
}

customElements.define("chrome-plugin", ChromePluginElement);

Routing.instance.onChange(async route => {
    if (route.path[0] === "apps" && route.path[1] === "chrome-plugin") {
        await AppContextStore.instance.present("", document.createElement("chrome-plugin"), "Bookmarks", "Apps", "Chrome plugin");
    }
});