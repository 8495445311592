import { UrlProvider } from "lib/UrlProvider";
import { ILoginProvider } from "./ILoginProvider";
import { LoginResponse } from "../Model";
import { Pipeline, Request } from "lib/HTTP";
import { Plugins } from "@capacitor/core";
const { SignInWithApple } = Plugins
import { App } from '@capacitor/app';

export class AppleIOSLoginProvider implements ILoginProvider {
    getName = () => "AppleNativeLoginProvider";

    login(): Promise<LoginResponse> {
        return new Promise(async (resolve, reject) => {
            try {
                const signinResponse = await SignInWithApple.Authorize() as ResponseSignInWithApplePlugin;

                const response = await fetch(`${new UrlProvider().root}/api/external-login/apple`, {
                    method: "POST",
                    credentials: "include",
                    headers: {
                        "content-type": "text/json"
                    },
                    body: JSON.stringify({
                        tokenId: signinResponse.response.identityToken
                    })
                });

                if (!response.ok)
                    reject("Unable to login");

                const loginResponse = await response.json() as LoginResponse;
                resolve(loginResponse);
            }
            catch (error) {
                reject("Unable to login");
            }
        });
    }
}

interface ResponseSignInWithApplePlugin {
    response: {
        user: string;
        email: string | null;
        givenName: string | null;
        familyName: string | null;
        identityToken: string;
        authorizationCode: string;
    };
}
