import { StackStore, Linkstack } from "features/stacks/StackStore";
import { Formatting } from "lib/Formatting";
import { PagedResult } from "lib/Model";

import "./StackSelectorElement-style";

class StackSelectorElement extends HTMLElement {
    private escape = Formatting.htmlEscape;
    private name: string;
    private stackSearch: HTMLInputElement;
    private hiddenStackSlugs: HTMLInputElement;
    private stackSlugs: string[] = [];
    private suggest: HTMLElement;
    private suggestValue: string;
    private atLeastOne: boolean;

    async connectedCallback() {
        this.name = this.getAttribute("field-name");

        this.atLeastOne = this.hasAttribute("at-least-one");

        const stackSlugValues = this.getAttribute("stack-slugs");
        this.stackSlugs = stackSlugValues ? stackSlugValues.split(",").filter(f => f !== "") : [];
        this.innerHTML = this.view();
        await this.afterRender();
    }

    private view = () => this.escape `
        <input type="text" name="${this.name}" style="display:none" required tabindex="9999" />
        <fieldset class="selected-stacks"></fieldset>
        <fieldset class="select-stack modern">
            <div class="group">      
                <input name="stack-search" type=text placeholder="Select stacks">
            </div>
        </fieldset>`;

    private suggestView = (stacks: PagedResult<Linkstack>) => `
        <div class=suggest-frame>
            <ul class=suggest>${stacks.items.map(stack => `<li data-slug="${stack.slug}" class="${this.isSelected(stack.slug) ? "selected" : ""}"><div class=title>${stack.title}</div><div class=check>${require("!!raw-loader!image/fa-check.svg")}</div></li>`).join("")}</ul>
        </div>`;


    private async renderStacks(stackSlugs: string[]) {
        const stacks = await Promise.all(this.parseStacks(this.hiddenStackSlugs.value)
                        .filter(stackSlug => stackSlug !== "links" && stackSlug !== "")
                        .map(async stackSlug => await StackStore.instance.lookupStack(stackSlug)));

        const html = this.escape `$${stacks.map(stack => `
            <a class="stack-button" data-slug="${stack.slug}" ${stacks.length <= 1 && this.atLeastOne ? "readonly" : ""}>
                ${stack.slug !== "inbox" ? `<img src="${stack.smallPreview}" />` : ""}
                ${stack.title} 
                ${require("!!svg-inline-loader!image/icons/close-icon.svg")}
            </a>`).join("")}`;

        this.querySelector(".selected-stacks").innerHTML = html;
    }

    renderHidden() {
        this.hiddenStackSlugs.value = this.stackSlugs
            .filter(i => i !== "links")
            .join(",");
        if (this.hiddenStackSlugs.dispatchEvent)
            this.hiddenStackSlugs.dispatchEvent(new Event("change"));
    }

    private bindListEvents() {
        Array.prototype.slice.call(this.querySelectorAll("li")).forEach((element: HTMLLIElement) => {
            element.addEventListener("click", async event => {
                event.preventDefault();
                
                const clickedElement = <HTMLLIElement>event.target;
                const li = (<any>clickedElement).closest("li");
                const slug = li.attributes.getNamedItem("data-slug").value;

                if (element.classList.contains("selected")) {
                    await this.removeStack(slug);
                    li.classList.remove("selected");

                }
                else {
                    await this.addStack(slug);
                    li.classList.add("selected");
                }

                this.stackSearch.value = "";
                this.stackSearch.focus();
            });
        });
    }

    private isSelected(stackSlug: string) {
        return this.stackSlugs.indexOf(stackSlug) !== -1;
    }

    private async removeStack(stackSlug: string) {
        const index = this.stackSlugs.indexOf(stackSlug);
        if (index === -1)
            alert(`What? Stack not found: ${stackSlug}`);

        this.stackSlugs.splice(index, 1);
        this.renderHidden();
        await this.renderStacks([]);
    }

    private async addStack(stackSlug: string) {
        const index = this.stackSlugs.indexOf(stackSlug);
        if (index !== -1)
            return;

        this.stackSlugs.push(stackSlug);
        this.renderHidden();
        await this.renderStacks([stackSlug]);
    }

    private parseStacks(source: string): string[] {
        return source.split(",").map(i => i.trim());
    }

    async afterRender() {
        this.stackSearch = <HTMLInputElement>this.querySelector("input[name=stack-search]");
        this.hiddenStackSlugs = <HTMLInputElement>this.querySelector(`input[name='${this.name}']`);
        this.renderHidden();
        await this.renderStacks(this.stackSlugs);
        
        ["keyup", "focus"].forEach(eventName => this.stackSearch.addEventListener(eventName, async (event: KeyboardEvent) => {

            if (this.suggestValue !== this.stackSearch.value.toLocaleLowerCase() || !this.querySelector(".suggest")) {
                this.suggestValue = this.stackSearch.value.toLocaleLowerCase();

                const stacks = await StackStore.instance.lookupStacks(this.suggestValue);
                this.showSuggestions(stacks);
            }

            const move = (action) => {
                let highlighted = this.suggest.querySelector(".highlight");
                if (!highlighted)
                    highlighted = this.suggest.querySelector("li");
                else {
                    const items = Array.prototype.slice.call(this.suggest.querySelectorAll("li"));
                    const current = action(items.indexOf(highlighted));

                    if (current < items.length) { // -1 = Clear selection
                        items.forEach(item => (<any>item).classList.remove("highlight"));

                        if (current > -1)
                            highlighted = <any>items[current];
                        else
                            highlighted = undefined;
                    }
                }

                if (highlighted)
                    highlighted.classList.add("highlight");
            };

            if (event.key === "ArrowUp") {
                move(current => current - 1);
            }

            if (event.key === "ArrowDown") {
                move(current => current + 1);
            }

            // if (event.key === "Enter") {
            //     const highlighted = <HTMLElement>this.suggest.querySelector(".highlight");

            //     //if (highlighted)
            //     //    this.addNewTag(highlighted.innerText.toLocaleLowerCase());
            //     //else
            //     //    this.addNewTag(this.stackSearch.value.toLocaleLowerCase());
            // }

        }));

        this.addEventListener("click", async (event: Event) => {
            let element = <HTMLElement>event.target;
            if (element.classList.contains("stack-button") || element.parentElement.classList.contains("stack-button")) {

                if (element.parentElement.classList.contains("stack-button"))
                    element = element.parentElement;

                event.preventDefault();
                await this.removeStack(element.attributes.getNamedItem("data-slug").value);
                element.remove();
            }

        });
    }

    private hideSuggestions() {
        document.removeEventListener("click", this.clickOutsideSuggestHandler);
        this.suggest.remove();
    }

    private clickOutsideSuggestHandler = (event) => {
        if (this.querySelector(".select-stack").contains(event.target))
            return;

        this.hideSuggestions();
    };


    private showSuggestions(stacks: PagedResult<Linkstack>) {
        if (this.suggest)
            this.hideSuggestions();

        this.stackSearch.parentElement.insertAdjacentHTML("afterend", this.suggestView(stacks));
        this.suggest = <HTMLElement>this.querySelector(".suggest-frame");

        this.bindListEvents();
        document.addEventListener("click", this.clickOutsideSuggestHandler)
    }
}

customElements.define("stack-selector", StackSelectorElement);