import { DOM } from "lib/DOM";
import { Routing, RouteChangeOrigin } from "lib/Routing";
import { UrlProvider } from "lib/UrlProvider";
import { SettingsStore } from "features/settings/SettingsStore";
import { AuthenticationStore } from "features/authentication";

interface DisplayProperties {
    width: number;
    height: number;
    ratio: number;
}

export class HitTracker {
    private static _instance = new HitTracker();
    static get instance() { return HitTracker._instance; }
    private _urlProvider = new UrlProvider();
    private _sessionId;
    private _client: string;
    private _displayProperties: DisplayProperties;
    private constructor() { }

    async collect(path: string) {
        await import( /* webpackChunkName: "platform" */ "navigator.sendbeacon");

        if (!this._client && document && document.documentElement)
            this._client = document.documentElement.getAttribute("client");

        this.ensureSessionId();

        if (!this._displayProperties) {
            if (window.screen && window.screen.width && window.screen.height && window.devicePixelRatio) {
                this._displayProperties = {
                    width: window.screen.width,
                    height: window.screen.height,
                    ratio: window.devicePixelRatio
                }
                
            }
        }

        const user = await AuthenticationStore.instance.currentUserNoFetch();

        let speed = 0;
        
        if (navigator && (<any>navigator).connection && (<any>navigator).connection.downlink)
            speed = (<any>navigator).connection.downlink;

        const params = new URLSearchParams();
        params.set("path", path);
        params.set("sid", this._sessionId);
        params.set("w", this._displayProperties.width.toString());
        params.set("h", this._displayProperties.height.toString());
        params.set("r", this._displayProperties.ratio.toString());
        params.set("c", this._client);
        params.set("s", speed.toString());
        params.set("v", SettingsStore.instance.version.toString());
        
        if (user && user.userId)
            params.set("u", user.userId)
        
        navigator.sendBeacon(`${this._urlProvider.root}/api/tracking/hit?${params.toString()}`);
    }

    ensureSessionId() {
        if (this._sessionId) 
            return;

        if (this._client === "browser")
            this.tryReadingCookie();
        else
            this.tryReadingLocalstorage();
        
        if (this._sessionId) 
            return;
        
        this._sessionId = HitTracker.guid();
        try {
            if (this._client === "browser")
                document.cookie = `session-id=${this._sessionId}; path=/; max-age=2592000; Secure`; 
            else
                localStorage.setItem("session-id", this._sessionId);
        } catch(error) {
            console.log(error);
        }
    }

    tryReadingCookie() {
        try {
            this._sessionId = DOM.getCookie("session-id");
        } catch(error) {
            console.log(error);
        }
    }

    tryReadingLocalstorage() {
        try {
            this._sessionId = localStorage.getItem("session-id");
        } catch(error) {
            console.log(error);
        }
    }

    static guid() {
        const s4 = () => Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
        return s4() + s4() + s4() + s4() + s4() + s4() + s4() + s4();
    }

}


Routing.instance.onChange(async route => {

    if (route.origin === RouteChangeOrigin.PageLoad)
        return;

    const path = route.path || [];
    await HitTracker.instance.collect(path.join("/"));
});