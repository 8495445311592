import "./FavoriteViewElement-style";
import * as cardsStyle from "../../../../styles/grid-cards-module.less" 
import { Link, LinkStore } from "features/links";
import { Request } from "lib/HTTP";
import { UrlProvider } from "lib/UrlProvider";

export class FavoriteViewElement extends HTMLElement {
    private _favorite: Link;

    set favorite(favorite: Link) {
        this._favorite = favorite;
        this.refreshHandler();
    }

    connectedCallback() {
        //this.className += ` ${cardsStyle.card}`;
        this.addEventListener("click", this.clickHandler);
    }

    disconnectedCallback() {
        this.removeEventListener("click", this.clickHandler);
    }

    private clickHandler = async (event: MouseEvent) => {
        if (!(event.target instanceof Element))
            return;

        const button = event.target.closest("button");
        if (button?.name === "favorite") {
            await LinkStore.instance.unFavorite(this._favorite.slug, this._favorite.stack);
            return;
        }
        window.requestIdleCallback(async () => {
            await fetch(`${new UrlProvider().root}/api/tracking/collect`, new Request().setJSON({target: this._favorite.id }));
        });
                        
        window.open(this._favorite.url, "_blank");
    }

    private refreshHandler = () => {
        this.innerHTML = this.view(this._favorite);
        this.setAttribute("title", this._favorite.title);
    }

    private view = (link: Link) => `
        <div>
            <progressive-image auto-size show-loader lazy-load class="${cardsStyle.card}" pico-image="${link.picoImage}" source-image="${link.preview}"></progressive-image>
            <div class="slide-panel">
                <button type=button name=favorite>
                    ${require("!!raw-loader!image/favorite.svg")}
                </button>
            </div>
            <div name=detail>
                <div name=title>${link.title}</div>
            </div>
        </div>
    `;
}

customElements.define("favorite-view", FavoriteViewElement);