import * as floatNavStyle from "./FloatNavigation-module.less";

class FloatNavigationElement extends HTMLElement {
    private scrollTarget: HTMLElement;
    private isActive = false;

    connectedCallback() {
        if (!this.parentElement) 
            return;

        this.scrollTarget = this.parentElement.querySelector("[name=scroller]");
        // this.previousPosition = this.scrollTarget.scrollTop;
        this.setAttribute("hide", "");

        this.className += floatNavStyle.floatNavigation;

        this.innerHTML = `
            <button name=up>${require("!!raw-loader!image/arrow-down-20-v2.svg")}</button>
            <button name=down>${require("!!raw-loader!image/arrow-down-20-v2.svg")}</button>
        `;

        //this.scrollTarget.addEventListener("scroll", this.scrollHandler, { passive: true });
        this.scrollTarget.addEventListener("open", this.openHandler);
        this.scrollTarget.addEventListener("close", this.closeHandler); 

        this.addEventListener("click", this.clickHandler); 
    }

    disconnectedCallback() {
        //this.scrollTarget.removeEventListener("scroll", this.scrollHandler);
        this.scrollTarget.removeEventListener("open", this.openHandler);
        this.scrollTarget.removeEventListener("close", this.closeHandler); 
        this.removeEventListener("click", this.clickHandler); 
    }

    private clickHandler(event: MouseEvent) {
        const element = event.target as HTMLElement;
        const button = element.closest("button");
        if (!button)
            return;

        if (button.name === "up")
            this.dispatchEvent(new CustomEvent("up"));
        else if (button.name === "down")
            this.dispatchEvent(new CustomEvent("down"));
        
        
    }

    private openHandler = () => {
        // this.previousPosition = this.scrollTarget.scrollTop-10;
        this.isActive = true;
        this.show();
        this.hasOpen = true;
    };

    private hasOpen = false;

    private closeHandler = () => {
        if (this.hasOpen) {
            this.hasOpen = false;
            return;
        }
            
        this.isActive = false;  

        if (!this.hasAttribute("hide"))
            this.hide();
    };

    private hideTimer: any;

    private hide() {
        this.hideTimer = setTimeout(() => {
            this.setAttribute("hide", "");
            requestAnimationFrame(() => {
                this.style.display = "none";
            });  
        }, 150);
    }

    private show() {
        if (!this.isActive)
            return;

        clearTimeout(this.hideTimer);
        requestAnimationFrame(() => {
            this.style.display = "block";
            requestAnimationFrame(() => {
                this.removeAttribute("hide");
            });    
        });
    }

    // private scrollHandler = () => {
    //     if (this.previousPosition > this.scrollTarget.scrollTop)
    //         this.show();
    //     else
    //         this.hide();

    //     this.previousPosition = this.scrollTarget.scrollTop;
    // }
}

customElements.define("float-nav", FloatNavigationElement);