import { Request, Pipeline } from "lib/HTTP"
import { UrlProvider } from "lib/UrlProvider";

export class PaymentStore {
    private static _instance = new PaymentStore();
    private urlProvider = new UrlProvider();

    static get instance() {
        return PaymentStore._instance;
    }

    async all() : Promise<Payment>{
        const response = await new Pipeline().fetch(`${this.urlProvider.root}/api/payment`, Request.get.authenticate());
        return await response.json() as Payment;
    }

    async setAutoRenew(enabled: boolean) : Promise<void> {
        await new Pipeline().fetch(`${this.urlProvider.root}/api/payment/autorenew`, Request.post.authenticate().setJSON({autoRenew: enabled}));
    }

}

export interface Payment {
    currentLevel: "basic" | "premium" | "professional";
    transactions?: Transaction[],
    memberSince?: string;
    nextRenewal?: string;
    autoRenew: boolean;
}

export interface Transaction{
    transactionId: string,
    capturedAmount: number,
    card: Card,
    created: string,
    validFrom: string,
    validTo: string,
    currency: string
}

export interface Card {
    expiry: Date,
    last4: string,
    scheme: string
}