import "./UserLoginElement-style";
import { LoginResponse, SignInStatus, ValidationMessage } from "./Model";

import { AuthenticationStore } from "./AuthenticationStore";

class UserLoginElement extends HTMLElement {

    async connectedCallback() {
        this.innerHTML = this.view();
        this.classList.add("form");
        this.setupEvents()

        setTimeout(() => {
            this.style.opacity = "1";
        });
    }

    private async login() {
        this.setAttribute("busy", "");

        const usernameElement = this.querySelector("[name=username-or-email]") as HTMLInputElement;
        const passwordElement = this.querySelector("[name=password]") as HTMLInputElement;

        let result;
        try{
            result = await AuthenticationStore.instance.login(usernameElement.value, passwordElement.value);
        } finally {
            this.removeAttribute("busy");
        }
        

        if ((<LoginResponse>result).status && (<LoginResponse>result).status !== "success") {
            const formMessageElement = this.querySelector(`[name="form-message"]`) as HTMLSpanElement;

            if ((<LoginResponse>result).status === "requiresVerification")
            {
                this.setAttribute("busy", "");
                try{
                    if (await AuthenticationStore.instance.resendRegistrationMail(usernameElement.value)) {
                        const loginFlow = document.querySelector("login-flow .content");
                        loginFlow.innerHTML = "<user-registration resend></user-registration>";
                        return;
                    } else {
                        formMessageElement.innerText = "Unable to resend registration mail";
                        return;
                    }
                } finally {
                    this.removeAttribute("busy");
                }
            } else if ((<LoginResponse>result).status === "failure") {
                this.showForgotPassword();
             } else {
                if (formMessageElement)
                    formMessageElement.innerText = this.translateMessage((<LoginResponse>result).status);
             }

        } else if ((<ValidationMessage>result).modelState) {
            for(const key in (<ValidationMessage>result).modelState){
                const messageElement = this.querySelector(`[name="${key}"]`) as HTMLSpanElement;
                if (messageElement)
                    messageElement.innerText = (<ValidationMessage>result).modelState[key];
            }
        }  
    }

    private showForgotPassword() {
        const forgotPasswordElement = this.querySelector(`[name="forgot-password"]`) as HTMLElement;
        forgotPasswordElement.style.display = "block";
    }

    private setupEvents() {
        const loginButton = this.querySelector("[name=login]") as HTMLButtonElement;
        loginButton.addEventListener("click", async () => await this.login());

        this.addEventListener("keydown", async event => {
            if (event.key === "Enter")
                await this.login();
        });

        [].slice.call(this.querySelectorAll("[name=provider]")).forEach( (button: HTMLButtonElement) => {
            button.addEventListener("click", async () => {
                const providerId = button.getAttribute("value");
                await AuthenticationStore.instance.loginExternal(providerId);
            });
        });
    }

    private translateMessage(status: SignInStatus): string {
        switch (status) {
            case "lockedOut": return "Account locked, please try later";
            case "requiresVerification": return "Account not verified";
            case "failure": return "Invalid username/email or password";
            default: return "Unknown error";
        }
    }

    private view = () => `
        <header>
            <a>Log in</a>
        </header>
        <section>
            <span name="form-message" class="error"></span>
            <div name="forgot-password" class=error route href="/forgot-password">
                Did you forget your password?
            </div>
            
            <div class=form-group>
                <label for="username-or-email">Username or email</label>
                <div>
                    <input autocapitalize="none" autocorrect="off" class="form-control" data-val="true" data-val-required="The Username or email field is required." id="username-or-email" name="username-or-email" placeholder="Username or email" type="text" value="">
                    <span name="model.UsernameOrEmail" class="error"></span>
                </div>
            </div>
            <div class=form-group>
                <label for="Password">Password</label>
                <div>
                    <input data-val="true" data-val-required="The Password field is required." id="password" name="password" placeholder="Password" type="password">
                    <span name="model.Password" class="error"></span>
                </div>
            </div>
            <div class=form-group>
                <div>
                    <div class=checkbox>
                        <input checked data-val="true" data-val-required="The Remember me field is required." id="remember-me" name="remember-me" type="checkbox" value="true"><input name="RememberMe" type="hidden" value="false">
                        <label for="remember-me">Remember me</label>
                    </div>
                </div>
            </div>
            <button type=button class=power name=login>Log in</button>
            <div id="socialLoginList">
                <button type="button" class="light google" id="google" name="provider" value="Google" title="Log in using your Google account">
                    ${require("!!raw-loader!image/icons/google.svg")}Google
                </button>
                <button type="button" class="light facebook" id="facebook" name="provider" value="Facebook" title="Log in using your Facebook account">
                    ${require("!!raw-loader!image/icons/facebook.svg")}Facebook
                </button>
                <button type="button" class="light twitter" id="twitter" name="provider" value="Twitter" title="Log in using your Twitter account">
                    ${require("!!raw-loader!image/icons/twitter.svg")}Twitter
                </button>
            </div>
            <button type="button" class="power" name="register" href="/register" route>Register as a new user</button>
        </section>
    `;
}

// Routing.instance.onChange(route => {
//     if (route.path[0] === "login") {
//         const loginFlow = document.querySelector("login-flow .content");
//         loginFlow.innerHTML = "<user-login></user-login>";
//     }
// });

customElements.define("user-login", UserLoginElement);