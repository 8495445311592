/**
 * Will lock a parent elements scroll-position so that target element is always at the top.
 * If lockToStartingOffset is specified, element will be kept at current position instead of top.
 */
export class ScrollLocker {
    private _destroyed = false;
    private targetTop = 0;

    constructor(private parentElement: HTMLElement, private targetElement: HTMLElement, lockToStartingOffset = false, timeout = 500) {
        if (lockToStartingOffset)
            this.targetTop = targetElement.getBoundingClientRect().top;

        setTimeout(() => this.destroy(), timeout);
        this.lock();
    }

    private lock = () => {
        if (this._destroyed)
            return;

        const top = this.targetElement.offsetTop - this.parentElement.offsetTop;    
        const target = (top - this.targetTop);

        this.parentElement.scrollTop = target;

        // this.parentElement.scrollTo(0, target);
        requestAnimationFrame(this.lock);
    }
    
    destroy() {
        this._destroyed = true;
    }
}