import "./LinkFetchMonitor"
import "url-polyfill";
import { NotifyNativeViewController } from "features/notification";
import { NotifyViewController } from "features/notification/NotifyViewController";
import { Request, Pipeline } from "lib/HTTP"
import { Linkstack } from "features/stacks/StackStore";
import { UrlProvider } from "lib/UrlProvider"

/*
    Now only provides actions on links, no events or any state
*/
export class LinkStore {
    private static _instance: LinkStore = new LinkStore();
    private urlProvider: UrlProvider = new UrlProvider();

    static get instance() {
        return LinkStore._instance;
    }

    async removeFromStack(slug: string, stackId: string) {
        const stackSlug = this.urlProvider.slugPart(stackId);
        const url = `${await this.urlProvider.linkLocationInStack(stackSlug, slug)}/_remove?_token=${Request.getSourceToken()}`;
        await new Pipeline().fetch(url, Request.delete.authenticate());
    }

    async favorite(linkSlug: string, stackSlug: string) {
        const url = `${await this.urlProvider.linkLocationInStack(stackSlug, linkSlug)}/_favorite?_token=${Request.getSourceToken()}`;
        await new Pipeline().fetch(url, Request.post.authenticate());
    }

    async unFavorite(linkSlug: string, stackSlug: string) {
        const url = `${await this.urlProvider.linkLocationInStack(stackSlug, linkSlug)}/_favorite?_token=${Request.getSourceToken()}`;
        await new Pipeline().fetch(url, Request.delete.authenticate());
    }

    public async copyToStack(linkSlug: string, sourceStackSlug: string, destinationStackSlug: string) {
        if (destinationStackSlug)
            destinationStackSlug = this.urlProvider.slugPart(destinationStackSlug);

        if (!sourceStackSlug)
            sourceStackSlug = "inbox";

        const url = `${await this.urlProvider.linkStackAssignLocation(sourceStackSlug, linkSlug)}?newstack=${destinationStackSlug}&_token=${Request.getSourceToken()}`;
        await new Pipeline().fetch(url, Request.post.authenticate());
    }

    public async moveToStack(linkSlug: string, sourceStackSlug: string, destinationStackSlug: string): Promise<LinkAddStatus> {
        if (destinationStackSlug)
            destinationStackSlug = this.urlProvider.slugPart(destinationStackSlug);

        if (!sourceStackSlug)
            sourceStackSlug = "inbox";


        const url = `${await this.urlProvider.linkLocationInStack(sourceStackSlug, linkSlug)}/_move?newstack=${destinationStackSlug}&_token=${Request.getSourceToken()}`;
        const response = await new Pipeline().fetch(url, Request.post.authenticate());

        if (!response.ok)
            if ((await response.text()).indexOf("Link already in stack") !== -1)
                return LinkAddStatus.UrlAlreadyExists;

        return LinkAddStatus.Ok;
    }

    public async delete(link: Link) {
        const url = `${await this.urlProvider.linkLocationInStack(link.stack, link.slug)}?_token=${Request.getSourceToken()}`;
        const response = await new Pipeline().fetch(url, Request.delete.authenticate());
        if (!response.ok) {
            alert(`Unable to delete link. (${response.status})`);
        }
    }

    public async getLink(linkSlug: string): Promise<Link[]> {
        const response = await new Pipeline().fetch(await this.urlProvider.linkLocation(linkSlug), Request.get.authenticate());
        return await response.json() as Link[];
    }

    async addAndForget(url: string): Promise<void> {
        if ((<any>window).TapticEngine) 
            (<any>window).TapticEngine.unofficial.weakBoom();

        new NotifyViewController().showWeb({ title: "Saving link ...", showLoader: true, showSeconds: 2 });
        const showError = (title: string, ok = false) => new NotifyNativeViewController().show({ title: title, showSeconds: 6.5, showOk: ok });

        const result = await this.add({ url: url });
        switch (result) {
            case LinkAddStatus.Ok:
                return;
            case LinkAddStatus.InvalidUrl:
                showError("Unable to add link: Invalid url");
                break;
            case LinkAddStatus.UrlAlreadyExists:
                showError("Url already exists", true);
                break;
            default:
                showError("Unable to add link");
        }
    }

    async add(link: Link, stackId?: string): Promise<LinkAddStatus> {
        if (!stackId)
            stackId = `inbox`;

        const stackSlug = this.urlProvider.slugPart(stackId);
        const action = await this.urlProvider.linksStackLocation(stackSlug);
        const response = await new Pipeline().fetch(`${action}?_token=${Request.getSourceToken()}`, Request.post.authenticate().setJSON(link));

        if (response.ok)
            return LinkAddStatus.Ok;

        switch (response.status) {
            case 400: return LinkAddStatus.InvalidUrl;
            case 409: return LinkAddStatus.UrlAlreadyExists;
            default:
                throw new Error(`Unable to add link: status is ${response.status}, ${response.statusText}`)
        }
    }

    public async refreshMetadata(link) {
        const url = `${await this.urlProvider.linkLocationInStack(link.stack ? link.stack : "inbox", link.slug)}/_refresh?_token=${Request.getSourceToken()}`
        await new Pipeline().fetch(url, Request.post.authenticate().setJSON(link));
    }

    public async update(link: Link): Promise<Link> {
        if (!link.slug)
            throw new Error("link.slug is required");

        const url = `${await this.urlProvider.linkLocationInStack(link.stack, link.slug)}?_token=${Request.getSourceToken()}`;
        const response = await new Pipeline().fetch(url, Request.post.authenticate().setJSON(link));

        if (!response.ok) {
            alert(`Unable to update link. (${response.status}, ${response.statusText})`);
            return;
        }

        return await response.json() as Link;
    }

    public async tagMany(updateGram: LinkTagChangeGram): Promise<Boolean> {
        const url = `${await this.urlProvider.userHomeLocation()}links/tag?_token=${Request.getSourceToken()}`;
        const response = await new Pipeline().fetch(url, Request.post.authenticate().setJSON(updateGram));

        if (!response.ok) {
            alert(`Unable to update links. (${response.status}, ${response.statusText})`);
        }

        return response.ok;
    }

    public async deleteMany(updateGram: LinkChangeGram): Promise<Boolean> {
        const url = `${await this.urlProvider.userHomeLocation()}links/delete?_token=${Request.getSourceToken()}`;
        const response = await new Pipeline().fetch(url, Request.post.authenticate().setJSON(updateGram));

        if (!response.ok) {
            alert(`Unable to delete links. (${response.status}, ${response.statusText})`);
        }

        return response.ok;
    }

    public async moveMany(updateGram: LinkOriginChangeGram): Promise<{ [key: string]: "conflict" | "ok" | "notfound" }> {
        const url = `${await this.urlProvider.userHomeLocation()}links/move?_token=${Request.getSourceToken()}`;
        const response = await new Pipeline().fetch(url, Request.post.authenticate().setJSON(updateGram));

        if (!response.ok) {
            alert(`Unable to move links. (${response.status}, ${response.statusText})`);
        }

        return await response.json();
    }

    public async copyMany(updateGram: LinkOriginChangeGram): Promise<{ [key: string]: "conflict" | "ok" | "notfound" }> {
        const url = `${await this.urlProvider.userHomeLocation()}links/copy?_token=${Request.getSourceToken()}`;
        const response = await new Pipeline().fetch(url, Request.post.authenticate().setJSON(updateGram));

        if (!response.ok) {
            alert(`Unable to copy links. (${response.status}, ${response.statusText})`);
        }

        return await response.json();
    }
}

export enum LinkAddStatus {
    Unknown,
    Ok,
    InvalidUrl,
    UrlAlreadyExists
}

export interface LinkHighlights {
    title?: string;
    description?: string;
    texts?: string;
    userTags?: string; // this is only for highlighting, usertags are called tags everywhere else
}

export interface Link {
    id?: string;
    prettyUrl?: string;
    userId?: string;
    userUrl?: string;
    externalUrl?: string;
    preview?: string;
    picoImage?: string;
    availablePreviews?: string[];
    title?: string;
    url: string;
    location?: string;
    slug?: string;
    description?: string;
    stack?: string;
    imageCount?: number;
    isCreated?: boolean;
    visitCount?: number;
    age?: string;
    highlights?: LinkHighlights;
    jumpLocation?: string;
    created?: string;
    tags?: string[];
    favorite?: boolean;
}


export interface LinkEventArgs {
    link: Link;
    stackId?: string;
    stackSlug?: string;
}

export interface StackEventArgs {
    stack: Linkstack;
}

export interface Highlighted<T> {
    item: T;
    highlights: { [field: string]: string; };
}

export interface PagedResult<T> {
    items: T[];
    total: number;
}

export interface SearchResult {
    stacks: Linkstack[];
    links: Link[];
}

export interface LinkTagChangeGram {
    added: string[];
    removed: string[];
    linkIds?: string[];
}

export interface LinkChangeGram {
    linkIds?: string[];
}

export interface LinkOriginChangeGram {
    linkIds: string[];
    targetStack: string;
}
