import "./TagButtonElement-style.less";
import { Formatting } from "lib/Formatting";
import { UrlProvider } from "lib/UrlProvider";

type Mode = "link" | "toggle";

class TagButtonElement extends HTMLElement {
    private escape = Formatting.htmlEscape;
    static get observedAttributes() { return ["selected"]; }

    attributeChangedCallback(attrName, oldVal, newVal) {
        if (attrName === "selected") {
            if (this.hasAttribute("selected"))
                this.querySelector("round-check")?.setAttribute("checked", "");
            else
                this.querySelector("round-check")?.removeAttribute("checked");
        }    
    }

    connectedCallback() {
        const text = this.innerText;
        this.setAttribute("tag", text);
        this.setAttribute("title", text);
        this.setAttribute("id", `tag-${text.toLowerCase()}`);
        this.innerHTML = this.view(text, this.hasAttribute("selected"), this.mode);
        this.addEventListener("click", this.clickHandler);
        window.requestIdleCallback(this.updateLink);
    }

    private get mode() : Mode {
        if (this.hasAttribute("route"))
            return "link";

        return "toggle";
    }

    private updateLink = async () => {
        this.setAttribute("href", `${await new UrlProvider().userHome()}/links?query=%22${encodeURIComponent(this.getAttribute("tag"))}%22`);
    }

    private clickHandler = (event: MouseEvent) => {
        if (this.hasAttribute("route"))
            return;

        event.stopPropagation();
        this.toggleAttribute("selected");
        this.attributeChangedCallback("selected", "", ""); // IE Edge Hack
        this.dispatchEvent(new CustomEvent("tag-change", { bubbles: true}));
    }

    private view = (tag, checked: boolean, mode: Mode) => this.escape ` 
        $${mode === "toggle" ? `<round-check${checked ? " checked" : ""}></round-check>` : ""}
        <div class=text>${tag}</div>
    `;
}

customElements.define("tag-button", TagButtonElement);
