
import { Routing } from "lib/Routing";
import { Package } from "features/packages/Package";
import { BasePackageElement } from "features/packages/BasePackageElement";
import { AppContextStore } from "features/application-context/AppContextStore";


class IOSAppElement extends BasePackageElement {
    protected slug = "ios";    

    protected detailView = (thePackage: Package) => `
        <a name="ios" target="_blank" href="https://itunes.apple.com/us/app/linkstacks/id1307607864?mt=8">
            <img src="${this.appsStore.ressources["apple-app-store-badge"]}">
        </a>
    `;      
}

customElements.define("ios-app", IOSAppElement);

Routing.instance.onChange(async route => {
    if (route.path[0] === "apps" && route.path[1] === "ios") {
        await AppContextStore.instance.present("", document.createElement("ios-app"), "Bookmarks", "Apps", "iOS");
    }
});