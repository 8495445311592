import { BrowserInfo } from "lib/Browser";
export class PackageStore {
    private packages = [
        {slug: "membership", logo: require("!!raw-loader!image/membership-logo.svg"), name: "Membership & payment", description: "Manage or upgrade to paid membership."},
        {slug: "login", logo: require("!!raw-loader!image/login-logo.svg"), name: "Login & password", description: "Setup password and login via Facebook, Google and Twitter."},
        {slug: "notifications", logo: require("!!raw-loader!image/notifications-logo.svg"), name: "Emails & notifications", description: "Manage email and notification settings."},
        {slug: "authorized-applications", logo: require("!!raw-loader!image/authorized-applications-logo.svg"), name: "Authorized Applications", description: "Control access for external applications."},
        {slug: "deactivate-account", logo: require("!!raw-loader!image/deactivate-account-logo.svg"), name: "Deactivate account", description: "Delete your account."}
        // {slug: "logout", logo: require("image/logout-logo.svg"), name: "Logout", description: "Log out of Linkstacks."}
    ];

    all() {
        if (BrowserInfo.isIOSApp())
            return this.packages.filter(i => i.slug !== "membership");
            
        return this.packages;
    }
}