import "./SiteLoginElement-style";
import { AuthenticationStore, LoginResponse } from "features/authentication";
import * as formsStyle from "../forms/forms.module.less";
import { Routing, RouteChangeOrigin } from "lib/Routing";
import { AppContextStore } from "features/application-context/AppContextStore";
import * as style from "./SiteRegisterElement-module.less"
class SiteRegisterElement extends HTMLElement {
    private named = <T extends HTMLElement> (name: string): T => this.querySelector(`[name=${name}]`) as T;
   
    private form: HTMLFormElement;
    private registerButton: HTMLButtonElement;
    private usernameGroup: HTMLElement;
    private emailGroup: HTMLElement;
    private passwordGroup: HTMLElement;
    private passwordRepeatGroup: HTMLElement;
    private socialProviders: HTMLElement;

    connectedCallback() {
        this.innerHTML = this.view();
        this.classList.add(formsStyle.loginControl);

        this.form = this.querySelector("form");
        this.usernameGroup = this.named("username");
        this.emailGroup = this.named("email");
        this.passwordGroup = this.named("password");
        this.passwordRepeatGroup = this.named("repeat-password");
        this.socialProviders = this.querySelector("external-login");

        this.socialProviders.addEventListener("success", this.loggedInProvider);
        this.socialProviders.addEventListener("complete-external", this.completeExternal);

        this.usernameGroup.addEventListener("validate", async (event: CustomEvent) => {
            if (event.detail === "")
                return;

            const status = await AuthenticationStore.instance.usernameAvailable(event.detail);
            this.usernameGroup.setAttribute("error-text", status ? "" : "Sorry, this username is not available.");
        });

        this.emailGroup.addEventListener("validate", async (event: CustomEvent) => {
            if (event.detail === "")
                return;

            const status = await AuthenticationStore.instance.emailAvailable(event.detail);
            this.emailGroup.setAttribute("error-text", status ? "" : "Sorry, this email address is already registered.");
        });

        const checkPassword = () => {
            if (!this.passwordGroup.hasAttribute("touched"))
                return;

            if (!this.passwordRepeatGroup.hasAttribute("touched"))
                return;

            if (this.passwordGroup.getAttribute("value") !== this.passwordRepeatGroup.getAttribute("value")) {
                this.passwordGroup.setAttribute("error-text", "Passwords must match.");
                this.passwordRepeatGroup.setAttribute("error-text", "Passwords must match.");
            } else {
                this.passwordGroup.setAttribute("error-text", "");
                this.passwordRepeatGroup.setAttribute("error-text", "");
            }   
        }

        this.passwordGroup.addEventListener("validate", checkPassword);
        this.passwordRepeatGroup.addEventListener("validate", checkPassword);
        this.registerButton = this.named("register");
        this.registerButton.addEventListener("click", this.register);

        this.addEventListener("blur", this.checkForm);
        this.addEventListener("validity", this.checkForm);
    
        this.form.addEventListener("submit", async (event) => {
            event.preventDefault();
            await this.register();
        });

        this.usernameGroup.focus();
        setTimeout(this.checkForm, 200);
    }

    private completeExternal = (event: CustomEvent) => {
        const response = event.detail as LoginResponse;
        const siteExternalUserRegistration = document.createElement("site-external-registration");
        if (response.username)
            siteExternalUserRegistration.setAttribute("username", response.username);

        if (response.email)
            siteExternalUserRegistration.setAttribute("email", response.email);

        if (response.sameEmailUser)
            siteExternalUserRegistration.setAttribute("same-email-user", response.sameEmailUser);

        siteExternalUserRegistration.setAttribute("provider", response.provider);
        siteExternalUserRegistration.setAttribute("show-logo", "");
        this.replaceWith(siteExternalUserRegistration);
    };

    private loggedInProvider = () => {
        document.location.href ="/";
    };

    private register = async () => {
        if (this.registerButton.hasAttribute("disabled")) 
            return;

        try {
            this.registerButton.setAttribute("state", "busy");
            await this.doRegister(
                this.usernameGroup.getAttribute("value"),
                this.emailGroup.getAttribute("value"),
                this.passwordGroup.getAttribute("value"));
        } 
        finally{
            this.registerButton.setAttribute("state", "done");
        }
    }

    private async doRegister(username: string, email: string, password: string) {
        const result = await AuthenticationStore.instance.register(username, email, password);
        if (result.succeeded) {
            this.replaceWith(document.createElement("site-confirm-email"));
            return;
        } else {
            alert("Unable to register at this time. Please try again.");
        }
    }

    private isFormFullyFilled = () => this.querySelectorAll("input[required]").length === this.querySelectorAll("input[touched]").length;

    private checkForm = () => {
        if (!this.isFormFullyFilled())
            return;

        this.registerButton.toggleAttribute("disabled", !this.form.checkValidity());
    };


    view = () => `
        <form autocapitalize=none>
            <div name=logo class="${style.logo}">
                <a href="/">${require("!!raw-loader!image/logo-solo.svg")}</a>
            </div>
            <header class="${style.registerHeader}">
                <h1>Create account</h1>
                <p>Sign in instead? <a href="/login" route>Sign in ❯ </a></p>
            </header>
            <section>
        
                <form-group 
                    name=username
                    label=Username 
                    pattern="^[a-zA-Z0-9]*$"
                    minlength=2
                    maxlength=30
                    autocomplete=username></form-group>

                <form-group 
                    name=email
                    label=Email 
                    pattern="^.+@.+\\..+$"
                    autocomplete=email></form-group>

                <form-group 
                    name=password
                    label=Password 
                    type=password
                    autocomplete=new-password></form-group>

                <form-group 
                    name=repeat-password
                    label="Repeat password" 
                    type=password
                    autocomplete=new-password></form-group>

                <progress-button 
                    disabled 
                    name=register 
                    busy-text="Registering ..."
                    success-text="Registered" 
                    disable-query="input, button">Create account</progress-button>

                <external-login sign-up></external-login>
            </section>
            <footer>
             
            </footer>
        </form>
    `;
}

customElements.define("site-register", SiteRegisterElement);

Routing.instance.onChange(async route => {
    if (route.origin === RouteChangeOrigin.PageLoad)
        return;

    if (route.path[0] === "register") {
        await AppContextStore.instance.present("register", document.createElement("site-register"), "Register");
    }
});