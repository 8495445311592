import * as formsStyle from "../forms/forms.module.less";   
import * as confirmStyle from "./SiteConfirmEmailElement-module.less";

class SiteConfirmEmailElement extends HTMLElement {

    connectedCallback() {
        this.innerHTML = this.view();
        this.classList.add(formsStyle.loginControl);
        this.style.border = "none";
    }

    private view = () => `
        <header class=${confirmStyle.header}>
            <h1>Please check your email</h1>
        </header>
        <section>
            <p>We have now sent you a message containing a verification link.</p>
            <p>We do this to make sure that your email is valid and active, so that we know where to get a message to you in the future.</p>
        </section>
    `;
}

customElements.define("site-confirm-email", SiteConfirmEmailElement);