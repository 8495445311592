import "./SwitchButtonElement-style";

class SwitchButtonElement extends HTMLElement {
    private isRendered = false;
    connectedCallback() {
        if (this.isRendered)
            return;

        this.innerHTML = "<span>ON</span><span class=off>OFF</span>";
        this.addEventListener("click", this.toggle);
        this.isRendered = true;
    }

    disconnectedCallback() {
        this.removeEventListener("click", this.toggle);
    }

    private toggle = () => {
        if (this.hasAttribute("checked"))
            this.removeAttribute("checked");
        else
            this.setAttribute("checked", "");
        
        this.dispatchEvent(new Event("change"));
    }
}

customElements.define("switch-button", SwitchButtonElement);
