import "./DomainsWidgetElement-style";
import * as cardsStyle from "../../../../styles/cards-module.less"
import * as widgetStyle from "../Widgets-module.less";
import { BaseWidget, PreferSize } from "../BaseWidget";

interface DomainStat {
    domain: string;
    count: number;
}

class DomainsWidgetElement extends BaseWidget {
    private range = (size, startAt = 0) => [...Array(size).keys()].map(i => i + startAt);

    public get preferredWidth(): PreferSize {
        return "wide";
    }

    connectedCallback() {
        const domains: DomainStat[] = [
            { domain: "Google.com", count: 23 },
            { domain: "Facebook.com", count: 14 },
            { domain: "LinkedIn.com", count: 22 },
            { domain: "Microsoft.com", count: 12 },
            { domain: "Medium.com", count: 10 },
            { domain: "Gmail.com", count: 7 },
            { domain: "Youtube.com", count: 5 },
            { domain: "TV2.dk", count: 30 },
            { domain: "DR.dk", count: 30 },
            { domain: "Other", count: 20 },
        ]

        super.connectedCallback();
        this.className += ` ${cardsStyle.cardContainer}`;
        const total = domains.map(m => m.count).reduce( (a, b) => a + b);
        this.innerHTML = this.view(domains, total);

        

    }

    disconnectedCallback() {
        super.disconnectedCallback();
    }

    private view = (domains: DomainStat[], total: number) => `
        <header>
            <h3>Top domains</h3>
        </header>
        <div class="${cardsStyle.card} ${widgetStyle.card}" name=facts>
            <div name=bars>
                ${this.range(domains.length).map(i => `
                    <div style="height: 100%; width: ${total/100 * domains[i].count}%; background-color: var(--a-color-${i})">
                    </div>
                `).join("")}
            </div>
            <div name=names>
                ${this.range(domains.length).map(i => `
                    <div>
                        ${domains[i].domain}
                    </div>
                `).join("")}
            </div>
        </div> 
    `;
}

customElements.define("domains-widget", DomainsWidgetElement);