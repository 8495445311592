import { EventEmitter } from "events"

export class TaskStore extends EventEmitter {
    private tasks = [];
    private static _instance = new TaskStore();

    static get instance() {
        return TaskStore._instance;
    }

    private emitInProgress(message?: string) {
        this.emit("InProgress", message);
    }

    public onInProgress(callback: (message?: string) => void) {
        this.on("InProgress", callback);
    }

    public offInProgress(callback: (message?: string) => void) {
        this.removeListener("InProgress", callback);
    }

    private emitCompletedTask(message?: string) {
        this.emit("CompletedTask", message);
    }

    public onCompletedTask(callback: (message?: string) => void) {
        this.on("CompletedTask", callback);
    }

    private emitComplete(message?: string) {
        this.emit("Complete", message);
    }

    public onComplete(callback: (message?: string) => void) {
        this.on("Complete", callback);
    }

    public offComplete(callback: (message?: string) => void) {
        this.removeListener("Complete", callback);
    }


    async add<T>(promise: Promise<T>, message?: string) : Promise<T> {
        this.tasks.push(true);
        this.emitInProgress(message);

        try {
            return await promise;
        } finally {
            this.tasks.pop();
            this.emitCompletedTask();
            if (this.tasks.length === 0){
                this.emitComplete();
            }
        }
    }
}

export interface Task {

}