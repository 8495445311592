import { DialogController } from "features/dialog";
import { AuthenticationStore, AuthenticationMode } from "features/authentication";
import { TaskStore } from "lib/Tasks";
import { Delay } from "lib/System";
import { NotifyViewController, NotifyHandle } from "features/notification/NotifyViewController";

export type HttpMethod = "post" | "get" | "delete";

export type CredentialsMode = "omit" | "same-origin" | "include";

export class Request implements RequestInit {
    public credentials?: CredentialsMode = "same-origin";
    public headers = { };
    public body: any;
    public method: HttpMethod = "get";

    authenticate = (mode?: AuthenticationMode) : Request => {
        if ((mode || AuthenticationStore.instance.mode) === AuthenticationMode.Header) {
            this.headers["Authorization"] = AuthenticationStore.instance.token;
            this.credentials = "same-origin";
        } else {
            this.credentials = "same-origin";
        }
        return this;
    }

    setSignal(signal: AbortSignal) : Request {
        (<any>this).signal = signal;
        return this;
    }

    static get delete() : Request {
        return new Request().setMethod("delete");
    }

    static get post() : Request {
        return new Request().setMethod("post");
    }

    static get get() : Request {
        return new Request().setMethod("get");
    }

    setCredentials(credentialsMode: CredentialsMode) {
        this.credentials = credentialsMode;
        return this;
    }
    
    setJSON(obj: any): Request {
        this.setMethod("post");
        this.headers["content-type"] = "application/json";
        this.headers["Accept"] = "application/json";

        this.body = JSON.stringify(obj);
        return this;
    }

    html(): Request {
        this.headers["content-type"] = "html/text";
        this.headers["Accept"] = "html/text";
        return this;
    }

    setJSONFromFields(root: HTMLElement): Request {
        const data = {};
        [].slice.call(root.querySelectorAll("input, textarea")).forEach((element: HTMLInputElement) => {
            data[element.name] = element.value;
        });
        return this.setJSON(data);
    }

    setText(text: string): Request {
        this.setMethod("post");
        this.headers["content-type"] = "text/plain";
        this.body = text;
        return this;
    }

    setBody(body: any) : Request {
        this.body = body;
        return this;
    }

    setMethod(method: HttpMethod): Request{
        this.method = method;
        if (method === "post") {
            this.headers["x-token"] = Request.getSourceToken()
        }

        return this;
    }

    static getSourceToken() : string {
        return Request.getCookie("_token");
    }

    static getCookie(name) {
        const nameEQ = `${name}=`;
        const ca = document.cookie.split(";");
        for(let i=0;i < ca.length;i++) {
            let c = ca[i];
            while (c.charAt(0) === " ") {
                c = c.substring(1,c.length);
            }
            if (c.indexOf(nameEQ) === 0) {
                return c.substring(nameEQ.length,c.length);
            }
        }
        return undefined;
    }

    private static showingDialog = false;

    static async checkLoggedInStatus(response: Response) {
        if (response && !response.ok){
             if (response.status === 401) {
                 if (!Request.showingDialog) {
                    Request.showingDialog = true;
                    try {
                        await new DialogController().prompt({
                            caption: "Logged out",
                            message: "You have been logged out.",
                            options: ["Log in again"]
                        });
                    } finally {
                        Request.showingDialog = false;
                    }
                    window.location.href = `/?returnurl=${window.location.href}`;
                }
             }
        }
    }

    static throwOnServerError(response: Response ){
        if (response.ok)
            return;

        throw new Error(`Invalid statusCode ${response.status} response ${response.statusText}`);
    }
}

export class Pipeline {
    private _showLoader = true;
    private _ignoreLoggedInStatus = false;
    //private _beQuiet = false;
    private _throwOnError = false;
    private static _offlineNotifyHandle: NotifyHandle;
    private static backAway = 0;

    hideLoader() {
        this._showLoader = false;
        return this;
    }

    beQuiet() {
        //this._beQuiet = true;
        return this;
    }


    ignoreLoggedInStatus() {
        this._ignoreLoggedInStatus = true;
        return this;
    }

    throwOnError() {
        this._throwOnError = true;
        return this;
    }

    private async showOffline() {
        if (!Pipeline._offlineNotifyHandle)
            Pipeline._offlineNotifyHandle = new NotifyViewController().showWeb({ title : "Trying to connect to server", showLoader: true });
        
        await Delay.wait(4000);
    }

    private static offlineThrottleCounter = 0;


    private static pendingRequests: { [key: string]: Promise<Response> } = {};

    async fetchQueued(input: RequestInfo, init?: RequestInit) : Promise<Response> {
        const url = input as string;
        
        if (!Pipeline.pendingRequests[url])
            Pipeline.pendingRequests[url] = this.fetch(input, init);

        return await Pipeline.pendingRequests[url];
    }

    async fetch(input: RequestInfo, init?: RequestInit): Promise<Response>{
        let response: Response;
        
        try {
            let request = fetch(input, init);
        
            if (this._showLoader)
                request = TaskStore.instance.add(request);
            
             response = await request;

            if (response.ok && Pipeline._offlineNotifyHandle) {
                Pipeline._offlineNotifyHandle.hide();
                Pipeline._offlineNotifyHandle = undefined;
                Pipeline.backAway = 0;
            }

             if (response.status >= 500)
                throw new Error(response.statusText);

             Pipeline.offlineThrottleCounter = 0;
        } catch (error) {
            if (init && init.signal) {
                if (init.signal.aborted)
                    return;
            }

            await Delay.wait(1000 * Pipeline.backAway);
            
            if (Pipeline.backAway < 5)
            Pipeline.backAway++;
            
            if (this._throwOnError)
                throw error;

            // if (!this._beQuiet) 
            //     await this.showOffline();
            // else {
                Pipeline.offlineThrottleCounter++;
                
                if (Pipeline.offlineThrottleCounter > 3) {
                    Pipeline.offlineThrottleCounter = 0;
                    await this.showOffline();
                }    
            // }

            await Delay.wait(2000);
        }

        if (!this._ignoreLoggedInStatus)
            await Request.checkLoggedInStatus(response);
            
        return response;
    }


}