import { DOM } from "lib/DOM";

export class HotkeyStore {
    private static _instance = new HotkeyStore();
    private hash = (source: KeyboardEvent | Hotkey)  => `${source.altKey ? "alt+" : ""}${source.ctrlKey ? "ctrl+" : ""}${source.code || source.key}`;
    private globalHandlers: { [key: string]: () => void } = {};


    private _keySets: { [key: string]: KeySet[]  } = {
        "global": [
            { id: "addUrl", description: "Add url", hotkeys: [ { altKey: true, code: "KeyL" } ] },
            { id: "showHub", description: "Show hub page", hotkeys: [ { altKey: true, code: "KeyH" } ] },
            { id: "showBookmarks", description: "Show bookmarks page", hotkeys: [ { altKey: true, code: "KeyB" } ] },
            { id: "showFeeds", description: "Show feeds page", hotkeys: [ { altKey: true, code: "KeyR" } ] },
            { id: "showSearch", description: "Show search page", hotkeys: [ { altKey: true, code: "KeyS" } ] },
            { id: "showProfile", description: "Show profile page", hotkeys: [ { altKey: true, code: "KeyP" } ] }
        ],
        "bookmarks": [
            { id: "showInbox", description: "Show inbox", hotkeys: [ { altKey: true, code: "KeyI" } ] },
            { id: "createStack", description: "Create stack", hotkeys: [ { altKey: true, code: "KeyC" } ] },
            { id: "filters", description: "Filters", hotkeys: [ { altKey: true, code: "KeyF" } ] }
        ],
        "feeds": [
            { id: "previousArticle", description: "Previous article", hotkeys: [ { key: "j" }, { key: "backspace" } ] },
            { id: "nextArticle", description: "Next article", hotkeys: [ { key: "k" }, { key: "space" } ] }
        ]
    };    

    static get instance() { return HotkeyStore._instance; } 
    
    get keySets() : { [key: string]: KeySet[]  } {
        return this._keySets;
    }

    private constructor() {
        window.requestIdleCallback(async () => {
            await DOM.ready();

            window.addEventListener("keydown", (ev: KeyboardEvent) => {
                if ((event.target as HTMLElement).closest("input, password, textarea"))
                    return;

                const element = event.target as HTMLElement;

                if (element.hasAttribute && element.hasAttribute("contenteditable"))
                    return;

                const hotkey = this.globalHandlers[this.hash(ev)];
    
                if (hotkey) {
                    ev.preventDefault();
                    ev.cancelBubble = true;
                    hotkey();
                }
                    
            });

        }, { timeout: 1000});
    }

    confirm(id: string) : HotkeyStore {
        for(const area of Object.values(this._keySets)) {
            for(const keySet of area) {
                if (keySet.id === id)
                    return this;
            }
        }

        throw new Error(`KeySet does not exist: ${id}.`);
    }

    registerGlobal(id: string, callback: () => void) {
        const global = this._keySets["global"];

        const keySet = global.filter(m => m.id === id)[0];

        if (!keySet) {
            console.log(`Global key does not exist: ${id}.`);
            return;
        }

        keySet.hotkeys.forEach(hotkey => {
            const lookup = this.hash(hotkey);
            
            if (this.globalHandlers[lookup])
                throw new Error(`Global hotkey already exists '${lookup}', id '${id}'.`);
                
            this.globalHandlers[lookup] = callback;
        });
    }
}

export interface KeySet {
    id: string; 
    description: string;
    hotkeys: Hotkey[];
}

export interface Hotkey {
    key?: string;
    code?: string;
    altKey?: boolean;
    ctrlKey?: boolean;
}


