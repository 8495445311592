import { UrlProvider } from "lib/UrlProvider";
import { Pipeline, Request } from "lib/HTTP";
import { cityline } from "features/cityline";
import EventTarget from "@ungap/event-target";
import { ClassTypename, Typename } from "lib/Types";

interface EventMap {
    "changed": HubSettings;
}

@ClassTypename("HubStore")
export class HubStore {
    private eventTarget = new EventTarget();
    private _hubSettings: HubSettings;
    private _initializer: Promise<void>;
    private static _instance = new HubStore();
    static get instance() { return HubStore._instance; }
    private constructor() {
        this._initializer = new Promise(async (r) => {
            this._hubSettings = await cityline.getFrame<HubSettings>("hub");
            cityline.addEventListener("hub", this.hubSettingsChangedHandler);
            r();
        });
    }

    addEventListener<K extends Extract<keyof EventMap, string>>(type: K, listener: (this: EventSource, ev: CustomEvent<EventMap[K]>) => any, options?: boolean | AddEventListenerOptions) {
        return this.eventTarget.addEventListener(type, listener, options);
    }
    
    removeEventListener<K extends Extract<keyof EventMap, string>>(type: K, listener: (this: EventSource, ev: CustomEvent<EventMap[K]>) => any, options?: boolean | AddEventListenerOptions) {
        return this.eventTarget.removeEventListener(type, listener, options);
    }
    
    private emit<K extends Extract<keyof EventMap, string>, T extends EventMap>(type: K, value: T[K]) {
        return this.eventTarget.dispatchEvent(new CustomEvent(type, { detail:  Object.assign({}, value, { [Typename] : HubStore[Typename] })})); 
    }
    
    private hubSettingsChangedHandler = (event: CustomEvent<HubSettings>) => {
        this._hubSettings = event.detail;
        this.emit("changed", this._hubSettings);
    }

    async getSettings() {
        await this._initializer;
        return this._hubSettings;
    }

    async hide(widget: string) {
        const url = `${new UrlProvider().root}/api/hub/${widget}`;
        await new Pipeline().fetch(url, Request.delete.authenticate());
    }

    async show(widget: string) {
        const url = `${new UrlProvider().root}/api/hub/${widget}`;
        await new Pipeline().fetch(url, Request.post.authenticate());
    }
}

export interface HubSettings {
    hiddenWidgets: string[];
    visibleWidgets: string[];
}
