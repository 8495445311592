import { UrlProvider } from "lib/UrlProvider";
import { ILoginProvider } from "./ILoginProvider";
import { LoginResponse } from "../Model";
import { Pipeline, Request } from "lib/HTTP";
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import { DOM } from "lib/DOM";


interface GoogleResponse {
    accessToken: string;
}

interface LoginRequest {
    scope: string;
    webClientId: string;
    offline: boolean;
}

interface GooglePlusPlugin {
    login(request: LoginRequest, success: (response: GoogleResponse) => void, error: (error) => void): void;
    isAvailable(callback): void;
    trySilentLogin(options, successCallback, errorCallback): void;
    logout(successCallback, errorCallback): void;
    disconnect(successCallback, errorCallback): void;
    getSigningCertificateFingerprint(successCallback, errorCallback): void;
}

export class GoogleNativeLoginProvider implements ILoginProvider {
    static async init() {
        await DOM.ready();

        GoogleAuth.initialize({
            clientId: '1019953375418-013mvp0e8vbq48ee6itps37dupkrea2m.apps.googleusercontent.com',
            scopes: ['profile', 'email'],
            grantOfflineAccess: true,
        });
    }
    getName = () => "GoogleNativeLoginProvider";
     
    async login(): Promise<LoginResponse> {
        
        
        const googleUser = await GoogleAuth.signIn();

        if (!googleUser?.authentication?.idToken)
            return { status: "failure"};

        console.log("Google user", googleUser);

        const credentials = { token: googleUser.authentication.idToken };
        const response = await new Pipeline().fetch(`${new UrlProvider().root}/api/external-login/google`, new Request().setJSON(credentials));

        if (!response.ok)
            return { status: "failure"};

        const loginResponse = await response.json() as LoginResponse;

        loginResponse.provider = "Google";
        loginResponse.email = googleUser.email;

        console.log("Returning", loginResponse);

        return loginResponse;

        // return new Promise((resolve, reject) => {
        //     try {
        //         this.google.login(
        //             {
        //                 "scope": "https://www.googleapis.com/auth/userinfo.email+https://www.googleapis.com/auth/userinfo.profile",
        //                 "webClientId": "1019953375418-013mvp0e8vbq48ee6itps37dupkrea2m.apps.googleusercontent.com", // this points to our server side id to be able to get data later
        //                 "offline": true
        //             },
        //             async (googleResponse: GoogleResponse) => {
        //                 console.log("response was ", googleResponse);

        //                 const credentials = { token: googleResponse.accessToken };
        //                 const response = await new Pipeline().fetch(`${new UrlProvider().root}/api/external-login/google`, new Request().setJSON(credentials));
    
        //                 if (!response.ok)
        //                     reject("Unable to login");
    
        //                 const loginResponse = await response.json() as LoginResponse;
        //                 resolve(loginResponse);
        //             },
        //             err => {
        //                 console.log("Rejected by login sdk", err);
        //                 reject("Unable to login");
        //             }
        //         );    
        //     } catch(error) {
        //         console.log("Unable to login via google", error);
        //         reject("Unable to login");
        //     }
            
        // });
    }    
    
}



try { 
    GoogleNativeLoginProvider.init();
} catch (error) {
    console.log("Unable to initialize google login provider", error);
}