export class MemoryValueStore {
    private _values: { [id: string] : any; } = {};

    get<TValue>(key: string) : Promise<TValue> { 
        return Promise.resolve(this._values[key]);
    }

    set<TValue>(key: string, value: TValue): Promise<void> { 
        this._values[key] = value;
        return Promise.resolve();
    }
}