import { AuthenticationStore } from "./AuthenticationStore";

import { DialogController } from "features/dialog";
import { Routing } from "lib/Routing";

class ForgotPasswordElement extends HTMLElement {
    public static ClassName = "ForgotPasswordElement";
    async connectedCallback() {
        this.innerHTML = this.view();
        this.classList.add("form");
        setTimeout(() => {
            this.style.opacity = "1";
        });

        const sendLink = this.querySelector("[name=send-link]") as HTMLElement;
        sendLink.addEventListener("click", async () => {
            const usernameOrEmailElement = this.querySelector("[name=username-or-email]") as HTMLInputElement;
            if (usernameOrEmailElement.value === "") {
                const usernameOrEmailErrorElement = this.querySelector("model.UsernameOrEmail") as HTMLInputElement;
                usernameOrEmailErrorElement.innerText = "This field is requered";
            } else {
                this.setAttribute("busy", "");
                let result;
                try {
                    result = await AuthenticationStore.instance.forgotPassword(usernameOrEmailElement.value);
                } finally {
                    this.removeAttribute("busy");
                }
                
                if (result) {
                    await new DialogController().prompt({
                        caption: "Check your email",
                        message: "If your account matches you will receive an email with a reset link.",
                        options: ["Ok"]
                    });
                    Routing.instance.go("/login");
                } else {
                    await new DialogController().prompt({
                        caption: "Unable to recover password",
                        message: "Please verify the information you provided and contact us if the problem persists.",
                        options: ["Ok"]
                    });
                }

            }
        });
    }

    


    private view = () => `
        <header>
            <a>Forgot your password?</a>
        </header>
        <section>
            <p>Enter your username or email.</p>

            <div class=form-group>
                <label for="username-or-email">Username or email</label>
                <div>
                    <input autocapitalize="none" autocorrect="off" class="form-control" id="username-or-email" name="username-or-email" placeholder="Username or email" type="text" value="">
                    <span name="model.UsernameOrEmail" class="error"></span>
                </div>
            </div>
            <button type=button class=power name=send-link>Send me a link</button>
            <button type=button route href="/">Go back</button>
        </section>
    `;
}

Routing.instance.onChange(route => {
    if (route.path[0] === "forgot-password") {  
        const loginFlow = document.querySelector("login-flow .content");
        loginFlow.innerHTML = "<forgot-password></forgot-password>";
    }
});

customElements.define("forgot-password", ForgotPasswordElement);