import "./AppsControlElement-style";
import { AppContextStore } from "features/application-context/AppContextStore";
//import { AppsStore } from "features/apps-index/AppsStore";
import { Package } from "features/packages/Package";
import { Routing } from "lib/Routing";

class AppsControlElement extends HTMLElement {
    protected appsStore: import("features/apps-index/AppsStore").AppsStore;

    async connectedCallback() {
        this.appsStore = (await import( /* webpackChunkName: "packages" */ "features/apps-index/AppsStore")).AppsStore.instance;

        const packages = await this.appsStore.all();
        this.innerHTML = this.view(packages);
    }

    private view = (packages: Package[]) => `${packages.map(thePackage => this.packageView(thePackage)).join("")}`;

    private packageView = (thePackage: Package) => `
        <a href="/apps/${thePackage.slug}" route>
            <card>
                <header style="background-image:url('data:image/svg+xml;utf8,${encodeURIComponent(this.appsStore.logo(thePackage.logo))}')"></header>
                <div class="title">${thePackage.name}</div>
                <div class="description">${thePackage.description}</div>
            </card>
        </a>`;
}

customElements.define("apps-control", AppsControlElement);



Routing.instance.onChange(async route => {
    if (route.path[0] === "apps" && !route.path[1]) {
        await AppContextStore.instance.present("", document.createElement("apps-control"), "Bookmarks", "Apps");
        document.title = "Apps - Linkstacks";
    }
});