import { AuthenticationStore } from "features/authentication";

export class ShareActivator {
    static async shareStack(stackId: string, defaultAction: "social" | "collaborate") {
        const element = document.createElement("share-stack");
        element.setAttribute("stack-id", stackId);
        element.setAttribute("active-tab", defaultAction);

        const user = await AuthenticationStore.instance.currentUser();

        if (user.userId === stackId.split("/")[0])
            element.setAttribute("is-owner", "");

        document.body.appendChild(element);
    }

}