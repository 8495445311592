import { IRevision, Simplified } from "features/events/EventsModel";
import { EventEmitter } from "events";
import { cityline } from "features/cityline";

export class EventsStore extends EventEmitter {
    private static _instance: EventsStore = new EventsStore();
    private currentSequence: number;
    private _initializer: Promise<void>;
   
    private constructor() {
        super();
        this._initializer = this.initialize();
        window.requestIdleCallback(async () => {
            await this._initializer;
        });      
    }

    async getHistoricRevisions() : Promise<IRevision[]>{
        return await cityline.getFrame<IRevision[]>("link-revisions");
    }

    private initialize = async () => {
        const initial = await cityline.getFrame<IRevision[]>("link-revisions");
        this.currentSequence = Math.max(...initial.map(m => m.sequence));

        cityline.addEventListener("link-revisions", (event: CustomEvent<IRevision[]>) => {

            let incomingRevisions = event.detail;
            if (this.currentSequence) {
                incomingRevisions = incomingRevisions.filter(m => m.sequence > this.currentSequence);
            }

            if (incomingRevisions.length === 0)
                return;

            this.currentSequence = Math.max(...incomingRevisions.map(m => m.sequence));

            const revisions = this.enrich(incomingRevisions);
            this.emitRevisions(revisions);
        });
    }

   static get instance(): EventsStore {
        return EventsStore._instance;
    }

    private emitRevisions(revision: IRevision[]) {
        this.emit("Revision", revision);
    }

    onRevision(callback: (revision: IRevision[]) => void) {
        this.on("Revision", callback);
    }

    private enrich(revisions: IRevision[]) {
        revisions.forEach(revision => {
            revision.whenDate = new Date(revision.when);

            switch (revision.action.$type) {
                case "moveFromStackToStackCommand":
                case "addingLinkCommand":
                    revision.simplified = Simplified.Created;
                    break;
                case "deleteCommand":
                    revision.simplified = Simplified.Deleted;
                    break;
                case "updateCommand":
                case "enrichedCommand":
                case "createCommand":
                case "addTagsToLinkCommand":
                case "refreshedMetadataCommand":
                    revision.simplified = Simplified.Updated;
                    break;
                default:
                    revision.simplified = Simplified.Unknown;
                    break;
            }
        });
        return revisions;
    }
}
