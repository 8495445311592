import { Request } from "lib/HTTP"
import { UrlProvider } from "lib/UrlProvider";

export module Tracking {
    export class LinkTracker {
        private urlProvider = new UrlProvider();

        constructor() {
            window.addEventListener("load", () => setTimeout(() => this.setup(), 200));
        }

        private async setup() : Promise<void>  {
            // select the target node
            const target = document.body;

            Array.prototype.slice.call(document.querySelectorAll("a[data-real-url]")).forEach(atag => {
                const urlAttribute = atag.attributes.getNamedItem("data-real-url");
                const hrefAttribute = atag.attributes.getNamedItem("href");
                hrefAttribute.value = urlAttribute.value;
            });


            target.addEventListener("click", async event => {
                setTimeout(async () => {
                    const clickedElement = <HTMLElement>event.target;

                    
                    let atag = <HTMLAnchorElement>event.target;

                    if (!atag.attributes || !atag.attributes["data-track"]) {
                        if (!(<any>clickedElement).closest)
                            return;

                        atag = <HTMLAnchorElement>(<any>atag).closest("a[data-track]");
                        if (!atag)
                            return;
                    }
                    
                    
                    if (event.target && atag.attributes) {
                        const urlAttribute = atag.attributes.getNamedItem("data-track");
                        
                        if (urlAttribute) {
                            const response = await fetch(`${this.urlProvider.root}/api/tracking/collect`, new Request().setJSON({target:urlAttribute.value}));
                            Request.throwOnServerError(response);
                        }
                    }
                }, 1000);
            });
        }
    }

    //tslint:disable-next-line: no-unused-variable
    const linkTracker = new LinkTracker();
}