import "./GestureViewerElement-style";
import { Formatting } from "lib/Formatting";
import { GestureStore, GestureSet } from "./GestureStore";

class GestureViewerElement extends HTMLElement {
    public static ClassName = "GestureViewerElement";
    // protected titleText: string = "Gestures";
    private escape = Formatting.htmlEscape;
    private _areas = [
        // { area: "global", descritpion: "Global" },
        { area: "bookmarks", descritpion: "Bookmarks" },
        { area: "feeds", descritpion: "Feeds" }
    ]

    private icons = {
        "tap": require("!!raw-loader!image/tap-45.svg"),
        "swipe-left": require("!!raw-loader!image/swipe-left-45.svg"),
        "swipe-right": require("!!raw-loader!image/swipe-right-45.svg"),
        "long-press": require("!!raw-loader!image/long-press-45.svg")
    };

    async connectedCallback() {
        // super.connectedCallback();
        const keysets = GestureStore.instance.gestureSets;
        this.insertAdjacentHTML("beforeend", this.view(keysets));
    }   

    private keyView = (gestureSet: GestureSet) => this.escape`
        <div class=gesture-set>
            <div class=keys>$${this.icons[gestureSet.gesture] || gestureSet.gesture}</div>
            <div class=desc>${gestureSet.description}</div>
        </div>`;

    private areaView = (gestureSet: GestureSet[], description: string) => this.escape`
        <h1>${description}</h1>
        $${gestureSet.map(this.keyView).join("")}   
    `;

    private view = (gestures: { [key: string]: GestureSet[]  }  ) => this.escape`
        <div name=window-area>
            $${this._areas.map(a => this.areaView(gestures[a.area], a.descritpion)).join("")}
        </div>
    `;
}

customElements.define("gesture-viewer", GestureViewerElement)
