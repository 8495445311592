import "./SiteLoginElement-style";
import { AuthenticationStore } from "features/authentication";
import * as formsStyle from "../forms/forms.module.less";   

class SiteExternalRegistrationElement extends HTMLElement {
    private named = <T extends HTMLElement> (name: string): T => this.querySelector(`[name=${name}]`) as T;
    private usernameGroup: HTMLElement;
    private emailGroup: HTMLElement;
    private registerButton: HTMLButtonElement;
    private mergeButton: HTMLButtonElement;
    private form: HTMLFormElement;

    connectedCallback() {

        this.classList.add(formsStyle.loginControl);

        const sameEmailUser = this.getAttribute("same-email-user"); 
        if (sameEmailUser) {
            this.setupMerge(sameEmailUser);
            return;
        }

        this.innerHTML = this.view();
        this.form = this.querySelector("form");
        this.registerButton = this.named("register");
        this.usernameGroup = this.named("username");
        this.usernameGroup.addEventListener("validate", this.validateUsernameHandler);

        this.emailGroup = this.named("email");
        this.emailGroup?.addEventListener("validate", this.validateEmailHandler);

        this.registerButton.addEventListener("click", this.register);

        this.addEventListener("blur", this.checkForm);
        this.addEventListener("validity", this.checkForm);
    
        this.form.addEventListener("submit", async (event) => {
            event.preventDefault();
            await this.register();
        });

        this.usernameGroup.focus();
        setTimeout(this.checkForm, 200);
    }

    private setupMerge(sameEmailUser: string) {
        this.innerHTML = this.mergeView(sameEmailUser);
        this.mergeButton = this.named("add-login");
        this.mergeButton.addEventListener("click", this.merge);

    }

    private merge = async () => {
        await AuthenticationStore.instance.mergeExternal();        
        this.closeHandler();
    };

   

    disconnectedCallback() {
        
    }

    private validateUsernameHandler = async (event: CustomEvent) => {
        if (event.detail === "")
            return;

        const status = await AuthenticationStore.instance.usernameAvailable(event.detail);
        this.usernameGroup.setAttribute("error-text", status ? "" : "Sorry, this username is not available.");
    };

    private validateEmailHandler = async (event: CustomEvent) => {
        if (event.detail === "")
            return;

        const status = await AuthenticationStore.instance.emailAvailable(event.detail);
        this.usernameGroup.setAttribute("error-text", status ? "" : "Sorry, this email is not available.");
    };

    private register = async () => {
        if (this.registerButton.hasAttribute("disabled")) return;


        this.registerButton.setAttribute("state", "busy");

        try {
            await AuthenticationStore.instance.registerExternal(
                this.usernameGroup.getAttribute("value"),
                this.emailGroup?.getAttribute("value"),
            );

            this.registerButton.setAttribute("state", "success");

        } catch(error) {
            this.registerButton.setAttribute("state", "done");
        }

        this.closeHandler();
    }
   
    private closeHandler = () => {
        if (this.closest("auth-switcher"))
            return;
        
        window.location.href = "/";
    };

    private isFormFullyFilled = () => this.querySelectorAll("input[required]").length === this.querySelectorAll("input[touched]").length;

    private checkForm = () => {
        if (!this.isFormFullyFilled())
            return;

        this.registerButton.toggleAttribute("disabled", !this.form.checkValidity());
    };

    view = () => `
        <form autocapitalize=none>
            <div name=logo>
                <a href="/">${require("!!raw-loader!image/logo-solo.svg")}</a>
            </div>
            <header style="height:80px;">
                <h1>Complete registration with ${this.getAttribute("provider")}</h1>
            </header>
            <section>
                <form-group 
                    name=username
                    label=Username 
                    pattern="^[a-zA-Z0-9]*$"
                    value="${this.getAttribute("username") || ""}"
                    minlength=2
                    maxlength=30
                    autocomplete=username></form-group>

                ${this.hasAttribute("email") ? "" : `
                    <form-group 
                        name=email
                        label=Email 
                        pattern="^.+@.+\\..+$"
                        autocomplete=email></form-group>
                `}
                
                <progress-button 
                    disabled 
                    name=register
                    busy-text="Registering ..." 
                    success-text="Registered"
                    disable-query="input, button">Register</progress-button>
            </section>
            <footer>
                
            </footer>
        </form>
    `;

    mergeView = (sameEmailUser: string) => `
    <form autocapitalize=none>
        <div name=logo>
            <a href="/">${require("!!raw-loader!image/logo-solo.svg")}</a>
        </div>
        <header>
            <h1>Complete registration with ${this.getAttribute("provider")}</h1>
        </header>
        <section>
            <p>An existing account has been found with this email.</p> 
            <p>Add ${this.getAttribute("provider")} as login to that user?</p>

            <progress-button 
                type=button 
                name=add-login 
                busy-text="Adding ..." 
                success-text="Added"
                disable-query="input, button">Add login</progress-button>
        </section>
        <footer>
            
        </footer>
    </form>
`;
}

customElements.define("site-external-registration", SiteExternalRegistrationElement);

