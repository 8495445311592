import "./BaseSidebarChildElement-style";
import "./SideBarElement-style";

export abstract class BaseSidebarChildElement extends HTMLElement {
    private closeButtons: HTMLButtonElement[];
    protected abstract titleText: string;
    
    connectedCallback()  {
        this.innerHTML = this.headerView();
        this.closeButtons = Array.from(this.querySelectorAll("[name=close]")) as HTMLButtonElement[];

        for(const closeButton of this.closeButtons) 
            closeButton.addEventListener("click", this.closeHandler);
    }

    disconnectedCallback() {
        for(const closeButton of this.closeButtons) 
            closeButton.removeEventListener("click", this.closeHandler);
    }

    protected closeHandler = async () => {
        const element = document.querySelector("side-bar") as HTMLElement;
        if (element)
            element.removeAttribute("open");
    };

    private headerView = () => `
        <side-bar-header>
            <div></div>
            <div>${this.titleText}</div>
            <div>
                <button name=close>Hide</button>
                <div name=buttons><button name=close>Hide</button></div>
            </div>
        </side-bar-header>
    `;
}