import { AppContextStore } from "features/application-context/AppContextStore";
import { AuthenticationStore, User } from "features/authentication";

import { BaseManagePackageElement } from "features/manage/BaseManagePackageElement";
import { DialogController } from "features/dialog";
import { Routing } from "lib/Routing"

export class DeactivateAccountElement extends BaseManagePackageElement {
    protected static path = ["manage", "deactivate-account"];
    protected slug = "deactivate-account";
    private user: User;

    protected detailView = () => `
        <div class="box case">
            <header>Confirm deactivation</header>
            <div class=description>
                This means that you will not be able to login, and you will not receive any backup mails.
            <div>
            <fieldset>
                <label>To confirm please type your username <span style="text-transform:uppercase">${this.user.username}</span> below.</label>
                <input type="text" name=username />
            </fieldset>
            <fieldset class="right">
                <button class="power" disabled type="submit">Deactivate my account</button>
            </fieldset>
        </div>
    `; 

    protected setupDOM() {
        const submitButton = this.querySelector("button") as HTMLButtonElement;
        const usernameElement = this.querySelector("input[name=username]") as HTMLInputElement;
        usernameElement.addEventListener("keyup", () => {
            if (usernameElement.value.toLocaleLowerCase() === this.user.username.toLocaleLowerCase())
                submitButton.removeAttribute("disabled");
            else
                submitButton.setAttribute("disabled", undefined);
        });

        submitButton.addEventListener("click", async () => {
            const result = await AuthenticationStore.instance.deactivateAccount(usernameElement.value);
            if (result){
                await new DialogController().prompt({
                    caption: "Account Deactivated",
                    message: "You will now be logged out.",
                    options: ["Ok"]
                });
                await AuthenticationStore.instance.logout();
            }
        });
    }

    protected async initialize() {
        this.user = await AuthenticationStore.instance.currentUser();
    }
}

customElements.define("deactivate-account", DeactivateAccountElement);

Routing.instance.onChange(async route => {
    if (route.path[0] === "manage" && route.path[1] === "deactivate-account") {
        await AppContextStore.instance.present("", document.createElement("deactivate-account"), "Manage", "Deactivate account");
    }
});