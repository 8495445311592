import "./TagEditorElement-style";
import { TagStore } from "./TagStore";
import { UrlProvider } from "lib/UrlProvider";
import { Pipeline, Request } from "lib/HTTP";
import { Formatting } from "lib/Formatting";
import { UserPreferenceStore } from "features/user-preferences/UserPreferenceStore";

/**
 * Add and remove tags from an url
 * @element tag-editor
 * @attribute {String} detected-tags - set list of detected tags from the outside
 * @attribute {String} url - as an alternative let the component find tags based on an url
 * @attribute {String} tags - set list of current tags on a url
 * @attribute {Boolean} changed - set by the element when anything has been changed
 * @fires tags-changed - Dispatched when any tags has been changed
 */
class TagEditorElement extends HTMLElement {
    private tagArea: HTMLElement;
    private captionElement: HTMLElement;
    private autoTagButton: HTMLElement;
    private tagInput: HTMLElement;
    private isRendered = false;
    private escape = Formatting.htmlEscape;

    async connectedCallback() {
        if (!this.isRendered) {
            if (!this.hasAttribute("detected-tags"))
                setTimeout(this.refresh, 100);

            const selected = this.getSelectedTags();
            let detected = this.getDetectedTags();

            detected = detected
                .map(tag => tag)
                .filter(m => selected.map(m => m.toLocaleLowerCase()).indexOf(m) === -1);

            this.innerHTML = this.view(selected, detected);
            setTimeout(this.checkAutomaticTagging);
        }

        this.tagInput = this.querySelector("tag-input");

        this.tagInput.addEventListener("added", this.addCustomTagHandler);
        this.captionElement = this.querySelector(".caption");
        this.captionElement.addEventListener("click", this.openToggle);
        this.autoTagButton = this.querySelector("switch-button");
        this.autoTagButton.addEventListener("click", this.autoTagToggleHandler);

        this.tagArea = this.querySelector("[name=tag-area]");
        this.addEventListener("tag-change", this.updateTagsAttribute);

        this.addEventListener("tag-change", this.markChanged, { once: true });

        this.isRendered = true;
    }

    private openToggle = () => this.toggleAttribute("open");

    disconnectedCallback() {
        this.captionElement?.removeEventListener("click", () => this.openToggle);
        this.tagInput?.removeEventListener("added", this.addCustomTagHandler);
        this.autoTagButton?.removeEventListener("click", this.autoTagToggleHandler);
        this?.removeEventListener("tag-change", this.updateTagsAttribute);
        this?.removeEventListener("tag-change", this.markChanged);
    }

    private checkAutomaticTagging = async () => {
        const automaticTagging = await UserPreferenceStore.instance.get<boolean>("automatic-tagging", true);

        if (automaticTagging) {
            this.autoTagButton.setAttribute("checked", "");
            
            const autoTags = Array.from(this.tagArea.querySelectorAll("[auto]"));
            autoTags.forEach(tag => tag.setAttribute("selected", ""));
        }
            
        this.autoTagButton.removeAttribute("disabled");
    }

    private markChanged = () => {
        this.setAttribute("changed", "");
    }

    private getSelectedTags = () => {
        return (this.getAttribute("tags") || "")
            .split(";")
            .filter(m => m !== "");
    }

    private getDetectedTags = () => {
        return (this.getAttribute("detected-tags") || "")
            .split(";")
            .filter(m => m !== "");
    }

    private updateTagsAttribute = () => {
        const tagButtons = Array.from(this.querySelectorAll("tag-button[selected]"));
        const tags = tagButtons.map(m => m.getAttribute("tag")).join(";");
        this.setAttribute("tags", tags);
        this.dispatchEvent(new CustomEvent("tags-changed", { detail: tags }));
    }

    private autoTagToggleHandler = async () => {

        await new Pipeline().fetch(`${new UrlProvider().root}/api/account/automatic-tagging`, Request.post.authenticate().setJSON({ enabled: this.autoTagButton.hasAttribute("checked") }));

        const autoTags = Array.from(this.tagArea.querySelectorAll("[auto]"));
        if (this.autoTagButton.hasAttribute("checked")) {
            autoTags.forEach(tag => tag.setAttribute("selected", ""));
        } else {
            autoTags.forEach(tag => tag.removeAttribute("selected"));
        }
        this.updateTagsAttribute();
    }

    private addCustomTagHandler = (event: CustomEvent<string>) => {
        const existing = this.tagArea.querySelector(`tag-button[id="tag-${event.detail.toLowerCase()}"]`); // i for invariant case does not work in edge
        if (existing) {
            existing.setAttribute("selected", "");
            setTimeout(this.updateTagsAttribute);
            return;
        }

        const tagButton = document.createElement("tag-button");
        tagButton.innerText = event.detail;
        tagButton.setAttribute("selected", "");
        this.tagArea.appendChild(tagButton);
        setTimeout(this.updateTagsAttribute);
    }

    static get observedAttributes() { return ["detected-tags"]; }

    async attributeChangedCallback(attrName, oldVal, newVal) {
        if (attrName === "detected-tags" || attrName === "url") {
            if (this.isRendered)
                await this.refresh();
        }
    }

    private refresh = async () => {
        let detectedTags: string[];
        if (this.getAttribute("link-id")) {
            this.refreshCaption(undefined, "Detecting tags ...");
            detectedTags = await TagStore.instance.autoTag(this.getAttribute("link-id"));
        } else {
            detectedTags = (this.getAttribute("detected-tags") || "").split(";");
        }

        detectedTags = detectedTags.filter(m => m && m !== "");

        this.refreshTags(detectedTags);
        this.refreshCaption(detectedTags.length);
    }

    private refreshTags = (tags: string[]) => {
        const autoTags = Array.from(this.tagArea.querySelectorAll("tag-button[auto]"));
        autoTags.forEach(element => element.remove());

        const select = this.autoTagButton.hasAttribute("checked");

        const filteredTags = [];

        tags.forEach(tag => {
            const existing = this.querySelector(`tag-button[id="tag-${tag.toLowerCase()}"]`); // i for invariant case does not work in edge
            
            if (existing) {
                if (select)
                    existing.setAttribute("selected", "");

                this.updateTagsAttribute();
                return;
            }

            filteredTags.push(tag);
        });

        this.tagArea.insertAdjacentHTML("beforeend", filteredTags.map(tag => this.escape`<tag-button auto${select ? " selected" : ""}>${tag}</tag-button>`).join(""));
        this.updateTagsAttribute();
    }

    private refreshCaption = (tagCount: number = undefined, statusText: string = undefined) => {

        if (statusText) {
            this.captionElement.innerText = statusText;
            return;
        }

        this.captionElement.innerHTML = this.captionView(tagCount);
    }

    private captionView = (tagCount: number = undefined) => this.escape`
        ${tagCount || "No"} detected tag${tagCount !== 1 ? "s" : ""} <button type="button" name="expand-entry">$${require("!!raw-loader!image/arrow-down-light-20-v1.svg")}</button>`;

    private view = (selectedTags: string[], autoTags: string[]) => this.escape`
    
        <div class=auto>Auto tag&nbsp;<switch-button disabled></switch-button></div>
        <div>
            <div class=caption>&nbsp;</div>
            <div name=tag-area>
                $${selectedTags.map(tag => this.escape`
                    <tag-button selected>${tag}</tag-button>
                `).join("")}
                $${autoTags.map(tag => this.escape`
                    <tag-button auto>${tag}</tag-button>
                `).join("")}
            </div>
            <tag-input></tag-input>
       </div>
       <div></div>
   
    `;
}

customElements.define("tag-editor", TagEditorElement);