export class DateTime {
    private static range = (size, startAt = 0) => [...Array(size).keys()].map(i => i + startAt);

    static days = {
        1: "Monday",
        2: "Tuesday",
        3: "Wednesday",
        4: "Thursday",
        5: "Friday",
        6: "Saturday",
        7: "Sunday"
    }

    static months = {
        1: "Jan",
        2: "Feb",
        3: "Mar",
        4: "Apr",
        5: "May",
        6: "Jun",
        7: "Jul",
        8: "Aug",
        9: "Sep",
        10: "Oct",
        11: "Nov",
        12: "Dec"
    }
    
    static UTCToLocal(date: Date): Date {
        const newDate = new Date(date.getTime()+date.getTimezoneOffset()*60*1000);
        const offset = date.getTimezoneOffset() / 60;
        const hours = date.getHours();
    
        newDate.setHours(hours - offset);
    
        return newDate;   
    }

    static LocalToUTC(date: Date) : Date {
        const now_utc =  Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
        return new Date(now_utc);
    }

    static adjustHourFromLocalToUTC = (hour: number) => {
        const timezoneOffset = (new Date().getTimezoneOffset() / 60);
        let adjusted = hour + timezoneOffset;

        if (adjusted >= 24)
            adjusted = adjusted - 24;

        return adjusted;
    }

    static getTheLast24HoursInLocal() : number[] {
        let hour = new Date().getHours();
        const hours = [];
        for(const _ of DateTime.range(24, 1)) {
            hours.push(++hour);
            if (hour >= 24)
                hour = 0;
        }
        return hours;
    }

    static getTheLast7DaysInLocal() : number[] {
        let day = new Date().getDay();
        const days = [];
        for(const _ of this.range(7, 1)) {
            days.push(++day);
            if (day >= 7)
                day = 0;
        }

        return days;
    }

    static getTheLast12MonthsInLocal() : number[] {
        let month = new Date().getMonth() + 1; 
        const months = [];
        for(const _ of this.range(12, 1)) {
            months.push(++month);
            if (month >= 12)
            month = 0;
        }

        return months;
    }

    
}