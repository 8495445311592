import { UrlProvider } from "lib/UrlProvider";

import { Routing } from "lib/Routing";
import { Package } from "features/packages/Package";
import { BasePackageElement } from "features/packages/BasePackageElement";
import "./ExportDataElement-style";
import { AppContextStore } from "features/application-context/AppContextStore";

class ExportDataElement extends BasePackageElement {
    protected slug = "export";    
    private urlProvider = new UrlProvider();

    protected detailView = (thePackage: Package) => `
        <button type="button" class="power" name="get-file">Get file</button>
    `;      

    protected setupDOM() {
        const button = this.querySelector("[name=get-file]") as HTMLElement;
        button.addEventListener("click", () => {
            window.location.href = `${this.urlProvider.root}/api/export/bookmarks?format=netscape bookmark format`;
        });
    }
}

customElements.define("export-data", ExportDataElement);

Routing.instance.onChange(async route => {
    if (route.path[0] === "apps" && route.path[1] === "export") {
        await AppContextStore.instance.present("", document.createElement("export-data"), "Bookmarks", "Apps", "Export");
    }
});