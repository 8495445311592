import "./Mixin-field-style";
import "./Mixin-field-2-style";

export class MixinField {
    constructor(element: HTMLElement, version = 0){
        if (version === 2)
            element.classList.add("mixin-field-2");
        else
        element.classList.add("mixin-field");
    }
}
