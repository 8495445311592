import { Formatting } from "lib/Formatting";

export class TitleFromUrlGenerator {
    static create(source: string): string {
        
        let parsed = undefined;
        
        try {
            if (source.indexOf("http") !== 0)
                parsed = new URL(`http://${source}`);
            else
                parsed = new URL(source);
        } catch(error) {
            //console.log(error);
        }

        if (!parsed)
            return Formatting.capitalize(source);

        const host = TitleFromUrlGenerator.readableHost(parsed);
        const path = TitleFromUrlGenerator.readableFile(parsed);

        if (host && host !== "" && path && path !== "")
            return `${host.trim()}: ${path.trim()}`;
        
        return `${host.trim()}${path.trim()}`;
    }

    static readableHost(url: URL) {
        const hostParts = url.host.split(".");

        if (hostParts.length === 0)
            return "";

        if (hostParts.length === 1)
            return Formatting.deslugify(hostParts[0]);

        return Formatting.capitalize(`${hostParts[hostParts.length - 2]}.${hostParts[hostParts.length-1]}`);
    }

    static readableFile(url: URL): string
    {
        let path = url.pathname;

        if (!path || path === "")
            return "";

        path = decodeURIComponent(path);

        const parts = path.split("/");

        if (parts.length === 0)
            return "";

        const deslugged = parts
            .map(i => Formatting.capitalize(Formatting.deslugify(i)));

       
        return deslugged.join(" ");
    }

}