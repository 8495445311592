
import "./StackIndicatorElement-style";
import { StackStore } from "features/stacks/StackStore";
import { IValidity } from "lib/Model";

class StackIndicatorElement extends HTMLElement {
    getSlugs = () => this.getAttribute("value").split(",").filter(i => i !== "");
    setSlugs = (value: string[]) => this.setAttribute("value", value.join(","));

    async connectedCallback() {
        this.innerHTML = this.view();
        this.addEventListener("click", () => {
            const selector = document.createElement("stack-selector-2");
            selector.setAttribute("value", this.getAttribute("value"));
            selector.setAttribute("title", "Select stacks");
            selector.addEventListener("selected", async (arg: CustomEvent) => {
                const value = this.getSlugs();
                value.push(arg.detail);
                this.setSlugs(value);
                await this.render();
            });
            selector.addEventListener("deselected", async (arg: CustomEvent) => {
                let value = this.getSlugs();
                value = value.filter(i => i !== arg.detail);
                this.setSlugs(value);
                await this.render();
            });

            selector.addEventListener("canceled", async (arg: CustomEvent) => {
                this.setSlugs(["inbox"]);
                await this.render();
            });

            document.body.appendChild(selector);
        });
        await this.render();
    }

    emitValidityChange(valid: boolean, message?: string) {
        const validity: IValidity = { valid: valid, reason: message };
        this.dispatchEvent(new CustomEvent("validityChange", { detail: validity }));
    }

    private async render() {
        const slugs = this.getSlugs();

        const stacksElement = this.querySelector("[name=stacks]");
        this.setAttribute("value", slugs.join(",")); 

        if (slugs.length === 0) {
            
            stacksElement.innerHTML = "";
            this.emitValidityChange(false, "A stack must be selected");
            return;
        }
        
        const stacks = await Promise.all(slugs.map(async i => await StackStore.instance.lookupStack(i)));
        stacksElement.innerHTML = stacks.map(i => i.title).join(", ");
        this.emitValidityChange(true);    
    }

    private view = () => `
        <label>Stacks</label>
        <div name=stacks></div>
        ${require("!!raw-loader!image/arrow-right-20-v1.svg")}
    `;
}

customElements.define("stack-indicator", StackIndicatorElement);