import { AlternateEmail } from "features/manage/alternate-emails/AlternateEmailsStore";

export interface ValidationMessage {
    message?: string;
    modelState?:  {[id: string]: string};
}


export interface LoginResponse extends ValidationMessage
{
    //validation: {[id: string]: string}
    status?: SignInStatus;
    username?: string;
    email?: string;
    provider?: string;
    bearer?: string;
    sameEmailUser?: string;
}

export type SignInStatus = "success" | "lockedOut" | "requiresVerification" | "failure";


export type State = "unknown" | "not-signed-in" | "locked-out" | "require-verification" | "complete-external" | "signed-in";


export interface IdentityResult extends ValidationMessage {
    succeeded?: boolean;
    errors?: string[];
}

export type AddProviderResult = "unableToGetInfoFromProvider" | "unableToAddLogin" | "success" | "anotherUserWithThisLogin";


export enum AuthenticationMode {
    Cookie = "cookie",
    Header = "header"
}

export interface User {
    userId: string;
    username: string;
    avatarLocation: string;
    email: string;
    siteLocation?: string;
    alternateEmails?: AlternateEmail[];
    logins: Provider[];
}

export interface Provider {
    loginProvider: ProviderName;
    providerKey: string;
}

export interface ChangePassword
{
    oldPassword: string;
    newPassword: string;
    confirmPassword: string;
}

export interface AddProviderCallbackMessage {
    status: AddProviderResult;
    provider: Provider;
}

export type ProviderName = "Twitter" | "Facebook" | "Google";

export interface Activity {
    status: ActivityStatus;
    reason?: string;
}

export enum ActivityStatus {
    Idle = "Idle",
    TryingToSignIn = "TryingToSignIn",
    FailedSigningIn = "FailedSigningIn",
    SignedIn = "SignedIn"
};
