import "./UserLoginElement-style";
import { AdaptivePopup } from "features/authentication/AdaptivePopup";
import { AuthenticationStore } from "./AuthenticationStore";

import {  ValidationMessage, IdentityResult  } from "features/authentication/Model";

class ExternalUserRegistrationElement2 extends AdaptivePopup {
    async connectedCallback() {
        this.innerHTML = this.view();
        super.connectedCallback();
        this.setupEvents()
    }

    private setupEvents() {
        const usernameElement = this.querySelector("[name=username]") as HTMLInputElement;
        const emailElement = this.querySelector("[name=email]") as HTMLInputElement;
        const loginButton = this.querySelector("[name=register]") as HTMLButtonElement;

        loginButton.addEventListener("click", async () => {
            let result;
            this.setAttribute("busy", "");
            try {
                result = await AuthenticationStore.instance.registerExternal(usernameElement.value, emailElement.value);
            } finally{
                this.removeAttribute("busy");
            }

            if ((<ValidationMessage>result).modelState) {
                for(const key in (<ValidationMessage>result).modelState){
                    const messageElement = this.querySelector(`[name="${key}"]`) as HTMLSpanElement;
                    if (messageElement)
                        messageElement.innerText = (<ValidationMessage>result).modelState[key];
                }
            } else if (!(<IdentityResult>result).succeeded) {
                const formMessageElement = this.querySelector(`[name="form-message"]`) as HTMLSpanElement;
    
                if (formMessageElement)
                    formMessageElement.innerText = (<IdentityResult>result).errors.join(" ");
            } else {
                this.dispatchEvent(new CustomEvent("success"));
                this.remove();

            }
                

        });
    }

    private get provider() {
        return this.getAttribute("provider");
    }

    private get username() {
        return this.getAttribute("username");
    }

    private get email() {
        return this.getAttribute("email");
    }

    private view = () => `
        <div>
            <header>
                <div><button type=button name=back class=icon>${require("!!raw-loader!image/arrow-left-20-v1.svg")}</button></div>
                <div>Login with ${this.provider}</div>
                <div></div>
            </header>
            <section>
                <p>You've successfully authenticated with ${this.provider}.</p>
                
                <p>Please enter a user name and/or email below to finish logging in.</p>
                <span name="form-message" class="error"></span>
                <fieldset>
                    <legend for="email">Email</legend>
                    <div class=field>
                        <input autocapitalize="none" autocorrect="off" class="form-control" id="email" name="email" placeholder="Email" type="text" value="${this.email || ""}">        
                    </div>
                </fieldset>
                <span name="model.Email" class="error"></span>

                <fieldset>
                    <legend for="username">Username</legend>
                    <div class=field>
                        <input autocapitalize="none" autocorrect="off" class="form-control" id="username" name="username" placeholder="Username" type="text" value="${this.username || ""}">
                    </div>
                </fieldset>
                <span name="model.Username" class="error"></span>
                
            </section>
            <footer>
                <button type=button class=power name=register>Register</button>
            </footer>
        </div>
    `;
}



customElements.define("external-user-registration-2", ExternalUserRegistrationElement2);