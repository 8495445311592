import EventTarget from "@ungap/event-target";

export class CitylineClient {
    private eventTarget = new EventTarget();
    private _frames: { [key: string]: Frame } = {};
    private _idCache = {};
    private abortListener = false;
    public static terminator = String.fromCharCode(13);
    private mainLoop: Promise<void>;
    private reader: ReadableStreamDefaultReader;
    private xhr: XMLHttpRequest;
    private backawayCooldownSeconds = 0;

    private parseFrame = (line: string): Frame => {
        let frame: Frame;

        try {
            frame = JSON.parse(line) as Frame;
        } catch(error) {
            console.log(`Unable to parse line`, line);
            return;
        }

        if (frame.data)
            frame.data = JSON.parse(frame.data);

        return frame;
    };

    constructor(private url: string, private requestFactory: () => Promise<RequestInit> = () => Promise.resolve({}), private xhrRequestFactory: (request: XMLHttpRequest) => Promise<void> = (request: XMLHttpRequest) => Promise.resolve()) {
        window.requestIdleCallback(() => this.mainLoop = this.startListener());
        let started = new Date().getTime();

        const driftHandler = async () => {
            try {
                const now = new Date().getTime();
                const diff = now - started;

                let allowedDrift = 3000;
                if (/Trident\/|MSIE/.test(window.navigator.userAgent)) {
                    allowedDrift = 30000;
                }

                if (diff > allowedDrift) {
                    console.log("DRIFT! (not resetting)", diff);
                    // await this.reset();
                }
            }
            finally {
                started = new Date().getTime();
                setTimeout(driftHandler, 1000);
            }
        };
        
        setTimeout(driftHandler, 1000);
    }

    private startListener = async () => {
        if (window.ReadableStream)
            await this.startFetchListener();
        else
            await this.startXHRListener();
    };


    public reset = async () => {
        console.log("Resetting");

        this.abortListener = true;
        console.log("Canceling reader");
        try {
            await this.reader?.cancel();
            console.log("Reader closed success");
            this._abortController?.abort();
            this.xhr?.abort();
        } catch (error) {
            console.log("Close errror", error);
        }
        await this.mainLoop;
        this._idCache = {};
        this._frames = {};
        console.log("Completed reset");

        this.abortListener = false;
        this.mainLoop = this.startListener();
    }

    addEventListener(
        type: string,
        listener: EventListenerOrEventListenerObject,
        options?: boolean | AddEventListenerOptions
    ) {
        return this.eventTarget.addEventListener(type, listener, options);
    }

    removeEventListener(
        type: string,
        listener: EventListenerOrEventListenerObject,
        options?: boolean | EventListenerOptions
    ) {
        this.eventTarget.removeEventListener(type, listener, options);
    }

    async getFrames(...names: string[]): Promise<any[]> {
        const promises = names.map(name => {
            if (this._frames[name])
                return Promise.resolve(this._frames[name]);

            return new Promise(r => {
                const handler = (event: CustomEvent<any>) => {
                    this.removeEventListener(name, handler);
                    r(event.detail);
                };
                this.addEventListener(name, handler);
            })
        });

        return await Promise.all(promises);
    }

    async getFrame<T>(name: string): Promise<T> {
        if (this._frames[name])
            return this._frames[name].data;

        return new Promise<T>(r => {
            const handler = (event: CustomEvent<any>) => {
                this.removeEventListener(name, handler);
                r(event.detail);
            };
            this.addEventListener(name, handler);
        });
    }

    private async buildRequest(): Promise<RequestInit> {
        const externalRequest = await this.requestFactory();
        const headers = new Headers(externalRequest.headers);

        headers.set("Content-Type", "application/json");

        const requestData: CitylineRequest = { tickets: this._idCache };
        const request: RequestInit = {
            ...externalRequest,
            ...{ body: JSON.stringify(requestData), method: "post", headers: headers }
        };

        return request;
    }

    private startXHRListener = async () => {
        // console.log("Using XHR listener");

        try {
            await new Promise(async (resolve, reject) => {
                const started = new Date().getTime();

                const loadEndHandler = () => {
                    this.xhr.removeEventListener("loadend", loadEndHandler);

                    const timeSpent = new Date().getTime() - started;
                    // this is normal scenario - if we actually spent time chrunching data we can start quick again
                    if (timeSpent < 5000)
                        reject(new Error("Loop ended too quickly, expects atleast 5 seconds"));
                    else
                        resolve(undefined);
                }

                const buffer = new Buffer();
                let seenBytes = 0;
                this.xhr = new XMLHttpRequest();
                this.xhr.open("post", this.url);

                this.xhr.onreadystatechange = () => {
                    if (this.xhr.readyState === 3) {
                        this.backawayCooldownSeconds = 0;

                        const newData = this.xhr.response.substr(seenBytes);

                        console.log("added", newData);

                        buffer.add(newData);

                        let chunk = undefined;
                        do {
                            chunk = buffer.take();

                            if (chunk !== undefined) {
                                const frame = this.parseFrame(chunk);
                                this.addFrame(frame);
                            }

                        } while (chunk !== undefined);

                        seenBytes = this.xhr.responseText.length;
                    }
                };

                this.xhr.addEventListener("loadend", loadEndHandler, { once: true }); // NOTE: IE does not support once

                const requestData: CitylineRequest = { tickets: this._idCache };
                const externalRequest = await this.requestFactory();

                await this.xhrRequestFactory(this.xhr);
                this.xhr.setRequestHeader("Authorization", externalRequest.headers["Authorization"]);
                this.xhr.setRequestHeader("Content-Type", "application/json");

                this.xhr.send(JSON.stringify(requestData));
            });

            if (!this.abortListener)
                setTimeout(this.startXHRListener, 100);
        }
        catch (error) {
            if (!this.abortListener) {
                this.eventTarget.dispatchEvent(new CustomEvent("error", { detail: error }));
                setTimeout(this.startXHRListener, 1000 * this.backawayCooldownSeconds++);
            }
        }
        console.log("Finish main loop");
    }

    private _abortController: AbortController;

    private startFetchListener = async () => {
        // console.log("Using Fetch listener");

        this._abortController = new AbortController();

        try {
            const decoder = new TextDecoder();

            const request = await this.buildRequest();
            request.signal = this._abortController?.signal;

            const response = await fetch(this.url, request);

            this.reader = response.body.getReader();
            const buffer = new Buffer();
            const started = new Date().getTime();


            if (!response.ok)
                throw new Error(response.statusText);

            while (!this.abortListener) {
                const result = await this.reader.read();

                if (result.done)
                    break;

                // all is good, lets reset cooldown
                this.backawayCooldownSeconds = 0;

                const decoded = decoder.decode(result.value);
                buffer.add(decoded);

                let chunk = undefined;
                do {
                    chunk = buffer.take();

                    if (chunk !== undefined) {
                        const frame = this.parseFrame(chunk);
                        this.addFrame(frame);
                    }

                } while (chunk !== undefined);
            }

            const timeSpent = new Date().getTime() - started;
            // this is normal scenario - if we actually spent time chrunching data we can start quick again
            if (timeSpent < 5000)
                throw new Error("Loop ended too quickly, expects atleast 5 seconds");

            if (!this.abortListener)
                setTimeout(this.startFetchListener, 100);
        }
        catch (error) {
            if (!this.abortListener) {
                this.eventTarget.dispatchEvent(new CustomEvent("error", { detail: error }));
                setTimeout(this.startFetchListener, 1000 * this.backawayCooldownSeconds++);
            }
        }

        console.log("Finish main loop");
    }



    private addFrame(frame: Frame) {
        if (frame && frame.event) {
            if (frame.id)
                this._idCache[frame.event] = frame.id;

            this._frames[frame.event] = frame;

            setTimeout(() => {
                this.eventTarget.dispatchEvent(
                    new CustomEvent(frame.event, {
                        detail: frame.data
                    })
                );

                this.eventTarget.dispatchEvent(
                    new CustomEvent("frame-received", {
                        detail: frame
                    })
                );
            });
        }
    }
}

class Buffer {
    private _buffer = [""];

    add(chunk: string) {
        const lines = chunk.split("\n");

        if (lines.length > 0)
            this._buffer[this._buffer.length - 1] += lines[0];

        if (lines.length > 1)
            this._buffer = this._buffer.concat(lines.slice(1));
    }

    take() {
        if (this._buffer.length === 0)
            return undefined;

        if ((this._buffer[0].trim().startsWith("{") && this._buffer[0].trim().endsWith("}"))) {
            const chunk = this._buffer[0];
            this._buffer = this._buffer.slice(1);
            return chunk;
        }

        return undefined;
    }
}

interface CitylineRequest {
    tickets: { [key: string]: string };
}

export interface Frame {
    id?: string;
    event?: string;
    data: any;
}
