
import { Linkstack } from "features/stacks/StackStore";
import "./StackTeaserElement-style";
import { Highlight, IHighlighted  } from "lib/Model";

class StackTeaserElement extends HTMLElement {
    connectedCallback() {
        const jsonElement = this.querySelector("script[type='application/json+stack']") as HTMLElement;
        if (!jsonElement)
            return; 
            
        const stack = JSON.parse(jsonElement.innerText) as Linkstack & IHighlighted;
        this.innerHTML = this.view(stack);
        this.setAttribute("href", stack.siteLocation);
        this.setAttribute("route", "");
    }

    private view = (stack: Linkstack & IHighlighted) => `
        
            <img src="${stack.preview}" />
            <div class=details>
                <auto-time datetime="${stack.created}"></auto-time>
                <h2>${Highlight.property(stack, s => s.title)}</h2>
                <div class=description>${Highlight.property(stack, s => s.description)}</div>
            </div>
        
    `;
}

customElements.define("stack-teaser", StackTeaserElement);