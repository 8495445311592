import { AuthenticationStore } from "features/authentication";
import { DialogController } from "features/dialog";
import "./SiteLoginElement-style";
import "features/external-login";
import { Routing, RouteChangeOrigin } from "lib/Routing";
import * as formsStyle from "../forms/forms.module.less";   
import { AppContextStore } from "features/application-context/AppContextStore";
import { BrowserInfo } from "lib/Browser";
import { ClassTypename, Typename } from "lib/Types";

@ClassTypename("SiteForgotPasswordElement")
class SiteForgotPasswordElement extends HTMLElement {
    private named = <T extends HTMLElement> (name: string): T => this.querySelector(`[name=${name}]`) as T;
    private usernameOrEmailGroup: HTMLElement;
    private sendButton: HTMLElement;
    private form: HTMLFormElement;

    async connectedCallback() {
        this.innerHTML = this.view();
        this.classList.add(formsStyle.loginControl);
        this.form = this.querySelector("form");
        this.usernameOrEmailGroup = this.named("username-or-email");

        console.log(SiteForgotPasswordElement[Typename]);

        this.sendButton = this.querySelector(
            "[name=send-link]"
        ) as HTMLButtonElement;

        const sendLink = this.querySelector("[name=send-link]") as HTMLElement;
        sendLink.addEventListener("click", this.sendHandler);

        this.addEventListener("blur", this.checkForm);
        this.addEventListener("validity", this.checkForm);

        this.addEventListener("input", () => {
            this.usernameOrEmailGroup.setAttribute("error-text", "");
        });


        this.form.addEventListener("submit", async (event) => {
            event.preventDefault();
            await this.sendHandler();
        });
    }

    private sendHandler = async () => {
        if (this.sendButton.hasAttribute("disabled")) 
            return;

        let status: boolean;
        try {
            this.sendButton.setAttribute("state", "busy");
            status = await AuthenticationStore.instance.forgotPassword(this.usernameOrEmailGroup.getAttribute("value"));

            this.sendButton.setAttribute("state", "done");

            if (status) {
                await new DialogController().prompt({
                    caption: "Check your email",
                    message: "If your account matches you will receive an email with a reset link.",
                    options: ["Ok"]
                });
                this.closeHandler();

            } else {
                await new DialogController().prompt({
                    caption: "Unable to recover password",
                    message: "Please verify the information you provided and contact us if the problem persists.",
                    options: ["Ok"]
                });
            }
        } 
        catch(error) {
            throw error;
        }
        finally {
            this.sendButton.setAttribute("state", "done");
        }
    };

    private closeHandler = () => {
        if (this.closest("auth-switcher")) {
            Routing.instance.go("/login", SiteForgotPasswordElement[Typename]);
            return;
        }
            
        const returnUrl = this.getAttribute("return-url");

        if (returnUrl && returnUrl !== "")
            window.location.href = returnUrl;
        else
            window.location.href = "/";
    };

    private isFormFullyFilled = () => this.querySelectorAll("input[required]").length === this.querySelectorAll("input[touched]").length;

    private checkForm = () => {
        if (!this.isFormFullyFilled()) 
            return;

        this.sendButton.toggleAttribute("disabled", !this.form.checkValidity());
    };
    

    private view = () => `
        ${BrowserInfo.isApp() ? `<a href="/login" route class="page-level ">${require("!!raw-loader!image/back-arrow.svg")}</a>` : ""}
        <form autocapitalize=none>
            <div name=logo>
                <a href="/">${require("!!raw-loader!image/logo-solo.svg")}</a>
            </div>
            <header>
                <h1>Forgot your password?</h1>
            </header>
            <section>
                <form-group 
                    name="username-or-email"
                    label="Username or email address" 
                    autocomplete=username></form-group>
                
                <progress-button 
                    disabled 
                    name="send-link" 
                    busy-text="Sending ..." 
                    success-text="Sent"
                    disable-query="input, button">Send link</progress-button>
            </section>
            <footer>
                
            </footer>
        </form>
    `;
}

customElements.define("site-forgot-password", SiteForgotPasswordElement);


Routing.instance.onChange(async route => {
    if (route.origin === RouteChangeOrigin.PageLoad)
        return;
        
    if (route.path[0] === "forgotpassword") {
        await AppContextStore.instance.present("forgot-password", new SiteForgotPasswordElement(), "Forgot password");
    }
});