export class Lazy<T> {
    private _value : T;
    private resolved: boolean;

    constructor(private action: () => T) {}
    
    get value(): T { 
        if (this.resolved)
            return this._value;
        
        this.resolved = true;
        return this._value = this.action(); 
    }

    get isResolved(): boolean{
        return this.resolved;
    }
}

export interface IDisposable {
    dispose();
}

export class Template {
    static replace(source: string, args: any) {
        return source.replace(/{(\w+)}/g, (_, v) => args[v]);
    }
}

export class AppError extends Error {
    __proto__: Error;
    constructor(message?: string) {
        const trueProto = new.target.prototype;
        super(message);
        this.__proto__ = trueProto;
    }
}


export class Collection {
    static selectMany<TIn, TOut>(input: TIn[], selectListFn: (t: TIn) => TOut[]): TOut[] {
        return input.reduce((out, inx) => {
          out.push(...selectListFn(inx));
          return out;
        }, new Array<TOut>());
      }

    static distinctBy<TEntity>(input: TEntity[], keyFn: (t: TEntity) => any): TEntity[] {
        const unique = {};
        const result = [];
        input.forEach(entity => {
            const key = keyFn(entity);
            if (unique[key])
                return;

            unique[key] = true;
            result.push(entity);
        });
        return result;
    }
}

export class NotAuthorizedError extends AppError { }

export class UnableToReachServiceError extends AppError {}

export class EmptyResultError extends AppError { }

export class Delay {
    public static wait(t) : Promise<void> {
        return new Promise((resolve) => setTimeout(resolve, t));
    }
}

export function isEquivalent<TClass>(a: TClass, b: TClass) {
    // Create arrays of property names
    const aProps = Object.getOwnPropertyNames(a);
    const bProps = Object.getOwnPropertyNames(b);

    // If number of properties is different,
    // objects are not equivalent
    if (aProps.length !== bProps.length) {
        return false;
    }

    for (let i = 0; i < aProps.length; i++) {
        const propName = aProps[i];

        // If values of same property are not equal,
        // objects are not equivalent
        if (a[propName] !== b[propName]) {
            return false;
        }
    }

    // If we made it this far, objects
    // are considered equivalent
    return true;
}

export interface Package {
    name:string;
    version: string;
    description: string;
    main: string;
    scripts: any;
    repository:any;
    keywords: string[];
    author: string,
    license: string;
    dependencies: any;
    devDependencies: any;
}
