import { AdaptivePopup } from "features/authentication/AdaptivePopup";
import { AuthenticationStore } from "./AuthenticationStore";

import { DialogController } from "features/dialog";

class ForgotPasswordElement2 extends AdaptivePopup {
    async connectedCallback() {
        this.innerHTML = this.view();
        super.connectedCallback();
        

        setTimeout(() => {
            this.style.opacity = "1";
        });

        const sendLink = this.querySelector("[name=send-link]") as HTMLElement;
        sendLink.addEventListener("click", async () => {
            const usernameOrEmailElement = this.querySelector("[name=username-or-email]") as HTMLInputElement;
            if (usernameOrEmailElement.value === "") {
                const usernameOrEmailErrorElement = this.querySelector("model.UsernameOrEmail") as HTMLInputElement;
                usernameOrEmailErrorElement.innerText = "This field is requered";
            } else {
                this.setAttribute("busy", "");
                let result;
                try {
                    result = await AuthenticationStore.instance.forgotPassword(usernameOrEmailElement.value);
                } finally {
                    this.removeAttribute("busy");
                }
                
                if (result) {
                    await new DialogController().prompt({
                        caption: "Check your email",
                        message: "If your account matches you will receive an email with a reset link.",
                        options: ["Ok"]
                    });
                    this.remove();
                } else {
                    await new DialogController().prompt({
                        caption: "Unable to recover password",
                        message: "Please verify the information you provided and contact us if the problem persists.",
                        options: ["Ok"]
                    });
                }

            }
        });
    }

    


    private view = () => `
        <div>
            <header>
                <div><button type=button name=back class=icon>${require("!!raw-loader!image/arrow-left-20-v1.svg")}</button></div>
                <div>Forgot your password?</div>
                <div></div>
            </header>
            <section>
                <fieldset>
                    <legend>Enter your username or email</legend>
                    <div class=field>
                        <input autocapitalize="none" autocorrect="off" class="form-control" id="username-or-email" name="username-or-email" type="text" value="">
                    </div>
                </fieldset>
                <span name="model.UsernameOrEmail" class="error"></span>

            </section>
            <footer>
                <button type=button class=power name=send-link>Send me a link</button>
            </footer>
        </div>
    `;
}

customElements.define("forgot-password-2", ForgotPasswordElement2);