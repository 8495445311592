
import { Delay } from "lib/System";

export class AdaptivePopup extends HTMLElement {

    connectedCallback() {
        // this.setAttribute("slide", "");
        this.classList.add("adaptive");

        this.querySelector("[name=back]").addEventListener("click", this.closeHandler);
    }

    protected closeHandler = async () => {
        requestAnimationFrame(async () => {
            this.setAttribute("slide", "");
            await Delay.wait(300);
            this.remove();
        });
    };

    static addAndslide(element: HTMLElement) {
        requestAnimationFrame(() => {
            document.body.appendChild(element);
            requestAnimationFrame(() => {
                element.removeAttribute("slide");
            });
        });
    }
}