import "./InAppHelpElement-style";
import "./HelpSidebarElement-style";
import { BaseSidebarChildElement } from "features/side-bar/BaseSidebarChildElement";

class HelpSidebarElement extends BaseSidebarChildElement {
    protected titleText: string = "Linkstacks help";
    
    async connectedCallback() {
        super.connectedCallback();
        const { InAppHelpElement } = await import( /* webpackChunkName: "help", webpackPrefetch: true */ "./InAppHelpElement");

        const element = new InAppHelpElement();
        element.setAttribute("help-topic", this.getAttribute("help-topic"));

        this.insertAdjacentElement("beforeend", element);
    }   
}

customElements.define("help-sidebar", HelpSidebarElement)
