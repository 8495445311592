import { Formatting } from "lib/Formatting";
import { StackStore, Linkstack } from "features/stacks/StackStore";
import * as dialogStyle from "../../styles/dialog-module.less";
import * as selectorStyle from "./StackSelector-module.less";
import { ScrollContainerHeightObserver } from "lib/ScrollContainerHeightObserver";

class StackSelectorElement2 extends HTMLElement {
    private escape = Formatting.htmlEscape;
    previousValue: string;
    private searchBox: HTMLInputElement;
    private createText: HTMLElement;
    private stackList: HTMLElement;
    private getSelected = () => (this.getAttribute("value") || "").split(",").filter(m => m.trim() !== "");
    private getExcludes = () => (this.getAttribute("exclude") || "").split(",");
    private selectOnly = () => this.hasAttribute("select-only");
    private button: HTMLElement;
    private named = <T extends HTMLElement> (name: string): T => this.querySelector(`[name=${name}]`) as T;
    private hasFixed = false;
    
    private select(slug: string) { 
        const selected = this.getSelected();
        selected.push(slug);
        this.setAttribute("value", selected.join(","));

        const element = this.querySelector(`[stack-value="${slug}"]`);
        element.setAttribute("selected", "");

        this.dispatchEvent(new CustomEvent("selected", { detail: slug })); 
        this.refreshButton();
    }

    private deselect(slug: string) { 
        let selected = this.getSelected();
        selected = selected.filter(i => i !== slug);
        this.setAttribute("value", selected.join(","));

        const element = this.querySelector(`[stack-value="${slug}"]`);
        element.removeAttribute("selected");

        this.dispatchEvent(new CustomEvent("deselected", { detail: slug })); 
        this.refreshButton();
    }

    private refreshButton() {
        if (!this.button)
            return;

        if (this.getSelected().length > 0)
            this.button.removeAttribute("disabled");
        else
            this.button.setAttribute("disabled", "");
    }

    measureScrollBarWidth() {
        const scrollBox = document.createElement("div");
        scrollBox.style.overflow = "scroll";
        document.body.appendChild(scrollBox);
        document.body.style.setProperty("--scroll-width", `${scrollBox.offsetWidth - scrollBox.clientWidth}px`);
        scrollBox.remove();
      }
    
    async connectedCallback() {
        this.className += ` ${dialogStyle.frame}`;

        let fixed: Linkstack;
        let selected = false;
        // unless inbox is marked as excluded, mark it as fixed
        if (!this.getExcludes().some(i => i === "inbox")) {
            this.hasFixed = true;
            fixed = await StackStore.instance.lookupStack("inbox");
            selected = this.getSelected().some(i => i === fixed.slug);
        } 
            
        this.innerHTML = this.view(fixed, selected);

        // grab
        this.button = this.querySelector("progress-button");
        this.searchBox = this.named("search-box");
        this.createText = this.named("create-text");
        this.stackList = this.named("stack-list");

        //tslint:disable-next-line: no-unused-variable
        const _ = new ScrollContainerHeightObserver(this.stackList);

        // events
        this.addEventListener("click", this.clickHandler);
        this.searchBox.addEventListener("input", this.searchInputHandler);
        this.createText.addEventListener("click", this.doCreate);
        if (this.button)
            this.button.addEventListener("click", this.closeHandler);

        
        await this.lookupStacks(this.searchBox.value);

        this.searchBox.focus();

        StackStore.instance.addEventListener("stack-added", this.handleStackCreated);
        window.requestIdleCallback(() => this.measureScrollBarWidth());
        this.refreshButton();
    }

    disconnectedCallback() {
        this.removeEventListener("click", this.clickHandler);
        this.searchBox.removeEventListener("input", this.searchInputHandler);
        this.createText.removeEventListener("click", this.doCreate);
        StackStore.instance.removeEventListener("stack-added", this.handleStackCreated);

        if (this.button)
            this.button.removeEventListener("click", this.closeHandler);
    }

    private doCreate = async () => {
        if (this.searchBox.value === "")
            return;

        await StackStore.instance.createStack({ title: this.searchBox.value, private: false }, false);
        
    };

    private handleStackCreated = async (event: CustomEvent<Linkstack>) => {
        const stack = event.detail;
        this.searchBox.value = "";
        this.createText.innerHTML = "";
        await this.lookupStacks(stack.title, true);
    }

    private searchInputHandler = async () => {
        const value = this.searchBox.value;

        if (value.length === 0) {
            this.createText.innerHTML = "";
        } else {
            this.createText.innerHTML = this.createTextView(value);
        }
            
        await this.lookupStacks(value);
    }

    private createTextView = (value: string) => `Create new stack ${value.toLocaleUpperCase()}`;

    private closeHandler = () => {
        if (this.getSelected().length === 0)
            this.dispatchEvent(new CustomEvent("canceled"));

        this.remove();
        return;
    }

    private clickHandler = async (event: UIEvent) => {
        if (!(event.target instanceof Element))
            return;
        
        const button = (event.target as Element).closest("button");
        if (button && button.name === "close") {
            this.closeHandler();
        }

        const stack = (event.target as Element).closest("[name=stack]");
        if (!stack)
            return

        const slug = stack.getAttribute("stack-value");

        
        if (stack.hasAttribute("selected")) {
            this.deselect(slug);
        } else {
            this.select(slug);
        }
    
        
        //await this.lookupStacks(this.searchBox.value, true);
    }

    private async lookupStacks(q = "", force = false) {
        if (!force && q === this.previousValue)
            return;

        this.previousValue = q;
        const excludes = this.getExcludes(); 

        const stacks = (await StackStore.instance.lookup(q))
                        .filter(i => excludes.indexOf(i.slug) === -1)
                        .filter(i => i.slug !== "inbox");

        const selected = this.getSelected();
        const html = stacks
            .map(stack => this.stackView(stack, selected.some(l => l === stack.slug)))
            .join("");

        this.stackList.innerHTML = html;
    }

    private stackView = (stack: Linkstack, selected: boolean, addClass = "") => {
        return this.escape`
        <div class="${dialogStyle.listItem} ${dialogStyle.selectable} ${stack.slug === "inbox" ? selectorStyle.inbox : ""}" 
             stack-value="${stack.slug}"
             name="stack"
             ${selected ? "selected" : ""} 
             title="">  
            <div class="${dialogStyle.imageField}">
                $${stack.slug === "inbox" ? `
                    <div class="${selectorStyle.icon}">${require("!!raw-loader!image/icons/v2/inbox-20-v8.svg")}</div>
                ` : `
                    <span class="${dialogStyle.icon}" style="background-image: url(${stack.smallPreview});"></span>
                `}
            </div>
            <div class="${dialogStyle.titleField} ${addClass} ${selectorStyle.titleField}">${stack.title}</div>
            <div class="${dialogStyle.checkField}" name=check>
                ${require("!!raw-loader!image/fa-check.svg")}
            </div>
        </div>
    `};

    private view = (fixed: Linkstack, fixedSelected: boolean) => this.escape`
        <div class="${dialogStyle.window}">
            <header class="${dialogStyle.header}">
                <div>${this.getAttribute("title")}</div>
                <div>${this.getAttribute("description")}</div>
                <button type=button class="${dialogStyle.closeButton}" name=close>${require("!!raw-loader!image/close-icon.svg")}</button>
            </header>
            <div class="${dialogStyle.fixedArea}">
                <div class="${dialogStyle.listItem}" name="search-field">
                    <div class="${dialogStyle.imageField} ${dialogStyle.faded}">
                        ${require("!!raw-loader!image/search-20-v2.svg")}
                    </div>
                    <div class="${dialogStyle.inputField}">
                        <input placeholder="Search stacks" name=search-box />
                    </div>       
                </div>
                <div class="${dialogStyle.listItem}" name="create-field">
                    <div class="${selectorStyle.createText}" name=create-text></div>
                </div>
                $${this.hasFixed ? this.stackView(fixed, fixedSelected, selectorStyle.fixedListItem) : ""}
            </div>
            <div class="${dialogStyle.scrollArea}" name="stack-list">
                
            </div>

            $${this.selectOnly() ? `
                <div class="${dialogStyle.fixedArea} ${selectorStyle.spacer}"></div>
                ` : `
                <div class="${dialogStyle.fixedArea} ${selectorStyle.buttonArea}">
                    <progress-button 
                        disabled 
                        name=apply
                        busy-text="Applying ..." 
                        success-text="Applied"
                        disable-query="">Apply</progress-button>
                    
                </div>`}
            
        </div>
    `;
}

customElements.define("stack-selector-2", StackSelectorElement2);