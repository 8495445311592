import { Pipeline, Request } from "lib/HTTP";
import { UrlProvider } from "lib/UrlProvider";
import { Delay } from "lib/System";

export class EntriesReadUnreadQueue {
    private static _instance = new EntriesReadUnreadQueue();
    private constructor() {}
    static get instance() { return EntriesReadUnreadQueue._instance; }
    private readQueue: { [id: string] : number[]; }  = {};
    private unreadQueue: { [id: string] : number[]; }  = {};
    private workerRunning = false;
    private maxCallIntervalInMilliseconds = 3000;

    async markRead(feedId: string, sequence: number) : Promise<boolean> {
        if (!feedId || feedId === "")
            throw new Error("FeedId has to be provided");
        
        if (this.readQueue[feedId])
            this.readQueue[feedId] = [... this.readQueue[feedId], sequence];
        else    
            this.readQueue[feedId] = [sequence];

        if (this.unreadQueue[feedId] && this.unreadQueue[feedId][sequence])
            this.unreadQueue[feedId] = this.unreadQueue[feedId].filter(m => m !== sequence);
        
        setTimeout(this.ensureWorker);
        return true;       
    }

    async markUnread(feedId: string, sequence: number) : Promise<boolean> {
        if (!feedId || feedId === "")
            throw new Error("FeedId has to be provided");
        
        if (this.unreadQueue[feedId])
            this.unreadQueue[feedId] = [... this.unreadQueue[feedId], sequence];
        else    
            this.unreadQueue[feedId] = [sequence];

        if (this.readQueue[feedId] && this.readQueue[feedId][sequence])
            this.readQueue[feedId] = this.readQueue[feedId].filter(m => m !== sequence);
        
        setTimeout(this.ensureWorker);
        return true;       
    }

    private isEmpty = () => (Object.keys(this.readQueue).length === 0 && Object.keys(this.unreadQueue).length === 0);

    private ensureWorker = async () => {
        if (this.isEmpty())
            return;

        if (!this.workerRunning) {
            this.workerRunning = true;
            await this.readUnreadWorker();
            this.workerRunning = false;
            await this.ensureWorker();
        }
    }

    

    private readUnreadWorker = async () => {
        console.log("working");
        const started = new Date().getTime();

        if (Object.keys(this.readQueue).length > 0) {
            const url = `${new UrlProvider().root}/api/my-feeds/_read`;
            const clone = JSON.parse(JSON.stringify(this.readQueue));
            this.readQueue = {};
            const response = await new Pipeline().hideLoader().fetch(url, Request.post.authenticate().setJSON({ items: clone }));

            // put back on failure
            if (!response.ok) {
                this.readQueue = Object.assign({},this.readQueue, clone); 
            }
        }

        if (Object.keys(this.unreadQueue).length > 0) {
            const url = `${new UrlProvider().root}/api/my-feeds/_unread`;
            const clone = JSON.parse(JSON.stringify(this.unreadQueue));
            this.unreadQueue = {};
            const response = await new Pipeline().hideLoader().fetch(url, Request.post.authenticate().setJSON({ items: clone }));

            // put back on failure
            if (!response.ok) {
                this.unreadQueue = Object.assign({},this.unreadQueue, clone); 
            }
        }

        const took = new Date().getTime() - started;
        const waitTime = this.maxCallIntervalInMilliseconds - took;

        if (waitTime > 0)
            await Delay.wait(waitTime);
    }
}