import { DOM } from "lib/DOM";
import { AuthenticationStore } from "features/authentication";

export class FeedbackActivator {
    
    //tslint:disable-next-line: no-unused-variable
    private static _instance = new FeedbackActivator();

    constructor() {
        window.requestIdleCallback(this.setupFeedbackButton);
    }

    private setupFeedbackButton = async () => {
        await DOM.ready();

        if (!await AuthenticationStore.instance.isAuthenticated())
            return;

        document.body.insertAdjacentHTML("beforeend", this.globalFeedbackView());
        const button = document.body.querySelector("[name=global-feedback] button");
        button.addEventListener("click", FeedbackActivator.show);
    };

    static show = () => {
        const existing = document.querySelector("user-feedback");
        if (existing)
            return;
        
        document.body.appendChild(document.createElement("user-feedback"));
    };

    private globalFeedbackView = () => `
        <div name="global-feedback">
            <button type=button class=power>Feedback</button>
        </div>
    `;

}

