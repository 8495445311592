import { LoginResponse } from "../Model";
import { UrlProvider } from "lib/UrlProvider"; 
import { ILoginProvider } from "./ILoginProvider";

export class GenericWebLoginProvider implements ILoginProvider {
    private urlProvider = new UrlProvider();

    getName() { return `WebLoginProvider-${this.providerName}` }

    constructor(private providerName: string) {}

    login() : Promise<LoginResponse> {
        return new Promise<LoginResponse>(async (resolve, reject) => {
            try {
                const messageHandler = (event: MessageEvent) => {
                    if (event.origin !== "https://local.linkstacks.io" &&
                        event.origin !== "https://test.linkstacks.io" &&
                        event.origin !== "https://linkstacks.io")
                    return;
        
                    const loginResponse = event.data as LoginResponse;
                    resolve(loginResponse);
                    window.removeEventListener("message", messageHandler);
                } 

                window.addEventListener("message", messageHandler);

                const loginWindow = window.open(`${this.urlProvider.root}/account/ExternalLogin?provider=${this.providerName}&returnurl=urn://callback`, "_blank", "location=yes,width=1050,height=650");
                
                const pollTimer = window.setInterval(function() {
                    if (loginWindow.closed !== false) { // !== is required for compatibility with Opera
                        window.clearInterval(pollTimer);
                        setTimeout(() => {
                            reject("Window closed"); // promise may be resolve, then nothing happens
                        }, 50);
                    }
                }, 200);
            }
            catch (error) {
                reject("Unable to login");
            }
        });
    }
}

