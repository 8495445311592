import "./HubComponent-style";
import "./widgets";
import { BaseWidget } from "./widgets/BaseWidget";
import { TopMenuStore } from "features/top-panel/TopMenuStore";
import { HubStore, HubSettings } from "./HubStore";

export class HubComponentElement extends HTMLElement {
    private _mediaMatch = window.matchMedia("screen and (max-width: 1024px)");
    private widgetElements: BaseWidget[] = [];
    private columnA: HTMLElement;
    private columnB: HTMLElement;
    private _initializer: Promise<void>;
    private settingsChangeHandler = (event: CustomEvent<HubSettings>) => this.updateSettings(event.detail);
    
    constructor() {
        super();

        this._initializer = new Promise(async (r) => {
            await this.buildElements();
            r();
        });

    }

    private updateSettings = (settings: HubSettings) => {
        for(const hiddenWidget of settings.hiddenWidgets) {
            const element = this.widgetElements.filter(m => m.tagName.toLowerCase() === hiddenWidget)[0];
            if (element) {
                element.remove();
                this.widgetElements = this.widgetElements.filter(m => m.tagName.toLowerCase() !== hiddenWidget);
            }
        }

        let order = 0;
        for(const widget of settings.visibleWidgets) {
            let element = this.widgetElements.filter(m => m.tagName.toLowerCase() === widget)[0];

            if (!element) {
                element = document.createElement(widget) as BaseWidget;
                this.widgetElements.push(element);
            }

            element.style.order = `${order++}`;
        }

        this.updateContext(settings);
        requestAnimationFrame(this.columnize);
    } 

    private buildElements = async () => {
        const hubSettings = await HubStore.instance.getSettings();
        
        let order = 0;
        for(const elementName of hubSettings.visibleWidgets) {
            const element = document.createElement(elementName) as BaseWidget;
            element.style.order = `${order++}`;
            this.widgetElements.push(element);
        }

        this.updateContext(hubSettings);
    }

    private updateContext(settings: HubSettings) {
        if (settings.hiddenWidgets.indexOf("info-widget") === -1) {
            TopMenuStore.instance.setGroup("hub", []);
        } else {
            TopMenuStore.instance.setGroup("hub", [
                {
                    name: "info",
                    title: "Show info widget",
                    side: "context",
                    handler: async () => await HubStore.instance.show("info-widget")
                }
            ]);
        }
    }

    private columnize = async () => {
        await this._initializer;

        for(const element of this.widgetElements) {
            let wantedParent: HTMLElement;
            if (this._mediaMatch.matches || element.preferredWidth === "wide") {
                wantedParent = this.columnA;
            } else {
                wantedParent = this.columnB;
            }
           
            if (element.parentElement === wantedParent)
                continue;
        
            wantedParent.append(element);
        }
    }

    connectedCallback() {
        document.body.toggleAttribute("neo", true);
        document.body.toggleAttribute("show-side-menu", false);
        
        this.innerHTML = this.view();
        this.columnA = this.querySelector(".column-a");
        this.columnB = this.querySelector(".column-b");

       
        
        window.addEventListener("resize", () => window.requestAnimationFrame(this.columnize), { passive: true});
        window.requestAnimationFrame(this.columnize);
        HubStore.instance.addEventListener("changed", this.settingsChangeHandler);
    }

    disconnectedCallback() {
        document.body.toggleAttribute("neo", false);
        document.body.toggleAttribute("show-side-menu", true);
        HubStore.instance.removeEventListener("changed", this.settingsChangeHandler);
    }

    private view = () => `
        <div class="column column-a"></div>
        <div class="column column-b"></div>
    `;
}

customElements.define("hub-component", HubComponentElement);