
import "./FeedHeaderElement-style";
import { MyFeedStore } from "features/feeds/MyFeedStore";
import { Formatting } from "lib/Formatting";

class FeedHeaderElement extends HTMLElement {
    private escape = Formatting.htmlEscape;
    private feedId = () => this.getAttribute("feed-id");
    private isCategory = () => this.hasAttribute("category");

    async connectedCallback() {
        const feed = await MyFeedStore.instance.getFeed(this.feedId());
        if (!feed)
            throw new Error(`Feed not found looking for, ${this.feedId()}. `)

        this.innerHTML = this.escape`
            <div name=top-part>
                <header>${feed.title || ""}</header>
                <context-menu>
                    <item id="refresh" title="Refresh"></item>
                    <item id="mark-all-read" title="Mark all read"></item>
                   
                    $${this.isCategory()  ? "" : `
                        <item id="-"></item>
                        <item id="unsbscribe" title="Unsbscribe"></item>`}
                    
                    <button type=button class=icon>
                        ${require("!!raw-loader!image/icons/context-menu.svg")}
                    </button>
                    
                </context-menu>
            </div>
            $${feed.description ? `<section>${feed.description}</section>` : ""}
        `;

        this.querySelector("context-menu").addEventListener("context-item-click", this.contextMenuHandler);
    }

    disconnectedCallback() {
        this.querySelector("context-menu").removeEventListener("context-item-click", this.contextMenuHandler);
    }
    
    private contextMenuHandler = async (event: CustomEvent) => {
        switch(event.detail) {
            case "mark-all-read":
                await MyFeedStore.instance.markAllRead(this.feedId()); 
                break;
            case "refresh": 
                this.dispatchEvent(new CustomEvent("refresh"));
                break;
            default: 
                throw new Error(`Unknown context-menu-item: ${event.detail}`)
        }
    };
}

customElements.define("feed-header", FeedHeaderElement);