import "./NotificationSettingsElement-style"


class NotificationSettingsElement extends HTMLElement {
    connectedCallback() {
        if (!("Notification" in window)) {
            this.innerHTML = this.view(false, false);
            return;
        }

        this.render();
        this.registerEvents();
    }

    private registerEvents() {
        const button = this.querySelector("button");

        if (!button)
            return;

        button.addEventListener("click", () => (<any>window).Notification.requestPermission((permission) => {
            this.render();
            this.registerEvents();
        }));
    }

    private render() {
        this.innerHTML = this.view((<any>window).Notification.permission !== "denied", (<any>window).Notification.permission === "granted");
    }

    view = (available, activated) => `
        <div class="box browser-notifications">
            <header>Browser notifications</header>
            <div class=description>
                Allows Linkstacks to show system-wide popups when events occur.
            </div>

            ${available ? `
                <label>
                    ${activated ? `Native notifications enabled.` : `<button name=enable class=power>Enable notifications</button>`}
                </label>
            ` : `
                Native browser-notifications not available
            `}
        </div>
    `;
}

customElements.define("notification-settings", NotificationSettingsElement);
