import "./TopMenuElement-style"
import { AppContextStore, Context } from "features/application-context/AppContextStore";
import { Formatting } from "lib/Formatting";
import { TopMenuStore, TopMenuItem } from "features/top-panel/TopMenuStore";


class TopMenuElement extends HTMLElement {
    private centerElement: HTMLElement;
    private escape = Formatting.htmlEscape;
    private centerHTML = "";
    
    connectedCallback() {
        document.documentElement.style.setProperty("--top-menu-height", "44px");

        TopMenuStore.instance.onChange(this.refresh);
        this.refresh();
        AppContextStore.instance.onContextChange(this.contextChangeHandler);
        this.addEventListener("click", this.clickHandler);
        this.addEventListener("context-item-click", this.contextClickHandler);
    }
    
    disconnectedCallback() {
        if (this.contextChangeHandler)
            AppContextStore.instance.offContextChange(this.contextChangeHandler);

        TopMenuStore.instance.offChange(this.refresh);
        this.removeEventListener("click", this.clickHandler);
        this.removeEventListener("context-item-click", this.contextClickHandler);
    }

    private contextClickHandler = (event: CustomEvent) => {
        TopMenuStore.instance.trigger(event.detail);  
    };

    private clickHandler = (event: UIEvent) => {
        const button = (event.target as HTMLElement).closest("button");
        if (button)
            TopMenuStore.instance.trigger(button.name);
    };

    private refresh = () => {
        // preserve title
        this.innerHTML = this.view(TopMenuStore.instance.all()); 
        this.centerElement = this.querySelector(".center") as HTMLElement;
    };

    private contextChangeHandler = (event: Context) => {
        if (!event.titlePath || !event.titlePath[0])
            this.centerHTML = "";
        else {
            const length = event.titlePath.length;
            const highPriority =  event.titlePath[length-1];
            const lowPriority = event.titlePath.filter( (e,i) => i !== length-1);

            this.centerHTML = this.escape`$${lowPriority.map(l => this.escape`<span class=low>${l}&nbsp;<span class=s>/</span>&nbsp;</span>`).join("")}${highPriority}`;
        }

        this.centerElement.innerHTML = this.centerHTML;
    };

    private buttonView = (item: TopMenuItem) => this.escape`
        <button type="button" title="${item.title}" class="icon" name="${item.name}" ${item.toggled ? "toggled" : ""}>${item.icon}</button>
    `;
    
    private contextMenuView = (items: TopMenuItem[]) => this.escape`
        <context-menu>
            $${items.map(item => this.escape`<item id="${item.name}" title="${item.title}"></item>`).join("")}
            <button type=button class=icon>
                $${require("!!raw-loader!image/icons/context-menu.svg")}
            </button>
        </context-menu>
    `;

    private view = (items: TopMenuItem[]) => {
        const leftItems = items.filter(i => i.side === "left");
        const rightItems = items.filter(i => i.side === "right");
        const contextItems = items.filter(i => i.side === "context");

        const iconBarWidth = Math.max(leftItems.length, (rightItems.length + (contextItems.length > 0 ? 1 : 0))*38.334);
        return this.escape`
        <div class=left style="width:${iconBarWidth}px">$${leftItems.map(item => this.buttonView(item)).join("")}</div>
        <div class=center>$${this.centerHTML}</div>
        <div class=right > 
            $${contextItems.length > 0 ? this.contextMenuView(contextItems) : ""}
            $${rightItems.map(item => this.buttonView(item)).join("")}
        </div>
    `}; //style="width:${iconBarWidth}px"
}

customElements.define("top-menu", TopMenuElement);