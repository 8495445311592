import "./ProgressButtonElement-style";

//type states = "" | "busy" | "success" | "done"; 

class ProgressButton extends HTMLElement {
    private initialHTML;
    private button: HTMLButtonElement; 

    connectedCallback() {
        this.initialize();
    }

    set state(value) {
        this.setAttribute("state", value);        
    }

    get state() {
        return this.getAttribute("state");
    }

    static get observedAttributes() {
        return ["state"];
    }

    attributeChangedCallback(attrName, oldVal, newVal) {
        if (attrName === "state") {
            if (this.state !== newVal) this.state = newVal;

            this.reflectState();
        }   
        
    }

    private reflectState() {
        switch(this.state) {
            case "busy":
                this.disableElements(true);
                requestAnimationFrame(() => {
                    this.button.innerHTML = this.getAttribute("busy-text");
                });
                break;

            case "done": 
                requestAnimationFrame(() => {
                    setTimeout(() => {
                        this.setAttribute("state", "");
                    }, 300);
                });
                break;

            case "success": 
                this.disableElements(true);
                requestAnimationFrame(() => {
                    this.button.innerHTML = this.getAttribute("success-text");
                });
                break;

            default: 
                this.button.innerHTML = this.initialHTML;
                this.button.style.backgroundPositionX = "";
                this.disableElements(false);
            
        }
    }

    private initialize() {
        this.initialHTML = this.innerHTML;
        this.innerHTML = "";
        this.button = document.createElement("button");
        this.button.setAttribute("default", "");
        this.button.setAttribute("type", this.getAttribute("type") || "submit");
        this.button.innerHTML = this.initialHTML;
        this.appendChild(this.button);
    }

    private disableElements(disabled: boolean) {
        const disableElementsQuery = this.getAttribute("disable-query");

        if (!disableElementsQuery)
            return;

        const inputs = [].slice.call(document.querySelectorAll(disableElementsQuery)) as HTMLInputElement[];

        inputs.forEach(element => {
            element.disabled = disabled;
        });
    }
}

customElements.define("progress-button", ProgressButton);
