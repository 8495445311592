import "./FeedViewerElement-style";
import { AppContextStore } from "features/application-context/AppContextStore";
import { AuthenticationStore } from "features/authentication";
import { CategoryStore } from "../category-selector/CategoryStore";
import { DOM } from "lib/DOM";
import { DialogController } from "features/dialog";
import { Feed, Entry } from "features/feeds";
import { Formatting } from "lib/Formatting";
import { LazyImageHandler } from "features/lazy-images/LazyImageHandler";
import { Routing } from "lib/Routing";
import { ScrollLocker } from "lib/ScrollLocker";
import { TopMenuItem } from "../top-panel/TopMenuStore";
import { TopMenuStore } from "features/top-panel/TopMenuStore";
import { UrlProvider } from "lib/UrlProvider";
import { MyFeedStore, FeedSequenceDetail } from "features/feeds/MyFeedStore";
import { FeedViewSettings } from ".";


class FeedViewerElement extends HTMLElement {
    private escape = Formatting.htmlEscape;
    private urlProvider = new UrlProvider();
    private feed: Feed;
    private lazyImageHandler: LazyImageHandler;
    private entryScrollLocker: ScrollLocker;
    private floatNav: HTMLElement;
    private isTouch = (("ontouchstart" in window) || (navigator.maxTouchPoints > 0));
    private scroller: HTMLElement;
    private named = <T extends HTMLElement> (name: string): T => this.querySelector(`[name=${name}]`) as T;
    

    feedUrl = () => this.getAttribute("feed-url");
    feedSlug = () => this.getAttribute("feed-slug");
    isMyFeed = () => this.hasAttribute("my-feed");
    entrySlug = () => this.getAttribute("entry-slug");
    queryText = () => this.getAttribute("query-text");

    private async showFeed() {

        if (this.feedSlug() !== "") {
            const route = Routing.instance.route;
            
            // if (!route.path[2])
            //     Routing.instance.replace(`/${route.path[0]}/${route.path[1]}/all`);

            this.setAttribute("feed-slug", route.path[2]);
            this.setAttribute("my-feed", "");

            this.setAttribute("query-text", route.parameters.query || "");

            if (route.path[3])
                this.setAttribute("entry-slug", route.path[3]);
        }
        
        // show feed
        await this.getAndRenderEntries();

        this.lazyImageHandler = new LazyImageHandler(this, false);

        this.addEventListener("transitionend", this.transitionendHandler);

        this.addEventListener("scroll", this.nextPageScrollHandler, { passive: true } );

        if (!this.parentElement)
            return;

        const button = this.parentElement.querySelector("feed-viewer > footer > button");

        if (button)
            button.addEventListener("click", this.markAllReadHandler);
    }

    async connectedCallback() {
        if ((<any>window).cloning)
            return;
            
        this.innerHTML = this.view();
        this.scroller = this.named("scroller");

        // pre-events
        MyFeedStore.instance.addEventListener("articles-read", this.readHandler);
        // MyFeedStore.instance.onRead(this.readHandler);
        MyFeedStore.instance.addEventListener("articles-unread", this.unreadHandler);
        // MyFeedStore.instance.onUnread(this.unreadHandler);
        
        this.addEventListener("open", this.childOpenHandler);
        window.addEventListener("keydown", this.keyboardHandler);
        this.addEventListener("click", this.clickHandler);

        await this.showFeed();

        this.floatNav = this.querySelector("float-nav");

        if (!this.isTouch)
            this.floatNav.setAttribute("hidden", "");

        this.floatNav.addEventListener("down", this.downHandler);
        this.floatNav.addEventListener("up", this.upHandler);

       
    }

    disconnectedCallback() {
        TopMenuStore.instance.setGroup("feed", []);

        this.removeEventListener("transitionend", this.transitionendHandler);

        MyFeedStore.instance.removeEventListener("articles-read", this.readHandler);
        MyFeedStore.instance.removeEventListener("articles-unread", this.unreadHandler);

        this.removeEventListener("open", this.childOpenHandler);
        window.removeEventListener("keydown", this.keyboardHandler);
        this.removeEventListener("click", this.clickHandler);

        this.removeEventListener("scroll", this.nextPageScrollHandler);
        
        if (this.lazyImageHandler)
            this.lazyImageHandler.destroy();

        if (this.floatNav) {
            this.floatNav.removeEventListener("down", this.downHandler);
            this.floatNav.removeEventListener("up", this.upHandler);        
        }
        
    }

    private clickHandler = async (event: MouseEvent) => {
        if (!(event.target instanceof Element))
            return;

        const targetElement = event.target as Element;

        const button = targetElement.closest("button");

        // if (!button) 
        //     return;
            
        if (button?.name === "mark-all-read")
            await this.markAllReadHandler();

    }

    private downHandler = async () => {
        await this.gotoOlderEntry(true);
    }

    private upHandler = async () => {
        await this.gotoNewerEntry(true);
    }

    private transitionendHandler = (event: TransitionEvent) => {
        if (event.propertyName !== "max-height" || (event.target as HTMLElement).localName !== "div" || !(event.target as HTMLElement).classList.contains("body"))
            return;

        setTimeout(() => {
            if (this.entryScrollLocker)
                this.entryScrollLocker.destroy();

        }, 100);
    };

    private refreshHandler = async () => {
        await this.getAndRenderEntries(true);
    };

    private readHandler = (event: CustomEvent<FeedSequenceDetail>) => {
        const feedId = event.detail.feedId;
        const sequences = event.detail.sequence;
        const elements = [].slice.call(this.querySelectorAll(`entry-control[entry-feed-id='${feedId}']`));

        elements.forEach( (element: HTMLElement) => {
            if (element.hasAttribute("read"))
                return;

            const sequence = parseInt(element.getAttribute("sequence"));
            if (sequences.indexOf(sequence) !== -1)
                element.setAttribute("read", "");
        });
    };

    private unreadHandler = (event: CustomEvent<FeedSequenceDetail>) => {
        const feedId = event.detail.feedId;
        const sequences = event.detail.sequence;
        const elements = [].slice.call(this.querySelectorAll(`entry-control[entry-feed-id='${feedId}']`));

        elements.forEach( (element: HTMLElement) => {
            if (!element.hasAttribute("read"))
                return;

            const sequence = parseInt(element.getAttribute("sequence"));
            if (sequences.indexOf(sequence) !== -1)
                element.removeAttribute("read");
        });
    };


    private keyboardHandler = async (event: KeyboardEvent) => {
        if ((event.target as HTMLElement).closest("input"))
            return;
        
        if ((event.target as HTMLElement).closest("textarea"))
            return;

        if (event.key === "j" || event.key === "Backspace") {
            event.preventDefault();
            await this.gotoNewerEntry(true);
        }

        if (event.key === "k" || event.key === " ") {
            event.preventDefault();
            await this.gotoOlderEntry(true);
        }
    };


    private openEntry(entry: Entry, doScrollLock: boolean) {
        
        const viewer = this.querySelector(`entry-control[entry-feed-id='${entry.feedId}'][entry-slug='${entry.slug}']`) as HTMLElement;
        if (viewer) {
            viewer.setAttribute("open", "");

            if (this.entryScrollLocker) {
                this.entryScrollLocker.destroy();
                this.entryScrollLocker = undefined;
            }

            if (doScrollLock)
                this.entryScrollLocker = new ScrollLocker(this.scroller, viewer);
           
        }   
    }

    private async gotoOlderEntry(doScrollLock: boolean) {
        let index = 0;
        const currentOpen = this.currentOpenEntry();
        if (currentOpen)
            index = this.feed.entries.indexOf(currentOpen) + 1;

        const older = this.feed.entries[index];

        if (older)
            this.openEntry(older, doScrollLock);
    }

    private async gotoNewerEntry(doScrollLock: boolean)  {
        let index = this.querySelectorAll("entry-control").length-1;
        const currentOpen = this.currentOpenEntry();
        if (currentOpen)
            index = this.feed.entries.indexOf(currentOpen) - 1;

        const newer = this.feed.entries[index];

        if (newer) {
            this.openEntry(newer, doScrollLock);
            return newer;
        }
            
    }

    private entryFromElement(element: HTMLElement) {
        const entryFeedId = element.getAttribute("entry-feed-id");
        const entrySlug = element.getAttribute("entry-slug");

        return this.feed.entries.filter(m => m.feedId === entryFeedId && m.slug === entrySlug)[0];
    }

    private childOpenHandler = (event: CustomEvent) => {
        if ((event.target as HTMLElement).localName !== "entry-control")
            return;

        if (this.entryScrollLocker) {
            this.entryScrollLocker.destroy();
            this.entryScrollLocker = undefined;
        }
        
        const openEntries = [].slice.call(this.querySelectorAll("entry-control[open]")).filter(e => e !== (event.target as HTMLElement));
        
        if (openEntries.length > 0)
            this.entryScrollLocker = new ScrollLocker(this.scroller, (event.target as HTMLElement) as HTMLElement, true);


        openEntries.forEach( (m:HTMLElement) => m.removeAttribute("open"));
    };

    private currentOpenEntry = () => {
        const openViewer = this.querySelector("entry-control[open]") as HTMLElement;
        if (openViewer)
            return this.entryFromElement(openViewer);
    };

    private nextPageScrollHandler = async () => {
        const nextPageMarker = this.querySelector("[name=page-marker]") as HTMLElement;
        if (!nextPageMarker)
            return;
        
        if (!DOM.isElementInViewport(nextPageMarker))
            return;

        nextPageMarker.remove();

        const nextPage = await MyFeedStore.instance.getEntriesFilteredAndSorted(this.feedSlug(), { 
            from: nextPageMarker.getAttribute("from")
        });

        const footer = this.parentElement.querySelector("feed-viewer > footer");
        if (footer)
            footer.remove();

        this.feed.entries = this.feed.entries.concat(nextPage.entries);

        const html = this.entriesView(nextPage, await this.urlProvider.userHome(), 0);
        this.scroller.insertAdjacentHTML("beforeend", html);

        const button = this.parentElement.querySelector("feed-viewer > footer > button");

        if (button)
            button.addEventListener("click", this.markAllReadHandler);

    };

    private getAndRenderEntries = async (refresh = false, viewSettings: FeedViewSettings = {}) => {
        this.feed = await MyFeedStore.instance.getEntries(this.feedSlug(), { 
            from: "",
            refresh : refresh,
            entrySlug: this.entrySlug(),
            query: this.queryText()
        }, viewSettings);
    
        if (!this.feed)
            throw new Error(`Could not get feed, myFeed:${this.isMyFeed()}, slug:${this.feedSlug()}.`);
        else { 
            
            let queryPart = this.feed.title;

            if (this.queryText())
                queryPart += ` matching ${this.queryText()}`;

            if (this.feed.categories.length === 0)
                AppContextStore.instance.update("Feeds", queryPart);
            else
                AppContextStore.instance.update("Feeds", this.feed.categories[0].title, queryPart);

            // menu will triger this on click, but first load requires something else
            if (!MyFeedStore.instance.activeCategory) {
                if (this.feed.isCategory) {
                    MyFeedStore.instance.changedActiveCategory(this.feed.id);
                } else {
                    MyFeedStore.instance.changedActiveCategory(this.feed.categories[0]);
                }
            }


        }

        const lazyThreshold = Math.ceil(this.clientHeight / 76);
        this.scroller.innerHTML = this.entriesView(this.feed, await this.urlProvider.userHome(), lazyThreshold);
        // this.innerHTML = html;
        
        if (this.feed) {
            await this.refreshTopMenu()
        }
       
        if (await MyFeedStore.instance.count() <= 1) { // only "all-feed"
            this.insertAdjacentHTML("afterbegin", `
                <tip-display caption="Welcome to feeds (beta)" image="${require("image/tip-add-link.jpg")}">
                    <p>You currently don't have any feeds.</p><br />
                    <p>To add feeds use the plus-button in the top.</p>
                </tip-display>
            `);
        } else if (this.feed.entries.length === 0) {
            this.insertAdjacentHTML("afterbegin", `
                <tip-display caption="All read">
                    No new entries in this feed
                </tip-display>
            `);
        }

    }

    private markAllReadHandler = async () => {
        
        const feedRef = await MyFeedStore.instance.getFeed(this.feed.id);
        if (feedRef.unread === 0)
         return;

        const response = await new DialogController().prompt({
            caption: "Mark all read",
            message: `Are you sure that you want to mark ${feedRef.unread} ${feedRef.unread === 1 ? "entry" : "entries"} read?`,
            options: ["Cancel", "Mark all read"]
        });

        if (response === "Mark all read")
            await MyFeedStore.instance.markAllRead(this.feed.id) 
    };

    private refreshTopMenu = async () => {
        
        const menuGroup: TopMenuItem[] = [];


        menuGroup.push(
            {
                name: "toggle-read",
                title: `Hide read items <switch-button ${this.feed.viewSettings.hideReadEntries ? "checked" : ""}></switch-button>`,
                side: "context",
                priority: 0,
                handler: this.toggleHideReadItems
            },
            {
                name: "refresh",
                title: "Refresh",
                side: "context",
                priority: 0,
                handler: this.refreshHandler
            },
            {
                name: "mark-all-read",
                title: "Mark all read",
                side: "context",
                priority: 0,
                handler: this.markAllReadHandler
            });

            if (this.feed.isCategory) {
                if (this.feed.id !== "all") {
                    menuGroup.push(
                    {
                        name: "-",
                        title: "-",
                        side: "context",
                        priority: 0,
                    },
                    {
                        name: "category",
                        title: "Delete category",
                        side: "context",
                        priority: 0,
                        handler: this.deleteCategoryHandler
                    });
                }
            } else {
                menuGroup.push({
                    name: "category",
                    title: "Category ...",
                    side: "context",
                    priority: 0,
                    handler: this.categoryHandler
                },
                {
                    name: "-",
                    title: "-",
                    priority: 0,
                    side: "context"
                },
                {
                    name: "unsubscribe",
                    title: "Unsubscribe",
                    side: "context",
                    priority: 0,
                    handler: this.unsubscribeHandler
                });
            }

            TopMenuStore.instance.setGroup("feed", menuGroup);
    }

    private toggleHideReadItems = async () => {
        this.feed.viewSettings.hideReadEntries = !this.feed.viewSettings.hideReadEntries;
        await this.getAndRenderEntries(false, this.feed.viewSettings);
    };

    private categorySelector: HTMLElement;

    private unsubscribeHandler = async () => {
        const currentUserTask = AuthenticationStore.instance.currentUser();

        const result = await new DialogController().prompt({
            caption: "Unsubscribe",
            message: "Are you sure that you want to unsubscribe from this feed?",
            options: ["Cancel", "Ok"]
        });

        if (result === "Ok") {
            await MyFeedStore.instance.unsubscribe(this.feed.id);
            Routing.instance.go(`/@${(await currentUserTask).userId}/feeds`);
        }
            
    }

    private deleteCategoryHandler = async () => {
        const currentUserTask = AuthenticationStore.instance.currentUser();

        const result = await new DialogController().prompt({
            caption: "Delete category",
            message: "Are you sure that you want to delete this category? Any feeds will be moved to uncategorized.",
            options: ["Cancel", "Ok"]
        });

        if (result === "Ok") {
            await CategoryStore.instance.delete("feed", this.feed.id);
            Routing.instance.go(`/@${(await currentUserTask).userId}/feeds`);
        }

        
    };

    private categoryHandler = () => {
        this.categorySelector = document.createElement("category-selector");
        this.categorySelector.setAttribute("exclude", this.feed.id);
        this.categorySelector.addEventListener("close", this.categorySelectorCloseHandler);
        this.categorySelector.addEventListener("selected", this.categorySelectorSelectedHandler);
        document.body.appendChild(this.categorySelector);
    }

    private categorySelectorCloseHandler = () => {
        if (!this.categorySelector)
            return;

        this.categorySelector.removeEventListener("close", this.categorySelectorCloseHandler);
        this.categorySelector.removeEventListener("selected", this.categorySelectorSelectedHandler);
        this.categorySelector = undefined;
    };
    
    private categorySelectorSelectedHandler = async (event: CustomEvent) => { 
        await MyFeedStore.instance.changeCategory(this.feed.id, event.detail);
    };


    private entryView = (feed: Feed, entry: Entry, userHomeUrl: string, lazy: boolean) => this.escape`
        <entry-control ${entry.slug === this.entrySlug() ? "open" : ""}>
            <script type="application/json+entry">
                $${JSON.stringify(entry)}
            </script>
        </entry-control>
    `;

    private footerView = (feed: Feed) => `
        <footer class=forms3>
            <button class=medium name="mark-all-read">Mark all read</button>
        </footer>
    `;

    view = () => `
        <float-nav></float-nav>
        <div name=scroller>
        </div>    
    `;
    
    entriesView = (feed: Feed, userHomeUrl: string, lazyThreshold: number) => `
        ${feed.entries.map( (entry, index) => this.entryView(feed, entry, userHomeUrl, index > lazyThreshold)).join("")}
        ${feed.entries.some(i => i.read === false) ? this.footerView(feed) : ""}
        ${feed.previousPageMarker ? `<div name=page-marker from="${feed.previousPageMarker}">Load more</div>` : ""}
    `;
}

customElements.define("feed-viewer", FeedViewerElement);