import { ITap, BaseDialogViewController } from "features/dialog";
import "./AboutStyle";
import { SettingsStore, SemanticVersion } from "features/settings/SettingsStore";

export class AboutViewController extends BaseDialogViewController {
    constructor() {
        super();
        this.clazz = "about";
    }

    getTabs(): ITap[] {

        return [{
            title: "About",
            viewController: () => ({ view: () => this.view(SettingsStore.instance.version) })
        }];

    }

    private view = (version : SemanticVersion) => `
        <p></p>
        <p class=version>
            Version ${version.number()})<br />
            Build ${version.buildDate}
        </p>     
    `;
}


window.addEventListener("keydown", async (event: KeyboardEvent) => {
    if (event.altKey && (event.key === "v" || event.keyCode === 86)) {
        await new AboutViewController().show();
    }
});
