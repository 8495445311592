import "./SiteLoginElement-style";
import { AuthenticationStore, LoginResponse } from "features/authentication";
import "features/external-login";
import { Routing, RouteChangeOrigin } from "lib/Routing";
import * as formsStyle from "../forms/forms.module.less";   
import { AppContextStore } from "features/application-context/AppContextStore";

class SiteLoginElement extends HTMLElement {
    private named = <T extends HTMLElement> (name: string): T => this.querySelector(`[name=${name}]`) as T;
    private usernameOrEmailGroup: HTMLElement;
    private passwordGroup: HTMLElement;
    private loginButton: HTMLElement;
    private providers: HTMLElement;
    private form: HTMLFormElement;
    private disableHandler = () => this.toggleDisabledState(true);
    private enableHandler = () => this.toggleDisabledState(false);
    
    connectedCallback() {
        this.innerHTML = this.view();
        this.classList.add(formsStyle.loginControl);
        
        this.form = this.querySelector("form");

        this.loginButton = this.querySelector(
            "[name=login]"
        ) as HTMLButtonElement;
        
        this.usernameOrEmailGroup = this.named("username-or-email");
        this.passwordGroup = this.named("password");

        this.providers = this.querySelector("external-login");
        this.providers.addEventListener("success", this.closeHandler);
        this.providers.addEventListener("complete-external", this.completeExternal);
        this.providers.addEventListener("busy", this.disableHandler);
        this.providers.addEventListener("ready", this.enableHandler);

        this.loginButton.addEventListener("click", this.login);


        this.addEventListener("blur", this.checkForm);
        this.addEventListener("validity", this.checkForm);

        this.addEventListener("input", () => {
            this.usernameOrEmailGroup.setAttribute("error-text", "");
            this.passwordGroup.setAttribute("error-text", "");
        });

        this.form.addEventListener("submit", async (event) => {
            event.preventDefault();
            await this.login();
        });
       
        setTimeout(this.checkForm, 200);
    }

    private completeExternal = (event: CustomEvent) => {
        const response = event.detail as LoginResponse;
        const siteExternalUserRegistration = document.createElement("site-external-registration");
        if (response.username)
            siteExternalUserRegistration.setAttribute("username", response.username);

        if (response.email)
            siteExternalUserRegistration.setAttribute("email", response.email);

        if (response.sameEmailUser)
            siteExternalUserRegistration.setAttribute("same-email-user", response.sameEmailUser);

        siteExternalUserRegistration.setAttribute("provider", response.provider);
        siteExternalUserRegistration.setAttribute("show-logo", "");
        this.replaceWith(siteExternalUserRegistration);
    };

    disconnectedCallback() {
        this.loginButton.removeEventListener("click", this.login);
    }

    private closeHandler = () => {
        if (window.location.protocol === "file:" || window.location.hostname === "localhost")
            return;

        const returnUrl = this.getAttribute("return-url");

        if (returnUrl && returnUrl !== "")
            window.location.href = returnUrl;
        else
            window.location.href = "/";
    };

    private login = async () => {
        if (this.loginButton.hasAttribute("disabled")) 
            return;

        let status: boolean;
        try {
            this.loginButton.setAttribute("state", "busy");
            status = await this.DoLogin(this.usernameOrEmailGroup.getAttribute("value"), this.passwordGroup.getAttribute("value"));
        } 
        catch(error) {
            this.loginButton.setAttribute("state", "done");
        }

        if (status)
            this.loginButton.setAttribute("state", "success");
        else
            this.loginButton.setAttribute("state", "done");

    };

    private toggleDisabledState(disabled: boolean) {
       const inputs = [].slice.call(this.querySelectorAll("input, button")) as HTMLInputElement[];

        inputs.forEach(element => {
            element.disabled = disabled;
        });
    }

    private async DoLogin(usernameOrEmail: string, password: string) : Promise<boolean> {
        const result = await AuthenticationStore.instance.login(usernameOrEmail, password);
        
        switch (result.status) {
            case "success":
                this.closeHandler();
                return true;
            case "failure":
                this.setAttribute("login-failure", ""); // show forgot password
                this.passwordGroup.setAttribute("error-text", "Username/email or password is incorrect.");
                return false;
            case "lockedOut":
                this.passwordGroup.setAttribute("error-text", "User is currently locked out due to too many login-attempts.");
                return false;
            case "requiresVerification":
                await this.showVerificationReminder();
                return false;
        }
    }

    private async showVerificationReminder() {
        if (await AuthenticationStore.instance.resendRegistrationMail(this.usernameOrEmailGroup.getAttribute("value"))) {
            this.replaceWith(document.createElement("site-confirm-email"));
        }
        else {
            this.usernameOrEmailGroup.setAttribute("error-text", "Unable to resend registration mail");
        }
    }

    private isFormFullyFilled = () => this.querySelectorAll("input[required]").length === this.querySelectorAll("input[touched]").length;

    private checkForm = () => {
        if (!this.isFormFullyFilled()) 
            return;

        this.loginButton.toggleAttribute("disabled", !this.form.checkValidity());
    };

    private view = () => `
        <form autocapitalize=none>
            <div name=logo>
                <a href="/">${require("!!raw-loader!image/logo-solo.svg")}</a>
            </div>
            <header>
                <h1>Sign in</h1>
                <p>Don't have an account? <a href="/register" route>Sign up ❯ </a></p>
                <p name=forgot-password>Forgot your password? <a href="/forgotpassword" route>Get a new one ❯ </a></p>
            </header>
            <section>
                <form-group 
                    name="username-or-email"
                    label="Username or email address" 
                    autocomplete=username></form-group>
                
                <form-group 
                    name=password
                    type=password
                    label=Password
                    autocomplete=password></form-group>

                <progress-button 
                    disabled 
                    name="login" 
                    busy-text="Signing in ..." 
                    success-text="Signed in"
                    disable-query="input, button">Sign in</progress-button>
                
                <external-login></external-login>
            </section>
            <footer>
                
            </footer>
        </form>
    `;
}

customElements.define("site-login", SiteLoginElement);

Routing.instance.onChange(async route => {
    if (route.origin === RouteChangeOrigin.PageLoad)
        return;
        
    if (route.path[0] === "login") {
        await AppContextStore.instance.present("", document.createElement("site-login"), "Login");
    }
});