import "./LayoutManagerElement-style";
import { BrowserInfo } from "lib/Browser";
import { DOM } from "lib/DOM";
//import * as SafeAreaInsets from "safe-area-insets";
import {
    Plugins,
  } from '@capacitor/core';
  
  import { StatusBar, Style } from '@capacitor/status-bar';

/*
    Creates a fallback on iOS <= 10.x devices that does not set safe-area-inset-top
    Since there were fewer devices back then we can assume 20px (maybe)

    Use this with cordova-plugin-wkwebview-engine to ensure that timing of set properties are correct
*/
class LayoutManagerElement extends HTMLElement {
    private topGap: number;
    
    connectedCallback() {
        setTimeout(() => window.requestIdleCallback(this.initializerInsetFallback, { timeout: 1000} ), 500);
        document.addEventListener("deviceready", this.initStatusbar, false);
    }

    disconnectedCallback() {
        window.removeEventListener("orientationchange", this.changeHandler);
    }

    private initStatusbar = async () =>  
    {
        await DOM.ready();
        if ((<any>window).StatusBar) {
            (<any>window).StatusBar.overlaysWebView(true);
            (<any>window).StatusBar.hide();
            (<any>window).StatusBar.styleDefault();
            (<any>window).StatusBar.show();
        }

        if (StatusBar) { 
            await StatusBar.setStyle({
                style: BrowserInfo.isIOSApp ? Style.Light : Style.Dark
            });
        }
    }

    private initializerInsetFallback = () => {
        if (BrowserInfo.isIOSApp()) {

            const style = getComputedStyle(document.documentElement);
            
            const frame = [
                parseInt(style.getPropertyValue("--inset-top")),
                parseInt(style.getPropertyValue("--inset-right")),
                parseInt(style.getPropertyValue("--inset-bottom")),
                parseInt(style.getPropertyValue("--inset-left"))
            ];

            console.log("SafeArea", frame);
            this.topGap = Math.max(...frame); //SafeAreaInsets.top; //
            //window.addEventListener("orientationchange", this.changeHandler);
            //this.changeHandler();
  
        }
    }

    private changeHandler = () => {
        let topInset = 0;
        if (window.orientation === 0 || window.orientation === 180)
            topInset = this.topGap;

        document.documentElement.style.setProperty("--inset-top", `${topInset}px`);

    }
}

customElements.define("layout-manager", LayoutManagerElement);