import { BulkLinkStore } from "./BulkLinkStore";
import { Collection } from "lib/System";
import { LinkStore, LinkTagChangeGram } from "features/links";
import { DialogController } from "features/dialog";

export class BulkController {
    
    //tslint:disable-next-line: no-unused-variable
    private static _instance = new BulkController();

    private constructor() {
        BulkLinkStore.instance.addEventListener("select-started", this.selectStartedHandler);
        BulkLinkStore.instance.addEventListener("select-ended", this.selectEndedHandler);
        
    }

    private selectStartedHandler = () => {
        let batchPanel = document.querySelector("bulk-panel");
        if (batchPanel)
            return;
        
        batchPanel = document.createElement("bulk-panel");
        document.body.append(batchPanel);
    
    }

    private selectEndedHandler = () => {
        const batchPanel = document.querySelector("bulk-panel");
        if (!batchPanel)
            return;
        
        batchPanel.remove();
    }

    static tag() {
        const links = BulkLinkStore.instance.all();
        const allTags = Collection.selectMany(links, link => link.tags);
        const uniqueTags = Collection.distinctBy(allTags, k => k);

        const fullTags = uniqueTags.filter(tag => links.filter(link => link.tags.filter(t => t === tag)).length === links.length);
        const partial = uniqueTags.filter(tag => fullTags.indexOf(tag) === -1);


        const tagSelector = document.createElement("tag-selector");   

        tagSelector.setAttribute("fully-selected", fullTags.join(","));
        tagSelector.setAttribute("partial-selected", partial.join(","));
        tagSelector.setAttribute("sub-title", `${links.length} selected link${links.length > 1 ? "s" : ""}`);

        tagSelector.addEventListener("apply", async (event: CustomEvent<LinkTagChangeGram>) => {
            // ensure linkIds
            const updateGram = event.detail;
            updateGram.linkIds = links.map(link => link.id);
            
            const result = await LinkStore.instance.tagMany(updateGram);
            
            if (!result) {
                tagSelector.setAttribute("state", "done");
            } else {
                tagSelector.setAttribute("state", "success");
                requestAnimationFrame(() => {
                    tagSelector.remove();  
                });
            }   
        });

        document.body.append(tagSelector);        
    }

    static async delete() {
        const links = BulkLinkStore.instance.all();

        const okResponse = `Delete ${links.length} link${links.length === 1 ? "" : "s"}`;
        const response = await new DialogController().prompt({
            caption: "Delete links",
            message: `Are you sure that you want to delete ${links.length} link${links.length === 1 ? "" : "s"}?`,
            options: ["Cancel", okResponse]
        });

        if (response === okResponse) {
            await LinkStore.instance.deleteMany({linkIds: links.map(link => link.id)});
        }
            
    }

    static copy() {
        const links = BulkLinkStore.instance.all();

        const currentStacks = Collection.distinctBy(links.map(m => m.stack), m => m);

        const stackSelector = document.createElement("stack-selector-2");
        stackSelector.setAttribute("exclude", currentStacks.join(","));
        stackSelector.setAttribute("select-only", "");
        stackSelector.setAttribute("title", "Copy links to stack");
        stackSelector.setAttribute("description", `${links.length} selected link${links.length > 1 ? "s" : ""}`);

        document.body.insertAdjacentElement("beforeend", stackSelector);
        stackSelector.addEventListener("selected", async (arg: CustomEvent<string>) => {
            
            stackSelector.setAttribute("busy", "");

            await LinkStore.instance.copyMany({
                linkIds: links.map(i => i.id),
                targetStack: arg.detail
            });

            stackSelector.removeAttribute("busy");
        });
    }

    static move() {
        const links = BulkLinkStore.instance.all();

        const currentStacks = Collection.distinctBy(links.map(m => m.stack), m => m);

        const stackSelector = document.createElement("stack-selector-2");
        stackSelector.setAttribute("exclude", currentStacks.join(","));
        stackSelector.setAttribute("select-only", "");
        stackSelector.setAttribute("title", "Move links to stack");
        stackSelector.setAttribute("description", `${links.length} selected link${links.length > 1 ? "s" : ""}`);

        document.body.insertAdjacentElement("beforeend", stackSelector);
        stackSelector.addEventListener("selected", async (arg: CustomEvent<string>) => {
            stackSelector.setAttribute("busy", "");
            const result = await LinkStore.instance.moveMany({
                linkIds: links.map(i => i.id),
                targetStack: arg.detail
            })
            
            if (result) {
                stackSelector.remove();
                const values = Object.values(result);
                if (values.some( i => i === "conflict")) {
                    await new DialogController().prompt({
                        caption: "Unable to move all links",
                        message: "Some of the links exist in target stack",
                        options: ["Ok"]
                    });
                };
            } else {
                stackSelector.removeAttribute("busy");
            }
        });
    }
}
