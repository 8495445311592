import { DOM } from "lib/DOM";
import { EventEmitter } from "events";

export class ThemeStore extends EventEmitter {
    private static _instance = new ThemeStore();
    private _isDark: boolean;
    private _font: Font;

    private fonts: Font[] = [
        {
            name: "Open Sans",
            family: "'Open Sans', 'lucida grande', 'Segoe UI', arial, verdana, 'lucida sans unicode', tahoma, sans-serif"
        },
        {
            name: "Times New Roman",
            family: "'Times New Roman', Times, serif",
        },
        {
            name: "Courier New",
            family: "'Courier New', Courier, monospace"
        }

    ];

    private emitFontChanged(font: Font) {
        this.emit("FontChanged", font);
    }

    public onFontChanged(callback: (font: Font) => void) {
        this.on("FontChanged", callback);
    }

    public removeFontChanged(callback: (font: Font) => void) {
        this.removeListener("FontChanged", callback);
    }


    renderFontStyle = () => `font-family: ${this._font.family}; font-size: ${this._font.size}px; text-align: ${this._font.justify ? "justify" : "unset"};`;

    getFonts = () => this.fonts;
    
    getFont = () => this._font;

    getDefaultFont = () => ({
        name: this.fonts[0].name,
        family: this.fonts[0].family,
        weight: 400,
        size: 14
    });

    
    private constructor() {
        super();
        this._isDark = localStorage.getItem("theme:dark") === "true";
        const jsonFont = localStorage.getItem("theme:font");
        this._font = jsonFont ? JSON.parse(localStorage.getItem("theme:font")) : this.getDefaultFont();
        
        setTimeout(this.initHandler);
    }

    static get instance() { return ThemeStore._instance; }
    
    private initHandler = async () => {
        await DOM.ready();
        this.refreshDOMHandler();
    };

    private refreshDOMHandler = () => {
        if (this._isDark) {
            if ((<any>window).StatusBar) 
                (<any>window).StatusBar.styleLightContent();

            document.body.setAttribute("dark", "");
        } else {
            if ((<any>window).StatusBar) 
                (<any>window).StatusBar.styleDefault();
            
            document.body.removeAttribute("dark");
        }
            
    };

    isDark() : boolean {
        return this._isDark;
    }

    toggleDark(): void {
        this._isDark = !this._isDark;
        localStorage.setItem("theme:dark", this._isDark.toString());
        this.refreshDOMHandler();
    }

    setFont(font: Font): void {
        this._font = font;
        localStorage.setItem("theme:font", JSON.stringify(this._font));
        this.emitFontChanged(this._font);
    }
}

export interface Font {
    name: string;
    family: string;
    justify?: boolean;
    size?: number;
}