
import { Formatting } from "lib/Formatting";
import "./CreateStackElement-style";

class CreateStackElement extends HTMLElement {
    private escape = Formatting.htmlEscape;

    connectedCallback() {
        this.innerHTML = this.view();
    }
    
    private view = () => this.escape `
        <section>
            <header>
                <div><button type=button name=cancel>Cancel</button></div>
                <div class=caption>Create Stack</div>
                <div><button type=button name=add>Add</button></div>
            </header>
            
            <fieldset class=the-link>
                <legend></legend>
                <div class=field>
                    <input type="text" placeholder="Stack title" required focus />
                </div>
                <div class=field>
                    <input type="text" placeholder="Description (optional)" />
                </div>
            </fieldset>

            <div class=error-message></div>
            
            <div class=action>
                <button type=button class="power-inactive cancel" name=cancel>Cancel</button>
                <button type=button class=power name=add>Add stack</button>
            </div>
        </section>
    `;
}

customElements.define("create-stack", CreateStackElement);