import "./FilterViewElement-style";
import * as cardsStyle from "../../styles/grid-cards-module.less" 
import { FilterEditorViewController } from "./FilterEditorViewController";
import { FiltersStore, IFilter } from "./FiltersStore";

export class FilterViewElement extends HTMLElement {
    private _filter: IFilter;

    set filter(filter: IFilter) {
        this._filter = filter;
        this.refreshHandler();
    }

    connectedCallback() {
        this.className += ` ${cardsStyle.card}`;
        this.addEventListener("click", this.clickHandler);
    }

    disconnectedCallback() {
        this.removeEventListener("click", this.clickHandler);
    }

    private clickHandler = async (event: MouseEvent) => {
        if (!(event.target instanceof Element))
            return;

        const switchButton = event.target.closest("switch-button");
        if (switchButton) {
            const currentAction = switchButton.hasAttribute("checked");
            this._filter.enabled = currentAction;
            await FiltersStore.instance.upsert(this._filter);
            this.toggleAttribute("disabled", !this._filter.enabled);
            return;
        }

        await new FilterEditorViewController().editNoRoute(this._filter);
    }

    private refreshHandler = () => {
        this.toggleAttribute("disabled", !this._filter.enabled);
        this.innerHTML = this.view(this._filter);
    }

    private view = (filter: IFilter) => `
        <header>
            <div class="dot series-${filter.series}"></div>
            ${this.getTitle(filter)}
        </header>
        <div class=slide-panel>
            <switch-button ${filter.enabled ? "checked" : ""}></switch-button>
        </div>
        <section>${filter.summary}</section>
    `;

    private getTitle(filter: IFilter) {
        switch (filter?.type) {
            case "filterActionAssignTags":
                return "Assign tags";
            case "filterActionAssignStacks":
                return "Copy to stacks";
            default:
                return "Unknown";
        }
    }
}

customElements.define("filter-view", FilterViewElement);