import "./Mixin-popup-style";
import { IDisposable } from "lib/System";

export class MixinPopup implements IDisposable {
    public closeHandler = () => {
        this.element.dispatchEvent(new CustomEvent("close"));
        this.element.remove();
        this.dispose();
    }

    constructor(private element: HTMLElement){
        element.classList.add("mixin-popup");
        [].slice.call(element.querySelectorAll("[popup-close]")).forEach( (element:HTMLElement) => element.addEventListener("click", this.closeHandler));
    }

    dispose() {
        [].slice.call(this.element.querySelectorAll("[popup-close]")).forEach( (element:HTMLElement) => element.removeEventListener("click", this.closeHandler));
    }
}
