import { AuthenticationStore } from "features/authentication";
import { DOM } from "lib/DOM";
import { Delay } from "lib/System"
import { Link } from "features/links"
import { PagedResult } from "lib/Model";
import { Request, Pipeline } from "lib/HTTP"
import { Routing } from "lib/Routing";
import { UrlProvider } from "lib/UrlProvider";
import { cityline } from "features/cityline";
import { ClassTypename, Typename } from "lib/Types";
import EventTarget from "@ungap/event-target";

interface EventMap {
    "stack-added": Linkstack;
    "stack-removed": Linkstack;
    "stack-updated": Linkstack;
}

/*
    Actions on stacks
    Events on stacks
    Maintains a list of stacks
*/
@ClassTypename("StackStore")
export class StackStore {
    private static _instance = new StackStore();
    private _stacks: Linkstack[];
    private eventTarget = new EventTarget();
    private _urlProvider: UrlProvider;
    public inbox = "inbox";
    private initialized: Promise<void>;

    private constructor() {
        this._urlProvider = new UrlProvider
        this.reinitialize();
        this.registerForeignEvents();
    }

    static get instance(): StackStore {
        return StackStore._instance;
    }

    public addEventListener<K extends Extract<keyof EventMap, string>>(type: K, listener: (this: EventSource, ev: CustomEvent<EventMap[K]>) => any, options?: boolean | AddEventListenerOptions) {
        return this.eventTarget.addEventListener(type, listener, options);
    }

    public removeEventListener<K extends Extract<keyof EventMap, string>>(type: K, listener: (this: EventSource, ev: CustomEvent<EventMap[K]>) => any, options?: boolean | AddEventListenerOptions) {
        return this.eventTarget.removeEventListener(type, listener, options);
    }

    private emit<K extends Extract<keyof EventMap, string>, T extends EventMap>(type: K, value: T[K]) {
        return this.eventTarget.dispatchEvent(new CustomEvent(type, { detail: Object.assign({}, value, { [Typename]: StackStore[Typename] }) }));
    }

    private reinitialize() {
        this.initialized = new Promise<void>(async resolve => {
            await Delay.wait(10);
            await DOM.ready();
            await this.bootstrapData();
            resolve();
        });
    }

    private titleSort = (a: Linkstack, b: Linkstack) => {
        const atitle = a.title ? a.title.toLowerCase() : "";
        const btitle = b.title ? b.title.toLowerCase() : "";
        return atitle === btitle ? 0 : +(atitle > btitle) || -1;
    };

    async getAll() {
        await this.initialized;
        return this._stacks;
    }

    private async bootstrapData() {
        await AuthenticationStore.instance.whenSignedIn();
        this._stacks = await cityline.getFrame<Linkstack[]>("stacks");
        this._stacks = this._stacks.sort(this.titleSort);
    }

    private registerForeignEvents() {
        cityline.addEventListener("stacks", (event: CustomEvent<Linkstack[]>) => {
            if (!this._stacks)
                return;

            event.detail.forEach(stack => {

                if (this._stacks.map(m => m.id).indexOf(stack.id) === -1)
                    this.emit("stack-added", stack);
                else
                    this.emit("stack-updated", stack);
            });
        });
    }

    public get allLinksAsStack(): Linkstack {
        return {
            title: "All stacks",
            slug: "links",
            // id: string;
            // description?: string;
            // siteLocation?: string;
            // location?: string;
            // author?: Author;
            // preview?: string;
            // smallPreview?: string;
            // feedSiteLocation?: string;
            // created?: string;
            // linkCount?: number;
            // canEdit?: boolean;
            // query?: string;
            private: true
            // links?: LinkResult;
            // collaboratorCount?: number;
        };

    }

    public async getStackBySlug(userId: string, slug: string): Promise<Linkstack> {
        await this.initialized;

        if (slug === "links")
            return this.allLinksAsStack;

        return this._stacks.filter(m => m.author.userId === userId && m.slug === slug)[0];
    }

    public async getStackById(id: string): Promise<Linkstack> {
        await this.initialized;
        return this._stacks.filter(m => m.id === id)[0];
    }

    public async getStacks(slugs: string[]): Promise<Linkstack[]> {
        await this.initialized;
        return this._stacks.filter(stack => slugs.indexOf(stack.slug) !== -1);
    }

    public async lookupStack(slug: string): Promise<Linkstack> {
        if (!slug || slug === "")
            throw new Error("Slug must be provided to lookup a stack.");

        slug = slug.toLowerCase();

        await this.initialized;
        const stack = this._stacks.filter(stack => slug === stack.slug)[0];
        return stack;
    }

    public async deleteStack(slug: string): Promise<boolean> {
        const stack = this._stacks.filter(m => m.slug === slug)[0];
        const action = await this._urlProvider.stackLocation(slug);
        const response = await new Pipeline().fetch(action, Request.delete.authenticate());

        this._stacks = this._stacks.filter(m => m.id !== stack.id);

        if (response.ok)
            this.emit("stack-removed", stack);

        return response.ok;
    }

    public async updateStack(stack: Linkstack): Promise<void> {
        const action = await this._urlProvider.stackLocation(stack.slug);

        // clone stack
        const cloned = JSON.parse(JSON.stringify(stack)) as Linkstack;
        cloned.links = undefined;

        const response = await new Pipeline().fetch(action, Request.post.authenticate().setJSON(cloned));

        if (!response.ok) {
            alert(`Unable to update stack. (${response.status}, ${response.statusText})`);
            return;
        }

        const updatedStack = await response.json() as Linkstack;
        this._stacks = this._stacks.filter(m => m.id !== updatedStack.id);
        this._stacks.push(stack);
        this._stacks = this._stacks.sort(this.titleSort);

        this.emit("stack-updated", updatedStack);
    }

    public signalCreated(stack: Linkstack, showNow = true) {
        this._stacks.push(stack);
        this._stacks = this._stacks.sort(this.titleSort);

        this.emit("stack-added", stack);

        if (showNow)
            setTimeout(() => Routing.instance.go(stack.siteLocation));
    }

    public async createStack(stack: Linkstack, showNow = true) {
        const action = await this._urlProvider.stackLocation("");

        const response = await new Pipeline().fetch(action, Request.post.authenticate().setJSON(stack));
        const createdStack = await response.json() as Linkstack;
        this.signalCreated(createdStack, showNow);
    }



    public async fullRefresh(): Promise<void> {
        const action = await this._urlProvider.stacksLocation();
        const response = await new Pipeline().fetch(action, Request.get.authenticate());
        const result = await response.json() as PagedResult<Linkstack>;

        const oldStacks = this._stacks.slice(0);
        this._stacks = result.items;
        this._stacks.forEach(stack => {
            if (oldStacks.some(s => s.id === stack.id))
                this.emit("stack-updated", stack);
            else
                this.emit("stack-added", stack);
        });
    }

    public async lookup(q?: string): Promise<Linkstack[]> {
        await this.initialized;
        let result = this._stacks;

        if (q)
            result = result.filter(i => i.title && i.title.toLowerCase().indexOf(q.toLowerCase()) !== -1)

        return result;
    }

    public async lookupStacks(q = ""): Promise<PagedResult<Linkstack>> {
        let action = await this._urlProvider.stacksLocation();

        if (q)
            action += `?q=${q}`;

        const response = await new Pipeline().fetch(action, Request.get.authenticate());
        return await response.json() as PagedResult<Linkstack>;
    }

    public async getShortUrl(stack: Linkstack): Promise<string> {
        const response = await new Pipeline().fetch(`${this._urlProvider.stackLocationUser(stack.author.userId, stack.slug)}/_shorturl`, Request.post.authenticate().setJSON(stack));
        return await response.json();
    }
}

export interface Linkstack {
    title: string;
    slug?: string;
    id?: string;
    description?: string;
    siteLocation?: string;
    location?: string;
    author?: Author;
    preview?: string;
    smallPreview?: string;
    feedSiteLocation?: string;
    created?: string;
    linkCount?: number;
    canEdit?: boolean;
    query?: string;
    private: boolean;
    links?: LinkResult;
    collaboratorCount?: number;
}

export interface Author {
    userId: string;
    username: string;
    siteLocation: string;
    avatarLocation: string;
}


export interface LinkResult {
    items: Link[];
    nextPageLocation?: string;
    page: number;
    stackTotal: number;
    successfulIndexed: number;
    total: number;
}

