import * as widgetStyle from "./Widgets-module.less";

export type PreferSize = "wide" | "narrow";

export abstract class BaseWidget extends HTMLElement {
    public abstract get preferredWidth(): PreferSize;
    protected header: HTMLElement;
    protected _contextMenu: HTMLElement;

    connectedCallback() {
        this.className += ` ${widgetStyle.widget}`;

        this.header = this.querySelector("header");
        this.header?.addEventListener("click", this.headerClickHandler);

        this._contextMenu = this.querySelector("context-menu");
        this._contextMenu?.addEventListener("context-item-click", this.contextClickHandler);
    }
    
    disconnectedCallback() { 
        this.header?.removeEventListener("click", this.headerClickHandler);
        this._contextMenu?.removeEventListener("context-item-click", this.contextClickHandler);
    }

    protected modeChange(mode: string) {}

    private contextClickHandler = (event: CustomEvent<string>) => {
        const button = this.header.querySelector(`button[name="${event.detail}"]`) as HTMLButtonElement;
        if (button)
            this.activateButton(button);
    }

    private headerClickHandler = (event: MouseEvent) => {
        if (!(event.target instanceof Element))
            return;

        const button = event.target.closest("button");
        if (button)
            this.activateButton(button);
    }

    private activateButton(button: HTMLButtonElement) {
        this.header.querySelector("[active]")?.toggleAttribute("active", false);
        button.toggleAttribute("active", true);
        this.modeChange(button.name);
        this._contextMenu.querySelector("[name=select-legend] div").textContent = button.textContent;
    }

    protected headerView = (title: string, tabButtons: TabButton[] = [], selectedTabButton?: string, route?: string) => `
        <header  ${tabButtons?.length > 1 ? "multi" : "single"}>
            ${route ? `
                <h3>
                    <a href="${route}" route>
                        ${title}<span>${require("!!raw-loader!image/pop-out.svg")}</span>
                    </a>
                </h3>
            ` : `
                <h3>${title}</h3>
            `}
            
            <div name=buttons>
                ${tabButtons.map(button => `
                    <button type=button name="${button.value}" ${button.value === selectedTabButton ? "active": ""}>${button.label}</button>
                `).join("")}

                
            </div>

            <context-menu>
                    ${tabButtons.map(button => `
                        <item id="${button.value}" title="${button.label}"></item>
                    `).join("")}

                    <div name=select-legend>
                        <div>
                            ${tabButtons.filter(m => m.value === selectedTabButton)[0]?.label}
                        </div>
                        ${require("!!raw-loader!image/arrow-drop-down.svg")}
                    </div>
                    
                </context-menu>
        </header>`
} 


export interface TabButton {
    value: string;
    label: string;
}