import "./UserRegistrationElement-style";
import { AuthenticationStore } from "./AuthenticationStore";

import { Routing } from "lib/Routing";
import {  ValidationMessage, IdentityResult  } from "features/authentication/Model";

class UserRegistrationElement extends HTMLElement {
    async connectedCallback() {
        this.classList.add("form");
        
        if (this.hasAttribute("resend")) {
            this.innerHTML = this.checkEmailView();
            return;
        }
        
        this.innerHTML = this.view();        
        this.setupEvents()
    }

    private async register() {
        const usernameElement = this.querySelector("[name=username]") as HTMLInputElement;
        const emailElement = this.querySelector("[name=email]") as HTMLInputElement;
        const passwordElement = this.querySelector("[name=password]") as HTMLInputElement;
        const confirmPasswordElement = this.querySelector("[name=confirmPassword]") as HTMLInputElement;
        const formMessageElement = this.querySelector(`[name="form-message"]`) as HTMLSpanElement;

        formMessageElement.innerText = "";
        [].slice.call(this.querySelectorAll(".error")).forEach((element: HTMLElement) => {
            element.innerText = "";
        });

        if (passwordElement.value !== confirmPasswordElement.value){
            formMessageElement.innerText = "Password and repeated password must match.";
            return;
        }

        this.setAttribute("busy", "");

        let result;
        try {
            result = await AuthenticationStore.instance.register(usernameElement.value, emailElement.value, passwordElement.value);
        } finally {
            this.removeAttribute("busy");
        }
        
        
        if ((<ValidationMessage>result).modelState) {
            for(const key in (<ValidationMessage>result).modelState){
                const messageElement = this.querySelector(`[name="${key}"]`) as HTMLSpanElement;
                if (messageElement)
                    messageElement.innerText = (<ValidationMessage>result).modelState[key];
            }
        } else if (!(<IdentityResult>result).succeeded) {
            if (formMessageElement)
                formMessageElement.innerText = (<IdentityResult>result).errors.join("<br />");
        } else {
            this.innerHTML = this.checkEmailView();
        }
    }

    private setupEvents() {
        const registerButton = this.querySelector("[name=register]") as HTMLButtonElement;
        registerButton.addEventListener("click", async () => await this.register());

        this.addEventListener("keydown", async event => {
            if (event.key === "Enter")
                await this.register();
        });
    }


    private checkEmailView = () => `
        <header>
            <a>
                Please check your email
            </a>
        </header>
        <section>
            <p>We have now sent you a message containing a verification link.</p>
            <p>
                We do this to make sure that your email is valid and active, so that we know where to get a message to you in the future. 
            </p>
            <button type=button route href="/login">Back to login</button>
        </section>
    `;

    private view = () => `
        <header>
            <a>Create a new account</a>
        </header>
        <section>
            <span name="form-message" class="error"></span>
            <div class=form-group>
                <label for="username">Username</label>
                <div>
                    <input autocapitalize="none" autocorrect="off" class="form-control" id="username" name="username" placeholder="Username" type="text" value="">
                    <span name="model.Username" class="error"></span>
                </div>
            </div>
            <div class=form-group>
                <label for="email">Email</label>
                <div>
                    <input autocapitalize="none" autocorrect="off" class="form-control" id="email" name="email" placeholder="Email" type="text" value="">
                    <span name="model.Email" class="error"></span>
                </div>
            </div>
            <div class=form-group>
                <label for="Password">Password</label>
                <div>
                    <input data-val="true" id="password" name="password" placeholder="Password" type="password">
                    <span name="model.Password" class="error"></span>
                </div>
            </div>
            <div class=form-group>
                <label for="confirmPassword">Repeat Password</label>
                <div>
                    <input data-val="true" id="password" name="confirmPassword" placeholder="Repeat password" type="password">
                    <span name="model.ConfirmPassword" class="error"></span>
                </div>
            </div>
            <button type=button class=power name=register>Register</button>

            <div class="help-text">Or register using a provider</div>
            <social-providers></social-providers>

            <button type=button route href="/login">Back to login</button>
        </section>
    `;
}

Routing.instance.onChange(route => {
    if (route.path[0] === "register") {
        const loginFlow = document.querySelector("login-flow .content");
        if (loginFlow) // Right now server rendred stuff does not expose this .content, but renders form itself
            loginFlow.innerHTML = "<user-registration></user-registration>";
    }
});

customElements.define("user-registration", UserRegistrationElement);