import { EventEmitter } from "events";
import { SidebarActivator } from "features/side-bar/SidebarActivator";

export class TopMenuStore extends EventEmitter {
    private static _instance = new TopMenuStore();
    static get instance() : TopMenuStore { return TopMenuStore._instance; }
    private _phoneMediaMatch = window.matchMedia("screen and (max-width: 850px)");
    
    private constructor() 
    { 
        super(); 
        this._phoneMediaMatch.addListener(this.emitChange);
    }
    
    private customMenuGroups: { [groupName: string] : TopMenuItem[]; } = { 
        "standard": [
        {
            title: "Add url (Alt+L)",
            name: "add-anything",
            icon: require("!!raw-loader!image/plus-20-v1.svg"),
            side: "right",
            priority: 10,
            handler: () => SidebarActivator.toggleElement("add-anything", () => document.createElement("add-anything"))
        },
        {
            title: "Help (?)",
            name: "help",
            icon: require("!!raw-loader!image/help.svg"),
            side: "context",
            priority: 10,
            handler: () => SidebarActivator.toggleElement("help-sidebar", () => document.createElement("help-sidebar")),
        }
    ]};

    all() : TopMenuItem[] {
        let allItems = [];

        for(const key in this.customMenuGroups) {
            allItems = allItems.concat(this.customMenuGroups[key]);
        }

        allItems = allItems.filter(m => m.evaluator === undefined || m.evaluator());

        allItems.sort( (a:TopMenuItem, b:TopMenuItem) => {
            if (a.priority > b.priority)
                return 1;
            
            if (a.priority < b.priority)
                return -1;

            return 0;
                
        });

        return allItems;
    }

    public toggle(name: string, state = true) {
        this.all().filter(m => m.name === name).forEach(m => {
            m.toggled = state;
        });

        this.emitChange();
    }

    private emitChange = () => {
        this.emit("change");
    }

    onChange(callback: () => void) {
        this.on("change", callback);
    }

    offChange(callback: () => void) {
        if (callback)
            this.removeListener("change", callback);
    }

    setGroup(name: string, items: TopMenuItem[]) {
        this.customMenuGroups[name] = items;
        this.emitChange();
    }

    append(name: string, item: TopMenuItem) {
        this.customMenuGroups[name] = this.customMenuGroups[name] || [];
        this.customMenuGroups[name].push(item);
        this.emitChange();
    }

    trigger(name: string) {
        const item = this.all().filter(m => m.name === name)[0];
        if (item && item.handler)
            item.handler();
    }
}

export interface TopMenuItem {
    title: string;
    name: string;
    icon?: any;
    side: "left" | "right" | "context"
    priority?: number,
    handler?: () => void,
    evaluator?: () => boolean,
    toggled?: boolean
}