import "./ActiveAnchorElement-style";
import { LinkStore } from "features/links";

class ActiveAnchorElement extends HTMLElement {
    private hoverTimer;
    private saveLinkTimer;
    private isTrueClick = false;
    private isTouch = (("ontouchstart" in window) || (navigator.maxTouchPoints > 0));
    private actionThreshold: number;
    private aTag: HTMLAnchorElement;
    private url: string;
    private popup: HTMLElement;
    private timerDidFire = false;

    connectedCallback() {
        this.actionThreshold = this.isTouch ? 500 : 1000;

        // end quickly, this thing is shown a lot
        window.requestIdleCallback(() => {

            this.aTag = this.querySelector("a[href]") as HTMLAnchorElement;

            if (!this.aTag)
                return;
                
            this.aTag.classList.add("needsclick");
            this.url = this.aTag.getAttribute("href");
            if (!this.url || this.url === "")
                return;

            if (this.isTouch) {
                this.addEventListener("touchstart", this.touchStartHandler, { passive: true });
                this.addEventListener("touchend", this.touchEndHandler, { passive: true });   
                this.addEventListener("touchmove", this.touchMoveHandler, { passive: true });   
                this.addEventListener("click", this.clickHandler);    
            } else {
                this.addEventListener("mouseover", this.mouseoverHandler, { passive: true });
                this.addEventListener("mouseleave", this.mouseleaveHandler, { passive: true });
                this.addEventListener("mousemove", this.mouseoverHandler, { passive: true });
            }

        }, { timeout: 1000 });
        
    }

    disconnectedCallback() {
        if (this.isTouch) {
            this.removeEventListener("touchstart", this.touchStartHandler);
            this.removeEventListener("touchend", this.touchEndHandler);   
            this.removeEventListener("click", this.clickHandler);  
            this.removeEventListener("touchmove", this.touchEndHandler); 
        } else {
            this.removeEventListener("mouseover", this.mouseoverHandler);
            this.removeEventListener("mouseleave", this.mouseleaveHandler);
            this.removeEventListener("mousemove", this.touchMoveHandler);
        }
    }

    private mouseoverHandler = (event: MouseEvent) => {
        window.clearTimeout(this.hoverTimer);
        this.hoverTimer = setTimeout(() => this.popupHandler(event.clientX, event.clientY), this.actionThreshold);
    }

    private mouseleaveHandler = () => {
        window.clearTimeout(this.hoverTimer);

        if (this.popup)
            setTimeout(() => {
                if (!this.popup.hasAttribute("mouse-over"))
                    this.removePopup(this.popup);
            }, 2000);
    }

    removePopup(element: HTMLElement) {
        element.style.opacity = "0";
        setTimeout(() => {
            element.remove();
        }, 200);
    }

    private clickHandler = (event) => {
        console.log("click");
        if (this.isTrueClick === false || this.timerDidFire) {
            event.preventDefault();
            event.stopPropagation();
        }

        this.timerDidFire = false;

    }

    private touchMoveHandler = () => {
        this.isTrueClick = false;   
        clearTimeout(this.saveLinkTimer);
    }

    private touchEndHandler = () => {
        this.isTrueClick = true;   
        clearTimeout(this.saveLinkTimer);
    }

    private touchStartHandler = (event) => {
        this.saveLinkTimer = setTimeout(async () => {
            this.timerDidFire = true;
            this.isTrueClick = false;
            await LinkStore.instance.addAndForget(this.url);
            
        }, this.actionThreshold);

       
    }

    private popupHandler = (mouseX, mouseY) => {
        if (this.popup)
            this.removePopup(this.popup);

        const bounding = this.aTag.getBoundingClientRect();
        this.popup = document.createElement("anchor-popup");
        this.popup.style.opacity = "0";
        this.popup.setAttribute("url", this.url); 
        this.popup.style.left = `${mouseX - 20}px`;
        this.popup.style.top = `${bounding.top - 36}px`;

        
        requestAnimationFrame(() => {
            document.body.appendChild(this.popup);
            this.popup.style.opacity = "1";
        });
    } 
}

customElements.define("active-anchor", ActiveAnchorElement);