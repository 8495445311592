import { DOM } from "lib/DOM";
import * as dialogStyle from "../../styles/dialog-module.less";

export interface IViewController {
    view: () => string;
    load?: (element: HTMLElement) => void;
    unload?: (element: HTMLElement) => void;
}

export interface ITap {
    title: string;
    viewController: () => IViewController;
}

export class BaseDialogViewController {
    protected dialog: HTMLDialogElement;
    private static idCount = 0;
    protected clazz = "";
    protected renderFrame = true;
    private checkAndClose: (e) => void;
    private checkAndCloseKeyboard: (e) => void;
    protected targetSelector: () => HTMLElement = () => <HTMLElement>document.querySelector("body") ;
    protected targetLocation: InsertPosition = "afterbegin";
    protected tabs: ITap[];
    protected currentTab: ITap;

    constructor() {
        setTimeout(async () => await this.initialize());
    }

    async initialize() {
        await DOM.ready();
        this.setupDOM();
        this.registerEvents();
    }

    getTabs(): ITap[] {
        return [{
            title: this.getTitle(),
            viewController: () => ({ view: () => this.renderSection() })
        }];
    }

    getFormAction() {
        return "links";
    }

    setupDOM() {
    }

    registerEvents() {

    }
    
    cancel() {
        this.close();
    }

    afterRender() {
        const closeButton = <HTMLElement>this.dialog.querySelector(".close");

        if (closeButton)
            closeButton.addEventListener("click", (event) => {
                event.preventDefault();
                this.cancel();
            });    

        this.checkAndClose = (e) => {
            if (document.contains(e.target) && !this.dialog.contains(e.target)) {
                e.preventDefault();
                this.cancel();
            }
        }

        this.checkAndCloseKeyboard = (e : KeyboardEvent) => {
            if (e.key === "Escape")
                this.cancel();
        }

        window.setTimeout(() => {
            document.body.addEventListener("click", this.checkAndClose);
            document.body.addEventListener("keydown", this.checkAndCloseKeyboard);
        }, 0);


        if (this.getTabs().length > 1) {
            const tabButtons = Array.prototype.slice.call(this.dialog.querySelectorAll("header > a:not(.close)"));
            tabButtons.forEach(tabButton => {
                tabButton.addEventListener("click", event => {
                    event.preventDefault();
                    //tabButtons.forEach(button => button.classList.remove('active'));
                    //tabButton.classList.add('active');
                    const tab = this.getTabs().filter(l => l.title === tabButton.innerText)[0];
                    this.showTab(tab);
                });
                
            });
        }

        this.validateCurrentTab();
    }

    showTab(tab: ITap) {
        const section = <HTMLElement>this.dialog.querySelector("section");

        if (this.currentTab && this.currentTab.viewController().unload)
            this.currentTab.viewController().unload(section);

        this.currentTab = tab;

        const tabHtml = tab.viewController().view();
        
        section.innerHTML = tabHtml;

        if (this.currentTab.viewController().load)
            this.currentTab.viewController().load(section);

        // update submit button
        const changableElements = <HTMLInputElement[]>Array.prototype.slice.call(section.querySelectorAll("input"));
        changableElements.forEach(element => {
            element.addEventListener("change", () => this.validateCurrentTab());
            element.addEventListener("input", () => this.validateCurrentTab());
            element.addEventListener("keyup", () => this.validateCurrentTab());
            element.addEventListener("paste", () => setTimeout(() => this.validateCurrentTab(), 0));
        });

        // update tab-bar
        const tabButtons = <HTMLAnchorElement[]>Array.prototype.slice.call(this.dialog.querySelectorAll("header > a:not(.close)"));
        if (tabButtons.length > 1)
            tabButtons.forEach(button => button.innerText === tab.title ? button.classList.add("active") : button.classList.remove("active"));

    }

    validateCurrentTab() {
        const footer = this.dialog.querySelector("footer");
        const button = <HTMLButtonElement>footer.querySelector("button[type=submit]");
        const form = <HTMLFormElement>this.dialog.querySelector("form");

        if (!button)
            return;
        
        button.disabled = !form.checkValidity();

        const tabButtons = <HTMLAnchorElement[]>Array.prototype.slice.call(this.dialog.querySelectorAll("header > a:not(.close)"));
        tabButtons.forEach(button => form.checkValidity() ? button.classList.remove("disabled") : button.classList.add("disabled"));
    }

    beforeRender() {
        return Promise.resolve();
    }

    close() {
        document.body.removeEventListener("click", this.checkAndClose);
        document.body.removeEventListener("keydown", this.checkAndCloseKeyboard);

        if (this.renderFrame)
            this.dialog.parentElement.remove();
        else
            this.dialog.remove();
    }

    renderSection = () => "";

    renderFooter = () => "";

    getTitle(): string {
        return "";
    }

    async show() : Promise<void> {
        await this.beforeRender();
            
        const id = `dialog-${BaseDialogViewController.idCount++}`;

        const target = <HTMLElement>this.targetSelector();

        if (!target)
            throw Error("Target for dialog not found");

        target.insertAdjacentHTML(this.targetLocation, this.dialogView(id));
        this.dialog = <HTMLDialogElement>document.getElementById(id);
        this.showTab(this.getTabs()[0]);
        this.dialog.style.display = "block";
        this.afterRender();    
    }
    
    headerView = (title: string) => {
        const tabs = this.getTabs();
        return `<header>
                    ${tabs.map(l => `<a${l === tabs[0] && tabs.length > 1 ? " class=\"active\"" : ""}>${l.title}</a>`).join("")}
                    <a href="#" class=close>
                        ${require("!!svg-inline-loader!image/icons/close-icon.svg")}
                    </a>
                </header>`;
    }

    private dialogView = (id: string) => `
        ${this.renderFrame ? `<div class="${dialogStyle.frame}">`: ""}
            <div id="${id}" class="dialog ${this.clazz}">
                <form action="${this.getFormAction()}" method="post" autocomplete="off">    
                    <button style="overflow: visible !important; height: 0 !important; width: 0 !important; margin: 0 !important; border: 0 !important; padding: 0 !important; display: block !important;" type="submit"></button>
                    ${this.headerView(this.getTitle())}
                    <section>
                        
                    </section>
                    <footer>
                        ${this.renderFooter()}
                    </footer>
                </form>
            </div>
        ${this.renderFrame ? `</div>`: ""}`;
}
