import { AuthenticationStore } from "features/authentication";
import { DialogController } from "features/dialog";
import "./ChangePasswordElement-style";

interface BadResponse {
    message: string;
    modelState: { key: string; value: string[]; }
}

export class ChangePasswordElement extends HTMLElement {
    protected static path = ["manage", "password"];
    public static tag = "change-password";


    async connectedCallback(){
        await this.refresh();
    }

    async refresh(){
        const hasPassword = await AuthenticationStore.instance.hasPassword();
        this.innerHTML = this.view(hasPassword);

        const form = this.querySelector("form") as HTMLFormElement;
        form.addEventListener("submit", async (event) => {
            event.preventDefault();
            this.setAttribute("busy", "");

            [].slice.call(this.querySelectorAll(".validation-message")).forEach(element => {
                element.innerHTML = "";
            });

            const oldPassword = this.querySelector("[name=oldPassword]") as HTMLInputElement;
            const passwordChangeModel = {
                oldPassword: oldPassword ? oldPassword.value : undefined,
                newPassword: (this.querySelector("[name=newPassword]") as HTMLInputElement).value,
                confirmPassword: (this.querySelector("[name=confirmPassword]") as HTMLInputElement).value
            };

            let response;
            try {
                response = await AuthenticationStore.instance.setPassword(passwordChangeModel);
            } finally {
                this.removeAttribute("busy");
            }
            

            if (response.status === 400) {
                const badResponse = await response.json() as BadResponse;

                for(const key in badResponse.modelState) {
                    const validationMessageElement = this.querySelector(`.validation-message[name='${key}']`);
                    validationMessageElement.innerHTML = badResponse.modelState[key].join("<br />");
                }
            } else {
                if (hasPassword) {
                    await this.refresh();
                    await new DialogController().prompt({
                        caption: "Password changed",
                        message: "Your password was successfully changed.",
                        options: ["Ok"]
                    });
                } else {
                    await this.refresh();
                    await new DialogController().prompt({
                        caption: "Password added",
                        message: "Your account now has a password.",
                        options: ["Ok"]
                    });
                }
                    
            }

        });
    }


    

    private view = (hasPassword: boolean) => `
        <div class="box password case">
            <header>${hasPassword ? "Change password" : "Set password"}</header>
            <div class=description>
                ${hasPassword ? "Change the password that you use to access Linkstacks." : "Set a password to access Linkstacks without using a external login."} 
            </div>

            <form>

                <div class=validation-message name=""></div>

                ${hasPassword ? `
                <fieldset class=active>
                    <label for=old-password>Please enter old password</label>
                    <input type=password id=old-password name=oldPassword focus placeholder="" required>
                    <div class=validation-message name="model.OldPassword"></div>
                </fieldset>` : ""}

                <fieldset>
                    <label for=old-password>New password</label>
                    <input type=password id=new-password name=newPassword placeholder="" required>
                    <div class=validation-message name="model.NewPassword"></div>
                </fieldset>

                <fieldset>
                    <label for=old-password>Please repeat new password</label>
                    <input type=password id=repeat-new-password name=confirmPassword placeholder="" required>
                    <div class=validation-message name="model.ConfirmPassword"></div>
                </fieldset>

                <fieldset class="right">
                    <button class=power type=submit>${hasPassword ? "Update" : "Set"} password</button>
                </fieldset>

            </form>

        </div>
    `;
}

customElements.define(ChangePasswordElement.tag, ChangePasswordElement);
