import { AuthenticationStore } from "features/authentication";
import { BaseSideMenuElement, MenuCategory } from "features/side-menu/BaseSideMenuElement";
import { FeedbackActivator } from "../feedback/FeedbackActivator";
import { MenuItem } from "./BaseSideMenuElement";
import { BrowserInfo } from "lib/Browser";

class ProfileSideMenuElement extends BaseSideMenuElement {
    async connectedCallback() {
        await super.connectedCallback();
    }

    disconnectedCallback() {
        super.disconnectedCallback();
    }

    protected async itemClick(id: string) {
        if (id === "logout")
            await AuthenticationStore.instance.logout();


        if (id === "feedback")
            FeedbackActivator.show();

        // if (id === "history") {
        //     SidebarActivator.toggleElement("event-history", () => document.createElement("event-history"));
        // }
    }

    protected async getMenuCategories(selectedEvaluator: (item: MenuItem) => void) : Promise<MenuCategory[]> {
        let items = [
            {
                id: "notifications",
                title: "Emails & notifications",
                path: "/manage/notifications",
                svgIcon: require("!!raw-loader!image/email.svg")
            },
            BrowserInfo.isIOSApp() ? undefined : 
            {
                id: "membership-payment",
                title: "Membership & Payment",
                path: "/manage/membership",
                svgIcon: require("!!raw-loader!image/membership.svg")
            },
            {
                id: "login",
                title: "Login & Password",
                path: "/manage/login",
                svgIcon:  require("!!raw-loader!image/login.svg")
            },
            BrowserInfo.isIOSApp() ? undefined : 
            {
                id: "apps",
                title: "Apps",
                path: "/apps",
                svgIcon: require("!!raw-loader!image/icons/v2/apps-20-v1.svg")
            },
            {
                id: "authorized-applications",
                title: "Authorized applications",
                path: "/manage/authorized-applications",
                svgIcon: require("!!raw-loader!image/checklist.svg")
            },
            // {
            //     id: "history",
            //     title: "History",
            //     svgIcon: require("!!raw-loader!image/icons/header/history-20-v1.svg")
            // },
            {
                id: "deactivate-account",
                title: "Deactivate account",
                path: "/manage/deactivate-account",
                svgIcon: require("!!raw-loader!image/deactivate-account.svg")
            },
            {
                id: "feedback",
                title: "Feedback",
                svgIcon: require("!!raw-loader!image/feedback.svg")
            },
            {
                id: "logout",
                title: "Log out",
                path: "/manage/log-out",
                svgIcon: require("!!raw-loader!image/icons/v2/logout-20-v3.svg")
            }  
        ];

        items = items.filter(m => m !== undefined);

        items.forEach(selectedEvaluator);
        return [{ items: items }];
    }
}



customElements.define("profile-side-menu", ProfileSideMenuElement);
