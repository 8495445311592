import { EventEmitter } from "events";
import { Request, Pipeline } from "lib/HTTP";
import { UrlProvider } from "lib/UrlProvider";
import { LoginProviderFactory } from "./LoginProviders/LoginProviderFactory";
import { 
    AuthenticationMode,
    User,
    Provider,
    ChangePassword,
    AddProviderCallbackMessage,
    Activity,
    ActivityStatus,
    AddProviderResult,
    ValidationMessage,
    IdentityResult,
    State,
    ProviderName,
    LoginResponse } from "./Model";
import { UserPreferenceStore, UserPreferenceFlag } from "features/user-preferences/UserPreferenceStore";
import { cityline } from "features/cityline";

export class AuthenticationStore extends EventEmitter {
    private _hasPassword: boolean;
    private static _instance = new AuthenticationStore();
    private _auth: string;
    private _state: State = "unknown";
    private initialized: Promise<void>;
    private urlProvider = new UrlProvider(); 
    private _account: User;
    private _currentActivity: Activity = { status: ActivityStatus.Idle };

    static get instance() {
        return AuthenticationStore._instance;
    }

    get currentActivity() {
        return this._currentActivity;
    }

    private constructor() {
        super();
        
        try {
            this._auth = localStorage.getItem("auth");            
        }
        catch (error) {
            console.log(`Unable to read auth from local storage. ${error}`);
        }

        if (this._auth && this._auth !== "" && this._auth !== "undefined")
            this.changeActivity(ActivityStatus.TryingToSignIn);
        
        cityline.addEventListener("account", (event: CustomEvent<User>) => {
            this._account = event.detail;
        });

        
     

        this.reinitialize();
        this.onStateChanged(this.updateBody);

        // replace this with usernameproducer
        setTimeout(() => {
            this.onStateChangedOnce(() => cityline.reset());
        }, 3000);
    }

    private changeActivity(status: ActivityStatus, reason = "") {
        if (this._currentActivity.status === status)
            return;
        
        this._currentActivity = {
            status: status,
            reason: reason
        };
        this.emitActivityChanged(this._currentActivity);
    }

    async isAuthenticated() {
        if (this._state === "unknown") 
            await AuthenticationStore.instance.getState();
        
        return this._state === "signed-in";
    }

    private updateBody = (state: State) => {
        if (state === "signed-in")
            document.body.classList.add("authenticated");
        else
            document.body.classList.remove("authenticated");
    }

    // whenSignedIn() : Promise<void> {
    //     return new Promise(r => {
    //         if (this._state === "signed-in")
    //             setTimeout(() => r());

    //         const handler = state => {
    //             if (state === "signed-in") {
    //                 this.offStateChanged(handler);
    //                 setTimeout(() => r());
    //             }
    //         }
    //         this.onStateChanged(handler);
    //     }); 
    // }

    whenSignedIn = async () => await cityline.getFrame<User>("account");
    
    private reinitialize() {
        this.initialized = new Promise(resolve => {
            setTimeout(async () => {
                await this.doInitialize();
                resolve();
            });
        });
    }

    private async doInitialize() {
        try {
            this._hasPassword = await UserPreferenceStore.instance.isSet(UserPreferenceFlag.HasPassword);
            this._account = await cityline.getFrame<User>("account");
            
            if (this._account) {
                this.ensureState("signed-in");
                this.changeActivity(ActivityStatus.SignedIn);
            } else {
                this.changeActivity(ActivityStatus.FailedSigningIn);
            }
        } catch(error) {
            setTimeout(() => {
                console.log(error);
                this.changeActivity(ActivityStatus.FailedSigningIn);
            });
        }
    }

    public async providers() {
        await this.initialized;
        return this._account.logins;
    }

    public async hasPassword() {
        await this.initialized;
        return this._hasPassword;
    }

    // TODO: REMOVE
    getAuth() { return this._auth;}

    private emitActivityChanged(activity: Activity) {
        this.emit("ActivityChanged", activity);
    }

    public onActivityChanged(callback: (activity: Activity) => void) {
        this.on("ActivityChanged", callback);
    }

    public removeActivityChanged(callback: (activity: Activity) => void) {
        this.removeListener("ActivityChanged", callback);
    }

    private emitStateChanged(state: State, loginResponse?: LoginResponse) {
        this.emit("StateChanged", state, loginResponse);
    }

    public onStateChanged(callback: (state: State, loginResponse?: LoginResponse) => void) {
        this.on("StateChanged", callback);
    }

    public offStateChanged(callback: (state: State, loginResponse?: LoginResponse) => void) {
        this.removeListener("StateChanged", callback);
    }

    public onStateChangedOnce(callback: (state: State, loginResponse?: LoginResponse) => void) {
        this.once("StateChanged", callback);
    }

    private emitProvidersChanged(providers: Provider[]) {
        this.emit("ProvidersChanged", providers);
    }

    public onProvidersChanged(callback: (providers: Provider[]) => void) {
        this.on("ProvidersChanged", callback);
    }

    public removeOnProvidersChanged(callback: (providers: Provider[]) => void) {
        this.removeListener("ProvidersChanged", callback);
    }

    private emitAddProviderError(error: AddProviderResult) {
        this.emit("AddProviderError", error);
    }

    public onAddProviderError(callback: (error: AddProviderResult) => void) {
        this.on("AddProviderError", callback);
    }

    public removeOnAddProviderError(callback: (error: AddProviderResult) => void) {
        this.removeListener("AddProviderError", callback);
    }

    public async getState() {
        await this.getRemoteState();
        return this._state;
    }

    get token() {
        return this._auth;
    }

    get mode() : AuthenticationMode {
        return window.location.protocol === "file:" || window.location.hostname === "localhost" ? AuthenticationMode.Header : AuthenticationMode.Cookie; 
    }

    private ensureState(state: State) {
        if (this._state === state)
            return;

        this._state = state;
        this.reinitialize();
        this.emitStateChanged(state);
    }

    handleAddProviderCallbackMessage = (event: MessageEvent) => {
        if (event.origin !== this.urlProvider.root)
            return;

        try {
            const message = JSON.parse(event.data) as AddProviderCallbackMessage;
            if (!message.status)
                return;

            window.removeEventListener("message", this.handleAddProviderCallbackMessage);

            if (this.addProviderWindow)
                this.addProviderWindow.close();
            
            if (message.status === "success") {

                this._account.logins = this._account.logins.filter(i => i.loginProvider !== message.provider.loginProvider);
                this._account.logins.push(message.provider);
                this._account.logins.sort( (a, b) => a.loginProvider === b.loginProvider ? 0 : +(a.loginProvider < b.loginProvider) || -1);
                this.emitProvidersChanged(this._account.logins);
            } else {
                this.emitAddProviderError(message.status);
            }
            
        } catch(error) {
            //console.log("Unusable callback message", error, event);
        } 
    }

    private addProviderWindow: Window;

    async setPassword(passwordModel: ChangePassword) {
        const url = this._hasPassword ? `${this.urlProvider.root}/api/account/changepassword` : `${this.urlProvider.root}/api/account/setpassword`;
        const response = await new Pipeline().fetch(url, Request.post.authenticate().setJSON(passwordModel));
        if (response.ok && !this._hasPassword) {
            this._hasPassword = true;
            this.emitProvidersChanged(this._account.logins);
        }
        
        return response;
    }

    async usernameAvailable(username: string) : Promise<boolean> {
        const response = await new Pipeline().fetch(`${this.urlProvider.root}/api/account/username/${username}`);
        const result = await response.text();
        return result === "false";
    }

    async emailAvailable(email: string) : Promise<boolean> {
        const response = await new Pipeline().fetch(`${this.urlProvider.root}/api/account/email/${encodeURIComponent(email)}`);
        const result = await response.text();
        return result === "false";
    }

    async addProvider(providerName: ProviderName) {
        this.addProviderWindow = window.open(`${this.urlProvider.root}/api/login-providers/${providerName}`, "_blank", "location=yes,width=1050,height=650");
        
        window.addEventListener("message", this.handleAddProviderCallbackMessage);
    }

    async removeProvider(providerName: ProviderName) {
        const provider = this._account.logins.filter(p => p.loginProvider === providerName)[0];
        const result = await new Pipeline().fetch(`${this.urlProvider.root}/api/login-providers/${providerName}`, Request.delete.authenticate());
        if (result.ok) {
            provider.providerKey = undefined;
            this.emitProvidersChanged(this._account.logins);
        }
            
    }

    private async getRemoteState() {
        if (this.mode === AuthenticationMode.Header && !this._auth)
            return this.ensureState("not-signed-in");

        
        // const currentUser = await this.currentUser();
        // if (currentUser) {
        //     this.ensureState("signed-in");
        //     return;
        // }
    

        const response = await new Pipeline().ignoreLoggedInStatus().fetch(`${this.urlProvider.root}/api/login-manager`, new Request().authenticate());

            
        if (response.ok){
            this.ensureState("signed-in");
            this.updateAuth(response.headers.get("Authorization"));
            return;
        }
        
        if (response.status === 401) {
            return this.ensureState("not-signed-in");
        }
        
        if (response.status >= 500)
            alert("Unable to connect");
    }

    async registerExternal(username: string, email?: string) : Promise<IdentityResult | ValidationMessage> {
        const credentials = { username: username, email: email };
        const response = await new Pipeline().fetch(`${this.urlProvider.root}/api/login-manager/register-external`, new Request().authenticate(AuthenticationMode.Header).setJSON(credentials));

        const result: IdentityResult | ValidationMessage = await response.json();
        
        if (response.ok && (result as IdentityResult).succeeded ) {
            this.updateAuth(response.headers.get("Authorization"));
            this.ensureState("signed-in");
        }
        
        return result;
    }

    async mergeExternal() : Promise<IdentityResult | ValidationMessage> {
        const response = await new Pipeline().fetch(`${this.urlProvider.root}/api/login-manager/merge-external`, Request.post.authenticate(AuthenticationMode.Header));

        const result: IdentityResult | ValidationMessage = await response.json();
        
        if (response.ok && (result as IdentityResult).succeeded ) {
            this.updateAuth(response.headers.get("Authorization"));
            this.ensureState("signed-in");
        }
        
        return result;
    }

    async register(username: string, email: string, password: string) : Promise<IdentityResult> {
        const details = { username: username, email: email, password: password };
        const response = await new Pipeline().fetch(`${this.urlProvider.root}/api/login-manager/register`, Request.post.setJSON(details));

        const result: IdentityResult = await response.json();
        return result;
    }

    async resendRegistrationMail(username: string) : Promise<boolean> {
        const details = { username: username };
        const response = await new Pipeline().fetch(`${this.urlProvider.root}/api/login-manager/resend-registration-mail`, Request.post.setJSON(details));
        return response.ok;
    }

    async forgotPassword(usernameOrEmail: string) : Promise<boolean> {
        const response = await new Pipeline().fetch(`${this.urlProvider.root}/api/login-manager/forgot-password`, Request.post.setJSON({ usernameOrEmail: usernameOrEmail }));
        return response.ok;
    }

    async deactivateAccount(username: string) : Promise<boolean> {
        const response = await new Pipeline().fetch(`${this.urlProvider.root}/api/login-manager/deactivate-account`, Request.post.authenticate().setJSON({ username: username }));
        return response.ok;
    }

    async login(usernameOrEmail: string, password: string) : Promise<LoginResponse> {
        const credentials = { usernameOrEmail: usernameOrEmail, password: password };
        const response = await new Pipeline().fetch(`${this.urlProvider.root}/api/login-manager`, new Request().setJSON(credentials));

        const result: LoginResponse = await response.json();
        
        if (response.ok) {
            this.updateAuth(response.headers.get("Authorization"));
            this.ensureState("signed-in");
        }
        
        return result;
    }


    async loginExternal(providerName: string) : Promise<LoginResponse> {
        const loginProvider = await LoginProviderFactory.resolve(providerName);
        const response = await loginProvider.login();
        this.handleLoginResponse(response);
        return response;
    }

    async logout() {
        const response = await new Pipeline().fetch(`${this.urlProvider.root}/api/login-manager/logout`, Request.post);
        if (response.status !== 200 && response.status !== 401) {
            throw new Error("Unable to sign out");
        }

        
        if (response.ok) {
            this.updateAuth(undefined);
      
            if (window.location.protocol !== "file:" && window.location.hostname !== "localhost")
                window.location.href = "/";
            else
                window.location.reload();
        }
    }


    async resetPassword(userId: string, code: string, password: string) {
        const model = { userId: userId, code: code, password: password };
        const response = await new Pipeline().fetch(`${this.urlProvider.root}/api/login-manager/set-password`, Request.post.setJSON(model));
        if (response.ok)
            return true;
        
        return false;
    }

    private updateAuth(auth: string) {
        this._auth = auth;
        
        try {
            localStorage.setItem("auth", this._auth); 
        } catch (error) {
            console.log(`Unable to write auth to local storage. ${error}`);
        }
    }

    async refreshToken() {

    } 

    async currentUserNoFetch() : Promise<User> {
        return this._account;
    }

    async currentUser() : Promise<User> {
        await this.initialized;
        return this._account;
    }

    async currentAccount() : Promise<User> {
        await this.initialized;
        return this._account;
    }


    public handleLoginResponse(loginResponse: LoginResponse) {
        this.emit("login-response", loginResponse);    
        switch (loginResponse.status) {
            case "success":
                this.updateAuth(loginResponse.bearer);
                this.ensureState("signed-in");
                break;
            case "lockedOut":
                alert("locked out");
                break;
            case "requiresVerification":
                alert("requiresverification");
                break;
            case "failure":
                this.updateAuth(loginResponse.bearer);
                //this.loginResponse = loginResponse;
                this.emitStateChanged("complete-external", loginResponse);
                this.ensureState("complete-external");
                break;
        }
    }
}

