import "./WordTableElement-style";
import { scaleLinear } from "d3-scale";
import { wrapGrid } from "animate-css-grid";

interface Word {
    text: string;
    frequency: number;
}

export class WordTableElement extends HTMLElement {
    private forceGridAnimation: () => void;
    static get observedAttributes() { return ["words"]; }


    connectedCallback() {

        const words = this.parseWords();
        const frequencies = words.map(m => m.frequency);
        const minFrequency = Math.min(...frequencies);
        const maxFrequency = Math.max(...frequencies);

        const scaler = scaleLinear()
            .domain([minFrequency, maxFrequency])
            .range(["1%", "100%"]);

        this.innerHTML = this.view(this.parseWords(), scaler);

        const { forceGridAnimation } = wrapGrid(this, { duration: 800 });
        this.forceGridAnimation = forceGridAnimation;

        requestAnimationFrame(this.refresh);
    }

    private refresh = () => {
        const words = this.parseWords();

        const frequencies = words.map(m => m.frequency);
        const minFrequency = Math.min(...frequencies);
        const maxFrequency = Math.max(...frequencies);

        const scaler = scaleLinear()
            .domain([minFrequency, maxFrequency])
            .range(["1%", "100%"]);

        for (const wordElement of Array.from(this.children)) {
            const wordValue = wordElement.getAttribute("word-value");
            if (wordValue) {
                if (!words.some(word => word.text.toLowerCase() === wordValue.toLowerCase()))
                    wordElement.remove();
            }
        }

        for (const word of words) {
            const existing = this.querySelector(`a[word-value="${word.text.toLowerCase()}"]`) as HTMLDivElement;
            if (existing) {
                existing.parentElement.style.order = `${word.frequency * -1}`;
                (<HTMLDivElement>existing.querySelector(".frequency")).innerText = word.frequency.toString();
                (<HTMLDivElement>existing.querySelector(".indicator")).style.width = scaler(word.frequency);
            } else {
                this.insertAdjacentHTML("beforeend", this.wordView(word, scaler));
            }
        }

        if (this.forceGridAnimation)
            this.forceGridAnimation();
    }

    async attributeChangedCallback(attrName, oldVal, newVal) {
        if (attrName === "words") {
            this.refresh();
        }
    }

    private wordView = (word: Word, scaler: (freq: number) => number) => `
        <div style="order:${word.frequency * -1}">
            <a  word-value="${word.text.toLowerCase()}"
                title="${word.text} - ${word.frequency}" 
                href="${this.getAttribute("url-pattern").replace("{word}", encodeURIComponent(word.text))}" 
                route
                style="order:${word.frequency * -1}">
                <div class=word>${word.text}</div>
                <div class=frequency>${word.frequency}</div>
                <div class=indicator style="width:0%"></div>
                
            </a>
        </div>
    `;

    private view = (words: Word[], scaler: (freq: number) => number) => `
        ${words.map(word => this.wordView(word, scaler)).join("")}
    `

    private parseWords = (): Word[] => {
        const words = this.getAttribute("words");

        if (!words)
            return;

        const mapped = words
            .split(";")
            .map(m => m.split(":"))
            .map(pair => ({
                text: pair[0],
                frequency: parseFloat(pair[1])
            }));

        return mapped;
    }
}

customElements.define("word-table", WordTableElement);