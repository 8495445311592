import "./FiltersWidget-style";
import * as cardsStyle from "../../../../styles/cards-module.less" 
import "features/filters/FiltersListElement";
import { BaseWidget, PreferSize, TabButton } from "../BaseWidget";
import ResizeObserver from "resize-observer-polyfill";

class FiltersWidget extends BaseWidget {
    private resizeObserver: ResizeObserver;

    public get preferredWidth(): PreferSize {
        return "narrow";
    }

    connectedCallback() {
        this.className += ` ${cardsStyle.cardContainer}`;
        this.innerHTML = this.view();
        super.connectedCallback();


        this.resizeObserver = new ResizeObserver((entries) => {
            window.requestIdleCallback(() => this.resizeHandler(entries[0].contentRect.width));
        });

        this.resizeObserver.observe(this);
        requestAnimationFrame(() => this.resizeHandler(this.offsetWidth));
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        this.resizeObserver?.disconnect();
    }

    protected modeChange(mode: string) {
        this.querySelector("filters-list").setAttribute("display-mode", mode);
    }

    private resizeHandler = (containerWidth: number) => {
        const containerScrollPadding = 3;
        const containerPadding = 1;
        const itemMarginRight = 20;

        containerWidth -= (containerPadding + containerPadding + containerScrollPadding + containerScrollPadding);

        const desiredCount = Math.floor(containerWidth / 200);
        let width = containerWidth / desiredCount;

        // extract margin, pidgen hole style split over visible elements
        width -= ( ((desiredCount-1) * itemMarginRight) / desiredCount );

        if (width > 420)
            width = 420;
            
        this.style.setProperty("--flex-width", `${width}px`);
    };

    private tabButtons: TabButton[] = [
        { label: "All", value: "all" },
        { label: "Active", value: "active" },
        { label: "Passive", value: "passive" }
    ];

    // , [], "all", "/filters")
    private view = () => `
        ${this.headerView("Filters", undefined, undefined, "/filters")}
        <filters-list small></filters-list>
    `;
}

customElements.define("filters-widget", FiltersWidget);