import "./FiltersListElement-style";
import * as cardsStyle from "../../styles/grid-cards-module.less"
import { FilterViewElement } from "./FilterViewElement";
import { FiltersStore, IFilter } from "./FiltersStore";
import { NewFilterElement } from "./NewFilterElement";

export class FiltersListElement extends HTMLElement {
    private filterElements: { [id: string]: FilterViewElement; } = {};
    static get observedAttributes() { return ["display-mode"]; }


    async attributeChangedCallback(attrName, oldVal, newVal) {
        await this.render();    
    }

    async connectedCallback() {
        this.style.setProperty("--card-width", "260px");
        this.className += ` ${cardsStyle.container}`;
        this.append(document.createElement("box-loader"));

        window.requestIdleCallback(this.render, { timeout: 200 });
        FiltersStore.instance.addEventListener("upserted", this.filterUpsertedHandler);
        FiltersStore.instance.addEventListener("deleted", this.filterDeletedHandler);
    }

    disconnectedCallback() {
        FiltersStore.instance.removeEventListener("upserted", this.filterUpsertedHandler);
        FiltersStore.instance.addEventListener("deleted", this.filterDeletedHandler);
    }

    private filterUpsertedHandler = (event: CustomEvent<IFilter>) => {
        const existing = this.filterElements[event.detail.id];
        if (existing) {
            existing.filter = event.detail;
        } else {
            const element = new FilterViewElement();
            this.insertAdjacentElement("afterbegin", element);
            this.filterElements[event.detail.id] = element;
            element.filter = event.detail;
        }
    }

    private filterDeletedHandler = (event: CustomEvent<IFilter>) => {
        const existing = this.filterElements[event.detail.id];
        if (existing) {
            existing.remove();
            this.filterElements[event.detail.id] = undefined;
        }
    }

    private render = async () => {
        let filters = await FiltersStore.instance.getAll();

        switch(this.getAttribute("display-mode")) {
            case "active":
                filters = filters.filter(m => m.enabled);
                break;
            case "passive":
                filters = filters.filter(m => !m.enabled);
                break;
        }

        this.innerHTML = "";
        for (const filter of filters) {
            const element = new FilterViewElement();
            this.append(element);
            this.filterElements[filter.id] = element;
            element.filter = filter;
        }
        this.append(new NewFilterElement());
    }
}

customElements.define("filters-list", FiltersListElement);