import { UrlProvider } from "lib/UrlProvider";
import { Pipeline, Request } from "lib/HTTP";
import { cityline } from "features/cityline"
import EventTarget from "@ungap/event-target";
import { ClassTypename, Typename } from "lib/Types";

interface EventMap {
    "upserted": IFilter;
    "deleted": IFilter;
}

@ClassTypename("FiltersStore")
export class FiltersStore {
    private eventTarget = new EventTarget();
    private static _instance = new FiltersStore();
    private filters: IFilter[];
    private initializer: Promise<void>;

    public static get instance() {
        return FiltersStore._instance;
    }

    private constructor() {
        this.initializer = this.doInitialize();
        setTimeout(async () => await this.initializer);
    }

    private async doInitialize() {
        this.filters = await cityline.getFrame("filters");
        cityline.addEventListener("filters", (event: CustomEvent<IFilter[]>) => {
            for (const filter of event.detail) {

                this.filters = this.filters.filter(m => m.id !== filter.id);
                if (filter.isDeleted) {
                    this.emit("deleted", filter);
                } else {
                    this.filters.push(filter);
                    this.emit("upserted", filter);
                }
            }
        });
    }

    private emit<K extends Extract<keyof EventMap, string>, T extends EventMap>(type: K, value: T[K]) {
        return this.eventTarget.dispatchEvent(new CustomEvent(type, { detail: Object.assign({}, value, { [Typename]: FiltersStore[Typename] }) }));
    }

    addEventListener<K extends Extract<keyof EventMap, string>>(type: K, listener: (this: EventSource, ev: CustomEvent<EventMap[K]>) => any, options?: boolean | AddEventListenerOptions) {
        return this.eventTarget.addEventListener(type, listener, options);
    }

    removeEventListener<K extends Extract<keyof EventMap, string>>(type: K, listener: (this: EventSource, ev: CustomEvent<EventMap[K]>) => any, options?: boolean | AddEventListenerOptions) {
        return this.eventTarget.removeEventListener(type, listener, options);
    }

    public async getAll(): Promise<IFilter[]> {
        await this.initializer;
        return (this.filters || []).slice(0);
    }

    public async upsert(filter: IFilter): Promise<any> {
        await new Pipeline().fetch(await new UrlProvider().filtersLocation(), Request.post.authenticate().setJSON(filter));
    }

    public async getAffectCount(filter: IFilter): Promise<number> {

        const clone = JSON.parse(JSON.stringify(filter));
       
        clone.$type = clone.type; 

        const response = await new Pipeline().hideLoader().fetch(`${await new UrlProvider().filtersLocation()}/_affects`, Request.post.authenticate().setJSON(clone));
        const count = await response.text();
        return parseInt(count);
    }

    public async delete(filter: IFilter): Promise<void> {
        await new Pipeline().fetch(`${await new UrlProvider().filtersLocation()}/${filter.slug}`, Request.delete.authenticate());

        this.filters = this.filters.filter(
            i => i.id !== filter.id
        );
    }
}


export interface IFilter {
    id?: string;
    slug?: string;
    enabled?: boolean;
    condition: FilterConditionStacksAndSearch;
    action?: IFilterActionAssignTags | IFilterActionAssignStacks;
    summary?: string;
    series?: number;
    runNow?: boolean;
    type?: "filterConditionStacksAndSearch" | "filterActionAssignStacks" | "filterActionAssignTags";
    isDeleted?: boolean;
}

export interface FilterConditionStacksAndSearch {
    $type: "filterConditionStacksAndSearch";
    stackIds: string[];
    search: string;
}

export interface IFilterActionAssignTags {
    $type: "filterActionAssignTags";
    tags: string[];
}

export interface IFilterActionAssignStacks {
    $type: "filterActionAssignStacks";
    stackIds: string[];
    deleteFromSourceStack: boolean;
}
