import "./NewFilterElement-style";
import { FilterEditorViewController } from "./FilterEditorViewController";

export class NewFilterElement extends HTMLElement {
    connectedCallback() {
        this.innerHTML = this.view();

        this.addEventListener("click", this.clickHandler);
    }

    disconnectedCallback() {
        this.removeEventListener("click", this.clickHandler);
    }

    private clickHandler = async () => await new FilterEditorViewController().new();
    
    private view = () => `
        <button type=button>
            <div>+</div>
            <div>Add a new filter</div>
        </button>
    `;
}

customElements.define("new-filter", NewFilterElement);