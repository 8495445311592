import { Routing, RouteChangeOrigin, IRoute } from "lib/Routing";
import { AppContextStore } from "features/application-context/AppContextStore";
import { LinkPageCriteria } from "./LinkPageModel";
import { UrlProvider } from "lib/UrlProvider";
import { Linkstack, StackStore } from "features/stacks/StackStore";
import { ClassTypename, Typename } from "lib/Types";
import EventTarget from "@ungap/event-target";

interface EventMap {
    "stack-changed": Linkstack;
    "changed": LinkPageCriteria;
}

/*
    Keeps tap of where we are in links and stacks
    Reads and builds urls

    Does not change data
*/
@ClassTypename("LinkSession")
export class LinkSession {
    private eventTarget = new EventTarget();
    private _currentStack: Linkstack;
    private static _instance = new LinkSession();

    static get instance() { return LinkSession._instance; }
    private constructor() {
        Routing.instance.onChange(async route => {
            const criteria = LinkSession.parseRoute(route);

            if (criteria) {
                this._currentStack = await StackStore.instance.getStackBySlug(criteria.user, criteria.stack);
                const { LinkPageElement } = await import( /* webpackChunkName: "stacks" */ "./LinkPageElement");
                
                // if pageload, then server will take care of this
                if (route.origin !== RouteChangeOrigin.PageLoad) {
                    const dataElement = document.createElement("script");
                    dataElement.setAttribute("type", "application/json+link-page-criteria");
                    dataElement.innerText = JSON.stringify(criteria);
                    const element = new LinkPageElement();
                    element.append(dataElement);
                    await AppContextStore.instance.present("", element, ...LinkSession.buildTitle(criteria, this._currentStack));
                } else {
                    AppContextStore.instance.presentTitle("link-page", ...LinkSession.buildTitle(criteria, this._currentStack));
                }

                this.emit("changed", criteria);
                this.emit("stack-changed", this.currentStack);
            }
        });
    }

    public addEventListener<K extends Extract<keyof EventMap, string>>(type: K, listener: (this: EventSource, ev: CustomEvent<EventMap[K]>) => any, options?: boolean | AddEventListenerOptions) {
        return this.eventTarget.addEventListener(type, listener, options);
    }

    public removeEventListener<K extends Extract<keyof EventMap, string>>(type: K, listener: (this: EventSource, ev: CustomEvent<EventMap[K]>) => any, options?: boolean | AddEventListenerOptions) {
        return this.eventTarget.removeEventListener(type, listener, options);
    }

    private emit<K extends Extract<keyof EventMap, string>, T extends EventMap>(type: K, value: T[K]) {
        return this.eventTarget.dispatchEvent(new CustomEvent(type, { detail: Object.assign({}, value, { [Typename]: LinkSession[Typename] }) }));
    }

    get currentStack() { return this._currentStack; }

    static buildTitle(criteria: LinkPageCriteria, stack: Linkstack): string[] {
        if (criteria.create) {
            return [
                "Bookmarks",
                "Create stack"
            ];
        }

        return [
            "Bookmarks",
            stack?.title,
            criteria?.query ? `Matching ${criteria?.query}` : ""
        ];
    }

    // todo - "create" / "edit"
    static parseRoute(route: IRoute): LinkPageCriteria {
        if (route.path[1] !== "stacks" && route.path[1] !== "links")
            return undefined;

        if (!route.path[0].startsWith("@"))
            return undefined;

        if (route.path[2] === "create")
            return {
                user: route.path[0].substring(1),
                create: true
            };

        let page: number;
        if (route.parameters.page)
            page = parseInt(route.parameters.page);

        if (isNaN(page))
            page = 1;


        const criteria: LinkPageCriteria = {
            user: route.path[0].substring(1),
            stack: route.path[1] === "links" ? "links" : route.path[2],
            page: page
        };

        if (route.parameters.query)
            criteria.query = route.parameters.query;

        if (route.parameters.tags)
            criteria.tags = route.parameters.tags?.split(";");

        if (route.hash)
            criteria.linkId = route.hash.substring(1);

        return criteria;
    }

    static buildUrl(criteria: LinkPageCriteria): URL {
        const url = new URL(`${new UrlProvider().root}/@${criteria.user}/stacks/${criteria.stack}`);

        if (criteria.page && criteria.page > 1 && criteria.page < 1024)
            url.searchParams.append("page", criteria.page.toString());

        if (criteria.tags)
            criteria.tags.forEach(tag => {
                url.searchParams.append("tag", tag);
            });

        if (criteria.query)
            url.searchParams.append("query", criteria.query);

        if (criteria.linkId)
            url.hash = criteria.linkId;

        return url;
    }

}