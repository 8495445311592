
import "./StatusBarElement-style";

export class StatusBarElement extends HTMLElement {
    private text = () => this.getAttribute("text");

    connectedCallback() {
        this.setAttribute("hidden", "");
        this.innerHTML = this.view();

        requestAnimationFrame(() => {
            requestAnimationFrame(() => {
                this.removeAttribute("hidden");
            });
        });
    }    

    private view = () => `<div>${this.text()}</div>`;
}

customElements.define("status-bar", StatusBarElement);

export class StatusBarManager {
    static permanentStatusBar: HTMLElement;

    static hide(element: HTMLElement) {
        element.setAttribute("hidden", "");
        setTimeout(() => element.remove(), 1000);    
    }

    static show(severity: Severity, text: string, ...additionalClasses: string[]) {
        if (this.permanentStatusBar)
            StatusBarManager.hide(this.permanentStatusBar);

        this.permanentStatusBar = this.doShow(severity, text, ...additionalClasses);
    }

    static showShortly(severity: Severity, text: string, ...additionalClasses: string[]) { 
        if (this.permanentStatusBar)
            StatusBarManager.hide(this.permanentStatusBar);

        const statusBarElement = this.doShow(severity, text, ...additionalClasses);
        setTimeout(() => this.hide(statusBarElement), 7000);
    }

    static doShow(severity: Severity, text: string, ...additionalClasses: string[]) {
        const statusbar = document.createElement("status-bar");
        statusbar.classList.add(... additionalClasses);
        statusbar.classList.add(severity);
        statusbar.setAttribute("text", text);;
        document.body.appendChild(statusbar);
        return statusbar;
    }
}

export enum Severity {
    info = "info",
    error = "error"
}