
import { Routing } from "lib/Routing";
import { Package } from "features/packages/Package";
import { BaseManagePackageElement } from "features/manage/BaseManagePackageElement";
import { AppContextStore } from "features/application-context/AppContextStore";

class ManageLoginElement extends BaseManagePackageElement {
    protected slug = "login";

    protected detailView = (thePackage: Package) => `
        <change-password></change-password>
        <login-providers></login-providers>
        <alternate-emails></alternate-emails>
    `;
}

customElements.define("manage-login", ManageLoginElement);

Routing.instance.onChange(async route => {
    if (route.path[0] === "manage" && route.path[1] === "login") {
        await AppContextStore.instance.present("", document.createElement("manage-login"), "Manage", "Login & Password");
    }
});