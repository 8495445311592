import "./CategorySelectorElement-style";

import { CategoryStore } from "./CategoryStore";
import { Formatting } from "lib/Formatting";
import { HeightFollower } from "lib/DOM";
import { MixinField } from "../mixin/MixinField";
import { MixinHeader } from "features/mixin/MixinHeader";
import { MixinPopup } from "features/mixin/MixinPopup";
import { TaskStore } from "lib/Tasks";


class CategorySelectorElement extends HTMLElement {
    private escape = Formatting.htmlEscape;

    private hightFollower: HeightFollower;
    private searchInput: HTMLInputElement;
    private buttonSet: HTMLElement;
    private scrollable: HTMLElement;
    private mixinPopup: MixinPopup;
    async connectedCallback() {
        this.innerHTML = this.view();

        // mixin
        this.mixinPopup = new MixinPopup(this);
        
        //tslint:disable-next-line: no-unused-variable
        const _ = new MixinHeader(this.querySelector("header"));
        
        //tslint:disable-next-line: no-unused-variable
        const _m = new MixinField(this);

        // catch elements
        const window = this.querySelector("[name=window-area]") as HTMLElement;
        const scrollable = this.querySelector("[name=scroll-container]") as HTMLElement;
        this.hightFollower = new HeightFollower(window, scrollable, 237);
        this.searchInput = this.querySelector("input[type=text]") as HTMLInputElement;
        this.buttonSet = this.querySelector(".button-set") as HTMLHtmlElement;
        this.scrollable = this.querySelector("[name=scrollable]") as HTMLHtmlElement;
        
        // render categories
        await TaskStore.instance.add(this.showFiltered(""));

        // setup events
        this.searchInput.addEventListener("keyup", this.keyUpHandler);
        this.addEventListener("click", this.clickHandler);
    }   

    disconnectedCallback() {
        this.searchInput.removeEventListener("keyup", this.keyUpHandler);
        this.removeEventListener("click", this.clickHandler);
        
        if (this.hightFollower)
            this.hightFollower.destroy();
    }


    private clickHandler = async (event: UIEvent) => {
        const field = (event.target as HTMLElement).closest("[slug]") as HTMLElement;
        if (field) {
            this.dispatchEvent(new CustomEvent("selected", { detail: field.getAttribute("slug")}));
            this.mixinPopup.closeHandler();
            return;
        }

        const create = (event.target as HTMLElement).closest("[name=create]") as HTMLElement;
        if (create) {
            await CategoryStore.instance.create("feed", this.searchInput.value);
            await this.showFiltered(this.searchInput.value);
        }
            
    };

    private keyUpHandler = async () => {
        this.buttonSet.innerHTML = this.buttonView(this.searchInput.value);
        await this.showFiltered(this.searchInput.value);
    };

    private buttonView = (text: string) => `
        ${text.trim().length > 0 ? this.escape`<button class=power name=create>Create category '${text.trim()}'</button>` : "<button class=power-inactive>Create category</button>"}
    `;

    private async showFiltered(text: string){
        const categories = await CategoryStore.instance.filtered(text);
        this.scrollable.innerHTML = categories.map(category => `<div class=field slug=${category.slug}>${category.title}</div>`).join("");
    }

    private view = () => this.escape`
        <div name=window-area>
            <header>
                <div></div>
                <div class=caption>Select category</div>
                <button popup-close>Done</button>
            </header>
            <section>
                <fieldset name=filter-area>
                    <legend>Filter categories</legend>
                    <div class=field>
                        <input type=text name="search" value="" placeholder="Enter part of category title" />
                    </div>
                </fieldset>

                <fieldset name=categories-area>
                    <legend>Select new category</legend>
                    <div name=scroll-container>
                        <div name=scrollable>
                            
                            
                        </div>
                    </div>
                </fieldset>
                <fieldset class=button-set>
                    $${this.buttonView("")}
                </fieldset>
            </section>
        </div>
    `;
}

customElements.define("category-selector", CategorySelectorElement)
