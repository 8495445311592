import { Request, Pipeline } from "lib/HTTP";
import { UrlProvider } from "lib/UrlProvider";

export interface Invite {
    message: string;
    status: "ok" | "invitation-not-found" | "email-does-not-match" | "invalid-token";
    emailAddress?: string;
    title?: string;
    stackSlug?: string;
}

export type InviteStatus = "confict" | "invalid";

export interface Collaborator {
    avatarLocation: string;
    username?: string;
    status: "owner" | "awaiting" | "active";
    email: string;
}

export class CollaborationStore {
    private static _instance = new CollaborationStore();
    private _urlProvider: UrlProvider = new UrlProvider();
    private constructor() {}
    static get instance() { return CollaborationStore._instance }

    async getStackCollaborators(stackSlug: string) : Promise<Collaborator[]> {
        const response = await new Pipeline().fetch(`${this._urlProvider.root}/api/collaboration/stack/${stackSlug}`, new Request().authenticate());

        if (!response.ok)
            return undefined;

        return await response.json() as Collaborator[];
    }

    /* inite a user to a stack */
    async addInvitation(stackSlug: string, emailAddress: string) : Promise<Collaborator | InviteStatus> {
        const param = new URLSearchParams(<any>{
            emailAddress: emailAddress
        });
        
        const response = await new Pipeline().fetch(`${this._urlProvider.root}/api/collaboration/stack/${stackSlug}?${param.toString()}`, Request.post.authenticate());


        if (response.ok)
            return await response.json()

        if (response.status === 404)
            return "invalid";
     
        if (response.status === 409){
            return "confict";
        }

        if (response.status === 400){
            return "invalid";
        }

    }

    async removeInvitation(stackSlug: string, emailAddress: string) : Promise<boolean> {
        const param = new URLSearchParams(<any>{
            emailAddress: emailAddress
        });
        
        const response = await new Pipeline().fetch(`${this._urlProvider.root}/api/collaboration/stack/${stackSlug}?${param.toString()}`, Request.delete.authenticate());
        if (response.ok)
            return true;

        return false;
    }

    async breakConnection(stackSlug: string) : Promise<boolean> {
        const param = new URLSearchParams(<any>{
            stackSlug: stackSlug
        });
        
        const response = await new Pipeline().fetch(`${this._urlProvider.root}/api/collaboration/invitation?${param.toString()}`, Request.delete.authenticate());
        if (response.ok)
            return true;

        return false;
    }

    async getInvite(stackId: string, token: string) : Promise<Invite> {
        const param = new URLSearchParams(<any>{
            stackId: stackId,
            token: token
        });
        
        const response = await new Pipeline().fetch(`${this._urlProvider.root}/api/collaboration/invitation?${param.toString()}`, new Request().authenticate());


        if (response.ok)
            return await response.json()

        if (response.status === 404)
            return undefined;
     
        if (response.status === 400){
            return await response.json();
        }

    }

    

    async acceptInvite(stackId: string, token: string) : Promise<Invite> {
        const param = new URLSearchParams(<any>{
            stackId: stackId,
            token: token
        });
        
        const response = await new Pipeline().fetch(`${this._urlProvider.root}/api/collaboration/invitation?${param.toString()}`, Request.post.authenticate());


        if (response.ok)
            return await response.json()

        if (response.status === 404)
            return undefined;
     
        if (response.status === 400){
            return await response.json();
        }
    }
}

