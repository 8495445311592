import "./ContextMenuElement-style";

import { DOM } from "lib/DOM";
import { Formatting } from "lib/Formatting";

interface MenuItem {
    id: string;
    title?: string; 
    content?: string;
}

export class ContextMenuElement extends HTMLElement {
    private escape = Formatting.htmlEscape;  
    private menuItemsHolder: HTMLElement;
    private shadow: HTMLElement;
    private _phoneMediaMatch = window.matchMedia("screen and (max-width: 850px)");
    private items: MenuItem[];

    private parseAndRemoveItems() : MenuItem[] {
        const items = [].slice.call(this.querySelectorAll("item")) as HTMLElement[];
        const mapped = items.map(item => ({ id: item.getAttribute("id"), title: item.getAttribute("title"), content: item.innerHTML }));
        return mapped;
    }
    
    private clickHandler = () => {
        if (!this.items)
            this.items = this.parseAndRemoveItems();

        if (this.menuItemsHolder && this.menuItemsHolder.querySelector("[role=menu]"))
            return;
            
        this.openContext();
    };

    private globalClickHandler = (event: Event) => {
        if (!this.menuItemsHolder)
            return;
            
        const menu = this.menuItemsHolder.querySelector("[role=menu]");

        if (!menu)
            return;

        const target = event.target as HTMLElement;
        if (menu.contains(target))
            return;
        
        event.preventDefault();
        setTimeout(() => {
            this.closeContext();
        }, 100);
        
    };

    private openContext() {
        this.menuItemsHolder = DOM.parse(this.contextView(this.items));
        this.shadow = this.menuItemsHolder.querySelector(".shadow") as HTMLElement;

        if (this._phoneMediaMatch.matches)
            document.body.insertAdjacentElement("beforeend", this.menuItemsHolder);
        else
            this.insertAdjacentElement("beforeend", this.menuItemsHolder);
        
        this.dispatchEvent(new CustomEvent("open", { detail: this.querySelector("ul") }));
        [].slice.call(this.menuItemsHolder.querySelectorAll("li:not(.line)")).forEach( (element: HTMLElement) => {
            element.addEventListener("click", event => {
                event.stopPropagation();
                setTimeout(() => {
                    this.closeContext();
                    setTimeout(() => {
                        this.dispatchEvent(new CustomEvent("context-item-click", { detail: element.id, bubbles: true}));
                    });
                    
                });
            });
        });

        requestAnimationFrame(() => {
            const element = this.menuItemsHolder.querySelector("[role=menu]") as HTMLElement;
            element.style.transform = "translate(0, 0)";
        });
    
        setTimeout(() => {
            document.addEventListener("click", this.globalClickHandler);
            this.shadow.addEventListener("click", this.globalClickHandler);
        }, 300);
    }

    private closeContext() {
        if (this.menuItemsHolder) {
            this.menuItemsHolder.remove();
            this.menuItemsHolder = undefined;
        }
        
        if (this.shadow)
            this.shadow.removeEventListener("click", this.globalClickHandler);
        document.removeEventListener("click", this.globalClickHandler);
    }

    connectedCallback() {
        this.addEventListener("click", this.clickHandler);
    }

    disconnectedCallback() {
        this.removeEventListener("click", this.clickHandler);

        if (this.menuItemsHolder)
            this.closeContext();
    }

    contextView = (menuItems: MenuItem[]) => this.escape `
        <div name=context-menu-items>
            <div class=shadow></div>
            <ul role=menu>
                $${menuItems.map(item => item.id === "-" ? `<li class="line"><hr /></li>` : `<li id="${item.id}"><button type=button name="${item.id}">${item.content ? item.content : item.title}</button></li>`)}
            </ul>
        </div>
    `;
}

customElements.define("context-menu", ContextMenuElement);