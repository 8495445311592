import "./AnchorPopupElement-style";
import { LinkStore } from "features/links";

class AnchorPopupElement extends HTMLElement {
    private externalUrl = () => this.getAttribute("url");
    private closeTimer;

    connectedCallback() {
        this.innerHTML = `<button title="Save link">${require("!!raw-loader!image/add-logo.svg")}</button>`;
        this.addEventListener("click", this.clickHandler);
        this.addEventListener("mouseover", this.mouseoverHandler);
        this.addEventListener("mouseleave", this.mouseleaveHandler);
        this.removeAttribute("mouse-over");
        window.clearTimeout(this.closeTimer);
    }

    disconnectedCallback() {
        this.removeEventListener("click", this.clickHandler);
        this.removeEventListener("mouseover", this.mouseoverHandler);
        this.removeEventListener("mouseleave", this.mouseleaveHandler);
        window.clearTimeout(this.closeTimer);
    }

    private mouseoverHandler = () => {
        this.setAttribute("mouse-over", "");
    }

    private mouseleaveHandler = () => {
        this.removeAttribute("mouse-over");
        setTimeout(this.closeHandler, 2000);
    }

    private closeHandler = async () => {
        if (this.hasAttribute("mouse-over"))
            return;

        await this.fadeAway();
    }

    private async fadeAway() {
        this.style.opacity = "0";
        setTimeout(() => {
            this.remove();
        }, 200);
    }

    private clickHandler = async () => {
        await LinkStore.instance.addAndForget(this.externalUrl());
        await this.fadeAway();
    };
}

customElements.define("anchor-popup", AnchorPopupElement);