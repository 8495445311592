
import { IApplicationRegistration } from "features/manage/authorized-applications/IApplicationRegistration";
import { BaseManagePackageElement } from "features/manage/BaseManagePackageElement";
import { Routing } from "lib/Routing";
import { Package } from "features/packages/Package";
import { AuthorizedApplicationsStore } from "features/manage/authorized-applications/AuthorizedApplicationsStore";
import "./AuthorizedApplicationsElement-style";
//import { AppsStore } from "features/apps-index/AppsStore";
import { AppContextStore } from "features/application-context/AppContextStore";

class AuthorizedApplicationsElement extends BaseManagePackageElement {
    protected slug = "authorized-applications";
    private apps: IApplicationRegistration[];

    protected async initialize() {
        this.apps = await AuthorizedApplicationsStore.instance.getAll();
    }
    
    protected setupDOM() {
        this.addEventListener("click", async event => {
            const target = <HTMLElement>event.target;
            if (!target.attributes)
                return;

            const nameAttribute = target.attributes.getNamedItem("name");

            if (nameAttribute && nameAttribute.value === "revoke") {
                const li = target.closest("card");
                const installationId = li.attributes.getNamedItem("data-installation-id");
                if (installationId)
                    await AuthorizedApplicationsStore.instance.revoke(installationId.value);
            }
        });
    
        AuthorizedApplicationsStore.instance.onAppUpserted(app => {
             const liItems = <HTMLElement[]>Array.prototype.slice.call(this.querySelectorAll("card"));
             const toUpdate = liItems.filter(liItem => {
                 const installationId = liItem.attributes.getNamedItem("data-installation-id");
                 return installationId && installationId.value === app.installationId;
             })[0];
 
             if (toUpdate) {
                 toUpdate.outerHTML = this.renderItem(app);
             } else {
                 const packageIndex = this.querySelector(".package-index") as HTMLElement;
                 packageIndex.insertAdjacentHTML("afterbegin", this.renderItem(app));
             }
 
         });
     }

    protected detailView = (thePackage: Package) => `
        <div class="package-index">
            ${this.apps.map(app => this.renderItem(app)).join("")}
        </div>
    `; 

    private renderItem = (app: IApplicationRegistration) => `
        <card data-installation-id="${app.installationId}">
            <header style="background-image:url('data:image/svg+xml;utf8,${encodeURIComponent(this.appsStore.logo(app.logo))}')"></header>
            <div class="title">${app.name}</div>
            <div class="description">
                <div class="access-rights">
                    Access to ${app.rights ? app.rights.map(i => `${i}<br />`).join("") : ""}
                </div>
                <div class="source">
                    ${app.application ? `${app.application}, ` : "&nbsp;"} ${app.os || "&nbsp;"}
                </div>
                <div class="buttons">
                    <button class="power" name='revoke' ${app.enabled ? "" : "disabled"}>
                        ${app.enabled ? "Revoke" : "Revoked"}
                    </button>
                </div>
            </div>
        </card>
    `;
}

customElements.define("authorized-applications", AuthorizedApplicationsElement);

Routing.instance.onChange(async route => {

    // this is for backwards compatibility: /authorized-applications
    if (route.path[0] === "manage" && route.path[1] === "authorized-applications" || route.path[0] === "authorized-applications") {
        await AppContextStore.instance.present("", document.createElement("authorized-applications"), "Manage", "Authorized applications");
    }
});