import "./FontSelectorElement-style";
import { AdaptivePopup } from "features/authentication/AdaptivePopup";

import { ThemeStore, Font } from "features/theme/ThemeStore";

export class FontSelectorElement extends AdaptivePopup {
    private sizeInterval = {from: 10, to: 20};
    private selectedFont: Font = ThemeStore.instance.getFont();
    private demoElement: HTMLElement;
   
    connectedCallback() {
        this.setAttribute("slide", "");
        this.selectedFont =  ThemeStore.instance.getFont();
        this.innerHTML = this.view();
        this.demoElement = this.querySelector(".demo") as HTMLElement;
        super.connectedCallback();
        this.querySelector("[name=font-size] input[type=range]").addEventListener("change", this.fontSizeChangeHandler);
        this.querySelector("[name=font]").addEventListener("click", this.fontClickHandler);
        this.querySelector("[name=apply]").addEventListener("click", this.applyClickHandler);
        this.querySelector("[name=default]").addEventListener("click", this.defaultClickHandler);
        this.querySelector("[name=justify-text]").addEventListener("change", this.justifyChangeHandler);
    }

    private applyClickHandler = async () => {
        ThemeStore.instance.setFont(this.selectedFont);
        await this.closeHandler();
    };

    private defaultClickHandler = () => {
        this.selectedFont = ThemeStore.instance.getDefaultFont();
        this.updateFontSelector(this.selectedFont.name);
        (<HTMLInputElement>this.querySelector("[name=font-size] input[type=range]")).value = this.selectedFont.size.toString();

        if (this.selectedFont.justify)
            (<HTMLInputElement>this.querySelector("[name=justify-text]")).setAttribute("checked", "");
        else
            (<HTMLInputElement>this.querySelector("[name=justify-text]")).removeAttribute("checked");

        this.refreshHandler();
    };

    private refreshHandler = () => {
        this.demoElement.style.fontSize = `${this.selectedFont.size}px`;
        this.demoElement.style.textAlign = `${this.selectedFont.justify ? "justify" : ""}`;
        this.demoElement.style.fontFamily = this.selectedFont.family;
    };

    private justifyChangeHandler = () => {
        requestAnimationFrame(() => {
            const selector = this.querySelector("[name=justify-text]");
            this.selectedFont.justify = selector.hasAttribute("checked");
            this.refreshHandler();
        });
        
    }


    private fontSizeChangeHandler = (event: Event) => {
        requestAnimationFrame(() => {
            const input = (event.target as HTMLElement) as HTMLInputElement;
            this.selectedFont.size = parseInt(input.value);
            this.refreshHandler();
        });
    };


    private updateFontSelector = (name) => {
        [].slice.call(this.querySelectorAll("[name=font] .field")).forEach( (element: HTMLElement) => element.removeAttribute("selected"));
        this.querySelector(`.field[name='${name}']`).setAttribute("selected", "");
    }

    private fontClickHandler = (event: Event) => {
        const field = (<HTMLElement>event.target).closest(".field");

        if (!field)
            return;
            
        const name = field.getAttribute("name");
        this.updateFontSelector(name);
        const newFont = ThemeStore.instance.getFonts().filter(i => i.name === name)[0];
        this.selectedFont.name = newFont.name;
        this.selectedFont.family = newFont.family;
        this.refreshHandler();
    };

    private view = () => `
        <div>
            <header>
                <div><button type=button name=back class=icon>${require("!!raw-loader!image/arrow-left-20-v1.svg")}</button></div>
                <div>Break - select font</div>
                <div></div>
            </header>
            <section>

                <div class=demo style="font-size:${this.selectedFont.size}px; text-align:${this.selectedFont.justify ? "justify" : "unset"}; font-family: ${this.selectedFont.family}">Use the settings below to change these lines of text.</div> 

                <fieldset>
                    <legend>Font size</legend>
                    
                    <div class=field name=font-size>
                        <div class=indicator style="font-size:${this.sizeInterval.from}px">A</div>
                        
                        <input type=range min=${this.sizeInterval.from} max=${this.sizeInterval.to} step=1 value=${this.selectedFont.size}>
                        <div class=indicator style="font-size:${this.sizeInterval.to}px">A</div>

                        
                    </div>
                </fieldset>


                <fieldset class=checkboxes name=font>
                    <legend>Font family</legend>

                    ${ThemeStore.instance.getFonts().map(font => `
                        <div class=field name="${font.name}" ${font.name === this.selectedFont.name ? "selected" : ""}>
                            ${require("!!raw-loader!image/fa-check.svg")}
                            <label style="font-family:${font.family}">${font.name}</label>
                        </div>
                    `).join("")}
                </fieldset>

                
                <fieldset>
                    <legend>Font justification</legend>
                    <div class=field>
                        <label>Justify text</label>
                        <switch-button name=justify-text ${this.selectedFont.justify ? "checked" : ""}></switch-button>
                    </div>
                </fieldset>
            </section>
            <footer>
                <button type=button class=power name=default>Set defaults</button>
                <button type=button class=power name=apply>Apply</button>
            </footer>
        </div>
    `;
}

customElements.define("font-selector", FontSelectorElement);