class SideBarElement extends HTMLElement {
    static get observedAttributes() { return ["open"]; }
    private phoneMediaMatch = window.matchMedia("screen and (max-width: 850px)");

    connectedCallback() {
        this.addEventListener("transitionend", this.transitionHandler);

        window.addEventListener("resize", this.updateSidebarWidth, { passive: true });
        this.updateSidebarWidth();
    }

    disconnectedCallback() {
        this.removeEventListener("transitionend", this.transitionHandler);
        document.removeEventListener("click", this.globalClickHandler);
    }

    attributeChangedCallback(attrName, oldVal, newVal) {
        this.updateSidebarWidth();
    }

    private globalClickHandler = (event: TouchEvent) => {
        if (!this.phoneMediaMatch.matches)
            return;

        if (!(event.target instanceof Element))
            return;

        const target = event.target as Element;

        if (!target.isConnected)
            return;

        if (this.contains(target))
            return;

        if (target.closest("[name=search]"))
            return;

        if (this.hasAttribute("open"))
            this.removeAttribute("open");
    }

    private transitionHandler = () => {
        if (!this.hasAttribute("open")) {
            this.innerHTML = "";
            document.documentElement.style.setProperty("--side-bar-width", "0px");
            document.removeEventListener("click", this.globalClickHandler);
        } else {
            document.addEventListener("click", this.globalClickHandler);
        }
    };

    private updateSidebarWidth = () => {
        if (!this.hasAttribute("open")) {
            return;
        }

        const estate = window.innerWidth;

        let width = window.innerWidth;
        if (window.innerWidth > 850) {
            width = estate / 5;

            if (width < 320 && window.innerWidth > 320)
                width = 320;

        }

        document.documentElement.style.setProperty("--side-bar-width", `${width}px`);
    }

}

customElements.define("side-bar", SideBarElement);