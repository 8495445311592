import "./AlternateEmailsElement-style";
import { AlternateEmailsStore, AlternateEmail } from "features/manage/alternate-emails/AlternateEmailsStore";
import { DialogController } from "features/dialog";
import { Formatting } from "lib/Formatting";
import { User } from "features/authentication";
import { UserPreferenceStore, UserPreferenceFlag } from "features/user-preferences/UserPreferenceStore";
export class AlternateEmailsElement extends HTMLElement {
    private escape = Formatting.htmlEscape;
    
    async connectedCallback(){
        await this.refresh(await AlternateEmailsStore.instance.getUser());
        AlternateEmailsStore.instance.addEventListener("changed", this.accountChangedHandler);
    }

    disconnectedCallback() {
        AlternateEmailsStore.instance.removeEventListener("changed", this.accountChangedHandler);
    }   

    private accountChangedHandler = async (event: CustomEvent<User>) => this.refresh(event.detail);
    
    refresh = async (user: User) => {
        try {
            this.setAttribute("busy", "");

            const primary = {
                email: user.email,
                isVerified: await UserPreferenceStore.instance.isSet(UserPreferenceFlag.IsConfirmed),
                isPrimary: true
            }

            const emails = user.alternateEmails ?? [];

            const all = emails.concat([primary]);

            all.sort( (a, b) => a.email === b.email ? 0 : +(a.email > b.email) || -1);

            this.innerHTML = this.view(all);
        } finally {
            this.removeAttribute("busy");
        }
        
        const emailField = this.querySelector("[name=email]") as HTMLInputElement;

        const showError = async (message) => {
            await new DialogController().prompt({
                caption: "Add alternate email",
                message: message,
                options: ["OK"]
            });
        }

        const form = this.querySelector("form") as HTMLFormElement;
        form.addEventListener("submit", async (event) => {
            event.preventDefault();
        
            try {
                this.setAttribute("busy", "");
                const result = await AlternateEmailsStore.instance.add(emailField.value);

                switch(result.status) {
                    case "ok": 
                        await showError("Please check your email.");
                        break;
                    case "conflict": 
                        await showError("That address is already on your account.");
                        break;
                    default:
                        await showError("Unable to add address.");
                        break;
                }
            } finally {
                this.removeAttribute("busy");
            }
        });

        const removeButtons = [].slice.call(this.querySelectorAll("[name=remove]"));
        removeButtons.forEach( (removeButton: HTMLButtonElement) => {
            removeButton.addEventListener("click", async () => {

                const emailElement = removeButton.closest(".email");
                const email = emailElement.getAttribute("address");
                const response = await new DialogController().prompt({
                    caption: "Remove email address?",
                    message: `You will no longer be able to accept invitations sent to ${email}.`,
                    options: ["Cancel", "Remove address"]
                });
        
                if (response === "Remove address") {
                    
                    try {
                        this.setAttribute("busy", "");
                        await AlternateEmailsStore.instance.remove(email);
                    } catch(error) {
                        this.removeAttribute("busy");
                    }
                }
                    
            });
        });

        const promoteButtons = [].slice.call(this.querySelectorAll("[name=make-primary]"));
        promoteButtons.forEach( (promoteButton: HTMLButtonElement) => {
            promoteButton.addEventListener("click", async () => {
                const emailElement = promoteButton.closest(".email");
                const email = emailElement.getAttribute("address");
                const response = await new DialogController().prompt({
                    caption: "Change primary email?",
                    message: `Future backup emails and other notifications from us will be sent to ${email}.`,
                    options: ["Cancel", "Make primary"]
                });
        
                if (response === "Make primary") {
                    
                    try {
                        this.setAttribute("busy", "");
                        await AlternateEmailsStore.instance.setPrimary(email);
                    } finally {
                        this.removeAttribute("busy");
                    }
                }
                    
            });
        });
    }

    private emailView = (address: AlternateEmail) => this.escape `
        <div class=email address="${address.email}">
            $${address.isVerified ? this.escape `
                $${address.isPrimary ? require("!!raw-loader!image/star.svg") : `<button type=button name=make-primary title="Use this email for all Linkstacks correspondence.">${require("!!raw-loader!image/star.svg")}</button>`}
            ` : "<div class=filler></div>"}
            
            <div>${address.email}</div>
            <div class=status>$${address.isVerified ? `` : "<div class=not-verified>Not verified</div>"}</div>
            $${address.isPrimary ? "<div class=filler></div>" : `<button type=button name=remove>${require("!!raw-loader!image/trash.svg")}</button>`}
        </div>
    `;

    private view = (emails: AlternateEmail[]) => this.escape `
        <div class="box password case">
            <header>Email addresses</header>
            <div class=description>
                Mark the address where you wish to receive backup mail and other communication from us. Only validated email addresses can be marked.
            </div>
            <form>
                <div class=validation-message name=""></div>
                
                <fieldset>
                    <label></label>
                    $${emails.map(a => this.emailView(a)).join("")}
                </fieldset>
                <fieldset>
                    <label for=email>Add new email</label>
                    <input type=email id=email name=email placeholder="" required>
                </fieldset>
                <fieldset class="right">
                    <button class=power type=submit>Add</button>
                </fieldset>
            </form>
        </div>
    `;
    // Alternate
    //<fieldset class=active>
    //                 <label for=old-password>Primary</label>
    //                 $${this.emailView(primary)}
    //             </fieldset>
}

customElements.define("alternate-emails", AlternateEmailsElement);