import { MemoryValueStore } from "./MemoryValueStore";

export class ValueStoreFactory {
    private static _instance = new ValueStoreFactory();

    public static get instance() { 
        return ValueStoreFactory._instance;
    }

    public resolve(): ValueStore { 
        return new MemoryValueStore();
    }
}

export interface ValueStore {
    get<TValue>(key: string): Promise<TValue>;
    set<TValue>(key: string, value: TValue): Promise<void>;
}

//import { get, set } from "idb-keyval/dist/esm-compat";