import { UrlProvider } from "lib/UrlProvider";
import { ILoginProvider } from "./ILoginProvider";
import { LoginResponse } from "../Model";
import { Pipeline, Request } from "lib/HTTP";
import { FacebookLogin } from "@capacitor-community/facebook-login";

export class FacebookNativeLoginProvider implements ILoginProvider {
    getName = () => "FacebookNativeLoginProvider";

    async login(): Promise<LoginResponse> {
        const result = await FacebookLogin.login({ permissions: ["email"] });

        let accessToken = result?.accessToken?.token
        if (!accessToken) {
            const tokenResponse = await FacebookLogin.getCurrentAccessToken();
            accessToken = tokenResponse?.accessToken?.token;
        }

        if (!accessToken) {
            alert("Sorry, login did not provide a usable response. Please try again.");
            return;
        }

        const response = await new Pipeline().fetch(`${new UrlProvider().root}/api/external-login/facebook`, new Request().setJSON({ token: accessToken }));
        return await response.json() as LoginResponse;
    } 
}



