import { cityline } from "features/cityline";
import EventTarget from "@ungap/event-target";
import { ClassTypename, Typename } from "lib/Types";
import { Link } from "features/links";
import { Entry } from "features/feeds";
import { Collection } from "lib/System";


interface RecentFrame {
    links: Link[];
    articles: Entry[];
}


interface EventMap {
    "added": RecentFrame;
}


@ClassTypename("RecentStore")
export class RecentStore {
    private eventTarget = new EventTarget();
    private _initializer: Promise<void>;
    private static _instance = new RecentStore();
    private _recent: RecentFrame;
    static get instance() { return RecentStore._instance; }

    private constructor() {
        this._initializer = new Promise(r => {
            setTimeout(async () => {
                this._recent = await cityline.getFrame<RecentFrame>("recent");
                cityline.addEventListener("recent", this.recentHandler);
                r();
            });
        });
    }

    addEventListener<K extends Extract<keyof EventMap, string>>(type: K, listener: (this: EventSource, ev: CustomEvent<EventMap[K]>) => any, options?: boolean | AddEventListenerOptions) {
        return this.eventTarget.addEventListener(type, listener, options);
    }
    
    removeEventListener<K extends Extract<keyof EventMap, string>>(type: K, listener: (this: EventSource, ev: CustomEvent<EventMap[K]>) => any, options?: boolean | AddEventListenerOptions) {
        return this.eventTarget.removeEventListener(type, listener, options);
    }
    
    private emit<K extends Extract<keyof EventMap, string>, T extends EventMap>(type: K, value: T[K]) {
        return this.eventTarget.dispatchEvent(new CustomEvent(type, { detail:  Object.assign({}, value, { [Typename] : RecentStore[Typename] })})); 
    }
    
    

    private recentHandler = (event: CustomEvent<RecentFrame>) => {
        this.emit("added", event.detail);

        this._recent.links = this._recent.links.concat(event.detail.links);
        this._recent.links = Collection.distinctBy(this._recent.links, m => m.id);
        this._recent.links.sort((a, b) => a.created > b.created ? 1 : -1);
        
        if (this._recent.links.length > 10)
            this._recent.links.splice(0, this._recent.links.length - 10);

        this._recent.articles = this._recent.articles.concat(event.detail.articles);
        this._recent.articles = Collection.distinctBy(this._recent.articles, m => m.id);
        this._recent.articles.sort((a, b) => a.publishDate > b.publishDate ? 1 : -1);
        
        if (this._recent.articles.length > 10)
            this._recent.articles.splice(0, this._recent.articles.length - 10);
    }

    async getAll() : Promise<RecentFrame> {
        await this._initializer;
        return this._recent;
    }

    
}