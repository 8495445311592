import { Routing } from "lib/Routing";
import { AlternateEmailsStore } from "features/manage/alternate-emails/AlternateEmailsStore";
import { DialogController } from "features/dialog";


Routing.instance.onChange(async route => {
    if (route.path[0] === "manage" && route.path[1] === "add-alternate") {

        Routing.instance.go("/manage/login");

        window.addEventListener("loaded", async () => {

            const token = decodeURIComponent(route.parameters.token);
            const result = await AlternateEmailsStore.instance.verify(token);

            const showError = async (message) => {
                await new DialogController().prompt({
                    caption: "Add alternate email",
                    message: message,
                    options: ["OK"]
                });
            }

            switch(result.status) {
                case "ok": 
                    await showError("Address is now verified.");
                    break;
                case "invalid-token":
                    await showError("Unable to verify email, token may be invalid. Please try again.");
                    break;
                case "not-found": 
                    await showError("Email not found when trying to validate, are you logged in with the right account?");
                    break;
                default:
                    await showError("Unknown error while trying to verify email address. Please try again.");
                    break;
            }

        });
     }
});
