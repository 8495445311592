import "./AnalyticsWidgetElement-style";
import * as cardsStyle from "../../../../styles/cards-module.less"
import * as widgetStyle from "../Widgets-module.less";
import { BaseWidget, PreferSize } from "../BaseWidget";

class AnalyticsWidgetElement extends BaseWidget {
    
    public get preferredWidth(): PreferSize {
        return "narrow";
    }
    
    connectedCallback() {
        super.connectedCallback();
        this.className += ` ${cardsStyle.cardContainer}`;
        this.innerHTML = this.view();
    }

    disconnectedCallback() {
        super.disconnectedCallback();
    }

    

    private view = () => `
        <header>
            <h3>Analytics</h3>
        </header>
        <div class="${cardsStyle.card} ${widgetStyle.card}" name=facts>
            <div class=fact>
                <h4>Links this month</h4>
                <div class=number>
                    <div>7</div>
                    <div class="trend down">${require("!!raw-loader!image/arrow-drop-down.svg")}3%</div>
                </div>
            </div>
            <div class=fact>
                <h4>Visits this month</h4>
                <div class=number>
                    <div>27</div>
                    <div class="trend up">${require("!!raw-loader!image/arrow-drop-up.svg")}8%</div>
                </div>       
            </div>
        </div> 
    `;
}

customElements.define("analytics-widget", AnalyticsWidgetElement);