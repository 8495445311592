import { Pipeline, Request } from "lib/HTTP";

import { Routing } from "lib/Routing";
import { BasePackageElement } from "features/packages/BasePackageElement";
import { Package } from "features/packages/Package";
import "./ImportDataElement-style";
import { UrlProvider } from "lib/UrlProvider";
import { StackStore } from "features/stacks/StackStore";
import { Feed } from "features/feeds";
import { AppContextStore } from "features/application-context/AppContextStore";

interface ImportPackage extends Package {
    formats: ImportFormat[];
}

interface ImportFormat {
    name: string;
    webDescription: string;
    priority: number;
    contentType: string;
}

interface FeedCategory {
    title: string;
}

interface ImportFeed {
    item1: FeedCategory;
    item2: Feed[];
}

interface ImportReport {
    newStacks: {[key: string]: number};
    existingStacks: {[key: string]: number};
    newFeeds: ImportFeed[];
    unsortedLinks: number;
    fileId: string;
    formatName: string;
    failed: boolean;
    anythingToImport: boolean;
    message?: string;
}

class ImportDataElement extends BasePackageElement {
    private urlProvider = new UrlProvider();
    protected slug = "import";    

    protected setupDOM() {
        const dropzone = this.querySelector(".dropzone");       
        const fileInput = this.querySelector("[type=file]") as HTMLInputElement;

        dropzone.addEventListener("click", event => fileInput.click());

        fileInput.addEventListener("change", async event => await this.upload(fileInput.files[0]));

        "drag dragstart dragend dragover dragenter dragleave drop".split(" ").forEach(e =>
            dropzone.addEventListener(e, event => {
                event.preventDefault();
                event.stopPropagation();
            }));

        "dragover dragenter".split(" ").forEach(e =>
            dropzone.addEventListener(e, event => {
                console.log("dragover");
                dropzone.setAttribute("dragover", "");
            }));

        "dragleave dragend drop".split(" ").forEach(e =>
            dropzone.addEventListener(e, async (event: DragEvent) => {
                dropzone.removeAttribute("dragover");
                
                if (event.dataTransfer && event.dataTransfer.files && event.dataTransfer.files.length === 1) {
                    await this.upload(event.dataTransfer.files[0]);
                }

            }));
    }

    private async upload(file: File){
        const data = new FormData()
        data.append("file", file)
       
        const url = `${this.urlProvider.root}/api/import/dryrun`;
        const response = await new Pipeline().fetch(url, Request.post.authenticate().setBody(data));
        const importReport = await response.json() as ImportReport;
        this.showDryrunResult(importReport);
    }

    private showDryrunResult(importReport: ImportReport) {
        const detailElement = this.querySelector(".detail") as HTMLElement;
        detailElement.innerHTML = this.dryrunView(importReport);

        const processButton = this.querySelector("[name=process]");

        if (processButton)
            processButton.addEventListener("click", async () => await this.startImport(importReport.fileId));
    }

    private async startImport(fileId: string) {
        const url = `${this.urlProvider.root}/api/import/process`;
        const response = await new Pipeline().fetch(url, Request.post.authenticate().setJSON({ file: fileId}));
        const importReport = await response.json();
        const detailElement = this.querySelector(".detail") as HTMLElement;
        detailElement.innerHTML = this.importDoneView(importReport);
        await StackStore.instance.fullRefresh();
    }

    private dryrunView = (report: ImportReport)  => `
        ${report.failed ? 
            `<div class=caption>${report.message}</div>` : 
                `<div class=caption>This will import</div>
                ${this.reportView(report)}
            `}

        <div class="actions">
            <a href="/apps/import" route>Start over</a>
            ${report.failed ? "" : `<button type=button class=power name=process>Start import</button>`}
        </div>
    `;

    private importDoneView = (report: ImportReport)  => `
        ${report.failed ? 
            `<div class=caption>${report.message}</div>` : 
            `<div class=caption>Import was successful</div>`}

        ${this.reportView(report)}
        <div class="actions">
            <a href="/apps/import" route>Start over</a>
        </div>
    `;

    private reportView = (report: ImportReport)  => `
        ${Object.keys(report.newStacks).length > 0 ? `
            <p>
                ${Object.keys(report.newStacks).length} new stack${Object.keys(report.newStacks).length === 1 ? "" : "s"}:
                <ul>
                    ${Object.keys(report.newStacks).map( key => `
                        <li>${key} (${report.newStacks[key]} link${report.newStacks[key] === 1 ? "" : "s"})</li>
                    `).join("")}
                </ul>
            </p>
        ` : ""}

        ${Object.keys(report.existingStacks).length > 0 ? `
            <p>
                ${Object.keys(report.existingStacks).length} links to existing stacks:
                <ul>
                    ${Object.keys(report.existingStacks).map( key => `
                        <li>${key} (${report.existingStacks[key]} link${report.existingStacks[key] === 1 ? "" : "s"})</li>
                    `).join("")}
                </ul>
            </p>
        ` : ""}

        ${report.unsortedLinks > 0 ? `
            <p>
                ${report.unsortedLinks} unsorted link${report.unsortedLinks === 1 ? "" : "s"}.
            </p>    
        ` : ""}

        ${report.newFeeds && report.newFeeds.length > 0 ? `
        <p>
            New feed subscriptions:
            ${report.newFeeds.map(importFeed => `
                <ul>
                    <li>
                        <b>${importFeed.item1.title}</b><br />
                        <ul>
                            ${importFeed.item2.map( feed => `<li>${feed.title || feed.url}</li>`).join("")}
                        </ul>
                    </li>
                </ul>
            `).join("")};
            
        </p>  
        ` : ""}

        ${!report.anythingToImport ? `
            <p>Nothing to import, you seem to have all items already.</p>    
        ` : ""}

    
       
    `;

    protected detailView = (thePackage: ImportPackage) => `
        <p>Supported file types:</p>
        <ul>
            ${thePackage.formats.map(format => this.formatView(format)).join("")}
        </ul>

        <p>Imported stacks and links will be merged with existing data.</p>
        
        <div class="dropzone needsclick">
            <input type=file style="display:none" class="needsclick" />
            DROP FILE HERE<br />
            (or click to browse)
        </div>
    `;      

    private formatView = (format: ImportFormat) => `
        <li>
            ${format.name} (${format.webDescription})
        </li>
    `;
}

customElements.define("import-data", ImportDataElement);

Routing.instance.onChange(async route => {
    if (route.path[0] === "apps" && route.path[1] === "import") {
        await AppContextStore.instance.present("", document.createElement("import-data"), "Bookmarks", "Apps", "Import");
    }
});